import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
import 'swiper/swiper.scss';
import { SwiperSlide } from 'swiper/react';
import { sliderProps } from '../../../types/types';
import SliderWrapper from '../SliderWrapper';
import ContentBox from '../../ContentBox/ContentBox';
import { AppContext } from '../../../contexts/AppContext/AppContext';
import { nodeModuleNameResolver } from 'typescript';
import ProductCard from '../../ProductCard/ProductCard';
import './SingleProductSlider.scss';
import LazyImage from "../../../hooks/LazyImage";

const SingleProductSlider: React.FunctionComponent<sliderProps> = observer(
    ({ slideData = [], ...props }) => {
        // const { homeStore } = React.useContext(AppContext);
        // const { setActiveTopBannerSlideIndex, homeTopBannerData } = homeStore;
        // console.log(slideData, 'single!212321312 ');
        const { rootStore } = useContext(AppContext);
        const { initData } = rootStore;

        const [currentSlideIndx, setCurrentSlideIdx] = useState(1);

        return (
            <div style={{ position: 'relative' }}>
                <SliderWrapper
                    // setActiveSlideIndex={setActiveTopBannerSlideIndex}
                    // effect={'fade'}
                    // overFlowVisible={true}
                    withDefaultNavigation={true}
                    withDefaultPagination={true}
                    setCurrentSlideIdx={setCurrentSlideIdx}
                    // speed={1000}
                    {...props}
                >
                    {slideData.map((data, i) => {
                        return (
                            <SwiperSlide
                                className="SingleProductSliderContentBox"
                                key={i}
                            >
                                <ProductCard
                                    // images={data.images}
                                    // badgeText={data.promoText}
                                    // title={data.name}
                                    // regularPrice={data.regularPrice}
                                    // disCountedPrice={data.price}
                                    // disCountRate={data.discountRate}
                                    onTheImageBadgeText={data.customText}
                                    // isOnSale={data.isOnSale}
                                    product={data}
                                ></ProductCard>
                                <div
                                    // style={{ display: 'none' }}
                                    className="SingleProductSecondImageWrapper"
                                >
                                    <LazyImage
                                        className="SingleProductSecondImg"
                                        imgUrl={
                                            initData &&
                                            initData.resourcePrefix + data.images[1].src}
                                   />
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </SliderWrapper>
                {slideData.map((data, i) => {
                    if (data.customText && i + 1 === currentSlideIndx) {
                        return (
                            <div key={i} className="productCardOnTheImageBadge">
                                {data.customText}
                            </div>
                        );
                    }
                })}
            </div>
        );
    }
);

export default SingleProductSlider;
