import i18n from 'i18next';
import translationEn from './en/translation.json';
import translationKo from './ko/translation.json';
import { initReactI18next } from 'react-i18next';

export const resources = {
    en: {
        translation: translationEn,
    },
    ko: {
        translation: translationKo,
    },
};

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: ['ko'],
    // lng: 'kr',
});

export default i18n;
