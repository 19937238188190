
//npay btn이 html 안에 있어야 동작합니다.
export const npayBtnClick = () => {
    
    const npayBtns: any = document.querySelectorAll(
        '.npay_btn_link.npay_btn_pay.btn_green'
    );
    
    if (npayBtns && npayBtns.length > 0) {
        npayBtns[npayBtns.length - 1].click();
    }
}

