import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {AppContext} from '../../contexts/AppContext/AppContext';
import {useHistory} from 'react-router-dom';
import {defaultElementProps, sliderContainerProps} from '../../types/types';
import BBOptionSelectContainer from '../BBOptionSelectContainer/BBOptionSelectContainer';
import {stringLineBreak} from '../../utils/commonUtils';
import NavigationSlider from '../../components/Sliders/NavigationSlider/NavigationSlider';
import SliderWrapper from '../../components/Sliders/SliderWrapper';
import {SwiperSlide} from 'swiper/react';
import SNSCard from '../../components/SNSCard/SNSCard';
import {BREAKPOINT_TBL} from '../../constants/constants';
import './BBThemeContainer.scss';

const BBThemeContainer: React.FunctionComponent<sliderContainerProps> = observer(
    ({
         className,
         boxTitle = `매 달 새로운 즐거움,
     테마 스토리`,
         slideCartegoryData = [
             {
                 imageUrl: undefined,
                 name: '2021',
                 link: 'linkUrl',
                 color: 'red',
                 slug: 'slug-1',
             },
             {
                 imageUrl: undefined,
                 name: '2022',
                 link: 'linkUrl',
                 color: 'red',
                 slug: 'slug-12',
             },
             {
                 imageUrl: undefined,
                 name: '2023',
                 link: 'linkUrl',
                 color: 'red',
                 slug: 'slug-123',
             },
             {
                 imageUrl: undefined,
                 name: '2024',
                 link: 'linkUrl',
                 color: 'red',
                 slug: 'slug-1234',
             },
             {
                 imageUrl: undefined,
                 name: '2025',
                 link: 'linkUrl',
                 color: 'red',
                 slug: 'slug-11234',
             },
         ],
         slideData = [
             {
                 img: '/img/m-img-port-01@2x.png',
                 month: '2021.02',
                 name: '꽃들의 전쟁',
             },
             {
                 img: '/img/m-img-port-01@2x.png',
                 month: '2021.03',
                 name: '풀들의 전쟁',
             },
             {
                 img: '/img/m-img-port-01@2x.png',
                 month: '2021.02',
                 name: '푸들의 전쟁',
             },
             {
                 img: '/img/m-img-port-01@2x.png',
                 month: '2021.02',
                 name: '나무들의 전쟁',
             },
             {
                 img: '/img/m-img-port-01@2x.png',
                 month: '2021.02',
                 name: '나무들의 전쟁',
             },
             {
                 img: '/img/m-img-port-01@2x.png',
                 month: '2021.02',
                 name: '나무들의 전쟁',
             },
             {
                 img: '/img/m-img-port-01@2x.png',
                 month: '2021.02',
                 name: '나무들의 전쟁',
             },
             {
                 img: '/img/m-img-port-01@2x.png',
                 month: '2021.02',
                 name: '나무들의 전쟁',
             },
             {
                 img: '/img/m-img-port-01@2x.png',
                 month: '2021.02',
                 name: '나무들의 전쟁',
             },
         ],
     }) => {
        const {rootStore, homeStore} = React.useContext(AppContext);
        // const { isDimBgOn, setIsDimBgOn } = BBThemeContainerStore;
        const {homeData} = homeStore;
        const [target, setTarget] = useState(slideCartegoryData[0]);
        const [dates, setDates] = useState<any>();
        const [sliderDataToShow, setSliderDataToShow] = useState(slideData);
        const {setIsDimBgOn} = rootStore;
        let history = useHistory();

        useEffect(() => {
            if (homeData) {
                // BBPageData.bbReviews;
                setSliderDataToShow(homeData.bbThemes.contents);
                // NavSlider 의 인터페이싱을 맞추어 준다
                setDates(
                    homeData.bbThemes.years.map((el) => {
                        return {slug: el, name: el};
                    })
                );
            }
        }, [homeData]);
        useEffect(() => {
            // console.log(dates, '??');
            if (dates) {
                setTarget(dates[0]);
            }
        }, [dates]);

        useEffect(() => {
            // console.log(target, 'target');

            if (target && homeData) {
                const filteredData = homeData.bbThemes.contents.filter(
                    (data) => data.year === target.slug
                );
                setSliderDataToShow(filteredData);
            }
        }, [target]);

        return (
            <div
                className={`BBThemeContainerWrapper  ${
                    className ? className : ''
                }`}
            >
                <div className="BBThemeContainerTitle">
                    {stringLineBreak(boxTitle)}
                </div>

                <div style={{width: '30px'}}>
                    <NavigationSlider
                        className="homeProductListsSlider"
                        slideData={dates}
                        overFlowVisible={true}
                        target={target}
                        numberOfSlides={sliderDataToShow.length}
                        spaceBetween={20}
                        changeTargetFunction={setTarget}
                    />
                </div>
                <div className="themeMinHeight">
                    <SliderWrapper
                        key={target}
                        target={target}
                        numberOfSlides={sliderDataToShow.length}
                        spaceBetween={16}
                        // withDefaultPagination={true}
                        withProgressBar={true}
                        withDefaultPagination
                        withNavigationWithProgress
                        // withThumbnailPagination={true}
                        observer
                        observeParents
                        slidesPerView={2}
                        overFlowVisible={true}
                        objectsForThumbNailPagination={slideData}
                        breakpoints={{
                            [BREAKPOINT_TBL]: {
                                slidesPerView: 4,
                            },
                        }}
                        threshold={25}
                        // width={150}
                    >
                        {sliderDataToShow.map((el, i) => {
                            return (
                                <SwiperSlide key={i}>
                                    <div
                                        onClick={() =>
                                            history.push(
                                                `/portfolio/${el.slug}`
                                            )
                                        }
                                        className="monthlyThemeCard"
                                    >
                                        <img
                                            className="monthlyThemeImg"
                                            src={el.image}
                                            alt=""
                                        />
                                        <div className="themeMonth">{`${el.year}.${el.month}`}</div>
                                        <div className="themeTitle">
                                            {el.title}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </SliderWrapper>
                </div>
            </div>
        );
    }
);

export default BBThemeContainer;

const slideData2 = [
    {
        img: '/img/m-img-port-02@3x.png',
        month: '2021.02',
        name: '꽃들의 전쟁',
    },
    {
        img: '/img/m-img-port-02@3x.png',
        month: '2021.03',
        name: '풀들의 전쟁',
    },
    {
        img: '/img/m-img-port-02@3x.png',
        month: '2021.02',
        name: '푸들의 전쟁',
    },
    {
        img: '/img/m-img-port-02@3x.png',
        month: '2021.02',
        name: '나무들의 전쟁',
    },
    {
        img: '/img/m-img-port-02@3x.png',
        month: '2021.02',
        name: '나무들의 전쟁',
    },
    {
        img: '/img/m-img-port-02@3x.png',
        month: '2021.02',
        name: '나무들의 전쟁',
    },
    {
        img: '/img/m-img-port-02@3x.png',
        month: '2021.02',
        name: '나무들의 전쟁',
    },
    {
        img: '/img/m-img-port-02@3x.png',
        month: '2021.02',
        name: '나무들의 전쟁',
    },
    {
        img: '/img/m-img-port-02@3x.png',
        month: '2021.02',
        name: '나무들의 전쟁',
    },
];
