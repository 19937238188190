import { observer } from 'mobx-react';
import './StaticModal.scss';
import React from 'react';
import { AppContext } from '../../../contexts/AppContext/AppContext';

const StaticModal = observer(() => {
    const { rootStore } = React.useContext(AppContext);
    const { isStaticModalOn, setIsStaticModalOn } = rootStore;

    return (
        <div
            className="StaticModalWrapper"
            style={{ display: isStaticModalOn ? 'block' : 'none' }}
        >
            StaticModal
            <button
                className="StaticModalCloseButton"
                onClick={() => {
                    setIsStaticModalOn(false);
                }}
            >
                close
            </button>
        </div>
    );
});

export default StaticModal;
