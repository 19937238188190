import { observer } from 'mobx-react';
import React from 'react';
import ServiceSummaryCard from '../../components/ServiceSummaryCard/ServiceSummaryCard';
import { defaultElementProps } from '../../types/types';
import './HomeServiceSummaryContainer.scss';

interface HomeServiceSummaryContainerProps extends defaultElementProps {
    imgUrl?: string;
    imgUrl2?: string;
    mainText?: string;
    subText?: string;
    buttonLink?: string;
    bgColor?: string;
    buttonText?: string;
}

const HomeServiceSummaryContainer: React.FunctionComponent<HomeServiceSummaryContainerProps> = observer(
    ({
        imgUrl = 'img/img22@2x.png',
        mainText = `매 달 필요한 제품만 
받아보세요!`,
        imgUrl2 = 'img/banner-img@3x.png',
        subText = '베이컨박스는 합리적인 가격으로 우리집 반려견에게 \n 필요한 제품만 받아보실 수 있습니다.',
        buttonLink = 'aa',
        className,
        bgColor = '#ed3421',
        buttonText = '자세히 보기',
        ...otherProps
    }) => {
        return (
            <div
                className={`HomeServiceSummaryContainer ${
                    className ? className : ''
                }`}
                {...otherProps}
            >
                <ServiceSummaryCard
                    imgUrl2={imgUrl2}
                    imgUrl={imgUrl}
                    mainText={mainText}
                    subText={subText}
                    buttonLink={buttonLink}
                    bgColor={bgColor}
                    buttonText={buttonText}
                />
            </div>
        );
    }
);

export default HomeServiceSummaryContainer;
