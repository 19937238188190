

import React, { useEffect, useState, useContext } from 'react';
import './ImageGallery.scss';

import SwiperCore, { Navigation, Pagination, A11y, Lazy, Thumbs } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import "swiper/components/thumbs/thumbs.min.css"
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/lazy/lazy.scss';
import { BREAKPOINT_DT } from '../../constants/constants';
import { AppContext } from '../../contexts/AppContext/AppContext';
import PreloadImage from 'react-preload-image';
import {NavNextArrow, NavPrevArrow} from '../../assets/Arrows'

SwiperCore.use([Navigation, Pagination, A11y, Lazy, Thumbs]);

const ImageGallery = ({
  data,
  sliderHeight,
  isImageThumbnail,
  alwaysSlide ,
  isHoverCursorEnabled
}) => {

  const { rootStore } = useContext(AppContext);
  const { initData } = rootStore;

  const [slideThreshold, setSlideThreshold] = useState(window.innerWidth > BREAKPOINT_DT? 100 : 0);
  
  const [mainSwiper , setMainSwiper] = useState(null);

  useEffect(()=>{

    if (mainSwiper && thumbsSwiper) {
      setTimeout(()=>{
        mainSwiper.update();
        thumbsSwiper.update();
      }, 500);
   
    }


  }, [data]);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
        
  <div className="galleryMain">
      {data && <Swiper 
        key={thumbsSwiper}
        lazy={false}
        preloadImages={false}
        spaceBetween={0}
        slidesPerView={1}
        pagination={{ 
          el: '.pagination-main-detail-images',
          type: 'bullets',
          clickable: true }}
        loop= {true}
        threshold={alwaysSlide ? 0 : slideThreshold}
        navigation = {{
                    nextEl: `.swiper-button-next-product`,
                    prevEl: `.swiper-button-prev-product`,
                }}
        thumbs={{ swiper: thumbsSwiper }}
        className="swiper-main-container"
        initialSlide={0}
        onSwiper={(swiper) => {
          setMainSwiper(swiper);
                    }}

        // navigation = {
        //   { 
        //     nextEl : '.hoverCursorNext',
        //     prevEl : '.hoverCursorPrev',

        //   }
        // }

      >
                 
                 
        {data.map((img, i) =>{
          return (
            // cover 인지 contain인지에 따라 cover 클래스를 다르게 주어야 함
            <SwiperSlide key ={`slide_${i}`} >
               <div 
               // data-background={`${initData ? initData.resourcePrefix : ''}${img.src}`}
               className={`imgSliderContent ${img.type === 'cover' ? 'cover' : ''} swiper-lazy`} 
               style={{
                 height: sliderHeight, 
                 backgroundPosition: 'center', 
                 backgroundRepeat: 'no-repeat',
                 backgroundImage: `url(${initData ? initData.resourcePrefix : ''}${img.src})`
                 }}
               
               > 
               {/* <div className="swiper-lazy-preloader swiper-lazy-preloader-bacon"></div> */}
              </div>  
            </SwiperSlide>
          );
        })}



        {/* {isHoverCursorEnabled&& 
                    <>
                      <div className="hoverCursorPrev"
                        style={{
                          cursor: 'url(/img/arrow_prev_b_72.svg)'
                        }}
                      ></div>    
                      <div className="hoverCursorNext"
                        style={{
                          cursor: 'url(/img/arrow_prev_b_72.svg)'
                        }}
                      ></div>    
                    </>
        }                 */}
        <div className="pagination-main-detail-images">

        </div>
      </Swiper>}
      <div className="galleryThumbs">
      {data && <Swiper 
      onSwiper={setThumbsSwiper} 
      loop={false} 
      spaceBetween={1} 
      slidesPerView={8} 
      freeMode={true} 
      watchSlidesVisibility={true} 
      watchSlidesProgress={true} 
    
     >
      
      {data.map((img, i) =>{

        return (
          // cover 인지 contain인지에 따라 cover 클래스를 다르게 주어야 함
          <SwiperSlide key ={`slide_thumb_${i}`} >
          <div 
          className="galleryThumbnail"
          style={{backgroundImage : `url(${initData ? initData.resourcePrefix : ''}${img.src}`}}>

          </div>
          </SwiperSlide> 
      )})}


      </Swiper>}
      </div>
      <>
                        <div className="swiper-button-prev-product">

                        </div>
                        <div className="swiper-button-next-product">

                        </div>
                    </>
    </div>
          



  );
};

export default ImageGallery;