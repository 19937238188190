import React, {
    FunctionComponent,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import './Search.scss';
import { CloseIcon, HomeIcon } from '../../assets/Arrows';
import searchIcon from '../../assets/m-icon-gnb-btn-search.svg';
import CollectionProductsContainer from '../../containers/CollectionProductsContainer/CollectionProductsContainer';
import { fetchAndReceiveJSON } from '../../utils/ajaxUtils';
import { SEARCH_URL } from '../../constants/constants';
import { AppContext } from '../../contexts/AppContext/AppContext';
import homeIcon from '../../assets/m-icon-gnb-btn-home.svg';
import closeIcon from '../../assets/btn-gnb-popup-close.svg';
const Search: FunctionComponent<{
    keyword: string;
    topSearchedList?: any;
}> = ({ keyword, topSearchedList }) => {
    const { rootStore } = React.useContext(AppContext);
    const { showSearch, setShowSearch, initData } = rootStore;
    const [searchResult, setSearchResult] = useState<any>(null);
    const [searchText, setSearchText] = useState<string>('');

    const fetchData = async (keyword) => {
        const res = await fetchAndReceiveJSON({
            url: `${SEARCH_URL}?keyword=${keyword}`,
        });


        if (res) {
            const productIds = [];

            for (let i=0; i<res.data.length; i++) {
                const product = res.data[i];
    
                if (i <= 2 && product) {
                    productIds.push(product.id);
                }else {
                    break;
                }
    
              }
    
    
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                productList: productIds,
                searchKeyword : keyword
            });
    
    
            window.dataLayer.push({
                event: 'dataLayer.append.search',
                
            });
        }


        setSearchResult(res);
        document.getElementById('searchInput2').blur();
    };

    const doSearch = (keyword?) => {
        if (keyword) {
            setSearchText(keyword);
        } else {
            keyword = searchText;
        }

        if (keyword.length < 1) {
            alert('검색어를 입력해 주세요.');
            return;
        }

        fetchData(keyword);
    };

    const history = useHistory();

    return (
        <div
            className={`productSearchWrapper ${showSearch ? ' show' : ''} ${
                searchResult !== null ? 'resultBg' : ''
            }`}
        >
            <div className="contentArea">
                <div className="topArea">
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setShowSearch(false);
                            //closeCall();
                        }}
                    >
                        <img
                            className="homeIcon mobileHide"
                            src={homeIcon}
                            // src="../m-icon-gnb-btn-home.svg"
                            alt=""
                        />

                        <div className="backBtn mobileOnly">
                            <img
                                className="backBtn"
                                src="/img/icon/m-icon-gnb-btn-back.svg"
                                alt=""
                                onClick={() => {
                                    history.goBack();
                                }}
                            />
                        </div>
                    </div>

                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setShowSearch(false);
                            setSearchResult(null);
                            setSearchText('');
                            //closeCall();
                        }}
                    >
                        <img className="closeIcon" src={closeIcon} alt="" />
                        {/* <div className="dt">
                <BigBrownCloseIcon />
              </div>
              <div className="mo">
                <MediumBrownCloseIcon />
              </div> */}
                    </div>
                </div>

                <div className="bodyArea">
                    <div className="searchArea">
                        <form
                            action=""
                            onSubmit={(e) => {
                                e.preventDefault();

                                doSearch();
                                // if(searchWord.length < 1){
                                //   alert('검색어를 입력해 주세요.');
                                //   return;
                                // }
                                // searchCall();
                            }}
                            style={{ width: '100%' }}
                        >
                            {/* {alert('1')} */}
                            <input
                                id="searchInput2"
                                type="text"
                                value={searchText}
                                className="searchInput"
                                placeholder="검색어를 입력해 주세요."
                                onChange={(e) => {
                                    setSearchText(e.target.value.trim());
                                }}
                                onFocus={(e) => {
                                    e.target.placeholder = '';
                                }}
                                onBlur={(e) => {
                                    e.target.placeholder =
                                        '검색어를 입력해 주세요.';
                                }}
                            />
                        </form>

                        <div
                            className="searchButton"
                            onClick={() => {
                                doSearch();
                            }}
                        >
                            <img
                                className="searchIcon"
                                onClick={() => {
                                    setShowSearch(true);
                                }}
                                src={searchIcon}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="searchContent">
                {!searchResult && topSearchedList && (
                    <div className="popularArea">
                        <div className="popularTitle">인기 검색어</div>
                        <div className="popularContents">
                            {topSearchedList.map((keyword, idx) => {
                                return (
                                    <div
                                        key={'keyword' + idx}
                                        className="popularItem"
                                        onClick={() => {
                                            doSearch(keyword);
                                        }}
                                    >
                                        {keyword}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                {searchResult && (
                    <div className="searchResult">
                        {searchResult &&
                        searchResult.pageinfo &&
                        searchResult.pageinfo.totalCnt &&
                        searchResult.pageinfo.totalCnt !== 0 ? (
                            <CollectionProductsContainer
                                collectionData={{
                                    contents: {
                                        products: searchResult.data,
                                        subCollections: [],
                                    },
                                }}
                                useInSearch={true}
                                // currentSelectedCategory={
                                //     currentSelectedCategory
                                // }
                                // setCurrentSelectedCategory={
                                //     setCurrentSelectedCategory
                                // }
                                //productsList={productsList}
                            />
                        ) : (
                            <div className="noSearchResultWrapper">
                                <div className="title">
                                    검색 결과가 없습니다.
                                </div>
                                <div className="desc">
                                    다른검색어를 입력하시거나 <br />
                                    철자, 띄어쓰기를 다시 확인해주세요!
                                </div>
                                <div className="popularArea">
                                    <div className="popularTitle">
                                        인기 검색어
                                    </div>
                                    <div className="popularContents">
                                        {topSearchedList.map((keyword) => {
                                            return (
                                                <div
                                                    className="popularItem"
                                                    onClick={() => {
                                                        doSearch(keyword);
                                                    }}
                                                >
                                                    {keyword}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {!searchResult &&
                    initData &&
                    initData.eventText &&
                    initData.eventText.length > 0 && (
                        <div
                            onClick={() => {
                                history.push(initData.eventText[0].link);
                                setShowSearch(false);
                            }}
                            className="eventBanner"
                            style={{
                                backgroundColor: `${initData.eventText[0].bgColor}`,
                                color: `${initData.eventText[0].fontColor}`,
                            }}
                        >
                            {initData.eventText[0].text}
                        </div>
                    )}
            </div>
        </div>
    );
};

export default Search;
