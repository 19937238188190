import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import SingleProductSlider from '../../components/Sliders/SingleProductSlider/SingleProductSlider';
import { defaultElementProps, sliderContainerProps } from '../../types/types';
import { stringLineBreak } from '../../utils/commonUtils';
import './HomeSingleProductHighlightContainer.scss';

interface IHomeSingleProductHighlightContainer extends sliderContainerProps {
    buttonText?: string;
    buttonLink?: string;
}

const HomeSingleProductHighlightContainer: React.FunctionComponent<IHomeSingleProductHighlightContainer> =
    observer(
        ({
            className,
            slideData,
            title,
            buttonText,
            buttonLink,
            ...otherProps
        }) => {
            const history = useHistory();
            return (
                <div
                    className={`homeSingleProductHighlightContainer ${
                        className ? className : ''
                    }`}
                >
                    <div className="homeSingleProductHighlightHeadText">
                        {stringLineBreak(title)}
                    </div>
                    <SingleProductSlider
                        className="homeSingleProductSlider"
                        spaceBetween={16}
                        slideData={slideData}
                    />
                    <div
                        onClick={() => history.push(buttonLink)}
                        className="goToProductListBtn"
                    >
                        {buttonText}
                    </div>
                </div>
            );
        }
    );

export default HomeSingleProductHighlightContainer;
