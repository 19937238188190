
export const UpArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path fill="none" fillRule="evenodd" stroke="#C0A691" strokeLinecap="round" strokeLinejoin="round" d="M6 16L12.5 9 19 16"/>
    </svg>
  );     
};



export const DownArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path fill="none" fillRule="evenodd" stroke="#C0A691" strokeLinecap="round" strokeLinejoin="round" d="M6 16L12.5 9 19 16" transform="matrix(1 0 0 -1 0 25)"/>
    </svg>
  );     
};

export const DownArrowReview =() =>{

  return (

    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path d="M0 0H16V16H0z" transform="translate(-296 -790) translate(48 723) translate(214 67) translate(34)"/>
                <path stroke="#C0A691" strokeLinecap="round" strokeLinejoin="round" d="M4 11L8 6 12 11" transform="translate(-296 -790) translate(48 723) translate(214 67) translate(34) matrix(1 0 0 -1 0 17)"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export const UpArrowReview =() =>{

  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path d="M0 0H16V16H0z" transform="translate(-296 -1189) translate(48 855) translate(248 334)"/>
              <path stroke="#C0A691" strokeLinecap="round" strokeLinejoin="round" d="M4 11L8 6 12 11" transform="translate(-296 -1189) translate(48 855) translate(248 334)"/>
            </g>
          </g>
        </g>
      </g>
    </svg>


  );

};

export const BackwordArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" style={{cursor: 'pointer'}}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H32V32H0z"/>
        <path fill="#604448" d="M6 15H26V16.5H6z"/>
        <path stroke="#604448" strokeWidth="1.5" d="M3 20L10 12 17 20" transform="rotate(-90 10 16)"/>
      </g>
    </svg>
  );     
};

export const PreveSlideArrow = () =>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path fill="none" fillRule="evenodd" stroke="#604448" strokeWidth="1.5" d="M5 13L10 7 15 13" transform="matrix(0 1 1 0 0 0)"/>
    </svg>
  );
};



export const NextSlideArrow = () =>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path fill="none" fillRule="evenodd" stroke="#604448" strokeWidth="1.5" d="M5 13L10 7 15 13" transform="rotate(90 10 10)"/>
    </svg>
  );



};

export const SmallLinkRightArrow = () =>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <g fill="none" fill-rule="evenodd">
            <g>
                <g>
                    <g>
                        <path d="M0 0H14V14H0z" transform="translate(-1283.000000, -440.000000) translate(1185.000000, 437.000000) translate(98.000000, 3.000000)"/>
                        <path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.313" d="M2.625 9.625L7 4.375 11.375 9.625" transform="translate(-1283.000000, -440.000000) translate(1185.000000, 437.000000) translate(98.000000, 3.000000) translate(7.000000, 7.000000) rotate(90.000000) translate(-7.000000, -7.000000)"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>



  );
};

export const ReviewStar = () =>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13">
        <g fill="none" fill-rule="evenodd">
            <g fill="#101010">
                <g>
                    <g>
                        <path d="M7 10.5L2.886 12.663 3.671 8.082 0.343 4.837 4.943 4.168 7 0 9.057 4.168 13.657 4.837 10.329 8.082 11.114 12.663z" transform="translate(-1185.000000, -441.000000) translate(1185.000000, 437.000000) translate(0.000000, 4.000000)"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>



  );
};



export const SmallLinkRightArrowColorChagne = ({color = '#604448' }) =>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 10 10">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H10V10H0z"/>
        <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth=".7" d="M2 7.5L5.5 3.5 9 7.5" transform="matrix(0 -1 -1 0 11 11)"/>
      </g>
    </svg>
  
  
  );
};


export const DownArrowWithCircleDt = () =>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <circle cx="8" cy="8" r="8" fill="#EFEBE2"/>
        <path stroke="#604448" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".933" d="M4.667 10L8 6 11.333 10" transform="matrix(1 0 0 -1 0 16)"/>
      </g>
    </svg>


  );
};




export const DownArrowWithCircle = () =>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <g fill="none" fillRule="evenodd">
        <circle cx="6" cy="6" r="6" fill="#EFEBE2"/>
        <path stroke="#604448" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".7" d="M3.5 7.5L6 4.5 8.5 7.5" transform="matrix(1 0 0 -1 0 12)"/>
      </g>
    </svg>


  );
};


export const UpArrowWithCircleDt = () =>{
  return (
       
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-488 -1155) translate(210 1154) translate(246) translate(32 1)">
                <circle cx="8" cy="8" r="8" fill="#EFEBE2"/>
                <path stroke="#604448" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.244" d="M4.667 10L8 6 11.333 10"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};






export const UpArrowWithCircle = () =>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <g fill="none" fillRule="evenodd">
        <circle cx="6" cy="6" r="6" fill="#EFEBE2"/>
        <path stroke="#604448" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".7" d="M3.5 7.5L6 4.5 8.5 7.5"/>
      </g>
    </svg>


  );
};

export const RightArrowInfo = () =>{
  return (

    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H12V12H0z"/>
        <path stroke="#604448" d="M2 9L6 4 10 9" transform="rotate(90 6 6.5)"/>
      </g>
    </svg>

  );
};

export const LongRightArrow = () =>{
  return (
    <svg width="21px" height="13px" viewBox="0 0 21 13" version="1.1">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="M_05_my_account_order_list" transform="translate(-290.000000, -323.000000)" stroke="#C0A691">
          <g id="Group-3" transform="translate(48.000000, 280.000000)">
            <g id="Group" transform="translate(240.000000, 37.000000)">
              <polyline id="Path-Copy" transform="translate(19.500000, 12.500000) rotate(90.000000) translate(-19.500000, -12.500000) " points="14 16 19.5 9 25 16"/>
              <line x1="21.5" y1="12.5" x2="2.5" y2="12.5" id="Line-Copy" strokeLinecap="square"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};




export const PageBeforeArrow = () =>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H24V24H0z" transform="translate(-372 -1532) translate(372 1532)"/>
            <g stroke="#604448">
              <path strokeLinecap="square" d="M0.5 3L13.5 3" transform="translate(-372 -1532) translate(372 1532) matrix(-1 0 0 1 19 9)"/>
              <path d="M9 5L12 1 15 5" transform="translate(-372 -1532) translate(372 1532) matrix(-1 0 0 1 19 9) rotate(90 12 3)"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};




export const PageAfterArrow = () =>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path d="M0 0H24V24H0z" transform="translate(-564 -1532) translate(372 1532) translate(192)"/>
              <g stroke="#604448">
                <path strokeLinecap="square" d="M0.5 3L13.5 3" transform="translate(-564 -1532) translate(372 1532) translate(192) translate(5 9)"/>
                <path d="M9 5L12 1 15 5" transform="translate(-564 -1532) translate(372 1532) translate(192) translate(5 9) rotate(90 12 3)"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};


export const ReviewNextArrow = () =>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H56V56H0z" opacity=".202" transform="translate(-876 -426) translate(876 426)"/>
            <path stroke="#604448" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".778" d="M14.778 35L28.389 21.778 42 35" transform="translate(-876 -426) translate(876 426) rotate(90 28.389 28.389)"/>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const ReviewBeforeArrow = () =>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H56V56H0z" opacity=".202" transform="translate(-348 -426) translate(348 426)"/>
            <path stroke="#604448" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".778" d="M14.778 35L28.389 21.778 42 35" transform="translate(-348 -426) translate(348 426) matrix(0 1 1 0 0 0)"/>
          </g>
        </g>
      </g>
    </svg>
  );
};




export const ReviewImagesBeforeArrow = () =>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="40" viewBox="0 0 32 40">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path fill="#604448" d="M0 0H32V40H0z" opacity=".8" transform="translate(-120 -582) translate(120 546) translate(0 36)"/>
              <path stroke="#FFFFF5" strokeLinecap="round" strokeLinejoin="round" d="M9 24L16 17 23 24" transform="translate(-120 -582) translate(120 546) translate(0 36) matrix(0 1 1 0 -4.5 4.5)"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};



export const ReviewImagesAfterArrow = () =>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="40" viewBox="0 0 32 40">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path fill="#604448" d="M0 0H32V40H0z" opacity=".8" transform="translate(-800 -582) translate(120 546) matrix(-1 0 0 1 712 36)"/>
              <path stroke="#FFFFF5" strokeLinecap="round" strokeLinejoin="round" d="M9 24L16 17 23 24" transform="translate(-800 -582) translate(120 546) matrix(-1 0 0 1 712 36) matrix(0 1 1 0 -4.5 4.5)"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};


export const DownLoadArrow = () =>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 12 17">
      <g fill="none" fillRule="evenodd" strokeLinecap="round">
        <g stroke="#FFFFF5">
          <g>
            <g>
              <path d="M6 0L6 10.916M.5 14.848L11.5 14.848" transform="translate(-316 -376) translate(0 360) translate(316 17)"/>
              <path strokeLinejoin="round" d="M2 11.348L6 7.348 10 11.348" transform="translate(-316 -376) translate(0 360) translate(316 17) matrix(1 0 0 -1 0 18.695)"/>
            </g>
          </g>
        </g>
      </g>
    </svg>


  );

};


export const NavNextArrow = () =>{
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <path d="M0 0H40V40H0z" transform="translate(-1058.000000, -553.000000) translate(1058.000000, 553.000000)"/>
                <path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.333" d="M14.5 8.5l11.408 10.53c.812.75.862 2.015.113 2.827-.036.039-.074.076-.113.113L14.5 32.5h0" transform="translate(-1058.000000, -553.000000) translate(1058.000000, 553.000000)"/>
            </g>
        </g>
    </g>
</svg>
  );

};

export const NavPrevArrow = () =>{
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <path d="M0 0H40V40H0z" transform="translate(-64.000000, -435.000000) translate(64.000000, 435.000000)"/>
                <path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.333" d="M25 8L14.414 18.586c-.78.78-.78 2.047 0 2.828L25 32h0" transform="translate(-64.000000, -435.000000) translate(64.000000, 435.000000)"/>
            </g>
        </g>
    </g>
</svg>

  );

};

export const NavNextArrow16P = () =>{
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <path d="M0 0H16V16H0z" transform="translate(-1715.000000, -93.000000) translate(1715.000000, 93.000000)"/>
                <path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 11L8 5 13 11" transform="translate(-1715.000000, -93.000000) translate(1715.000000, 93.000000) translate(8.000000, 8.000000) rotate(90.000000) translate(-8.000000, -8.000000)"/>
            </g>
        </g>
    </g>
</svg>


  );

  

};


export const CloseIcon = (
  {size = 40 }
) =>{

return (
  
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <path d="M0 0H40V40H0z" transform="translate(-1800.000000, -32.000000) translate(1800.000000, 32.000000)"/>
                <path fill="#000" stroke="#101010" stroke-width="1.333" d="M29.742 29.128c.128.133.19.304.187.475-.003.17-.071.34-.204.468-.132.128-.304.19-.475.187-.17-.003-.34-.071-.467-.204h0L10.258 10.872c-.128-.133-.19-.304-.187-.475.003-.17.071-.34.204-.468.132-.128.304-.19.475-.187.17.003.34.071.467.204h0z" transform="translate(-1800.000000, -32.000000) translate(1800.000000, 32.000000)"/>
                <path fill="#000" stroke="#101010" stroke-width="1.333" d="M10.258 29.128c-.128.133-.19.304-.187.475.003.17.071.34.204.468.132.128.304.19.475.187.17-.003.34-.071.467-.204h0l18.525-19.182c.128-.133.19-.304.187-.475-.003-.17-.071-.34-.204-.468-.132-.128-.304-.19-.475-.187-.17.003-.34.071-.467.204h0z" transform="translate(-1800.000000, -32.000000) translate(1800.000000, 32.000000)"/>
            </g>
        </g>
    </g>
</svg>

);



};

export const HomeIcon = (
  {size = 40 }
) =>{

return (
  
<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <path d="M0 0H40V40H0z" transform="translate(-80.000000, -32.000000) translate(80.000000, 32.000000)"/>
                <path stroke="#000" stroke-width="2.267" d="M30.667 16.013v12.31c0 2.03-2.123 3.677-4.741 3.677H14.074c-2.618 0-4.74-1.647-4.74-3.678V16" transform="translate(-80.000000, -32.000000) translate(80.000000, 32.000000)"/>
                <path stroke="#101010" stroke-linecap="round" stroke-width="2.267" d="M17.333 23.333L22.667 23.333" transform="translate(-80.000000, -32.000000) translate(80.000000, 32.000000)"/>
                <path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.267" d="M5.333 20L20 6.667 34.667 20" transform="translate(-80.000000, -32.000000) translate(80.000000, 32.000000)"/>
            </g>
        </g>
    </g>
</svg>

);



};

export const SearchIcon = (
  {size = 30 }
) =>{

return (
<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g transform="translate(-229 -13) translate(229 13)">
                <path d="M0 0H30V30H0z"/>
                <path stroke="#101010" stroke-linecap="round" stroke-width="1.7" d="M19.641 19.221L24.5 24.5"/>
                <circle cx="14.5" cy="13.5" r="7.65" stroke="#101010" stroke-width="1.7"/>
            </g>
        </g>
    </g>
</svg>


);



};


