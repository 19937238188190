import { CUSTOM_ALERT_STATUS, LOGOUT_URL } from '../constants/constants';
import { RESPONSE_STATUS_FAIL_LOGIN } from '../constants/errorConstants';
import { moveToLink } from './commonUtils';

export const fetchAndReceiveJSON = async ({
    url,
    method = 'GET',
    body = {},
}) => {
    if (method === 'GET') {
        try {
            const res = await fetch(url, {
                method: 'GET',
                credentials: 'include',
            });
            if ((await res.status) !== 200) {
                const resBody = await res.json();
                alert(resBody.message);
                return false;
            }

            const data = await res.json();
            return data.data;
        } catch (err) {
            console.error(err);
            return false;
        }
    } else if (method === 'POST') {
        try {
            const res = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(body),
            });

            if (res.status !== 200) {

                try {
                    let resBody = await res.json();

                    // console.log(resBody);

                    if (isCustomAlert(resBody.data)) {
                        //custom alert
                        return resBody.data;
                    } else {
                        //system alert
                        alert(`${resBody.message}`);
                        return false;
                    }

                } catch (error) {
                    alert(`${res.status} ${res.statusText}`);
                    return false;
                }
            }

            const data = await res.json();
            return data.data;
        } catch (err) {
            console.error(err);
            return false;
        }
    }
};

const isCustomAlert = (response) => {

    return CUSTOM_ALERT_STATUS.includes(response);
}

export const logout = async () => {
    const res = await fetchAndReceiveJSON({ url: LOGOUT_URL });

    if (res) {
        moveToLink('/', null);
    } else {
        alert('서버와의 통신에 문제가 생겼습니다. 잠시후 다시 시도해 주세요');
    }
};
