import { makeAutoObservable } from 'mobx';

class TopBannerStore {
    topBannerData = null;

    constructor() {
        makeAutoObservable(this);
    }

    setTopBannerdata = (topBannerData) => {
        this.topBannerData = topBannerData;
    }
}

export default TopBannerStore;
