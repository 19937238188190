import { observer } from 'mobx-react';
import React from 'react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import { defaultElementProps } from '../../types/types';
import { moveToLink, stringLineBreak } from '../../utils/commonUtils';
import ContentBox from '../ContentBox/ContentBox';
import './HomeTopTextArea.scss';
import MIconLinkArrow10 from '../../assets/m-icon-link-arrow-10-black.svg';

const HomeTopTextArea: React.FunctionComponent<defaultElementProps> = observer(
    ({ className, ...otherProps }) => {
        const { homeStore } = React.useContext(AppContext);
        const { homeData, activeTopBannerSlideIndex } = homeStore;

        const { homeBanner } = homeData;
        const activeData = homeBanner
            ? homeBanner[activeTopBannerSlideIndex]
            : {};

        return (
            <ContentBox
                className={`homeTopTextAreaWrapper ${className}`}
                {...otherProps}
            >
                {activeData.text && (
                    <div
                        className="homeTopTextContent"
                        key={activeTopBannerSlideIndex}
                    >
                        <div className="homeTopText">
                            {stringLineBreak(activeData.text)}
                        </div>
                        <div className="homeTopSubText">
                            {stringLineBreak(activeData.description)}
                        </div>

                        <div
                            className={`homeTopLink ${
                                !activeData.cta?.btnLink ? 'noPointer' : ''
                            }`}
                            onClick={
                                activeData.cta?.btnLink
                                    ? (e) =>
                                          moveToLink(activeData.cta.btnLink, e)
                                    : undefined
                            }
                        >
                            {activeData.cta?.btnLink && (
                                <>
                                    {activeData.cta.btnText}
                                    <img src={MIconLinkArrow10} alt="" />
                                </>
                            )}
                        </div>
                    </div>
                )}
            </ContentBox>
        );
    }
);

export default HomeTopTextArea;
