import React, {
    FunctionComponent, useEffect
} from 'react';
import { useHistory } from 'react-router-dom';
import { SmallLinkRightArrow } from '../../assets/Arrows.jsx';
import { AppContext } from '../../contexts/AppContext/AppContext';
import {
    checkCSSHasSafeArea,
    getSrcUrl,
    // moveToLink,
    isEventPriceProduct
} from '../../utils/commonUtils';
import BBChildItemBox from '../BBChildItemBox/BBChildItemBox';
import MiniCart from '../MiniCart/MiniCart';
import ProductMiniCartPopup from '../MiniCart/ProductMiniCartPopup';
import ProductEventBanner from '../ProductEventBanner/ProductEventBanner';
import './PurchaseMenu.scss';

const PurchaseMenu: FunctionComponent<{
    productData: any;
    isDesktop?: any;
    isInProductpage?: any;
    isInPopUp?: any;
    productDescription?: any;
    eventBannerData?: any;
}> = ({
    productData,
    isDesktop,
    isInPopUp,
    productDescription,
    eventBannerData,
}) => {

        let history = useHistory();

        const { rootStore } = React.useContext(AppContext);
        const { initData } = rootStore;

        useEffect(() => {
            if (isInPopUp) {
                const handleNotch = () => {
                    if (checkCSSHasSafeArea()) {
                        //buttonWrapperRef.current.style.paddingBottom = '20px';
                    }
                };
                handleNotch();
                window.addEventListener('scroll', handleNotch);

                return () => {
                    window.removeEventListener('scroll', handleNotch);
                };
            }
        }, []);

        if (!productData) {
            return <></>;
        }

        let displayPriceWithoutWon = productData.product.displayPriceWithoutWon;

        // const isEventPrice = !initData.isWelcomeSpecialPriceCouponUsed && isEventPriceProduct(productData.product);
        // if (isEventPrice) {
        //     displayPriceWithoutWon = '100';
        //     productData.product.isOnSale = true;
        // }

        return (
            <div
                className="purchaseMenu-wrapper"
                style={{
                    opacity: productData ? 1 : 0,
                    transition: 'opacity 500ms',
                }}
            >
                <ProductEventBanner
                    className="product-page-banner-dt"
                    productDescription={productDescription}
                />

                <div className="purchase-content">
                    {productData.product.paths && (
                        <div className="pathArea">
                            {productData.product.paths.map((el, i) => (
                                <div key={el.name}>
                                    <span
                                        className="pathName"
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={(e) => {
                                            history.push(`/collection/${el.slug}`);
                                        }}
                                    >
                                        {el.name}
                                    </span>
                                    {i < productData.product.paths.length - 1 && (
                                        <img
                                            className="pathArrow"
                                            src="/img/mo-icon-review-arrow.svg"
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    )}

                    <>
                        <div className="purchaseMenu-name">
                            {productData.product.sub_title}
                        </div>
                        <div className="purchaseMenu-mainText">
                            {productData.product.productBrand && <div className="">
                                [{productData.product.productBrand}]
                            </div>}
                            {productData.product.name}
                        </div>

                        {/* 판매 가능 */}
                        {productData.product.stockStatus !== 'outofstock' ? (
                            <>
                                {/*{isEventPrice && <div className="priceDesc">신규 가입 혜택가</div>}*/}
                                <div
                                    style={{
                                        marginBottom:
                                            productData &&
                                                productData.product.type === 'simple'
                                                ? 0
                                                : '',
                                    }}
                                    className="purchaseMenu-price"
                                >
                                    <span className={`price-no-sale`}>
                                        {displayPriceWithoutWon}
                                    </span>
                                    <span className={`won`}>원</span>

                                    {productData.product.isOnSale && (
                                        <span className="discount-rate">
                                            {productData.product.discountRate.slice(
                                                2,
                                                productData.product.discountRate
                                                    .length - 1
                                            )}
                                        </span>
                                    )}
                                </div>
                                {productData.product.isOnSale && (
                                    <div className="price-before">
                                        {' '}
                                        {productData.product.regularPrice}
                                    </div>
                                )}
                            </>

                        ) : (
                            <>
                                {!productData.product.saleInFuture && (
                                    <div className="outofstock"> 일시품절</div>
                                )}
                            </>
                        )}

                        <div
                            style={{ cursor: 'pointer' }}
                            className="additional-info"
                            onClick={(e) => {
                                // if (productData && moveToOtherPage) {
                                //   moveToLink(`/store/${productData.product.slug}#review`, e);
                                // } else {
                                //   location.href = '#review';
                                // }

                                let target = document.getElementById('review');
                                if (target) {
                                    window.scrollTo({
                                        behavior: 'smooth',
                                        left: 0,
                                        top: target.offsetTop,
                                    });
                                }
                            }}
                        >
                            <div className="review-info">
                                {/* <ReviewStar /> <span className="count">1,000{productData.product.rating_count}</span><span className="text">개의 리뷰</span>  */}

                                <span className="text">리뷰 보기</span>
                                <SmallLinkRightArrow />
                            </div>
                            <div className="benefit-info">
                                {productData.isFreeShipping && (
                                    <span className="info-item">무료배송</span>
                                )}
                                {productData.shippingAvailableToday && (
                                    <span className="info-item">당일배송</span>
                                )}
                            </div>
                        </div>
                    </>
                    {/* )} */}

                    {/* 판매예정 */}
                    {productData.product.saleInFuture ? (
                        <div className="InfoBoxArea">
                            <BBChildItemBox
                                type={'baconbox'}
                                saleDate={productData.product.bbMetaSaleStartDate}
                            />
                        </div>
                    ) : (
                        //mini cart 영역
                        <div className="miniCart">
                            <MiniCart
                                isShowNpayBtn={isDesktop}
                                isProductInfo={true}
                            />
                        </div>
                    )}

                    {/* collectionEvent banner */}
                    {eventBannerData && (
                        <div className="collectionEvent"
                            onClick={() => {
                                history.push(
                                    eventBannerData.link
                                );
                            }}
                        >
                            <img src={getSrcUrl(eventBannerData.img)} width="100%" />
                        </div>
                    )}

                    {!isDesktop && <ProductMiniCartPopup />}
                </div>
            </div>
        );
    };

export default PurchaseMenu;
