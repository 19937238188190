import React, {useEffect, useRef, useState} from "react"
import "./RestockingModal.scss"
import CloseIcon from "../../../img/gnb_close_32.svg";
import {AppContext} from "../../contexts/AppContext/AppContext";
import {observer} from "mobx-react";
import {autoHypenPhone, fetchAndReceiveJSON, phone_format, validatePhonenum} from "../../utils/commonUtils";
import {BACON_API_DOMAIN} from "../../constants/constants";

const RestockingModal = observer(() => {
    const {modalStore} = React.useContext(AppContext);
    const {restockingModalState, setIsRestockingModalVisible, setIsRestockingModalProductId} = modalStore
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('')

    useEffect(() => {
        const nameValue = name.trim();
        const phoneValue = phone.trim();
        const restockingButton = document.getElementById("restocking-button")
        if (nameValue.trim().length === 0 || phoneValue.trim().length === 0 || !validatePhonenum(phoneValue)) {
            restockingButton?.classList.remove('active')
        } else {
            restockingButton?.classList.add('active')
        }
    }, [name, phone])

    if (!restockingModalState.isVisible) {
        return null
    }

    const submit = () => {
        const nameValue = name.trim();
        const phoneValue = phone.trim();

        if (nameValue.trim().length === 0) {
            return window.alert('알림을 받으실 정보를 정확히 입력해주세요.')
        }
        if (phoneValue.trim().length === 0) {
            return window.alert('알림을 받으실 정보를 정확히 입력해주세요.')
        }

        if (!validatePhonenum(phoneValue)) {
            return window.alert('SMS 알림을 받을 수 있는 형태의 휴대전화 번호를 정확히 입력해주세요.')
        }


        fetchAndReceiveJSON({
            url: `${BACON_API_DOMAIN}/api/wait/update`,
            method: "POST",
            body: {productId: restockingModalState.productId, destName: nameValue, destPhone: phoneValue}
        }).then(res => {
            if (res) {
                window.alert('잘 등록되었어요. 제품의 재입고 후 판매가 재개되면, 입력하신 연락처로 알려드릴게요.')
                setName('')
                setPhone('')
                setIsRestockingModalProductId(null);
                setIsRestockingModalVisible(false);
            } else {
                window.alert('등록을 실패했습니다.')
            }
        }).catch(err => console.log(err))
    }


    return (
        <>
            <div className="modal-page-bg" onClick={() => setIsRestockingModalVisible(false)}/>
            <div className="restocking-modal-wrapper">
                <div className="restocking-modal-close-button">
                    <img src={"/img/btn-gnb-popup-close.svg"} onClick={() => setIsRestockingModalVisible(false)}/>
                </div>
                <div className="restocking-modal-body">
                    <div className="restocking-modal-header">
                        알림을 받아보실 연락처를 남겨주세요.
                    </div>
                    <input placeholder={"이름"}
                           type={'text'}
                           value={name}
                           onChange={(e) => setName(e.target.value)}/>
                    <input placeholder={"휴대전화 번호"}
                           type={'tel'}
                           value={phone}
                           onChange={(e) => setPhone(autoHypenPhone(e.target.value))}/>
                    <div id="restocking-button" className="restocking-modal-button" onClick={() => submit()}>재입고 알림
                        신청하기
                    </div>
                </div>
            </div>
        </>
    )

})

export default RestockingModal
