import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppContext, stores } from './contexts/AppContext/AppContext';
import { ENV_NAME } from './constants/constants';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'mobx-react';
import { datadogRum } from '@datadog/browser-rum';
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-MBGVKRW'
}
 
TagManager.initialize(tagManagerArgs)

datadogRum.init({
    applicationId: '5a0018ca-cf13-4ec5-b0b3-006fa80be80b',
    clientToken: 'pub9e3a111555b6b9dfaab7d0bab2eec86c',
    site: 'datadoghq.com',
    env: ENV_NAME,
    service:'bacon_front',
    // Specify a version number to identify the deployed version of your application in Datadog 
    version: '1.0.0',
    sampleRate: 10,
    trackInteractions: true
});


ReactDOM.render(
    <CookiesProvider>
        <BrowserRouter>
            <AppContext.Provider value={stores}>
                <React.StrictMode>
                    <Provider {...stores}>
                    <App />
                    </Provider>
                </React.StrictMode>
            </AppContext.Provider>
        </BrowserRouter>
    </CookiesProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
