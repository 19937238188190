import { openChannelIO } from '../utils/commonUtils';
import {
    RESPONSE_ALREADY_HAS_DOGFOOD,
    RESPONSE_DOGFOOD_PRODUCT_LIMIT_COUNT,
    RESPONSE_REGISTER_BILLING_PHONE_ALREADY_EXIST,
    RESPONSE_UNAVAILABLE_UNSUBSCRIPTION_HAS_ACTIVE_SUBSCRIPTION,
    RESPONSE_STATUS_FAIL_LOGIN,
} from './errorConstants';

//const BACON_API_DOMAIN = 'https://dev-api-bacon.gitt.co';
export const BACON_API_DOMAIN = `${process.env.REACT_APP_API_URL}`;
export const BACON_WP_API = `${process.env.REACT_APP_WP_API_URL}`;
export const IAMPORT_CODE = `${process.env.REACT_APP_IAMPORT_CODE}`;
export const KAKAOPAY_CODE = `${process.env.REACT_APP_KAKAOPAY_CODE}`;
export const KAKAOPAY_SUBSCRIPTION_CODE = `${process.env.REACT_APP_KAKAOPAY_SUBSCRIPTION_CODE}`;
export const KCP_CODE = `${process.env.REACT_APP_KCP_CODE}`;
export const KCP_SUBSCRIPTION_CODE = `${process.env.REACT_APP_KCP_SUBSCRIPTION_CODE}`;
export const PAYCO_CODE = `${process.env.REACT_APP_PAYCO_CODE}`;
export const NPAY_BUTTON_KEY = `${process.env.REACT_APP_NPAY_BUTTON_KEY}`;
export const ENV_NAME = `${process.env.REACT_APP_ENV_NAME}`;
// export const NEW_BACON_API = `${process.env.REACT_APP_API_URL}`;

export const INIT_DATA_URL = `${BACON_API_DOMAIN}/v1/common/init`;
export const PRODUCTS_IN_CATEGORY_ENDPOINT = `${BACON_API_DOMAIN}/v1/home/product-list`;

export const COOKIE_LANG_NAME = 'lng';
export const COOKIE_BUYNOW_CART_NAME = 'BCBCART';
export const BREAKPOINT_DT = 1200;
export const BREAKPOINT_TBL = 768;
export const HOME_SLUG = 'home';

export const HOME_DATA_FETCH_URL = `${BACON_API_DOMAIN}/v1/home/all-data`;
export const BB_PAGE_DATA_URL = `${BACON_API_DOMAIN}/v1/baconbox/all-data`;
export const BB_PORTFOLIO_DATA_URL = `${BACON_API_DOMAIN}/v1/baconbox/theme-detail`;
export const EXISTING_EMAIL_CHECK_URL = `${BACON_API_DOMAIN}/v1/user/check`;
export const BB_ABUSING_URL = `${BACON_API_DOMAIN}/api/prevention/abuse`
//sign up or login
export const SIGNUP_URL = `${BACON_API_DOMAIN}/v1/user/signup`;
export const LOGIN_URL = `${BACON_API_DOMAIN}/v1/user/login`;
export const LOGIN_KAKAO_URL = `${BACON_API_DOMAIN}/v1/user/login-kakao`;
export const LOGOUT_URL = `${BACON_API_DOMAIN}/v1/user/logout`;
export const SIGNUP_PARTNER_URL = `${BACON_API_DOMAIN}/v1/partner/signup`;

export const CART_ADD_URL = `${BACON_API_DOMAIN}/v1/cart/add`;

export const COLLECTION_DETAIL_DATA_URL = `${BACON_API_DOMAIN}/v1/collection/detail`;

//cart
export const ADD_CART_URL = `${BACON_API_DOMAIN}/v1/cart/add`;
export const REMOVE_SLUG_ITEM_AND_ADD_CART_URL = `${BACON_API_DOMAIN}/v1/cart/remove-and-add/`;
export const ADD_CART_BUY_NOW = `${BACON_API_DOMAIN}/v1/cart/buynow`;
export const GET_CART_COUNT_URL = `${BACON_API_DOMAIN}/v1/cart/count`;
export const GET_CART_INFO_URL = `${BACON_API_DOMAIN}/v1/cart/info`;
export const REMOVE_CART_ITEM_URL = `${BACON_API_DOMAIN}/v1/cart/remove`;
export const RESET_CART_LIST_URL = `${BACON_API_DOMAIN}/v1/cart/reset`;
export const UPDATE_CART_QTY_URL = `${BACON_API_DOMAIN}/v1/cart/update`;
export const APPLY_COUPON_URL = `${BACON_API_DOMAIN}/v1/coupon/apply`;
export const REMOVE_COUPON_URL = `${BACON_API_DOMAIN}/v1/coupon/remove`;
export const BEST_PRODUCTS_LIST_URL = `${BACON_API_DOMAIN}/v1/home/section-type-fix-data`;
export const REMOVE_ALL_OUT_OF_STOCKS_URL = `${BACON_API_DOMAIN}/v1/cart/remove-out-of-stock-items`;
export const GET_COUPON_INFO_URL = `${BACON_API_DOMAIN}/v1/coupon/list`;
export const GET_PARTNER_ESTIMATE_URL = `${BACON_API_DOMAIN}/v1/cart/download-partner-offer-sheet`;
export const UPLOAD_PARTNER_DOCS_URL = `${BACON_API_DOMAIN}/v1/file/upload`;
export const REMOVE_COUPON_FROM_ORDER_URL = `${BACON_API_DOMAIN}/v1/coupon/remove-from-order`;

export const BEST_PRODUCTS = `${BACON_API_DOMAIN}/v1/home/section-type-fix-data`;

//product
const PRODUCT_BASE_URL = `${BACON_API_DOMAIN}/v1/product`;
export const GET_PRODUCT_INFO_URL = `${PRODUCT_BASE_URL}/detail/`;

//myaccount
export const MY_ORDERS_URL = `${BACON_API_DOMAIN}/v1/order/list`;
export const GET_ORDER_URL = `${BACON_API_DOMAIN}/v1/order/detail`;
export const UPDATE_ORDER_URL = `${BACON_API_DOMAIN}/v1/order/update`;
export const CANCEL_ORDER_URL = `${BACON_API_DOMAIN}/v1/order/cancel`;
export const ADD_ITEMS_TO_ORDERS = `${BACON_API_DOMAIN}/v1/order/add-items`;

//order
export const GET_ORDER_DOG_INFO_URL = `${BACON_API_DOMAIN}/v1/order/dog-detail`;
export const UPDATE_ORDER_DOG_INFO_URL = `${BACON_API_DOMAIN}/v1/order/update-dog`;
export const GET_ORDER_STATUS_URL = `${BACON_API_DOMAIN}/v1/order/status`;

//subscription
export const GET_SUBSCRIPTIONS_URL = `${BACON_API_DOMAIN}/v1/subscription/list`;
export const GET_DOG_INFO_URL = `${BACON_API_DOMAIN}/v1/subscription/dog-detail`;
export const ADD_DOG_INFO_URL = `${BACON_API_DOMAIN}/v1/subscription/add-dog`;
export const UPDATE_DOG_INFO_URL = `${BACON_API_DOMAIN}/v1/subscription/update-dog`;
export const GET_SUBSCRIPTION_URL = `${BACON_API_DOMAIN}/v1/subscription/detail`;
export const UPDATE_PAYMENT_METHOD = `${BACON_API_DOMAIN}/v1/subscription/update-payment-method`;
export const UPDATE_PAYMENT_DATE = `${BACON_API_DOMAIN}/v1/subscription/update-next-payment-date`;
export const SKIP_PAYMENT_DATE = `${BACON_API_DOMAIN}/v1/subscription/skip-this-month`;
export const CANCEL_SUBSCRIPTION = `${BACON_API_DOMAIN}/v1/subscription/cancel`;
export const UPDATE_SHIPPING = `${BACON_API_DOMAIN}/v1/subscription/update-shipping`;

//user info
export const GET_USER_INFO_URL = `${BACON_API_DOMAIN}/v1/user/info`;
export const UPDATE_USER_INFO_URL = `${BACON_API_DOMAIN}/v1/user/update`;
export const DELETE_ACCOUNT_URL = `${BACON_API_DOMAIN}/v1/user/unsubscribe`;

//search
export const SEARCH_URL = `${BACON_API_DOMAIN}/v1/search`;

//checkout
export const CHECKOUT_INIT_URL = `${BACON_API_DOMAIN}/v1/checkout/init`;
export const CHECKOUT_PROCESS_URL = `${BACON_API_DOMAIN}/v1/checkout/process`;

export const GET_CUSTOM_HTML_PAGE_URL = `${BACON_API_DOMAIN}/v1/common/custom-page-detail`;

export const UPDATE_PAYMENT_METHOD_URL = `${BACON_API_DOMAIN}/v1/subscription/update-payment-method`;
export const SHOW_BACK_BTN_PATHS = [
    '/my-account/',
    '/store/',
    '/checkout',
    '/partner',
    '/login',
    '/welcome',
    '/santa2021',
];
export const HIDE_SUB_COLLECTION_PAGE = ['/checkout', '/login'];

export const REMOVE_BUYNOW_URL = `${BACON_API_DOMAIN}/v1/cart/buynow-remove`;

export const NO_DATA = 'no_data';
export const IAMPORT_KCP_SUBSCRIPTION = 'iamport_subscription_ex';

//dogfood
export const DOG_FOOD_EVENT_IMG_PATH = '/img/dogfood/promotion_ta_bbs.jpg';
export const DOG_FOOD_CART_MAX_CNT = 1;
export const DOG_FOOD_BILLING_PERIOD = 'billing_period';
export const DOG_FOOD_BILLING_INTERVAL = 'billing_interval';
export const DOG_FOOD_COLLECTION_SLUG = 'dogfood-collection';
export const DOG_FOOD_PRODUCT_CLUSTER = 'food';
export const DOG_FOOD_CATEGORY_ID = 778;
export const DOG_FOOD_PERIOD_INTERVALS = [
    {
        id: 2,
        optionName: '2주',
        stock_status: 'instock',
        billing_period: 'week',
        billing_interval: 2,
    },
    {
        id: 4,
        optionName: '4주',
        stock_status: 'instock',
        billing_period: 'week',
        billing_interval: 4,
    },
    {
        id: 6,
        optionName: '6주',
        stock_status: 'instock',
        billing_period: 'week',
        billing_interval: 6,
    },
    {
        id: 8,
        optionName: '8주',
        stock_status: 'instock',
        billing_period: 'week',
        billing_interval: 8,
    },
];

export const DOG_FOOD_MAX_CNT_OVER_TEXT =
    '사료 정기배송은 동일한 상품으로 최대 ' +
    DOG_FOOD_CART_MAX_CNT +
    '개까지만 신청할 수 있습니다.';
export const defaultDogFoodDescription = {
    text: '첫 주문 시 50% 할인 혜택',
};

export const CUSTOM_ALERT_STATUS = [
    RESPONSE_DOGFOOD_PRODUCT_LIMIT_COUNT,
    RESPONSE_ALREADY_HAS_DOGFOOD,
    RESPONSE_STATUS_FAIL_LOGIN,
    RESPONSE_REGISTER_BILLING_PHONE_ALREADY_EXIST,
    RESPONSE_UNAVAILABLE_UNSUBSCRIPTION_HAS_ACTIVE_SUBSCRIPTION,
];

export const CUSTOM_POPUP_DOG_FOOD_OVER_MAX_CNT_OVER_TITLE =
    '현재 베이컨 밥 솔루션은 계정 당 ' +
    DOG_FOOD_CART_MAX_CNT +
    '개의 상품 이용을 제공하고 있습니다.';
export const CUSTOM_POPUP_DOG_FOOD_OVER_MAX_CNT_OVER_DESC =
    '복수 개 상품의 서비스 신청을 원하신다면, 채팅 상담으로 문의해주세요.';
export const CUSTOM_POPUP_ALREADY_HAS_DOGFOOD_IN_CART_TITLE =
    '이미 장바구니에 담은 베이컨 밥 솔루션 제품이 있습니다.';
export const CUSTOM_POPUP_ALREADY_HAS_DOGFOOD_IN_CART_DESC =
    '현재 베이컨 밥 솔루션은 계정 당 1개의 상품 이용을 제공하고 있습니다.\n 복수 개 상품의 서비스 신청을 원하신다면, 채팅 상담으로 문의해주세요.';

export const CUSTOM_POPUP_ALREADY_HAS_DOGFOOD_SUBSCIRPTION_TITLE =
    '이미 ' + DOG_FOOD_CART_MAX_CNT + '개의 사료 정기배송을 이용중이네요.';
export const CUSTOM_POPUP_ALREADY_HAS_DOGFOOD_SUBSCIRPTION_DESC =
    '추가 신청을 원하신다면, 채팅 상담을 통해 확인해주세요. ';
export const CUSTOM_POPUP_BTN_OPEN_CHANNEL_IO = {
    buttonName: '상담하기',
    bgColor: '#101010',
    clickFunc: openChannelIO,
};

export const COLLECTION_PREFIX_URL = '/store/collection/';
export const HUNDRED_SHOP_SLUG = 'happy-collection';
export const HUNDRED_SHOP_CATEGORY_SLUG = 'happy';
export const MINICART_LOGIN_HIGHLIGHT_TEXT =
    '장바구니에서 쿠폰 적용 시 신규가입 100원 혜택을 받으실 수 있습니다.';

export const STATIC_EVENT_BANNER = [
    {
        slug: 'note-nosework-toy',
        bgColor: '#ffffff',
        imgDtUrl: '/img/productEventBanner/detail_p_02.jpg',
        imgMoUrl: '/img/productEventBanner/detail_m_02.jpg',
    },
    // {
    //     slug: "g2-2010f",
    //     bgColor: "#ffffff",
    //     imgDtUrl: "/img/productEventBanner/detail_p_03.jpg",
    //     imgMoUrl: "/img/productEventBanner/detail_m_03.jpg"
    // },
    // {
    //     slug: "apple-tea-catch-toy",
    //     bgColor: "#ffffff",
    //     imgDtUrl: "/img/productEventBanner/detail_p_04.jpg",
    //     imgMoUrl: "/img/productEventBanner/detail_m_04.jpg"
    // },
    // {
    //     slug: "w013",
    //     bgColor: "#ffffff",
    //     imgDtUrl: "/img/productEventBanner/detail_p_05.jpg",
    //     imgMoUrl: "/img/productEventBanner/detail_m_05.jpg"
    // },
    // {
    //     slug: "ew004",
    //     bgColor: "#ffffff",
    //     imgDtUrl: "/img/productEventBanner/detail_p_06.jpg",
    //     imgMoUrl: "/img/productEventBanner/detail_m_06.jpg"
    // },
    // {
    //     slug: "eb005",
    //     bgColor: "#ffffff",
    //     imgDtUrl: "/img/productEventBanner/detail_p_07.jpg",
    //     imgMoUrl: "/img/productEventBanner/detail_m_07.jpg"
    // },
    {
        slug: 't3-1905f',
        bgColor: '#ffffff',
        imgDtUrl: '/img/productEventBanner/detail_p_08.jpg',
        imgMoUrl: '/img/productEventBanner/detail_m_08.jpg',
    },
    // {
    //     slug: "t2-1911f",
    //     bgColor: "#ffffff",
    //     imgDtUrl: "/img/productEventBanner/detail_p_09.jpg",
    //     imgMoUrl: "/img/productEventBanner/detail_m_09.jpg"
    // }
    //21-10-5 교체
    // {
    //     slug: 't2-2102f',
    //     bgColor: '#ffffff',
    //     imgDtUrl: '/img/productEventBanner/detail_p_10.jpg',
    //     imgMoUrl: '/img/productEventBanner/detail_m_10.jpg',
    // },
    // {
    //     slug: 'c0-2008f',
    //     bgColor: '#ffffff',
    //     imgDtUrl: '/img/productEventBanner/detail_p_11.jpg',
    //     imgMoUrl: '/img/productEventBanner/detail_m_11.jpg',
    // },
    {
        slug: 'es005',
        bgColor: '#ffffff',
        imgDtUrl: '/img/productEventBanner/detail_p_12.jpg',
        imgMoUrl: '/img/productEventBanner/detail_m_12.jpg',
    },
    {
        slug: 'es006',
        bgColor: '#ffffff',
        imgDtUrl: '/img/productEventBanner/detail_p_12.jpg',
        imgMoUrl: '/img/productEventBanner/detail_m_12.jpg',
    },
    {
        slug: 'es007',
        bgColor: '#ffffff',
        imgDtUrl: '/img/productEventBanner/detail_p_12.jpg',
        imgMoUrl: '/img/productEventBanner/detail_m_12.jpg',
    },
    {
        slug: 'c0-2009f',
        bgColor: '#ffffff',
        imgDtUrl: '/img/productEventBanner/detail_p_13.jpg',
        imgMoUrl: '/img/productEventBanner/detail_m_13.jpg',
    },
    // {
    //     slug: 't3-2102f',
    //     bgColor: '#ffffff',
    //     imgDtUrl: '/img/productEventBanner/detail_p_14.jpg',
    //     imgMoUrl: '/img/productEventBanner/detail_m_14.jpg',
    // },
    // {
    //     slug: 'goc_poopbag',
    //     bgColor: '#ffffff',
    //     imgDtUrl: '/img/productEventBanner/detail_p_15.jpg',
    //     imgMoUrl: '/img/productEventBanner/detail_m_15.jpg',
    // },
    {
        slug: 'clip-scoop',
        bgColor: '#ffffff',
        imgDtUrl: '/img/productEventBanner/detail_p_16.jpg',
        imgMoUrl: '/img/productEventBanner/detail_m_16.jpg',
    },
    {
        slug: 'note-nosework-toy',
        bgColor: '#ffffff',
        imgDtUrl: '/img/productEventBanner/p_message.jpg',
        imgMoUrl: '/img/productEventBanner/m_message.jpg',
    },
    {
        slug: 'iamyourbacon',
        bgColor: '#ffffff',
        imgDtUrl: '/img/productEventBanner/p_bacon.jpg',
        imgMoUrl: '/img/productEventBanner/m_bacon.jpg',
    },
    {
        slug: 'h0-2102f',
        bgColor: '#ffffff',
        imgDtUrl: '/img/productEventBanner/p_relax_powder.jpg',
        imgMoUrl: '/img/productEventBanner/m_relax_powder.jpg',
    },
];

export const GNB_NO_SUB_PATH = ['/login'];
export const GNB_MOBILE_NO_SUB_PATH = [
    '/my-account',
    '/store/',
    '/partner',
    '/welcome',
    '/checkout/order-received/',
    '/santa2021',
];

export const REDIRECT_URL_AFTER_SIGNUP = '/welcome?CompleteRegistration=1';

//product type
export const PRODUCT_TYPE_VARIABLE_SUBSCRIPTION = 'variable-subscription';
export const PRODUCT_TYPE_SUBSCRIPTION = 'subscription';

export const SANTA_COLLECTION_SLUG = 'xmas';
