import React, {useState, useEffect, useCallback} from 'react';
import {AppContext} from '../../contexts/AppContext/AppContext';
import {defaultElementProps} from '../../types/types';
import {addComma, hideChatBtn, openChannelIO} from '../../utils/commonUtils';
import {
    GET_PARTNER_ESTIMATE_URL,
    BREAKPOINT_TBL, BB_PAGE_DATA_URL, BB_ABUSING_URL,
} from '../../constants/constants';
import './CartPurchaseBox.scss';
import {fetchAndReceiveJSON} from "../../utils/ajaxUtils";
import Modal from "../../components/Modal/Modal.component";
import {toJS} from "mobx";
import {SubscribeFreeItemId} from "../../utils/orderUtils";

interface ICartpurchaseBox extends defaultElementProps {
    currentWidth?: any;
    setProcessCheckout?: any;
    viewPurchaseDetail?: any;
    setViewPurchaseDetail?: any;
    // goToOrder?: boolean;
    // setGoToOrder?: any;
}

const CartPurchaseBox: React.FunctionComponent<ICartpurchaseBox> = ({
                                                                        currentWidth,
                                                                        setProcessCheckout,
                                                                        viewPurchaseDetail,
                                                                        setViewPurchaseDetail,
                                                                        // goToOrder,
                                                                        // setGoToOrder,
                                                                    }) => {
    const {rootStore, cartPageStore, userInfoStore} = React.useContext(AppContext);
    const {userDeliveryInfo} = userInfoStore;


    const {
        setIsDimBgOn,
        setIsLoginModalOn,
        initData,
        setIsSpinnerOn,
        setCommonPopupContents,
        resetCommonPopupContents
    } =
        rootStore;
    const {
        cartInfoList,
        setOrderForNonUser,
        goToOrder,
        setGoToOrder,
        setPayForNpay,
        setCartListCount,
        updateCartInfoList,
        setCartInfoList,
        updateCartItemQty
    } = cartPageStore;
    const [showDeliveryInfo, setShowDeliveryInfo] = useState(false);
    const [buttonText, setButtonText] = useState('구매하기');
    const [isPurchasable, setIsPurchasable] = useState<boolean>(true);
    // const [isNpayButtonClicked, setIsNpayBUttonClicked] = useState(false);

    const receiveEstimate = async () => {
        setIsSpinnerOn(true);
        fetch(GET_PARTNER_ESTIMATE_URL, {
            credentials: 'include',
        })
            .then((res) => {
                if (res.ok) {
                    window.location.href = `${res.url}`;
                }
                setIsSpinnerOn(false);
            })
            .catch((err) => {
                console.error(err);
                setIsSpinnerOn(false);
            });
    };


    const isValidPurchaseForPartner = useCallback(() => {
        if (
            Number(initData.partnerMinPurchaseAmount.replace(',', '')) <
            cartInfoList.items.totals.regular_total
        ) {
            return true;
        } else {
            return false;
        }
    }, []);

    useEffect(() => {
        hideChatBtn(goToOrder);
    }, [goToOrder]);

    const getCouponName = (couponName) => {
        const defaultCoutponName = '쿠폰 할인';
        if (!couponName || couponName.length < 1) {
            return defaultCoutponName;
        }

        return couponName;
    };
    const checkBaconBoxAbusing = async (phone?: string) => {
        const resData = await fetchAndReceiveJSON({
            url: BB_ABUSING_URL + `${phone ? "?phone=" + phone : ""}`,
        });
        return resData
    }

    const checkHundredEventAbusing = () => {
        const targetItems = cartInfoList.items?.cartItems?.filter(item => item.price === 100 && item.quantity > 1)
        return targetItems.length > 0;
    }

    const changeHundredEventItemQty = async () => {

        const targetItems = cartInfoList.items?.cartItems?.filter(item => item.price === 100 && item.quantity > 1)
        let alertText = ''
        targetItems.map(item => alertText += `[${item.product_name}]의 최대 구매 가능 갯수는 1개입니다.\n`)
        if (targetItems.length > 0) {
            window.alert(alertText)
            setIsSpinnerOn(true);
            await targetItems.reduce(async (promise, item) => {
                await promise;
                return updateCartItemQty(item.key, 1, item.product_id, item.variation_id);
            }, Promise.resolve()).then(async () => await setCartListCount())
                .then(async () => await setCartInfoList())
                .then(() => setIsSpinnerOn(false))
        }
    }


    return (
        <>
            {cartInfoList && cartInfoList.items && (
                <div
                    className={`CartPurchaseBoxContainer ${
                        goToOrder && 'fullPage'
                    }`}
                >
                    <div
                        className={`CartPurchaseBox ${
                            viewPurchaseDetail && !goToOrder
                                ? 'showing'
                                : goToOrder && !viewPurchaseDetail
                                    ? 'fullPage'
                                    : goToOrder && viewPurchaseDetail
                                        ? 'fullPage showing'
                                        : ''
                        }`}
                    >
                        {viewPurchaseDetail && (
                            <div
                                onClick={() => {
                                    setViewPurchaseDetail(false);
                                    setIsDimBgOn(false);
                                }}
                                className="closePurchaseDetailBtn"
                            >
                                <img
                                    src="/img/btn-gnb-popup-close-white.svg"
                                    alt="close-button"
                                />
                            </div>
                        )}
                        <div
                            className={`purchaseDetailWrapper ${
                                viewPurchaseDetail ? 'openDetail' : ''
                            }`}
                        >
                            <div
                                onClick={
                                    currentWidth < BREAKPOINT_TBL
                                        ? () => {
                                            setViewPurchaseDetail(false);
                                            setIsDimBgOn(false);
                                        }
                                        : goToOrder ||
                                        (currentWidth < BREAKPOINT_TBL &&
                                            goToOrder)
                                            ? () => setViewPurchaseDetail(false)
                                            : undefined
                                }
                                className="purchaseDetailTitle"
                            >
                                결제하실 금액
                                <img
                                    src="/img/m-btn-icon-accordion-arrow-down-black-16.svg"
                                    alt=""
                                />
                            </div>
                            <div className="purchaseDetailDesc">
                                <div className="priceInfo">
                                    <div className="detailType">상품 가격</div>
                                    <div className="price">
                                        {cartInfoList.items &&
                                            cartInfoList.items.totals
                                                .display_regular_total}
                                    </div>
                                </div>
                                <div className="deliveryInfo">
                                    <div className="detailType">
                                        배송비
                                        <img
                                            onClick={() =>
                                                setShowDeliveryInfo(
                                                    (prev) => !prev
                                                )
                                            }
                                            src="/img/notice.svg"
                                        />
                                        <div
                                            className={`deliveryInfoBox ${
                                                showDeliveryInfo
                                                    ? ''
                                                    : 'noShowing'
                                            }`}
                                        >
                                            40,000원 이상 구매시 무료배송
                                            입니다.
                                        </div>
                                    </div>
                                    <div className="price">
                                        {cartInfoList.items.totals &&
                                            (cartInfoList.items.totals
                                                .shipping_origin_total > 0
                                                ? cartInfoList.items.totals
                                                    .shipping_total
                                                : '무료배송')}
                                    </div>
                                </div>
                                {initData.isPartner && (
                                    <div className="estimateBtn">
                                        견적서 내려받기
                                    </div>
                                )}
                            </div>

                            {((cartInfoList.items.coupon_discount_totals &&
                                    cartInfoList?.items.coupon_discount_totals
                                        .length > 0) ||
                                cartInfoList.items.totals
                                    .product_discount_total > 0 || cartInfoList.items.fees.length > 0) && (
                                <div className="discountDetailDesc">
                                    {cartInfoList.items.totals
                                            .display_total_discount_price &&
                                        cartInfoList.items.totals
                                            .display_total_discount_price !==
                                        '0원' && (
                                            <div className="discountInfo">
                                                <div className="detailType">
                                                    상품 할인 금액
                                                </div>
                                                <div className="price">
                                                    {`-${cartInfoList.items.totals.display_product_discount_total}`}
                                                </div>
                                            </div>
                                        )}
                                    {cartInfoList.coupon_discount_totals.map(
                                        (coupon) => {
                                            if (
                                                coupon.discount_price !== '0원'
                                            ) {
                                                return (
                                                    <div
                                                        className="discountInfo"
                                                        key={coupon.couponId}
                                                    >
                                                        <div className="detailType">
                                                            {getCouponName(
                                                                coupon.coupon_name
                                                            )}
                                                        </div>
                                                        <div className="price">
                                                            {typeof coupon.discount_price ===
                                                            'number'
                                                                ? addComma(
                                                                    coupon.discount_price
                                                                )
                                                                : coupon.discount_price}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }
                                    )}
                                    {cartInfoList.items.fees &&
                                        cartInfoList.items.fees.length > 0 &&
                                        cartInfoList.items.fees.map((fee) => {
                                            return (
                                                <div
                                                    className="discountInfo"
                                                    key={fee.name}
                                                >
                                                    <div className="detailType">
                                                        {fee.name === "수수료" ? "할인 금액" : fee.name}
                                                    </div>
                                                    <div className="price">
                                                        {fee.display_total}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            )}
                        </div>
                        <div className="bottomArea">
                            <div className="purchaseInfo">
                                <div
                                    onClick={
                                        currentWidth < BREAKPOINT_TBL ||
                                        goToOrder
                                            ? () => {
                                                setViewPurchaseDetail(true);
                                                setIsDimBgOn(true);
                                            }
                                            : undefined
                                    }
                                    className="purchaseDetail"
                                >
                                    {(!viewPurchaseDetail &&
                                        currentWidth < BREAKPOINT_TBL) ||
                                    (goToOrder &&
                                        currentWidth >= BREAKPOINT_TBL &&
                                        !viewPurchaseDetail)
                                        ? '결제하실 금액'
                                        : '총 결제하실 금액'}

                                    <img
                                        style={
                                            (!viewPurchaseDetail &&
                                                currentWidth <
                                                BREAKPOINT_TBL) ||
                                            (goToOrder &&
                                                currentWidth >=
                                                BREAKPOINT_TBL &&
                                                !viewPurchaseDetail)
                                                ? {display: 'block'}
                                                : {display: 'none'}
                                        }
                                        src="/img/m-btn-accordion-arrow-open-16.svg"
                                    />
                                </div>
                                <div className="right">
                                    {initData.isPartner && (
                                        <div
                                            className={`estimateBtn ${
                                                viewPurchaseDetail
                                                    ? 'opened'
                                                    : ''
                                            }`}
                                        >
                                            견적서 받기
                                        </div>
                                    )}

                                    <div className="purchasePrice">
                                        {cartInfoList.items.totals &&
                                            `${cartInfoList.items.totals.total}`}
                                    </div>
                                </div>
                            </div>
                            <div className="purchaseBtnBox">
                                {cartInfoList.npay_available && (
                                    <div
                                        onClick={() => setPayForNpay(true)}
                                        className="naverPayBtn"
                                    >
                                        <img
                                            src="/img/common-logo-npay-02@2x.png"
                                            alt="naver-pay"
                                        />
                                    </div>
                                )}
                                <div
                                    className="goToPurchaseBtn 1"
                                    onClick={
                                        initData.isLogin
                                            ? async () => {
                                                if (checkHundredEventAbusing()) {
                                                    return await changeHundredEventItemQty()
                                                }
                                                //setIsDimBgOn(false);
                                                if (initData.isPartner) {
                                                    if (
                                                        isValidPurchaseForPartner()
                                                    ) {
                                                        if (goToOrder) {
                                                            setProcessCheckout(
                                                                true
                                                            );
                                                        } else {
                                                            setGoToOrder(
                                                                true
                                                            );
                                                            setViewPurchaseDetail(
                                                                false
                                                            );
                                                        }
                                                    } else {
                                                        alert(
                                                            `${initData.partnerMinPurchaseAmount}원 이상 구매시 결제 가능합니다.`
                                                        );
                                                    }
                                                } else {
                                                    if (goToOrder) {
                                                        setProcessCheckout(
                                                            true
                                                        );
                                                    } else {
                                                        setGoToOrder(true);
                                                        setViewPurchaseDetail(
                                                            false
                                                        );
                                                    }
                                                }

                                                //setProcessCheckout(true);
                                            }
                                            : async () => {
                                                if (checkHundredEventAbusing()) {
                                                    return await changeHundredEventItemQty()
                                                }

                                                setIsLoginModalOn(true);
                                                setOrderForNonUser(true);
                                            }
                                    }
                                >
                                    {buttonText}{' '}
                                    {cartInfoList.items.totals.discount_total ||
                                    cartInfoList.items.totals
                                        .product_discount_total
                                        ? `(-${addComma(
                                            cartInfoList.items.totals
                                                .discount_total +
                                            cartInfoList.items.totals
                                                .product_discount_total
                                        )}원)`
                                        : ''}
                                </div>
                            </div>
                        </div>
                        {initData.isPartner && (
                            <div
                                onClick={() => receiveEstimate()}
                                className={`estimateBtnTbl ${
                                    goToOrder ? 'noShow' : ''
                                }`}
                            >
                                견적서 내려받기
                            </div>
                        )}
                    </div>
                    <div
                        className={`purchaseBtns ${
                            goToOrder ? 'fullPage' : ''
                        }`}
                    >
                        <div
                            onClick={
                                initData.isLogin
                                    ? async () => {
                                        if (checkHundredEventAbusing()) {
                                            return await changeHundredEventItemQty()
                                        }
                                        if (initData.isPartner) {
                                            if (isValidPurchaseForPartner()) {
                                                setGoToOrder((prev) => !prev);
                                                setIsDimBgOn(true);
                                            } else {
                                                alert(
                                                    `${initData.partnerMinPurchaseAmount}원 이상 구매시 결제 가능합니다.`
                                                );
                                            }
                                        } else {
                                            setGoToOrder((prev) => !prev);
                                            setIsDimBgOn(true);
                                        }
                                    }
                                    : async () => {
                                        if (checkHundredEventAbusing()) {
                                            return await changeHundredEventItemQty()
                                        }
                                        setIsLoginModalOn(true);
                                        setOrderForNonUser(true);
                                    }
                            }
                            className="goToPurchaseBtn 2"
                        >
                            {buttonText}{' '}
                            {cartInfoList.items.totals.discount_total ||
                            cartInfoList.items.totals.product_discount_total
                                ? `(-${addComma(
                                    cartInfoList.items.totals.discount_total +
                                    cartInfoList.items.totals
                                        .product_discount_total
                                )}원)`
                                : ''}
                        </div>
                        {cartInfoList.npay_available && (
                            <div id="iamport-naverpay-product-button2"></div>

                            // <>
                            //     <div className="naverPayBtn">
                            //         <div />
                            //     </div>
                            // </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default CartPurchaseBox;
