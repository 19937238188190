import { observer } from 'mobx-react';
import React from 'react';
import CollectionCard from '../../components/CollectionCard/CollectionCard';
import { defaultElementProps } from '../../types/types';
import { stringLineBreak } from '../../utils/commonUtils';
import './HomeCollectionNavContainer.scss';

interface HomeCollectionNavContainerProps extends defaultElementProps {
    collectionNavData?: Array<any>;
    containerTitle?: string;
}

const HomeCollectionNavContainer: React.FunctionComponent<HomeCollectionNavContainerProps> = observer(
    ({
        collectionNavData = [
            {
                id: 51112,
                title: '생활용품',
                icon: '/img/m-icon-home-category-toy@2x.png',
                link: '/care',
                badgeUrl: '/img/badge_text.png',
            },
            {
                id: 51112,
                title: '생활용품',
                icon: '/img/m-icon-home-category-toy@2x.png',
                link: '/care',
            },
            {
                id: 51112,
                title: '생활용품',
                icon: '/img/m-icon-home-category-toy@2x.png',
                link: '/care',
            },
            {
                id: 51112,
                title: '생활용품',
                icon: '/img/m-icon-home-category-toy@2x.png',
                link: '/care',
            },
            {
                id: 51114,
                title: '간식',
                icon: '/img/m-icon-home-category-toy@2x.png',
                link: '/eatsfun',
            },
            {
                id: 51114,
                title: '간식',
                icon: '/img/m-icon-home-category-toy@2x.png',
                link: '/eatsfun',
            },
            {
                id: 51114,
                title: '간식',
                icon: '/img/m-icon-home-category-toy@2x.png',
                link: '/eatsfun',
            },
            {
                id: 51114,
                title: '간식',
                icon: '/img/m-icon-home-category-toy@2x.png',
                link: '/eatsfun',
            },
        ],
        containerTitle,
        ...otherProps
    }) => {
        // console.log(collectionNavData, 'collection Data');
        return (
            <div {...otherProps}>
                <div className="homeCollectionNavigationTitle">
                    {stringLineBreak(containerTitle)}
                </div>
                <div className="collectionNavWrapper">
                    {collectionNavData?.map((el, i) => {
                        return (
                            <CollectionCard
                                key={i}
                                text={el.collection.name}
                                imgUrl={el.icon}
                                badgeUrl={el.badge}
                                link={el.link}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
);

export default HomeCollectionNavContainer;
