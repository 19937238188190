import { observer } from 'mobx-react';
import './OrderProduct.scss';
import { AppContext } from '../../contexts/AppContext/AppContext';
import { toJS } from 'mobx';
import { addComma, hideChatBtn, openChannelIO } from '../../utils/commonUtils';
import {
    getBBItems,
    getBundleItems,
    getDogFoodItems,
    getDogFoodOptionName,
    getLineItemCnt,
    getSubscriptionPlan,
} from '../../utils/orderUtils';
import { DOG_FOOD_CATEGORY_ID } from '../../constants/constants';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import AddDogInfo from '../AddDogInfo/AddDogInfo';

let dogId = null;

const OrderProduct = observer(({ data, type, targetId }) => {
    const { rootStore } = React.useContext(AppContext);
    const { setIsDimBgOn } = rootStore;

    const [addDogPopup, setAddDogPopup] = useState(false);

    useEffect(() => {
        setIsDimBgOn(addDogPopup);
        hideChatBtn(addDogPopup);
    }, [addDogPopup]);

    const history = useHistory();

    const productAreaTitle = '주문상품 정보'
    const subscriptionPlan = getSubscriptionPlan(data);

    const items = data.line_items;

    // console.log("items" , items);
    let dogFoodItems = getDogFoodItems(items);
    // console.log("dogFoodItems" , dogFoodItems);
    const processedItems = items.filter(
        (item) => !hasObjectInArr(dogFoodItems, item, 'id')
    );

    // console.log("processedItems" , processedItems);

    let bbParentItems: any = getBBItems(processedItems);

    //bb 제외
    let itemsNotSubscription = processedItems.filter(
        (item) =>
            !item.product.categories
                ?.map((category) => category.slug)
                .includes(['baconbox', 'dogfood', 'dogFood']) &&
            !item.product.categories
                ?.map((category) => category.id)
                .includes(DOG_FOOD_CATEGORY_ID)
    );

    // console.log("itemsNotSubscription", itemsNotSubscription);
    let bundleItems = getBundleItems(itemsNotSubscription);

    let freeItems = items.filter((item) =>
        item.meta_data?.some((meta) => meta.key === '_free_item')
    );

    //bb , bundle , free 제외
    let normalItems = itemsNotSubscription.filter(
        (item) =>
            item.product.type !== 'variable-subscription' &&
            !item.product.categories
                ?.map((category) => category.slug)
                .includes('bacon-select') &&
            item.product.type !== 'bundle' &&
            !item.meta_data?.some((meta) => meta.key === '_bundled_by') &&
            !item.meta_data?.some((meta) => meta.key === '_free_item') &&
            item.product.productCluster !== 'baconbox' &&
            item.product.productCluster !== 'baconbox_snack'
    );

    // console.log("normalItems" , normalItems);

    const getChildPrice = (bbParentItem, childItem) => {
        // console.log(bbParentItem);

        if (childItem.variation_id === undefined) {
            return bbParentItem.display_price;
        }

        return childItem.display_total;
    };
    const getChildRegularPrice = (bbParentItem, childItem) => {
        // console.log(bbParentItem);

        if (childItem.variation_id === undefined) {
            return addComma(bbParentItem.product.regular_price);
        }

        const variationId = childItem.variation_id;
        // console.log(variationId);
        let variations = childItem.product.product_variations;
        for (let i = 0; i < variations.length; i++) {
            // console.log(variations[i]);
            if (variations[i].id === variationId) {
                return addComma(variations[i].regular_price);
            }
        }

        return '';
    };

    // console.log("bbParentItems" , bbParentItems);

    function closePopupWhenClickDim(ref) {
        useEffect(() => {
            function handleClickOutsidePopup(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsDimBgOn(false);
                    setAddDogPopup(false);
                }
            }

            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutsidePopup);

            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener(
                    'mousedown',
                    handleClickOutsidePopup
                );
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    // closePopupWhenClickDim(wrapperRef);

    function hasObjectInArr(arr, target, key) {
        for (let i = 0; i < arr.length; i++) {
            // console.log()
            if (arr[i][key] == target[key]) {
                return true;
            }
        }

        return false;
    }

    return (
        <div className="orderItemsContainer">
            <div className="orderItemsTopArea">
                <div className="orderItemsTopText">{productAreaTitle}</div>
                <div className="orderItemsCount">{`총 ${getLineItemCnt(
                    items
                )}개`}</div>
            </div>

            <div className="orderItemsBodyArea">
                {items.length > 0 && (
                    <div className="normalItemWrapper">
                        {dogFoodItems.map((dogFoodItem) => {
                            const hasOption =
                                dogFoodItem.product.type.includes('variable');

                            return (
                                <div
                                    key={dogFoodItem.id}
                                    className="orderItemBox withOption"
                                >
                                    <div className="itemPaddingWrapper">
                                        <div className="orderItemHeadArea">
                                            <img
                                                className="orderItemsThumbnail"
                                                src={
                                                    dogFoodItem.product
                                                        .thumbnail
                                                }
                                                alt={dogFoodItem.product_name}
                                                onClick={() => {
                                                    history.push(
                                                        '/store/' +
                                                            dogFoodItem.slug
                                                    );
                                                }}
                                            />
                                            <div className="orderItemInfoTexts">
                                                <div className="orderItemInfoTop">
                                                    <div className="itemName">
                                                        {dogFoodItem.product
                                                            .productBrand && (
                                                            <div>
                                                                [
                                                                {
                                                                    dogFoodItem
                                                                        .product
                                                                        .productBrand
                                                                }
                                                                ]
                                                            </div>
                                                        )}
                                                        {
                                                            dogFoodItem.display_name
                                                        }
                                                    </div>
                                                    <div className="itemDesc">
                                                        {hasOption && (
                                                            <>
                                                                {getDogFoodOptionName(
                                                                    dogFoodItem
                                                                )}
                                                                <div className="line"></div>
                                                            </>
                                                        )}
                                                        <div className="qty">
                                                            {
                                                                dogFoodItem.quantity
                                                            }
                                                            개
                                                        </div>
                                                    </div>
                                                    <div className="itemDesc">
                                                        <div className="plan">
                                                            {subscriptionPlan}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="orderItemBottomArea bbItem">
                                        <div className="showTotalPrice">
                                            <div className="displayPrice">
                                                {dogFoodItem.display_subtotal}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        {/* BB */}
                        {bbParentItems.map((bbParentItem) => {
                            return (
                                <div
                                    key={bbParentItem.id}
                                    className="orderItemBox withOption"
                                >
                                    <div className="itemPaddingWrapper">
                                        <div className="orderItemHeadArea">
                                            <img
                                                className="orderItemsThumbnail"
                                                src={
                                                    bbParentItem.product
                                                        .thumbnail
                                                }
                                                alt={bbParentItem.product_name}
                                            />
                                            <div className="orderItemInfoTexts">
                                                <div className="orderItemInfoTop">
                                                    <div className="itemName">
                                                        {
                                                            bbParentItem.display_name
                                                        }
                                                    </div>
                                                    <div className="itemDesc">
                                                        <div className="plan">
                                                            {bbParentItem.sku ===
                                                            'bb_v2_1month'
                                                                ? '1개월'
                                                                : subscriptionPlan}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="bb-btns">
                                                    <div
                                                        className="btn"
                                                        onClick={() => {
                                                            const dogIdMeta =
                                                                bbParentItem.meta.filter(
                                                                    (meta) =>
                                                                        meta.key ===
                                                                        'dog_id'
                                                                );
                                                            if (
                                                                dogIdMeta &&
                                                                dogIdMeta.length >
                                                                    0 &&
                                                                dogIdMeta[0]
                                                                    .value
                                                            ) {
                                                                dogId = Number(
                                                                    dogIdMeta[0]
                                                                        .value
                                                                );
                                                            }

                                                            setAddDogPopup(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        반려견 정보
                                                        <img
                                                            className="pathArrow"
                                                            src="/img/icon/arrow.svg"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="border-line" />
                                        <div className="orderItemMiddleArea">
                                            {bbParentItem.children &&
                                                bbParentItem.children.map(
                                                    (childItem) => {
                                                        // { console.log("child ! ", toJS(childItem)) }
                                                        return (
                                                            <div className="childItem">
                                                                <div className="itemDetail">
                                                                    {
                                                                        childItem.name
                                                                    }
                                                                </div>
                                                                <div className="priceArea">
                                                                    <div className="showTotalPrice">
                                                                        <div className="displayPrice">
                                                                            {getChildPrice(
                                                                                bbParentItem,
                                                                                childItem
                                                                            )}
                                                                        </div>
                                                                        <div className="regularPrice">
                                                                            {getChildRegularPrice(
                                                                                bbParentItem,
                                                                                childItem
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        {/* simple , option */}
                        {normalItems.map((item) => {
                            return (
                                <div
                                    key={item.id}
                                    className="orderItemBox withOption"
                                >
                                    <div className="itemPaddingWrapper">
                                        <div className="orderItemHeadArea">
                                            <img
                                                className="orderItemsThumbnail"
                                                src={item.product.thumbnail}
                                                alt={item.product_name}
                                                onClick={() => {
                                                    history.push(
                                                        '/store/' + item.slug
                                                    );
                                                }}
                                            />
                                            <div className="orderItemInfoTexts">
                                                <div className="itemName">
                                                    {item.product.name}
                                                </div>
                                                <div className="itemDesc">
                                                    {item.product.type ==
                                                        'variable' &&
                                                        item.meta_data?.length >
                                                            0 && (
                                                            <>
                                                                <div className="optionName">
                                                                    {
                                                                        item
                                                                            .meta_data[0]
                                                                            ?.display_value
                                                                    }
                                                                </div>
                                                                <div className="line"></div>
                                                            </>
                                                        )}

                                                    <div className="qty">
                                                        {item.quantity}개
                                                    </div>
                                                </div>

                                                <div className="showTotalPrice">
                                                    <div className="displayPrice">
                                                        {item.display_subtotal}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        {/* bundle */}
                        {bundleItems.map((bundleItem) => {
                            // console.log(bundleItem);
                            return (
                                <div
                                    key={bundleItem.id}
                                    className="orderItemBox withOption"
                                >
                                    <div className="itemPaddingWrapper">
                                        <div
                                            className="orderItemHeadArea"
                                            onClick={() => {
                                                history.push(
                                                    '/store/' + bundleItem.slug
                                                );
                                            }}
                                        >
                                            <img
                                                className="orderItemsThumbnail"
                                                src={
                                                    bundleItem.product.thumbnail
                                                }
                                                alt={bundleItem.product_name}
                                            />
                                            <div className="orderItemInfoTexts">
                                                <div className="itemName">
                                                    {bundleItem.display_name}
                                                </div>
                                                <div className="itemDesc">
                                                    <div className="qty">
                                                        {bundleItem.quantity}개
                                                    </div>
                                                </div>
                                                <div className="showTotalPrice">
                                                    <div className="displayPrice">
                                                        {
                                                            bundleItem.display_subtotal
                                                        }
                                                    </div>
                                                    {/* <div className="regularPrice">
                                                        {
                                                            addComma(bundleItem.product.regular_price)
                                                        }
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {bundleItem.children.length > 0 && (
                                        <div className="border-line padding" />
                                    )}

                                    <div className="orderItemBodyArea">
                                        {bundleItem.children.map(
                                            (childItem) => {
                                                return (
                                                    <div
                                                        key={childItem.id}
                                                        className="bundleItem"
                                                    >
                                                        <div className="itemList bundle">
                                                            <div className="optionInfo">
                                                                <img
                                                                    className="optionThumbnail"
                                                                    src={
                                                                        childItem
                                                                            .product
                                                                            .thumbnail
                                                                    }
                                                                    alt=""
                                                                />
                                                                <div className="itemInfoAndPrice">
                                                                    <div className="orderItemInfoTexts">
                                                                        <div className="">
                                                                            <div className="itemName">
                                                                                {
                                                                                    childItem.display_name
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className="qty">
                                                                                {
                                                                                    childItem.quantity
                                                                                }

                                                                                개
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="showTotalPrice">
                                                                        <div className="displayPrice">
                                                                            {
                                                                                childItem.display_subtotal
                                                                            }
                                                                        </div>
                                                                        {/* <div className="regularPrice">
                                                                            {
                                                                                childItem.product.display_regular_price
                                                                            }
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                    <div className="bundleItemBottomArea">
                                        {/* empty div  */}
                                        <div></div>
                                        <div className="showTotalPrice">
                                            <div className="displayPrice">
                                                {addComma(
                                                    bundleItem.priceSum.total
                                                )}
                                                원
                                            </div>
                                            {/* <div className="regularPrice">
                                                {addComma(bundleItem.priceSum.regular_price)}
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        {/* free */}
                        {freeItems.length > 0 && (
                            <div className="freeItemArea">
                                <div className="freeItemTop">
                                    <img
                                        className="freeItemIcon"
                                        src="/img/icon-cart-gift.svg"
                                    />
                                    <div className="freeItemTitle">
                                        증정 상품
                                    </div>
                                </div>
                                {freeItems.map((item) => {
                                    return (
                                        <div
                                            key={item.id}
                                            className="orderItemBox withOption"
                                        >
                                            <div className="itemPaddingWrapper">
                                                <div className="orderItemHeadArea">
                                                    <img
                                                        className="orderItemsThumbnail"
                                                        src={
                                                            item.product
                                                                .thumbnail
                                                        }
                                                        alt={item.product_name}
                                                        onClick={() => {
                                                            history.push(
                                                                '/store/' +
                                                                    item.slug
                                                            );
                                                        }}
                                                    />
                                                    <div className="orderItemInfoTexts">
                                                        <div className="itemName">
                                                            {item.display_name}
                                                        </div>
                                                        <div className="itemDesc">
                                                            {item.product
                                                                .type ==
                                                                'variable' &&
                                                                item.meta_data
                                                                    ?.length >
                                                                    0 && (
                                                                    <>
                                                                        <div className="optionName">
                                                                            {/* 옵션영역 */}
                                                                            {
                                                                                item
                                                                                    .meta_data[0]
                                                                                    ?.value
                                                                            }
                                                                        </div>
                                                                        <div className="line"></div>
                                                                    </>
                                                                )}

                                                            <div className="qty">
                                                                {item.quantity}
                                                                개
                                                            </div>
                                                        </div>

                                                        <div className="showTotalPrice">
                                                            <div className="displayPrice">
                                                                {/* {item.display_price} */}
                                                            </div>
                                                            <div className="regularPrice">
                                                                {/* {
                                                                    addComma(item.product.regular_price)
                                                                } */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}
            </div>
            {/* {console.log(dogId)} */}
            <div ref={wrapperRef} className="addDogPopup">
                {addDogPopup && (
                    <AddDogInfo
                        closeAddDogInfoPopup={setAddDogPopup}
                        targetId={targetId}
                        dogId={dogId}
                        type={'order'}
                    />
                )}
            </div>
        </div>
    );
});

export default OrderProduct;
