import { makeAutoObservable } from 'mobx';

class HomeStore {
    notLogInPromoData = {
        text: '가입하고 무료 장난감 + 쿠폰 받기!',
        color: 'black',
        img: '/img/img-line-banner-thumbnail@2x.png',
    };
    homeTopBannerData = [
        {
            id: '1',
            text: '블랙프라이데이\n전상품 50% 할인중!',
            link: 'http://abc.com',
            linkText: '득템 하러가 가기',
            file_mo: '/img/img@2x.png',
            file_dt: '/img/banner@3x.png',
        },
        {
            id: '2',
            text: '블랙프라이데이\n전상품 50% 할인중2!',
            link: 'http://abc.com',
            linkText: '득템 하러가 가기',
            file_mo: '/img/img@2x.png',
            file_dt: '/img/banner@3x.png',
        },
        {
            id: '3',
            text: '블랙프라이데이\n전상품 50% 할인중3!',
            link: 'http://abc.com',
            linkText: '득템 하러가 가기',
            file_mo: '/img/img@2x.png',
            file_dt: '/img/banner@3x.png',
        },
    ];

    homeData;

    activeTopBannerSlideIndex = 0;

    openHamburgerPopup: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    setNotLogInPromoData = (data) => {
        this.notLogInPromoData = data;
    };

    setActiveTopBannerSlideIndex = (data) => {
        this.activeTopBannerSlideIndex = data;
    };

    setHomeData = (data) => {
        this.homeData = data;
    };

    setOpenHamburgerPopup = (bool: boolean) => {
        this.openHamburgerPopup = bool;
    };
}

export default HomeStore;

const homeDefaultData = {
    data: {
        strgPfx: 'https://fly.gitt.co/collins/wp-content/uploads/',
        instaPfx: 'https://www.instagram.com/',
        homeMenu: [
            {
                id: 11111,
                label: '베이컨박스',
                icon: '2021/03/baconbox_icon.png',
                badge: '2021/03/baconbox_badge.png',
                link: '/baconbox',
                hColor: '#FF0000',
            },
            {
                id: 11112,
                label: '고아웃클럽',
                icon: '2021/03/goc_icon.png',
                badge: '2021/03/goc_badge.png',
                link: '/goc',
                hColor: '#0000FF',
            },
            {
                id: 11113,
                label: '블랙프라이데이',
                icon: '2021/03/black_friday_icon.png',
                badge: '2021/03/black_friday_badge.png',
                link: '/black_friday',
                hColor: '#000000',
            },
        ],
        homeBanner: [
            // {
            //     id: 21111,
            //     type: 'video',
            //     file: '/img/img@2x.png',
            //     text: '블랙프라이데이 전상품 50% 할인중!',
            // },

            {
                cta: {
                    btnLink: '',
                    btnText: '할인',
                    isVisibleBtn: true,
                },
                file_dt: '/img/banner@3x.png',
                file_mo: '/img/img@2x.png',
                text: '블랙프라이데이 전상품 50% 할인중!',
                type: 'video',
            },
            {
                cta: {
                    btnLink: '',
                    btnText: '지금',
                    isVisibleBtn: true,
                },
                file_dt: '/img/banner@3x.png',
                file_mo: '/img/img@2x.png',
                text: '블랙프라이데이 전상품 50% 할인중!',
                type: 'video',
            },
        ],
        homePromotion: [
            {
                isLoginUserDisplay: true,
                text: '무료 장난감 + 쿠폰 받기!',
                icon: '/img/img-line-banner-thumbnail@2x.png',
                fontColor: '#81d742',
                bgColor: '#8224e3',
            },
            {
                isLoginUserDisplay: false,
                text: '가입하고 무료 장난감 + 쿠폰 받기!',
                icon: '/img/img-line-banner-thumbnail@2x.png',
                fontColor: '#dd9933',
                bgColor: '#1e73be',
            },
        ],
        // homePromotion: {
        //     id: 31111,
        //     isLogin: true,
        //     text: '가입하고 무료 장난감 + 쿠폰 받기!',
        //     icon: '/img/img-line-banner-thumbnail@2x.png',
        //     fontColor: 'black',
        //     bgColor: '#E6E6FA',
        // },
        homeSection: [
            {
                id: 41111,
                type: 'collection',
                title: '어떤 제품을 \n 찾고 계시나요?',
                contents: [
                    {
                        id: 51112,
                        title: '생활용품',
                        icon: '/img/m-icon-home-category-toy@2x.png',
                        link: '/care',
                        badgeUrl: '/img/badge_text.png',
                    },
                    {
                        id: 51112,
                        title: '생활용품',
                        icon: '/img/m-icon-home-category-toy@2x.png',
                        link: '/care',
                    },
                    {
                        id: 51112,
                        title: '생활용품',
                        icon: '/img/m-icon-home-category-toy@2x.png',
                        link: '/care',
                    },
                    {
                        id: 51112,
                        title: '생활용품',
                        icon: '/img/m-icon-home-category-toy@2x.png',
                        link: '/care',
                    },
                    {
                        id: 51114,
                        title: '간식',
                        icon: '/img/m-icon-home-category-toy@2x.png',
                        link: '/eatsfun',
                    },
                    {
                        id: 51114,
                        title: '간식',
                        icon: '/img/m-icon-home-category-toy@2x.png',
                        link: '/eatsfun',
                    },
                    {
                        id: 51114,
                        title: '간식',
                        icon: '/img/m-icon-home-category-toy@2x.png',
                        link: '/eatsfun',
                    },
                    {
                        id: 51114,
                        title: '간식',
                        icon: '/img/m-icon-home-category-toy@2x.png',
                        link: '/eatsfun',
                    },
                ],
            },
            {
                id: 41112,
                type: 'custom_highlight',
                image: 'img/img22@2x.png',
                title: '매 달 필요한 제품만 받아보세요!',
                text:
                    '베이컨박스는 합리적인 가격으로 우리집 반려견에게 필요한 제품만 받아보실 수 있습니다.',
                btnText: '자세히보기',
                btnLink: '/highlight',
            },
            {
                id: 41113,
                type: 'flex',
                title: '오늘만 특별 할인',
                filterType: 'server',
                hasBadge: true,
                btnText: '인기 상품 더보기',
                btnLink: '/products',
                categories: [
                    {
                        id: null,
                        name: '전체',
                        slug: 'product-all',
                    },
                    {
                        id: 61111,
                        name: '장난감',
                        slug: 'product-toys',
                    },
                    {
                        id: 61112,
                        name: '간식',
                        slug: 'product-eatsfun',
                    },
                    {
                        id: 61113,
                        name: '의류/액세서리',
                        slug: 'product-accs',
                    },
                    {
                        id: 61114,
                        name: '산책/생활용품',
                        slug: 'product-daily',
                    },
                ],
                products: [
                    {
                        id: 71111,
                        name: '핫초코 노즈워크 토이',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '신나는 노즈워크!',
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71112,
                        name: '삼계탕 프렌즈 토이 세트',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '신나는 노즈워크!',
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71113,
                        name: '눈사람 헌팅 토이',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '',
                        customText: '신나는 노즈워크!',
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71114,
                        name: '장 케어 유산균',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '맛있는 간식!',
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71115,
                        name: '베지 앤 후르츠 믹스 칩',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '맛있는 간식!',
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71117,
                        name: '미키 블랭킷 코트',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '간지나는 액세서리!',
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71118,
                        name: '멍돌프 머리띠',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '간지나는 액세서리!',
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71120,
                        name: '마사지 브러시',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '넘좋은 용품!',
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                ],
            },
            {
                id: 41114,
                type: 'fix',
                title: '오늘만 특별 할인',
                filterType: 'client',
                btnText: '특별 할인 상품 모두 보기',
                btnLink: '/collection?name=20210314',
                categories: [
                    {
                        id: null,
                        name: '전체',
                        slug: 'product-all',
                    },
                    {
                        id: 61111,
                        name: '장난감',
                        slug: 'product-toys',
                    },
                    {
                        id: 61112,
                        name: '간식',
                        slug: 'product-eatsfun',
                    },
                    {
                        id: 61113,
                        name: '의류/액세서리',
                        slug: 'product-accs',
                    },
                    {
                        id: 61114,
                        name: '산책/생활용품',
                        slug: 'product-daily',
                    },
                ],
                products: [
                    {
                        id: 71111,
                        name: '핫초코 노즈워크 토이',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '신나는 노즈워크!',
                        categories: [
                            {
                                id: 61111,
                                name: '장난감',
                                slug: 'product-toys',
                            },
                        ],
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71112,
                        name: '삼계탕 프렌즈 토이 세트',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '신나는 노즈워크!',
                        categories: [
                            {
                                id: 61111,
                                name: '장난감',
                                slug: 'product-toys',
                            },
                        ],
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71113,
                        name: '눈사람 헌팅 토이',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '',
                        customText: '신나는 노즈워크!',
                        categories: [
                            {
                                id: 61111,
                                name: '장난감',
                                slug: 'product-toys',
                            },
                        ],
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71114,
                        name: '장 케어 유산균',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '맛있는 간식!',
                        categories: [
                            {
                                id: 61112,
                                name: '간식',
                                slug: 'product-eatsfun',
                            },
                        ],
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71115,
                        name: '베지 앤 후르츠 믹스 칩',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '맛있는 간식!',
                        categories: [
                            {
                                id: 61112,
                                name: '간식',
                                slug: 'product-eatsfun',
                            },
                        ],
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71117,
                        name: '미키 블랭킷 코트',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '간지나는 액세서리!',
                        categories: [
                            {
                                id: 61113,
                                name: '의류/액세서리',
                                slug: 'product-accs',
                            },
                        ],
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71118,
                        name: '멍돌프 머리띠',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '간지나는 액세서리!',
                        categories: [
                            {
                                id: 61113,
                                name: '의류/액세서리',
                                slug: 'product-accs',
                            },
                        ],
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71120,
                        name: '마사지 브러시',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '넘좋은 용품!',
                        categories: [
                            {
                                id: 61114,
                                name: '산책/생활용품',
                                slug: 'product-daily',
                            },
                        ],
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                ],
            },
            {
                id: 41115,
                type: 'product_highlight',
                title: '지금 만나는 신상!',
                btnText: '신상품 모두 보기',
                btnLink: '/collection?name=20210321',
                products: [
                    {
                        id: 71111,
                        name: '핫초코 노즈워크 토이',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '신나는 노즈워크!',
                        categories: [
                            {
                                id: 61111,
                                name: '장난감',
                                slug: 'product-toys',
                            },
                        ],
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71112,
                        name: '삼계탕 프렌즈 토이 세트',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '신나는 노즈워크!',
                        categories: [
                            {
                                id: 61111,
                                name: '장난감',
                                slug: 'product-toys',
                            },
                        ],
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71113,
                        name: '눈사람 헌팅 토이',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '',
                        customText: '신나는 노즈워크!',
                        categories: [
                            {
                                id: 61111,
                                name: '장난감',
                                slug: 'product-toys',
                            },
                        ],
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71114,
                        name: '장 케어 유산균',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '맛있는 간식!',
                        categories: [
                            {
                                id: 61112,
                                name: '간식',
                                slug: 'product-eatsfun',
                            },
                        ],
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71115,
                        name: '베지 앤 후르츠 믹스 칩',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '맛있는 간식!',
                        categories: [
                            {
                                id: 61112,
                                name: '간식',
                                slug: 'product-eatsfun',
                            },
                        ],
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71117,
                        name: '미키 블랭킷 코트',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '간지나는 액세서리!',
                        categories: [
                            {
                                id: 61113,
                                name: '의류/액세서리',
                                slug: 'product-accs',
                            },
                        ],
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71118,
                        name: '멍돌프 머리띠',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '간지나는 액세서리!',
                        categories: [
                            {
                                id: 61113,
                                name: '의류/액세서리',
                                slug: 'product-accs',
                            },
                        ],
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                    {
                        id: 71120,
                        name: '마사지 브러시',
                        regularPrice: '22000',
                        salePrice: '11000',
                        discountRate: '50',
                        promoText: '사은품 증정',
                        customText: '넘좋은 용품!',
                        categories: [
                            {
                                id: 61114,
                                name: '산책/생활용품',
                                slug: 'product-daily',
                            },
                        ],
                        images: [
                            {
                                id: 81111,
                                src: '/img/thumbnail-02@2x.png',
                            },
                            {
                                id: 81112,
                                src: '/img/thumbnail-02@2x.png',
                            },
                        ],
                    },
                ],
            },
            {
                id: 41116,
                type: 'review',
                title: '생생한 리뷰',
                contents: [
                    {
                        id: 91111,
                        author: '뚱빈이',
                        stars: 5,
                        image: 'reviews/review_image_1.png',
                        text:
                            '우리 개가 환장하네요.. 소 간은 안 먹는데 소 허파는 좋아하는.. 미니 말고 큰 버젼도 있음 좋겠어요우리 개가 환장하ddddd ...',
                        date: '2021.02.02',
                        product: {
                            id: 71130,
                            name: '고아웃클럽 LED 산책 라이트',
                        },
                    },
                    {
                        id: 91112,
                        author: '탱이',
                        stars: 5,
                        image: 'reviews/review_image_2.png',
                        text: '진짜 너무너무나무나무 귀여워요 ㅎㅎㅎ 최곱니다',
                        date: '2021.01.20',
                        product: {
                            id: 71131,
                            name: '쪽지 노즈워크 토이',
                        },
                    },
                    {
                        id: 91113,
                        author: '찡이',
                        stars: 4,
                        image: 'reviews/review_image_3.png',
                        text: '재밌게잘가지고노네요귀여워요',
                        date: '2021.02.26',
                        product: {
                            id: 71132,
                            name: '토끼 슬리퍼 토이 세트',
                        },
                    },
                    {
                        id: 91113,
                        author: '찡이',
                        stars: 4,
                        image: 'reviews/review_image_3.png',
                        text: '재밌게잘가지고노네요귀여워요',
                        date: '2021.02.26',
                        product: {
                            id: 71132,
                            name: '토끼 슬리퍼 토이 세트',
                        },
                    },
                    {
                        id: 91113,
                        author: '찡이',
                        stars: 4,
                        image: 'reviews/review_image_3.png',
                        text: '재밌게잘가지고노네요귀여워요',
                        date: '2021.02.26',
                        product: {
                            id: 71132,
                            name: '토끼 슬리퍼 토이 세트',
                        },
                    },
                    {
                        id: 91113,
                        author: '찡이',
                        stars: 4,
                        image: 'reviews/review_image_3.png',
                        text: '재밌게잘가지고노네요귀여워요',
                        date: '2021.02.26',
                        product: {
                            id: 71132,
                            name: '토끼 슬리퍼 토이 세트',
                        },
                    },
                    {
                        id: 91113,
                        author: '찡이',
                        stars: 4,
                        image: 'reviews/review_image_3.png',
                        text: '재밌게잘가지고노네요귀여워요',
                        date: '2021.02.26',
                        product: {
                            id: 71132,
                            name: '토끼 슬리퍼 토이 세트',
                        },
                    },
                ],
            },
            {
                id: 41117,
                type: 'insta',
                title: '#혼자 보기 아까운 순간포착',
                icon: 'insta/icon_1.png',
                instaName: '세상에 이런견이',
                instaId: 'dogs_what_happen',
                btnName: '세상에 이런견이 인스타그램',
                contents: [
                    {
                        image: 'insta/insta_image_1.png',
                        text:
                            '#모래찜질 #크어어시원~하다 #여름아돌아와#여름아돌아와 최대2 줄 넘어가면 ... #베이컨박스 #세상에이런견이',
                        btnLink: 'p/CKJZCNCH2Qx/',
                    },
                    {
                        image: 'insta/insta_image_2.png',
                        text:
                            '#노즈워크 #쪽지 #댕댕이 #베이컨박스 #세상에이런견이',
                        btnLink: 'p/CKJZCNCH2Qx/',
                    },
                ],
            },
        ],
    },
    success: true,
    statusCode: 200,
    message: 'no_message',
    contentType: 'application/json',
    redirectURI: 'no_redirect',
};
