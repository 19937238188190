import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import HomeTopSlider from '../../components/Sliders/HomeTopSlider/HomeTopSlider';
import HomeTopTextArea from '../../components/HomeTopTextArea/HomeTopTextArea';
import { defaultElementProps } from '../../types/types';
import DropDown from '../DropDown/DropDown';
import './CouponSelector.scss';

const CouponSelector = observer(({ couponList, ...otherProps }) => {
    const [typedCouponCode, setTypedCouponCode] = useState('');

    const { rootStore, cartPageStore } = React.useContext(AppContext);
    const { initData } = rootStore;
    const { setIsSpinnerOn } = rootStore;
    const {
        cartInfoList,
        couponInfo,
        applyCoupon,
        removeCoupon,
        setCartInfoList,
        showWriteCouponCode,
    } = cartPageStore;

    return (
        <div className="CouponSelectorWrapper" {...otherProps}>
            <div className="CouponSelectorCategoryText">
                <div>쿠폰 할인/혜택</div>
                {initData.isLogin && couponList.length !== 0 && (
                    <div className="couponCnt">{`${couponList.length}장  보유`}</div>
                )}
            </div>
            {/* 이거 컴포넌트가 하나 필요할듯? */}
            {initData && initData.isLogin && (
                <DropDown
                    // 쿠폰 코드 직접 입력 선택에 따른 조건값 걸기
                    className={`couponDropDown ${
                        showWriteCouponCode ? 'addCouponCode' : ''
                    }`}
                    defaultText="쿠폰 선택"
                    itemClickEvent={applyCoupon}
                    dropDownType="selectCoupon"
                    dropDownItems={
                        couponList
                            ? [...couponList, '쿠폰 코드 직접 입력']
                            : ['쿠폰 코드 직접 입력']
                    }
                />
            )}
            {(!initData.isLogin || showWriteCouponCode) && (
                <div className="couponCodeBox">
                    <input
                        onChange={(evt) => setTypedCouponCode(evt.target.value)}
                        value={typedCouponCode}
                        type="text"
                        placeholder="쿠폰 코드 입력"
                    />
                    <div
                        onClick={() => {
                            if (typedCouponCode === '') {
                                alert('쿠폰 코드를 입력해 주세요.');
                                return;
                            }
                            setIsSpinnerOn(true);
                            applyCoupon(typedCouponCode)
                                .then(() => setCartInfoList())
                                .then(() => setIsSpinnerOn(false));
                            setTypedCouponCode('');
                        }}
                        className="applyCouponBtn"
                    >
                        쿠폰 적용
                    </div>
                </div>
            )}
            {cartInfoList.items.coupon_discount_totals &&
                cartInfoList.items.coupon_discount_totals.length > 0 && (
                    <>
                        <div className="CouponSelectorCategoryText">
                            적용하신 쿠폰
                        </div>
                        <div className="appliedCouponList">
                            {cartInfoList.coupon_discount_totals.map(
                                (coupon) => (
                                    <div
                                        className="appliedCouponItem"
                                        key={coupon.couponId}
                                    >
                                        <div className="couponName">
                                            {coupon.coupon_name
                                                ? coupon.coupon_name
                                                : '쿠폰 할인'}
                                        </div>
                                        <div className="couponPriceBox">
                                            {coupon.discount_price !==
                                                '0원' && (
                                                <div className="couponPrice">
                                                    {coupon.discount_price}
                                                </div>
                                            )}
                                            <div
                                                onClick={async () => {
                                                    setIsSpinnerOn(true);

                                                    const res =
                                                        await removeCoupon(
                                                            coupon.couponId
                                                                ? coupon.couponId
                                                                : coupon.code
                                                        );

                                                    if (res) {
                                                        await setCartInfoList();
                                                    }

                                                    setIsSpinnerOn(false);
                                                }}
                                                className="couponDeleteBtn"
                                            >
                                                <img
                                                    src="/img/common-btn-list-close-16.svg"
                                                    alt="deleteButton"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </>
                )}
        </div>
    );
});

export default CouponSelector;
