import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import { fetchAndReceiveJSON } from '../../utils/ajaxUtils';
import {
    GET_DOG_INFO_URL,
    UPDATE_DOG_INFO_URL,
    GET_ORDER_DOG_INFO_URL,
    UPDATE_ORDER_DOG_INFO_URL,
} from '../../constants/constants';
import SurveyDogFoodPreference from '../SurveyDogFoodPreference/SurveyDogFoodPreference';
import dogBreedsInfo from './dogBreeds.json';
import { defaultElementProps } from '../../types/types';
import './AddDogInfo.scss';
import { getTenDigitNum, openChannelIO } from '../../utils/commonUtils';
import moment from 'moment';

interface IAddDogInfo extends defaultElementProps {
    closeAddDogInfoPopup?: any;
    targetId: number;
    dogId?: number;
    type?:String;
}

let showAlert = false;

const AddDogInfo: React.FunctionComponent<IAddDogInfo> = ({
    closeAddDogInfoPopup,
    targetId,
    dogId,
    type,
}) => {
    const { rootStore } = useContext(AppContext);
    const { setIsSpinnerOn, setIsDimBgOn } = rootStore;

    const [resultBreedSearching, setResultBreedSearching] = useState(
        dogBreedsInfo.DOG_BREEDS
    );
    const [showSearchingBox, setShowSearchingBox] = useState(false);
    const [inputValues, setInputValues] = useState({
        dogName: '',
        breed: '',
        birthday: '',
        sex: '',
        neutral: false,
        size: '',
        health: {
            skin: false,
            eye: false,
            age: false,
            bone: false,
            weight: false,
        },
    });

    const [preferenceValues, setPreferenceValues] = useState({
        sizeOfSnack: {
            small: false,
            big: false,
        },
        textureOfSnack: {
            hard: false,
            soft: false,
            oilly: false,
            wet: false,
            mixed: false,
        },
        shapeOfSnack: {
            stick: false,
            crispy: false,
            cookie: false,
            gum: false,
            bone: false,
            powder: false,
            driedMeat: false,
            dried: false,
        },
        quadruped: {
            all: false,
            cow: false,
            pig: false,
            kangaroo: false,
            deer: false,
            sheep: false,
        },
        poultry: {
            all: false,
            chicken: false,
            dock: false,
            turkey: false,
            chickenfeet: false,
        },
        meatEtc: {
            organ: false,
        },
        allMeats: false,
        allfish: false,
        allgrain: false,
        allfruits: false,
        fish: {
            pollack: false,
            salmon: false,
            anchovy: false,
            shark: false,
            tuna: false,
        },
        grain: {
            wheat: false,
            corn: false,
            rice: false,
            oatmeal: false,
        },
        fruits: {
            banana: false,
            blueberry: false,
            peach: false,
        },
        vegetables: {
            main: false,
            sweetpotato: false,
            potato: false,
            broccoli: false,
            carrot: false,
        },
        etc: {
            nuts: false,
            bean: false,
            cheese: false,
            egg: false,
        },
    });

    useEffect(() => {
        //처음 등록을 하는 경우엔 등록된 강아지 정보를 불러오지 않는다.
        if (!dogId) {
            return;
        }

        //등록된 강아지 정보가 있는 경우 강아지 정보를 불러온다.
        setIsSpinnerOn(true);
        const fetchData = async () => {
            const res = await fetchAndReceiveJSON({
                method: 'POST',
                url: type === 'subscription' ? GET_DOG_INFO_URL : GET_ORDER_DOG_INFO_URL,
                body: {
                    id: targetId,
                    dog_info: {
                        id: dogId,
                    },
                },
            });

            if (!res) {
                closeAddDogInfoPopup(false);
                return;
            }

            const birthMM =
                getTenDigitNum(res.birthMonth) === '00'
                    ? '01'
                    : getTenDigitNum(res.birthMonth);

            setInputValues({
                dogName: res.name,
                breed: res.breed,
                birthday: res.birthYear
                    ? birthdayValidation(res.birthYear + ' ' + birthMM)
                    : '',
                sex: res.sex === '남자아이' ? 'boy' : 'girl',
                neutral: res.neutralizing === 1 ? true : false,
                size: res.size
                    ? res.size === '소형견'
                        ? 'small'
                        : res.size === '중형견'
                        ? 'midi'
                        : 'big'
                    : 'small',
                health: {
                    skin: res.metaData.skin ? true : false,
                    eye: res.metaData.eye ? true : false,
                    age: res.metaData.old ? true : false,
                    bone: res.metaData.joint ? true : false,
                    weight: res.metaData.diet ? true : false,
                },
            });
            setPreferenceValues({
                sizeOfSnack: {
                    small: res.metaData.small ? true : false,
                    big: res.metaData.big ? true : false,
                },
                textureOfSnack: {
                    hard: res.metaData.hard ? true : false,
                    soft: res.metaData.soft ? true : false,
                    oilly: res.metaData.greesy ? true : false,
                    wet: res.metaData.wet ? true : false,
                    mixed: res.metaData.mix ? true : false,
                },
                shapeOfSnack: {
                    stick: res.metaData.stick ? true : false,
                    crispy: res.metaData.chips ? true : false,
                    cookie: res.metaData.cookie ? true : false,
                    gum: res.metaData.gum ? true : false,
                    bone: res.metaData.bone ? true : false,
                    powder: res.metaData.powder ? true : false,
                    driedMeat: res.metaData.jerky ? true : false,
                    dried: res.metaData.freezeDrying ? true : false,
                },
                quadruped: {
                    all: res.metaData.fourlegs ? true : false,
                    cow: res.metaData.beef ? true : false,
                    pig: res.metaData.pork ? true : false,
                    kangaroo: res.metaData.kangarooMeat ? true : false,
                    deer: res.metaData.venison ? true : false,
                    sheep: res.metaData.lamb ? true : false,
                },
                poultry: {
                    all: res.metaData.poultry ? true : false,
                    chicken: res.metaData.chicken ? true : false,
                    dock: res.metaData.duck ? true : false,
                    turkey: res.metaData.turkey ? true : false,
                    chickenfeet: res.metaData.chickenFoot ? true : false,
                },
                meatEtc: {
                    organ: res.metaData.specialMeat ? true : false,
                },
                allMeats: res.metaData.allMeat ? true : false,
                allfish: res.metaData.seafood ? true : false,
                allgrain: res.metaData.grain ? true : false,
                allfruits: res.metaData.fruits ? true : false,
                fish: {
                    pollack: res.metaData.pollock ? true : false,
                    salmon: res.metaData.salmon ? true : false,
                    anchovy: res.metaData.anchovy ? true : false,
                    shark: res.metaData.shark ? true : false,
                    tuna: res.metaData.tuna ? true : false,
                },
                grain: {
                    wheat: res.metaData.wheat ? true : false,
                    corn: res.metaData.corn ? true : false,
                    rice: res.metaData.rice ? true : false,
                    oatmeal: res.metaData.oatmeal ? true : false,
                },
                fruits: {
                    banana: res.metaData.banana ? true : false,
                    blueberry: res.metaData.blueberry ? true : false,
                    peach: res.metaData.peach ? true : false,
                },
                vegetables: {
                    main: res.metaData.vegetable ? true : false,
                    sweetpotato: res.metaData.sweetpotato ? true : false,
                    potato: res.metaData.potato ? true : false,
                    broccoli: res.metaData.broccoli ? true : false,
                    carrot: res.metaData.carrot ? true : false,
                },
                etc: {
                    nuts: res.metaData.nuts ? true : false,
                    bean: res.metaData.bean ? true : false,
                    cheese: res.metaData.dairy ? true : false,
                    egg: res.metaData.egg ? true : false,
                },
            });
        };

        fetchData().then(() => {
            setIsSpinnerOn(false);
        });
    }, []);

    //기피 성품 선택시 alert 뜨게 해주는 로직
    useEffect(() => {
        const countOfSelected =
            Object.values(preferenceValues.quadruped).filter(
                (value) => value === true
            ).length +
            Object.values(preferenceValues.poultry).filter(
                (value) => value === true
            ).length +
            Object.values(preferenceValues.meatEtc).filter(
                (value) => value === true
            ).length +
            Object.values(preferenceValues.fish).filter(
                (value) => value === true
            ).length +
            Object.values(preferenceValues.grain).filter(
                (value) => value === true
            ).length +
            Object.values(preferenceValues.fruits).filter(
                (value) => value === true
            ).length +
            Object.values(preferenceValues.vegetables).filter(
                (value) => value === true
            ).length +
            Object.values(preferenceValues.etc).filter(
                (value) => value === true
            ).length;

        if (countOfSelected > 8 && !showAlert) {
            alert(
                '기피 사항 많으면 배송이 지연될 수 있습니다. \n 반려견이 꼭 피해야할 형태/성분만 골라주세요.'
            );
            showAlert = true;
        }
    }, [
        preferenceValues.quadruped,
        preferenceValues.poultry,
        preferenceValues.meatEtc,
        preferenceValues.fish,
        preferenceValues.grain,
        preferenceValues.fruits,
        preferenceValues.vegetables,
        preferenceValues.etc,
        showAlert,
    ]);

    //강아지 종류 입력시 검색 결과 세팅
    useEffect(() => {
        setResultBreedSearching(
            dogBreedsInfo.DOG_BREEDS.filter((value) =>
                value.dog_key.includes(inputValues.breed)
            )
        );
    }, [inputValues.breed]);

    //팝업 뒤 스크롤 막는 로직
    useEffect(() => {
        document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;`;

        // setIsDimBgOn(true);
        return () => {
            // setIsDimBgOn(false);

            const scrollY = document.body.style.top;
            document.body.style.cssText = '';
            window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
        };
    }, []);

    const updateDogInfo = async () => {

        const requestBody = {
            name: inputValues.dogName,
            breed: inputValues.breed,
            birthYear: inputValues.birthday.split('.')[0],
            birthMonth: inputValues.birthday.split('.')[1],
            sex: inputValues.sex === 'boy' ? '남자아이' : '여자아이',
            ...(inputValues.neutral ? { neutralizing: '1' } : null),
            size:
                inputValues.size === 'small'
                    ? '소형견'
                    : inputValues.size === 'midi'
                    ? '중형견'
                    : '대형견',
            metaData: {
                ...(inputValues.health.skin ? { skin: '1' } : null),
                ...(inputValues.health.eye ? { eye: '1' } : null),
                ...(inputValues.health.age ? { old: '1' } : null),
                ...(inputValues.health.bone ? { joint: '1' } : null),
                ...(inputValues.health.weight ? { diet: '1' } : null),
                ...(preferenceValues.sizeOfSnack.small ? { small: '1' } : null),
                ...(preferenceValues.sizeOfSnack.big ? { big: '1' } : null),
                ...(preferenceValues.textureOfSnack.hard
                    ? { hard: '1' }
                    : null),
                ...(preferenceValues.textureOfSnack.soft
                    ? { soft: '1' }
                    : null),
                ...(preferenceValues.textureOfSnack.oilly
                    ? { greesy: '1' }
                    : null),
                ...(preferenceValues.textureOfSnack.wet ? { wet: '1' } : null),
                ...(preferenceValues.textureOfSnack.mixed
                    ? { mix: '1' }
                    : null),
                ...(preferenceValues.shapeOfSnack.stick
                    ? { stick: '1' }
                    : null),
                ...(preferenceValues.shapeOfSnack.crispy
                    ? { chips: '1' }
                    : null),
                ...(preferenceValues.shapeOfSnack.cookie
                    ? { cookie: '1' }
                    : null),
                ...(preferenceValues.shapeOfSnack.gum ? { gum: '1' } : null),
                ...(preferenceValues.shapeOfSnack.bone ? { bone: '1' } : null),
                ...(preferenceValues.shapeOfSnack.powder
                    ? { powder: '1' }
                    : null),
                ...(preferenceValues.shapeOfSnack.driedMeat
                    ? { jerky: '1' }
                    : null),
                ...(preferenceValues.shapeOfSnack.dried
                    ? { freezeDrying: '1' }
                    : null),
                ...(preferenceValues.allMeats ? { allMeat: '1' } : null),
                ...(preferenceValues.quadruped.all ? { fourlegs: '1' } : null),
                ...(preferenceValues.quadruped.cow ? { beef: '1' } : null),
                ...(preferenceValues.quadruped.pig ? { pork: '1' } : null),
                ...(preferenceValues.quadruped.kangaroo
                    ? { kangarooMeat: '1' }
                    : null),
                ...(preferenceValues.quadruped.deer ? { venison: '1' } : null),
                ...(preferenceValues.quadruped.sheep ? { lamb: '1' } : null),
                ...(preferenceValues.poultry.all ? { poultry: '1' } : null),
                ...(preferenceValues.poultry.chicken ? { chicken: '1' } : null),
                ...(preferenceValues.poultry.dock ? { duck: '1' } : null),
                ...(preferenceValues.poultry.turkey ? { turkey: '1' } : null),
                ...(preferenceValues.poultry.chickenfeet
                    ? { chickenFoot: '1' }
                    : null),
                ...(preferenceValues.meatEtc.organ
                    ? { specialMeat: '1' }
                    : null),
                ...(preferenceValues.allfish ? { seafood: '1' } : null),
                ...(preferenceValues.fish.pollack ? { pollock: '1' } : null),
                ...(preferenceValues.fish.salmon ? { salmon: '1' } : null),
                ...(preferenceValues.fish.anchovy ? { anchovy: '1' } : null),
                ...(preferenceValues.fish.shark ? { shark: '1' } : null),
                ...(preferenceValues.fish.tuna ? { tuna: '1' } : null),
                ...(preferenceValues.allgrain ? { grain: '1' } : null),
                ...(preferenceValues.grain.wheat ? { wheat: '1' } : null),
                ...(preferenceValues.grain.corn ? { corn: '1' } : null),
                ...(preferenceValues.grain.rice ? { rice: '1' } : null),
                ...(preferenceValues.grain.oatmeal ? { oatmeal: '1' } : null),
                ...(preferenceValues.grain.wheat ? { wheat: '1' } : null),
                ...(preferenceValues.allfruits ? { fruits: '1' } : null),
                ...(preferenceValues.fruits.banana ? { banana: '1' } : null),
                ...(preferenceValues.fruits.blueberry
                    ? { blueberry: '1' }
                    : null),
                ...(preferenceValues.fruits.peach ? { peach: '1' } : null),
                ...(preferenceValues.vegetables.main
                    ? { vegetable: '1' }
                    : null),
                ...(preferenceValues.vegetables.sweetpotato
                    ? { sweetpotato: '1' }
                    : null),
                ...(preferenceValues.vegetables.potato
                    ? { potato: '1' }
                    : null),
                ...(preferenceValues.vegetables.broccoli
                    ? { broccoli: '1' }
                    : null),
                ...(preferenceValues.vegetables.carrot
                    ? { carrot: '1' }
                    : null),
                ...(preferenceValues.etc.nuts ? { nuts: '1' } : null),
                ...(preferenceValues.etc.bean ? { bean: '1' } : null),
                ...(preferenceValues.etc.cheese ? { dairy: '1' } : null),
                ...(preferenceValues.etc.egg ? { egg: '1' } : null),
            },
        };

        //반려견 생년월일 validation
        if(moment().isBefore(requestBody.birthYear + "." + requestBody.birthMonth) || Number(requestBody.birthMonth) > 12) {
            alert("반려견 생년월일을 확인해주세요!");
            return;
        }

        if (dogId) {
            const res = await fetchAndReceiveJSON({
                method: 'POST',
                url: type === 'subscription' ? UPDATE_DOG_INFO_URL : UPDATE_ORDER_DOG_INFO_URL,
                body: {
                    id: targetId,
                    dog_info: {
                        id: dogId,
                        ...requestBody,
                    },
                },
            });

            return res;
        } else {
            alert('강아지 정보가 없습니다. 상담 부탁드려요.');

            openChannelIO();
        }
    };

    const birthdayValidation = (str) => {
        
        if (!str) {
            return '';
        }

        str = str.toString().replace(/[^0-9]/g, '');

        var tmp = '';
        if (str.length < 5) {
            return str;
        } else if (str.length < 7) {
            tmp += str.substr(0, 4);
            tmp += '.';
            tmp += str.substr(4, 2);
            return tmp;
        }
    };

    return (
        <section className="AddDogInfo">
            <img
                onClick={() => {
                    closeAddDogInfoPopup(false);
                    setIsDimBgOn(false);
                }}
                className="closePopupButton"
                src="/img/btn-gnb-popup-close.svg"
                alt="close popup button"
            />
            <h3 className="addDogInfoTitle">
                고객님 반려견에 대해
                <br />
                알려주세요 :)
            </h3>
            <p className="subDesc">
                반려견에게 더욱 꼭 맞는 제품들로 보내드리겠습니다.
            </p>
            {inputs.dogInfo.map((input) => {
                return (
                    <div className="inputContainer">
                        <input
                            // onChange={
                            //     input.name === 'breed'
                            //         ? () => {
                            //               setShowSearchingBox(true);
                            //           }
                            //         : undefined
                            // }

                            onChange={
                                input.name === 'breed'
                                    ? (evt) => {
                                          setInputValues({
                                              ...inputValues,
                                              [evt.target.name]:
                                                  evt.target.value,
                                          });
                                          setShowSearchingBox(true);
                                      }
                                    : //     : (evt) => {
                                    //           setInputValues({
                                    //               ...inputValues,
                                    //               [evt.target.name]:
                                    //                   evt.target.value,
                                    //           });
                                    //       }

                                    input.name === 'birthday'
                                    ? (evt) => {
                                          setInputValues({
                                              ...inputValues,
                                              [evt.target.name]:
                                                  birthdayValidation(
                                                      evt.target.value
                                                  ),
                                          });
                                      }
                                    : (evt) => {
                                          setInputValues({
                                              ...inputValues,
                                              [evt.target.name]:
                                                  evt.target.value,
                                          });
                                      }
                            }
                            type={'text'}
                            pattern={input.name === 'birthday' && '[0-9]'}
                            maxLength={input.name === 'birthday' ? 7 : -1}
                            className={`dogInfoInputs ${
                                input.name === 'breed' &&
                                showSearchingBox &&
                                'breed'
                            }`}
                            placeholder={input.placeHolder}
                            value={inputValues[input.name]}
                            name={input.name}
                        />
                        <img
                            onClick={() => {
                                setInputValues({
                                    ...inputValues,
                                    [input.name]: '',
                                });
                            }}
                            className="clearInputFieldBtn"
                            src="/img/m-btn-text-field-delete-24.svg"
                            alt="clear input field"
                        />
                        {input.name === 'breed' && (
                            <div
                                className={`resultSearching ${
                                    showSearchingBox && 'show'
                                }`}
                            >
                                {resultBreedSearching.map((breed, idx) => {
                                    // console.log(breed);

                                    return (
                                        <div
                                            key={idx}
                                            onClick={() => {
                                                setInputValues({
                                                    ...inputValues,
                                                    breed: breed.dog_value,
                                                });
                                                setShowSearchingBox(false);
                                            }}
                                            className="searchingItem"
                                        >
                                            {breed.dog_value}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                );
            })}

            <h3 className="addDogInfoTitle mt">성별을 선택해주세요.</h3>
            <div className="buttonGroup sex">
                <div className="btnCardWrapper">
                    {inputs.sex.map((input) => {
                        return (
                            <div
                                onClick={() => {
                                    setInputValues({
                                        ...inputValues,
                                        sex: input.name,
                                    });
                                }}
                                // htmlFor={`sex-${input.name}`}
                                className="buttonItemWrapper"
                            >
                                <input
                                    type="radio"
                                    id={`sex-${input.name}`}
                                    value={input.value}
                                    name="sex"
                                />
                                <div
                                    className={`buttonItem ${
                                        inputValues.sex === input.name &&
                                        'selected'
                                    }`}
                                >
                                    {input.value}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div
                    onClick={() => {
                        setInputValues({
                            ...inputValues,
                            neutral: !inputValues.neutral,
                        });
                    }}
                    className={`neutralizingBtn ${
                        inputValues.neutral && 'selected'
                    }`}
                >
                    <img
                        src={
                            inputValues.neutral
                                ? '/img/m-btn-radio-active-16.svg'
                                : '/img/m-btn-radio-defualt-16.svg'
                        }
                        alt="select neutralizing dog"
                    />
                    중성화한 상태에요!
                </div>
            </div>
            <h3 className="addDogInfoTitle mt">사이즈를 선택해주세요.</h3>
            <div className="buttonGroup withImg">
                {inputs.size.map((input) => {
                    return (
                        <label
                            htmlFor={`size-${input.name}`}
                            className="buttonItemWrapper"
                        >
                            <input
                                onChange={() => {
                                    setInputValues({
                                        ...inputValues,
                                        size: input.name,
                                    });
                                }}
                                type="radio"
                                id={`size-${input.name}`}
                                value={input.value}
                                name="size"
                                checked={inputValues.size === input.name}
                            />
                            <div
                                className={`buttonItem ${
                                    inputValues.size === input.name &&
                                    'selected'
                                }`}
                            >
                                <img
                                    src={
                                        inputValues.size === input.name
                                            ? input.selectedIcon
                                            : input.icon
                                    }
                                    alt={`${input.name} size dog`}
                                />
                                <div>{input.value}</div>
                            </div>
                        </label>
                    );
                })}
            </div>
            <div className="titleContainer">
                <h3 className="addDogInfoTitle mt">
                    건강 상태 중 특별히 관심있는 <br />
                    부분이 있으신가요?
                </h3>
                <span>복수 선택 가능</span>
            </div>
            <div className="buttonGroup withImg mb">
                {inputs.health.map((input, idx) => {
                    return (
                        <label
                            htmlFor={`health-${input.name}`}
                            className="buttonItemWrapper"
                        >
                            <input
                                onClick={() => {
                                    setInputValues({
                                        ...inputValues,
                                        health: {
                                            ...inputValues.health,
                                            [input.name]:
                                                !inputValues.health[input.name],
                                        },
                                    });
                                }}
                                type="radio"
                                id={`health-${input.name}`}
                                value={input.value}
                                name="health"
                                checked={inputValues.health[idx]}
                            />
                            <div
                                className={`buttonItem ${
                                    inputValues.health[input.name]
                                        ? 'selected'
                                        : ''
                                }`}
                            >
                                <img
                                    src={
                                        inputValues.health[input.name]
                                            ? input.selectedIcon
                                            : input.icon
                                    }
                                    alt={input.name}
                                />
                                <div>{input.value}</div>
                            </div>
                        </label>
                    );
                })}
            </div>
            <div className="divider" />
            <SurveyDogFoodPreference
                preferenceValues={preferenceValues}
                setPreferenceValues={setPreferenceValues}
            />
            <div className="divider" />
            <SurveyDogFoodPreference
                type="ingredient"
                preferenceValues={preferenceValues}
                setPreferenceValues={setPreferenceValues}
            />
            <div className="divider" />
            <div
                onClick={() => {
                    setIsSpinnerOn(true);
                    updateDogInfo().then((res) => {

                        setIsSpinnerOn(false);

                        if (res?.id) {
                            // setIsSpinnerOn(false);
                            alert(
                                `잘 저장되었어요!\n변경된 정보는 향후 베이컨박스 맞춤 서비스에 이용됩니다.\n(이미 출고가 시작된 주문에는 반영되지 않아요.)`
                            );
                            closeAddDogInfoPopup(false);
                            setIsDimBgOn(false);
                        } else {
                            
                            // closeAddDogInfoPopup(false);
                            // setIsDimBgOn(false);
                        }

                        
                    });
                }}
                className="saveButton"
            >
                저장하기
            </div>
        </section>
    );
};

export default AddDogInfo;

const inputs = {
    dogInfo: [
        {
            placeHolder: '반려견 이름',
            name: 'dogName',
        },
        {
            placeHolder: '견종',
            name: 'breed',
        },
        {
            placeHolder: '반려견 생년월(YYYYMM)',
            name: 'birthday',
        },
    ],
    sex: [
        {
            name: 'girl',
            value: '여자아이',
        },
        {
            name: 'boy',
            value: '남자아이',
        },
    ],
    size: [
        {
            name: 'small',
            value: '소형견',
            icon: '/img/common-icon-dog-info-s-size-black.svg',
            selectedIcon: '/img/common-icon-dog-info-s-size-white.svg',
        },
        {
            name: 'midi',
            value: '중형견',
            icon: '/img/common-icon-dog-info-m-size-black.svg',
            selectedIcon: '/img/common-icon-dog-info-m-size-white.svg',
        },
        {
            name: 'big',
            value: '대형견',
            icon: '/img/common-icon-dog-info-l-size-black.svg',
            selectedIcon: '/img/common-icon-dog-info-l-size-white.svg',
        },
    ],
    health: [
        {
            name: 'skin',
            value: '피부와 털',
            icon: '/img/common-icon-dog-info-skin-black.svg',
            selectedIcon: '/img/common-icon-dog-info-skin-white.svg',
        },
        {
            name: 'eye',
            value: '눈 건강',
            icon: '/img/common-icon-dog-info-eye-black.svg',
            selectedIcon: '/img/common-icon-dog-info-eye-white-copy-4.svg',
        },
        {
            name: 'age',
            value: '노령견 케어',
            icon: '/img/common-icon-dog-info-age-black.svg',
            selectedIcon: '/img/common-icon-dog-info-age-white.svg',
        },
        {
            name: 'bone',
            value: '튼튼한 뼈',
            icon: '/img/common-icon-dog-info-bone-black.svg',
            selectedIcon: '/img/common-icon-dog-info-bone-white-copy-6.svg',
        },
        {
            name: 'weight',
            value: '체중 관리',
            icon: '/img/common-icon-dog-info-weight-black.svg',
            selectedIcon: '/img/common-icon-dog-info-weight-white-copy-3.svg',
        },
    ],
};
