
import React, { useContext, useEffect, useState } from 'react';
import { autoHypenPhone, validatePhonenum } from '../../utils/commonUtils';
import { AppContext } from '../../contexts/AppContext/AppContext';
import './ShippingInfoForm.scss';
import { fetchAndReceiveJSON } from '../../utils/ajaxUtils';
import SearchAddressPopup from '../SearchAddressPopup/SearchAddressPopup';
import { UPDATE_ORDER_URL , UPDATE_SHIPPING } from '../../constants/constants';

const ShippingInfoForm = (
    {
        orderId,
        shipping,
        customer_note,
        setIsShowPopup,
        isOrder,
        fetchParentData
    }
) => {

    // console.log("render shippingInfo form ");
    console.log(shipping);

    const { rootStore , accountStore } = useContext(AppContext);
    const { setIsSpinnerOn } = rootStore;
    // const { requestFetch } = accountStore;

    const [validAddressInfo, setValidAddressInfo] = useState({
        first_name: true,
        phone: true,
        postcode: true,
        address_1: true,
    });

    const [addressInfoValues, setAddressInfoValues] = useState({
        first_name: '',
        phone: '',
        postcode: '',
        address_1: '',
        address_2: '',
        customer_note: '',
    });

    useEffect(() => {
        setAddressInfoValues({
            first_name: shipping.first_name,
            phone: autoHypenPhone(shipping.phone?.replace(/'-'/g , '')),
            postcode: shipping.postcode,
            address_1: shipping.address_1,
            address_2: shipping.address_2,
            customer_note: customer_note,
        });
    }, []);

    const [openSearchAddressPopup, setOpenSearchAddressPopup] = useState(false);

    const changeInfoHandler = () => {
        if (addressInfoValues.first_name === '') {
            setValidAddressInfo({
                ...validAddressInfo,
                first_name: false,
            });
        } else if (!validatePhonenum(addressInfoValues.phone)) {
            setValidAddressInfo({
                ...validAddressInfo,
                phone: false,
            });
        } else if (addressInfoValues.postcode === '') {
            setValidAddressInfo({
                ...validAddressInfo,
                postcode: false,
            });
        } else if (addressInfoValues.address_1 === '') {
            setValidAddressInfo({
                ...validAddressInfo,
                address_1: false,
            });
        } else if (
            addressInfoValues.first_name !== '' &&
            validatePhonenum(addressInfoValues.phone) &&
            addressInfoValues.postcode !== '' &&
            addressInfoValues.address_1 !== ''
        ) {
            setValidAddressInfo({
                first_name: true,
                phone: true,
                postcode: true,
                address_1: true,
            });
            setIsSpinnerOn(true);
            const fetchData = async () => {
                const res = fetchAndReceiveJSON({
                    url: isOrder ? UPDATE_ORDER_URL : UPDATE_SHIPPING,
                    method: 'POST',
                    body: {
                        id : orderId,
                        shipping: {
                            first_name: addressInfoValues.first_name,
                            postcode: addressInfoValues.postcode,
                            address_1: addressInfoValues.address_1,
                            address_2: addressInfoValues.address_2,
                            phone: addressInfoValues.phone,
                        },
                        customer_note : addressInfoValues.customer_note,
                    },
                });

                return res;
            };

            fetchData().then((res) => {
                
                // requestFetch();
                fetchParentData();

                setIsSpinnerOn(false);
                setIsShowPopup(false);

            });
        }
    }


    return (
        <>
            <div className="shippingInfoFormWrapper">

                <div className="shippingInfoForm">
                    <div className="typeInfo">
                        배송지 정보 변경
                    </div>
                    <div className="inputsContainer">
                        {
                            deliveryAddressInputs.map((input) => {
                                if (input.placeHolder === '우편번호') {
                                    return (
                                        <div
                                            key={input.name}
                                            className={`inputFieldContainer ${validAddressInfo.postcode ===
                                                false && 'alert'
                                                }`}
                                        >
                                            <div
                                                // key={input.name}
                                                className="zipcodeLine"
                                            >
                                                <input
                                                    onChange={(evt) => {
                                                        setAddressInfoValues({
                                                            ...addressInfoValues,
                                                            [evt.target.name]:
                                                                evt.target
                                                                    .value,
                                                        });
                                                    }}
                                                    type="text"
                                                    name={input.name}
                                                    placeholder={
                                                        input.placeHolder
                                                    }
                                                    readOnly
                                                    defaultValue={
                                                        addressInfoValues[
                                                        input.name
                                                        ]
                                                    }
                                                />
                                                <div
                                                    onClick={() => {
                                                        setOpenSearchAddressPopup(
                                                            true
                                                        );
                                                    }}
                                                    className="searchAddressBtn"
                                                >
                                                    주소 찾기
                                                </div>
                                            </div>
                                            {validAddressInfo.postcode ===
                                                false && (
                                                    <div className="alertMsg">
                                                        {input.alert}
                                                    </div>
                                                )}
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            className={`inputFieldContainer ${validAddressInfo[
                                                input.name
                                            ] === false && 'alert'
                                                }`}
                                        >
                                            <input
                                                key={input.name}
                                                onChange={
                                                    input.name === 'phone'
                                                        ? (evt) => {
                                                            setAddressInfoValues(
                                                                {
                                                                    ...addressInfoValues,
                                                                    [evt
                                                                        .target
                                                                        .name]: autoHypenPhone(
                                                                            evt
                                                                                .target
                                                                                .value
                                                                        ),
                                                                }
                                                            );
                                                        }
                                                        : (evt) => {
                                                            setAddressInfoValues(
                                                                {
                                                                    ...addressInfoValues,
                                                                    [evt
                                                                        .target
                                                                        .name]:
                                                                        evt
                                                                            .target
                                                                            .value,
                                                                }
                                                            );
                                                        }
                                                }
                                                type="text"
                                                name={input.name}
                                                placeholder={
                                                    input.placeHolder
                                                }
                                                readOnly={
                                                    input.name ===
                                                    'address_1' && true
                                                }
                                                value={
                                                    addressInfoValues[
                                                    input.name
                                                    ]
                                                }
                                            />
                                            {input.name !== 'address_1' && (
                                                <div
                                                    onClick={() =>
                                                        setAddressInfoValues({
                                                            ...addressInfoValues,
                                                            [input.name]: '',
                                                        })
                                                    }
                                                    className="resetInputFieldBtn"
                                                />
                                            )}
                                            {validAddressInfo[input.name] ===
                                                false && (
                                                    <div className="alertMsg">
                                                        {input.alert}
                                                    </div>
                                                )}
                                        </div>
                                    );
                                }
                            })
                        }
                    </div>
                </div>
                <div onClick={
                    changeInfoHandler
                }
                    className="shippingInfoFormBtns">
                    <div className="btn">변경하기</div>
                </div>
            </div>
            {openSearchAddressPopup && (
                <SearchAddressPopup
                    setOpenSearchAddressPopup={setOpenSearchAddressPopup}
                    addressInfoValues={addressInfoValues}
                    setAddressInfoValues={setAddressInfoValues}
                />
            )}

        </>
    );
}

export default ShippingInfoForm;

const deliveryAddressInputs: Array<{
    name: string;
    placeHolder: string;
    alert?: string;
}> = [
        {
            name: 'first_name',
            placeHolder: '받는 분',
            alert: '받는 분을 입력해주세요',
        },
        {
            name: 'phone',
            placeHolder: '휴대전화 번호',
            alert: '휴대전화 번호를 입력해주세요',
        },
        {
            name: 'postcode',
            placeHolder: '우편번호',
            alert: '우편번호를 입력해주세요',
        },
        {
            name: 'address_1',
            placeHolder: '기본주소',
            alert: '기본주소를 입력해주세요',
        },
        {
            name: 'address_2',
            placeHolder: '상세주소',
        },
        {
            name: 'customer_note',
            placeHolder: '배송메모',
        },
    ];