import {makeAutoObservable} from 'mobx';

export class ModalStore {
    restockingModalState = {
        isVisible: false,
        productId: null
    };

    constructor() {
        makeAutoObservable(this);
    }

    setIsRestockingModalVisible = (bool: boolean) => {
        this.restockingModalState.isVisible = bool
    };
    setIsRestockingModalProductId = (id: number) => {
        this.restockingModalState.productId = id
    };
}
