import React, {useContext, useState, useEffect} from 'react';
import {AppContext} from '../../contexts/AppContext/AppContext';
import {observer} from 'mobx-react';
import './MiniCart.scss';
import {isEventPriceProduct} from '../../utils/commonUtils';
import {
    isDogFoodProduct,
    isSubscriptionProduct,
} from '../../utils/miniCartUtils';
import MinicartDropDownArea from './MinicartDropDownArea';
import MinicartSelectedArea from './MinicartSelectedArea';
import MinicartHighlightText from './MinicartHighlightArea';

const ProductBottomMiniCart = observer(({}) => {
    const {miniCartPopupStore, cartPageStore, rootStore} = useContext(AppContext);
    const {setCartListCount} = cartPageStore;
    const {
        initData,
        // setIsLoginModalOn,
        setSignUpModalOn,
    } = rootStore;

    const {
        miniCartProduct,
        miniCartProductQty,
        optionProducts,
        updateSelectedOption,
        updateSelectedDogOption,
        changeOptionProductQty,
        mainProductQtyInputHandler,
        optionProductQtyInputHandler,
        removeOptionProduct,
        miniCartPrice,
        changeMiniCartQty,
        addItemToCart,
        addItemToCartBuyNow,
        keys,
        setNextSelectOption,
        isExpand,
        setIsExpand,
        getSelectableOptions,
        addDataToOption,
        tempOptionProduct,
        subscriptionDiscountInfo,
    } = miniCartPopupStore;

    const [btnStatus, setBtnStatus] = useState(null);

    useEffect(() => {
        if (isExpand == false) {
            //init
            setBtnStatus(null);
        }
    }, [isExpand]);
    const {modalStore} =
        useContext(AppContext);
    const {setIsRestockingModalVisible, setIsRestockingModalProductId} = modalStore

    const title =
        miniCartProduct.type === 'variable' ||
        miniCartProduct.type === 'grouped' ||
        miniCartProduct.type === 'bundle' || miniCartProduct.type === 'variable-subscription'
            ? '옵션을 선택해주세요'
            : '수량을 선택해주세요';

    const isSubscriptionProd = isSubscriptionProduct(miniCartProduct);
    const isShowEventPrice = !initData.isWelcomeSpecialPriceCouponUsed && isEventPriceProduct(miniCartProduct);
    const guestHighlightDiv = <><span
        onClick={() => {
            // console.log("signup!");
        }}
        className="underlineTextBtn">로그인 또는 가입</span> 후 장바구니에서 쿠폰 적용 시 신규가입 100원 구매 혜택을 받으실 수 있습니다.</>;
    const miniCartHighlightText = initData.isLogin ? '장바구니에서 쿠폰 적용 시 신규가입 100원 혜택을 받으실 수 있습니다.' : guestHighlightDiv;

    let priceAdditionalInfo = null;
    if (subscriptionDiscountInfo) {
        // console.log("subcriptionDiscountInfo" , subscriptionDiscountInfo , miniCartProduct);
        priceAdditionalInfo = <span className="priceAddtionalText">2회차부터 <span
            style={{color: "#ed2e1b"}}>{subscriptionDiscountInfo.discountRate}% 할인 적용</span>되어 {subscriptionDiscountInfo.displayPrice}원으로 결제</span>;
    }

    // console.log(miniCartProduct);
    // console.log("redner ProductBottomMiniCart!");

    const btns = [];

    if (miniCartProduct.stockStatus === 'outofstock') {
        const unsaleBtn = miniCartProduct.saleInFuture ?
            <div className="cta-btn disabled">
                판매예정 상품입니다.
            </div> :
            <div className="cta-btn red"
                 onClick={() => {
                     setIsRestockingModalVisible(true);
                     setIsRestockingModalProductId(miniCartProduct.id);
                     event.stopPropagation();
                 }}>
                재입고 알림 받기
            </div>
        btns.push(unsaleBtn);
    } else {

        if (isSubscriptionProd) {
            const btn = <div
                className="cta-btn red"
                onClick={async () => {

                    if (isExpand) {
                        await addItemToCart();
                        setCartListCount()
                    } else {
                        setIsExpand(true);
                    }

                }}
            >
                정기배송 신청하기
            </div>;

            btns.push(btn);

        } else {

            if (isExpand) {

                if (btnStatus == 'buy') {
                    btns.push(<>
                        <div
                            id="add-cart-btn"
                            className="cta-btn"
                            onClick={async () => {
                                await addItemToCart();
                                setCartListCount()
                            }}
                        >
                            장바구니 담기
                        </div>
                        <div
                            id="buynow-btn"
                            className="cta-btn red"
                            onClick={() => {
                                addItemToCartBuyNow();
                            }}
                        >
                            바로 구매하기
                        </div>
                    </>);
                }
            } else {

                if (miniCartProduct.isNpayAvailable) {

                    btns.push(<div
                        className="cta-btn small"
                        onClick={() => {
                            // setIsNpayClicked(true);

                            setBtnStatus("npay");
                            setIsExpand(true);

                        }}
                    >
                        <div className="naverPayBtn">
                            <img
                                src="/img/common-logo-npay-02@2x.png"
                                alt="naver-pay"
                                style={{height: '20px'}}
                            />
                        </div>
                    </div>);

                }
                const buyBtn = <div
                    className="cta-btn red"
                    onClick={() => {
                        // setIsBuyClicked(true);
                        setBtnStatus("buy");
                        setIsExpand(true);
                    }}
                >
                    구매하기
                </div>;

                btns.push(buyBtn);
            }
        }
    }

    // console.log("btns", btns);

    return (
        <>
            <div className="miniCartTopArea">
                <div className="OptionTitle">{title}</div>

                <MinicartDropDownArea
                    miniCartProduct={miniCartProduct}
                    changeMiniCartQty={changeMiniCartQty}
                    mainProductQtyInputHandler={mainProductQtyInputHandler}
                    miniCartProductQty={miniCartProductQty}
                    setNextSelectOption={setNextSelectOption}
                    getSelectableOptions={getSelectableOptions}
                    keys={keys}
                    updateSelectedOption={updateSelectedOption}
                    updateSelectedDogOption={updateSelectedDogOption}
                    optionProducts={optionProducts}
                    addDataToOption={addDataToOption}
                    tempOptionProduct={tempOptionProduct}
                    removeOptionProduct={removeOptionProduct}
                />

                {/* 선택된 아이템 */}
                <MinicartSelectedArea
                    optionProducts={optionProducts}
                    miniCartProduct={miniCartProduct}
                    removeOptionProduct={removeOptionProduct}
                    changeOptionProductQty={changeOptionProductQty}
                    optionProductQtyInputHandler={optionProductQtyInputHandler}
                />
            </div>


            {/* bottomArea */}
            <div className="bottom-area">
                <div className="total-price-area">
                    <div className="total-price-main">
                        <div className="total-price-text">총 상품 금액</div>
                        <div className="total-price">
                            {miniCartPrice}
                            <span className="won">원</span>
                        </div>
                    </div>
                    {priceAdditionalInfo && <div className="priceAddtionalArea">{priceAdditionalInfo}</div>}
                </div>

                {isShowEventPrice &&
                    <MinicartHighlightText
                        text={miniCartHighlightText}
                        isClickable={!initData.isLogin}
                        clickAction={() => {
                            setSignUpModalOn(true)
                        }}
                    />
                }

                <div className="cta-btns2">
                    {btns.map((btn, idx) => {

                        return (
                            btn
                        );
                    })}

                    {miniCartProduct.stockStatus !== 'outofstock' && miniCartProduct.isNpayAvailable &&
                        (
                            <div
                                id="iamport-naverpay-product-button3"
                                style={{
                                    display:
                                        isExpand && btnStatus == 'npay'
                                            ? 'block'
                                            : 'none',
                                }}
                            ></div>
                        )}
                </div>
            </div>


        </>
    );
});

export default ProductBottomMiniCart;
