import { observer } from 'mobx-react';
import React from 'react';
import HomeTopSlider from '../../components/Sliders/HomeTopSlider/HomeTopSlider';
import HomeTopTextArea from '../../components/HomeTopTextArea/HomeTopTextArea';
import { defaultElementProps } from '../../types/types';
import './CartCouponSelectContainer.scss';
import { AppContext } from '../../contexts/AppContext/AppContext';
import CouponSelector from '../../components/CouponSelector/CouponSelector';
import PointSetter from '../../components/PointSetter/PointSetter';

const CartCouponSelectContainer = observer(({ couponList }) => {
    return (
        <div className="CartCouponAndPointContainer">
            <div className="couponTopText">쿠폰 적용</div>
            <CouponSelector
                className={'couponSelector'}
                couponList={couponList}
            />
            {/* <PointSetter /> */}
        </div>
    );
});

export default CartCouponSelectContainer;
