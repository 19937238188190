import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import 'swiper/swiper.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Thumbs,
    EffectFade,
} from 'swiper';
import { sliderProps } from '../../types/types';
import './Sliders.scss';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import ProgressBar from '../ProgressBar/ProgressBar';
import { addListener } from 'process';
import { BREAKPOINT_DT } from '../../constants/constants';
import { throttle } from 'lodash';

// 슬라이더를 쓰기 위해서는 SliderWrapper 로 감싸주고 슬라이드 할 것들을 넣어 주면 된다.

// withDefaultPagination = true, 기본 페이지네이션이 적용된다.
// withImagePagination = true, 커스텀 페이지네이션(이미지 썸네일)이 적용된다. slideData 가 필수로 주어져야 한다.

// 만약 슬라이더가 윈도우 리사이즈 시 정상 동작 하지 않는다면 파라미터를 구체적인 숫자로 집어 넣으면 동작한다. auto 로 두지말 고 1을 넣는다던가 하는 식으로 말이다

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Thumbs, EffectFade]);

// console.log(A11y);
// SwiperCore.use([Thumbs]);

const SliderWrapper: React.FunctionComponent<sliderProps> = observer(
    ({
        // slideData,
        className = '',
        overFlowVisible = false,
        children,
        // isFullWidth,
        withProgressBar,
        // totalSlide = 0,
        // wrapperWidth,
        withDefaultPagination,
        // withImagePagination,
        customNavigationClass,
        withDefaultNavigation,
        // isNavigationAroundProgressBar,
        spaceBetween,
        setActiveSlideIndex,
        slidesPerColumn,
        slidesPerView,
        slidesPerGroup,
        withProgressIndex,
        withThumbnailPagination,
        objectsForThumbNailPagination,
        effect,
        speed,
        withNavigationWithProgress,
        slideTargetOnResize,
        numberOfSlides,
        target,
        setCurrentSlideIdx,
        ...otherProps
    }) => {
        if (!speed) {
            speed = 300; // default speed
        }
        // 추후에 pagination, navigation 옵션으로 추가할 수 있게 할 것
        function RandId() {
            return Math.random()
                .toString(36)
                .replace(/[^a-z]+/g, '')
                .substr(2, 10);
        }
        const id = useMemo(() => {
            return RandId();
        }, []);

        const paginationEl = () => {
            if (withDefaultPagination) {
                return {
                    el: '.defaultPagenation',
                    clickable: true,
                };
            }
            // 추후 이미지 썸네일로 페이지네이션 할때 쓰게되 부분 그때 더 수정하기
            // if (withImagePagination) {
            //     return {
            //         el: '.defaultPagenation',
            //         clickable: true,
            //         renderBullet: (index, className) => {
            //             return (
            //                 '<span class="' +
            //                 className +
            //                 ' abc' +
            //                 '">' +
            //                 slideData[index] +
            //                 '</span>'
            //             );
            //         },
            //     };
            // }
            return {
                el: null,
            };
        };
        // custom인 경우 스타일은 Container수준에서 css로 정한다.
        const navigationEl = () => {
            if (customNavigationClass) {
                return customNavigationClass;
            } else if (withNavigationWithProgress) {
                return {
                    nextEl: `.swiper-button-next.withProgress.${id}`,
                    prevEl: `.swiper-button-prev.withProgress.${id}`,
                };
            } else if (withDefaultNavigation) {
                return {
                    nextEl: `.swiper-button-next.${id}`,
                    prevEl: `.swiper-button-prev.${id}`,
                };
            } else {
                return false;
            }
        };
        // 100으로 초기값을 설정해 두어야, 타겟이 바뀌어서 슬라이드가 바뀔때 프로그래스가 자연스럽게 바뀐다.
        const [totalSlide, setTotalSlide] = useState(100);

        const [curSlide, setCurSlide] = useState(1);
        const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
        // swiper 객체를 얻기 위해 사용함
        const [mainSwiper, setMainSwiper] = useState<any>(null);
        const setPaginationWidth = () => {
            if (mainSwiper && mainSwiper.pagination?.bullets) {
                // 이 컴포넌트가 데이터의 변화르 반영 하지 못함
                // 1. 데이터가 변화 하는 걸 어떻게 반영할 것인가
                // 2. 타겟 체인지로 리프렐시를 당해도 별 차이가 없는듯...
                // 이 숫자를 근본적으로 바꿀 수 있는 법
                // 한박자 늦게라도 바뀌긴 한다는거...

                setTotalSlide(mainSwiper.pagination.bullets.length);
            }
        };
        useEffect(() => {
            const throttleInner = throttle(() => {
                setPaginationWidth();
            }, 500);

            if (withProgressBar) {
                setPaginationWidth();
                // TODO: 리사이즈 이벤트 헤비 하지않은지 체크

                window.addEventListener('resize', throttleInner);
            }

            return () => {
                if (withProgressBar) {
                    window.removeEventListener('resize', throttleInner);
                }
            };
        }, [mainSwiper]);

        useEffect(() => {
            const checkPageLength = () => {
                const bullets = document.querySelectorAll(
                    `#${id}page .swiper-pagination-bullet`
                );
                const bulletLength = bullets?.length;
                // console.log(bulletLength, 'lenght', id);
                // 0번 슬라이드로 슬라이드 시킴
                mainSwiper && mainSwiper.slideTo(0, 500);
                setTotalSlide(bulletLength ? bulletLength : 100);
            };
            setTimeout(checkPageLength, 300);
        }, [numberOfSlides, target]);

        // overflow visoble을 false 로 만든다.
        // 이 컴포넌트의 클래스네임을 기준으로 전체 컴포넌트의 길이를 잡는다.
        // 이 컴포넌트의 자식들을 모두 셀렉팅해서 길이를 젠다.
        // 이걸 리사이즈 할 때 마다 다시한다.

        return (
            // ex) 부모 컴포넌트 width / siderPerView 가 하나의 슬라이더 width 가 됨
            // 특히, 이 width 를 %로 주는 경우 개별 슬라이더의 너비가 반응형이 된다

            <div
                className={`${className}`}
                style={{
                    width: '100%',
                    userSelect: 'none',
                }}
            >
                {withThumbnailPagination && (
                    <div
                        style={{ width: '100%' }}
                        className="thumbnailPaginationWrapper"
                    >
                        <Swiper
                            onSwiper={setThumbsSwiper}
                            spaceBetween={12}
                            width={160}
                            slidesPerView={3}
                            // freeMode={true}
                            watchSlidesVisibility={true}
                            watchSlidesProgress={true}
                            slideToClickedSlide={false}
                            preventClicks={true}
                            style={{
                                overflow: 'visible',
                            }}
                            breakpoints={{
                                [BREAKPOINT_DT]: {
                                    width: 260,
                                    spaceBetween: 24,
                                    touchRatio: 0,
                                    allowSlideNext: false,
                                    allowSlidePrev: false,
                                },
                            }}
                        >
                            {objectsForThumbNailPagination?.map((el, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <div
                                            className="thumbNailPageNations"
                                            style={{
                                                backgroundImage: `url(${el.mediaUrl})`,
                                            }}
                                        >
                                            {el.mediaType === 'video' && (
                                                <video
                                                    preload="metadata"
                                                    className="videoThumbnail"
                                                >
                                                    <source
                                                        src={el.mediaUrl}
                                                        type="video/mp4"
                                                    />
                                                </video>
                                            )}
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                )}

                <Swiper
                    key={thumbsSwiper}
                    onSwiper={(swiper) => {
                        setMainSwiper(swiper);
                        // console.log(swiper, target);
                    }}
                    slidesPerGroup={slidesPerGroup ? slidesPerGroup : 1}
                    slidesPerView={slidesPerView ? slidesPerView : 'auto'}
                    slidesPerColumn={slidesPerColumn ? slidesPerColumn : 1}
                    style={{
                        overflow: overFlowVisible ? 'visible' : '',
                    }}
                    slidesPerColumnFill={'row'}
                    spaceBetween={spaceBetween}
                    updateOnWindowResize={true}
                    loop={false}
                    speed={speed}
                    breakpoints={{
                        [BREAKPOINT_DT]: {
                            speed: 800,
                        },
                    }}
                    onSlideChange={(swiper) => {
                        setCurSlide(swiper.realIndex + 1);
                        if (setCurrentSlideIdx) {
                            setCurrentSlideIdx(swiper.realIndex + 1);
                        }
                        if (setActiveSlideIndex) {
                            // alert('1');
                            setActiveSlideIndex(swiper.realIndex);
                        }
                        // 비디오가 있는 경우 비디오가 active인 경우에 play를 시작하도록 한다.
                        let activeSlider, video;

                        activeSlider = swiper.slides[swiper.activeIndex];
                        if (activeSlider) {
                            video = activeSlider.querySelector('video');
                        }
                        video && video.play();
                    }}
                    onResize={(swiper) => {
                        if (typeof slideTargetOnResize !== 'number') {
                            return;
                        }
                        swiper.slideTo(slideTargetOnResize);
                    }}
                    // centerInsufficientSlides={true}
                    pagination={paginationEl()}
                    navigation={navigationEl()}
                    thumbs={{ swiper: thumbsSwiper }}
                    effect={effect}
                    {...otherProps}
                >
                    {children?.map
                        ? children?.map((el, i) => (
                              <React.Fragment key={i}>{el}</React.Fragment>
                          ))
                        : children}
                    {withDefaultPagination && (
                        <div
                            className="defaultPagenation"
                            id={id ? `${id}page` : ''}
                            style={{
                                visibility: withProgressBar
                                    ? 'hidden'
                                    : 'visible',
                                margin: withProgressBar ? 0 : '',
                                height: withProgressBar ? 0 : '',
                            }}
                        ></div>
                    )}

                    <div
                        style={{
                            position: 'unset',
                        }}
                    >
                        {customNavigationClass && (
                            <>
                                <div
                                    className={String(
                                        customNavigationClass.prevEl
                                    ).replace('.', ' ')}
                                ></div>
                                <div
                                    className={String(
                                        customNavigationClass.nextEl
                                    ).replace('.', ' ')}
                                ></div>
                            </>
                        )}

                        {withProgressBar && (
                            <div className="sliderProgressPosition">
                                {withNavigationWithProgress && (
                                    <div className="navWithProgressWrapper">
                                        <div
                                            className={`swiper-button-prev withProgress ${id}`}
                                        ></div>
                                        <div
                                            className={`swiper-button-next withProgress ${id}`}
                                        ></div>
                                    </div>
                                )}

                                <ProgressBar
                                    curSlide={curSlide}
                                    totalSlide={totalSlide}
                                    withProgressIndex={withProgressIndex}
                                />
                            </div>
                        )}
                    </div>
                </Swiper>
                {withDefaultNavigation && (
                    <>
                        <div className={`swiper-button-next ${id}`}></div>
                        <div className={`swiper-button-prev ${id}`}></div>
                    </>
                )}
            </div>
        );
    }
);

export default SliderWrapper;
