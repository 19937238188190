import React from 'react';
import './ProgressBar.scss';

const ProgressBar: React.FunctionComponent<{
    curSlide: number;
    totalSlide: number;
    withProgressIndex?: boolean;
}> = ({ curSlide, totalSlide, withProgressIndex }) => {
    //console.log(curSlide, totalSlide);
    // d

    return (
        <div className="progressBarWrapper">
            {withProgressIndex && (
                <div className="progressBarIndex">
                    <strong>{curSlide}/</strong>
                    {totalSlide}
                </div>
            )}

            <div className="progressBar">
                <div
                    className="progress"
                    style={{
                        width: `${(curSlide / totalSlide) * 100}%`,
                    }}
                ></div>
            </div>
        </div>
    );
};

export default ProgressBar;
