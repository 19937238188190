import React, { useRef, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import SearchAddressPopup from '../../components/SearchAddressPopup/SearchAddressPopup';
import { AppContext } from '../../contexts/AppContext/AppContext';
import './OrderAndCheckoutContainer.scss';
import { autoHypenPhone } from '../../utils/commonUtils';
import { defaultElementProps } from '../../types/types';
import { BREAKPOINT_TBL } from '../../constants/constants';

const OrderAndCheckoutContainer = observer(
    ({ hasSubscription, innerWidth, viewPurchaseDetail }) => {
        const { rootStore, cartPageStore, userInfoStore } =
            React.useContext(AppContext);
        const { setIsDimBgOn, setIsSpinnerOn, initData } = rootStore;
        const { goToOrder, setGoToOrder, paymentMethod, setPaymentMethod } =
            cartPageStore;
        const { userDeliveryInfo, setUserDeliveryInfo } = userInfoStore;

        // const [userInfoValues, setUserInfo] = useState({
        //     firstName: '',
        //     receiver: '',
        //     phone: '',
        //     email: '',
        //     postcode: '',
        //     address_1: '',
        //     address_2: '',
        //     deliveryMsg: '',
        // });
        const [openSearchAddressPopup, setOpenSearchAddressPopup] =
            useState<any>(false);

        useEffect(() => {
            //스토어에 세팅된 경우가 없는 경우는 initData를 이용하여 세팅한다.
            if (userDeliveryInfo.firstName === '') {
                setUserDeliveryInfo({
                    firstName: initData.userInfo.first_name,
                    receiver: initData.userInfo.shipping.first_name,
                    phone: initData.userInfo.shipping.phone,
                    email: initData.userInfo.email,
                    postcode: initData.userInfo.shipping.postcode,
                    address_1: initData.userInfo.shipping.address_1,
                    address_2: initData.userInfo.shipping.address_2,
                    deliveryMsg: '',
                });
            }
        }, []);

        return (
            <>
                {userDeliveryInfo && (
                    <div
                        className={`OrderAndCheckoutContainer ${
                            viewPurchaseDetail && innerWidth <= BREAKPOINT_TBL
                                ? 'addDimmer'
                                : ''
                        }`}
                        style={{ visibility: goToOrder ? 'visible' : 'hidden' }}
                    >
                        <div
                            onClick={() => {
                                setGoToOrder(false);
                                setIsDimBgOn(false);
                            }}
                            className="closeBtn"
                        >
                            <img
                                src="/img/common-btn-popup-close.svg"
                                alt="close-button"
                            />
                        </div>
                        <div className="orderAndCheckoutTitle">주문/결제</div>
                        <div className="orderAndCheckoutContentsArea">
                            <div className="ordererInfo">
                                <div className="orderAndCheckoutSubTitle">
                                    주문자 정보
                                </div>
                                <input
                                    // onChange={(evt) =>
                                    //     {return false;}
                                    // }
                                    type="text"
                                    placeholder="주문자 정보"
                                    name="firstName"
                                    value={userDeliveryInfo['firstName']}
                                    readOnly
                                />
                            </div>
                            <div className="deliveryInfo">
                                <div className="orderAndCheckoutSubTitle">
                                    배송지 정보
                                </div>
                                {deliveryInputs.map((input) => {
                                    if (input.id === 4) {
                                        return (
                                            <div
                                                key={input.id}
                                                className="addressInput"
                                            >
                                                <input
                                                    // onChange={(evt) =>
                                                    //     setUserInfo({
                                                    //         ...userInfo,
                                                    //         [evt.target.name]:
                                                    //             evt.target.value,
                                                    //     })
                                                    // }
                                                    type="text"
                                                    placeholder={
                                                        input.placeholder
                                                    }
                                                    name={input.name}
                                                    value={
                                                        userDeliveryInfo[
                                                            input.name
                                                        ]
                                                    }
                                                    readOnly
                                                />
                                                <div
                                                    onClick={() =>
                                                        setOpenSearchAddressPopup(
                                                            true
                                                        )
                                                    }
                                                    className="findAddressBtn"
                                                >
                                                    주소 찾기
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <input
                                                onChange={
                                                    input.id === 5
                                                        ? undefined
                                                        : input.name === 'phone'
                                                        ? (evt) => {
                                                              let targetVal =
                                                                  evt.target.value.trim();

                                                              if (
                                                                  input.name ==
                                                                  'phone'
                                                              ) {
                                                                  targetVal =
                                                                      autoHypenPhone(
                                                                          targetVal
                                                                      );
                                                              }

                                                              setUserDeliveryInfo(
                                                                  {
                                                                      ...userDeliveryInfo,
                                                                      [evt
                                                                          .target
                                                                          .name]:
                                                                          targetVal,
                                                                  }
                                                              );
                                                          }
                                                        : (evt) => {
                                                              setUserDeliveryInfo(
                                                                  {
                                                                      ...userDeliveryInfo,
                                                                      [evt
                                                                          .target
                                                                          .name]:
                                                                          evt
                                                                              .target
                                                                              .value,
                                                                  }
                                                              );
                                                          }
                                                }
                                                type="text"
                                                key={input.id}
                                                name={input.name}
                                                placeholder={input.placeholder}
                                                value={
                                                    userDeliveryInfo[input.name]
                                                }
                                                readOnly={
                                                    input.id === 5
                                                        ? true
                                                        : false
                                                }
                                            />
                                        );
                                    }
                                })}
                            </div>
                            <div className="checkoutMethods">
                                <div className="orderAndCheckoutSubTitle">
                                    결제 수단 선택
                                </div>
                                {hasSubscription ? (
                                    <div className="btnsGroup">
                                        <div className="group">
                                            <div
                                                className={
                                                    paymentMethod === 'kakao'
                                                        ? 'selected'
                                                        : ''
                                                }
                                                onClick={() => {
                                                    setPaymentMethod('kakao');
                                                }}
                                            >
                                                카카오페이
                                            </div>
                                            <div
                                                className={
                                                    paymentMethod === 'card'
                                                        ? 'selected'
                                                        : ''
                                                }
                                                onClick={() => {
                                                    setPaymentMethod('card');
                                                }}
                                            >
                                                카드결제(신용/체크)
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="btnsGroup">
                                        <div className="group">
                                            <div
                                                className={
                                                    paymentMethod === 'kakao'
                                                        ? 'selected'
                                                        : ''
                                                }
                                                onClick={() => {
                                                    setPaymentMethod('kakao');
                                                }}
                                            >
                                                카카오페이
                                            </div>
                                  
                                            <div
                                                className={
                                                    paymentMethod === 'payco'
                                                        ? 'selected'
                                                        : ''
                                                }
                                                onClick={() => {
                                                    setPaymentMethod('payco');
                                                }}
                                            >
                                                PAYCO 간편결제
                                            </div>
                                        </div>
                                        <div className="group">
                                        <div
                                                className={
                                                    paymentMethod === 'card'
                                                        ? 'selected'
                                                        : ''
                                                }
                                                onClick={() => {
                                                    setPaymentMethod('card');
                                                }}
                                            >
                                                카드결제(신용/체크)
                                            </div>
                                            <div
                                                className={
                                                    paymentMethod === 'vbank'
                                                        ? 'selected'
                                                        : ''
                                                }
                                                onClick={() => {
                                                    setPaymentMethod('vbank');
                                                }}
                                            >
                                                가상계좌 입금
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {openSearchAddressPopup && (
                            <SearchAddressPopup
                                userInfoValues={userDeliveryInfo}
                                setUserInfoValues={setUserDeliveryInfo}
                                setOpenSearchAddressPopup={
                                    setOpenSearchAddressPopup
                                }
                                isCartComponent={true}
                            />
                        )}
                    </div>
                )}
            </>
        );
    }
);

export default OrderAndCheckoutContainer;

//input element 재활용하기 위해 필요한 것
//1. placeholder
//2. 상태관리 -> 하나의 객체에서 프로퍼티로 접근해서 각각의 value를 받아야함
//3. onChange 이벤트

const deliveryInputs = [
    {
        id: 1,
        placeholder: '받는 분',
        name: 'receiver',
    },
    {
        id: 2,
        placeholder: '휴대전화 번호',
        name: 'phone',
    },
    {
        id: 3,
        placeholder: '이메일 주소',
        name: 'email',
    },
    {
        id: 4,
        placeholder: '우편번호',
        name: 'postcode',
    },
    {
        id: 5,
        placeholder: '받으실 주소',
        name: 'address_1',
    },
    {
        id: 6,
        placeholder: '상세 주소',
        name: 'address_2',
    },
    {
        id: 7,
        placeholder: '배송 메세지(예: 부재 시 전화주세요)',
        name: 'deliveryMsg',
    },
];
