import React, {useEffect, useState} from 'react';
import './OrderDetail.scss';
import {AppContext} from '../../contexts/AppContext/AppContext';
import {fetchAndReceiveJSON} from '../../utils/ajaxUtils';
import {toJS} from 'mobx';
import OrderProduct from '../OrderProduct/OrderProduct';
import {GET_ORDER_URL} from '../../constants/constants';
import {useHistory, useLocation} from 'react-router-dom';
import MyAccountPopup from '../MyAccountPopup/MyAccountPopup';
import ShippingInfoForm from '../ShippingInfoForm/ShippingInfoForm';
import CancelConfirm from '../CancelConfirm/CancelConfirm';
import {observer} from 'mobx-react';
import {hideChatBtn, openChannelIO} from '../../utils/commonUtils';
import moment from 'moment';
import {isMShopRenewalOrder} from '../../utils/orderUtils';

const OrderDetail = observer((
    {
        fetchData
    }
) => {
    const {rootStore} = React.useContext(AppContext);
    const {setIsSpinnerOn} = rootStore;

    const [isShippinInfoPopup, setIsShippingInfoPopup] = useState(false);
    const [isShowConfirmPopup, setIsShowComfirmPopup] = useState(false);
    const [isShowDeliveryInfo, setIsShowDeliveryInfo] = useState(false);

    const history = useHistory();
    const location = useLocation();

    const pathArr = location.pathname.split('/');
    const orderId = pathArr[pathArr.length - 1];

    const [order, setOrder] = useState(null);

    const fetchOrderData = async () => {
        setIsSpinnerOn(true);

        const resData = await fetchAndReceiveJSON({
            url: GET_ORDER_URL + '/' + orderId,
        });

        if (resData !== null && resData !== undefined) {
            setOrder(resData);
        }

        setIsSpinnerOn(false);
    };

    useEffect(() => {
        fetchOrderData();
    }, []);

    const refreshData = () => {

        fetchData();
        fetchOrderData();
    }

    useEffect(() => {
        hideChatBtn(isShowConfirmPopup || isShippinInfoPopup || isShowDeliveryInfo);
    }, [isShowConfirmPopup, isShippinInfoPopup, isShowDeliveryInfo]);

    if (!order || typeof order !== "object") {
        return <div style={{height: '100vh'}}></div>;
    }

    let createdDate = moment.utc(order.date_created);
    let paymentDate = createdDate.format('YYYY.MM.DD');
    let paymentDate2 = createdDate.format('YYYY-MM-DD HH:mm');

    const isShowAddtionalPrice =
        (order.product_discount_total?.length > 0 &&
            order.product_discount_total != '0원') ||
        order.coupons.length > 0;

    // console.log(order);

    const accountNum = (order.status === 'awaiting-vbank' || order.status === 'pending') && order.iamport_vbank_name ? order.iamport_vbank_name + ' ' + order.iamport_vbank_num : null;

    return (
        <div className="orderDetail">
            {order && (
                <>
                    <div className="orderInfo">
                        <div className="topArea">
                            <div
                                className="orderStatus">{order.status === 'preorder-received' ? "예약판매 발송 대기" : order.status_kr}
                            </div>
                            <div className="orderEtc">
                                {accountNum &&
                                    <div className="accountNum">
                                        {accountNum}
                                    </div>
                                }
                                {(order.status === 'order-received' || order.status === "preorder-received") && order.payment_method !== 'iamport_vbank' && (
                                    <div
                                        className="btn"
                                        onClick={() => {
                                            if (isMShopRenewalOrder(order)) {
                                                alert("해당 주문은 상담을 통해서만 취소가 가능합니다.");
                                                openChannelIO();
                                            } else if (order.total > 0) {
                                                setIsShowComfirmPopup(true);
                                            } else {
                                                openChannelIO();
                                            }
                                        }}
                                    >
                                        주문 취소
                                    </div>
                                )}
                                {order.status === 'shipping' &&
                                    order.msex_dlv_url?.length > 0 && (
                                        <div
                                            className="btn"
                                            onClick={() => {
                                                window.open(
                                                    order.msex_dlv_url,
                                                    '_blank'
                                                );
                                            }}
                                        >
                                            배송조회
                                        </div>
                                    )}
                            </div>
                        </div>

                        <div className="OrderInfoBox">
                            <div className="orderId">#{order.id}</div>
                            <div className="orderDate">{paymentDate}</div>
                        </div>
                    </div>
                    <div className="productContentArea">
                        <div className="productInfo">
                            {order.line_items?.length > 0 && (
                                <OrderProduct
                                    data={order}
                                    type={'order'}
                                    targetId={order.id}
                                />
                            )}
                        </div>
                    </div>

                    <div className="orderPriceArea">
                        <div className="titleArea">
                            <div className="title">주문 금액 정보</div>
                        </div>

                        <div className="priceDetailArea">
                            <div className="row">
                                <div className="name">상품 가격</div>
                                <div className="value">
                                    {order.display_regular_total}
                                    {/* <div className="won">
                                    원
                                </div> */}
                                </div>
                            </div>
                            {order.shipping_lines.length > 0 && (
                                <div className="row">
                                    <div className="keyArea">
                                        <div className="name">배송비</div>
                                        <div
                                            className="addIcon"
                                            onClick={() => {
                                                setIsShowDeliveryInfo(
                                                    !isShowDeliveryInfo
                                                );
                                            }}
                                        >
                                            <img
                                                className="question"
                                                src="/img/icon/question-mark.svg"
                                            />
                                            <div
                                                className={`deliveryInfoBox ${isShowDeliveryInfo
                                                    ? ''
                                                    : 'noShowing'
                                                }`}
                                            >
                                                40,000원 이상 구매시 무료배송
                                                입니다.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="value">
                                        +{order.shipping_lines[0].display_total}
                                    </div>
                                </div>
                            )}
                        </div>

                        <div
                            className={`priceAdditionalDetailArea ${isShowAddtionalPrice ? '' : 'mini'
                            }`}
                        >
                            {order.product_discount_total?.length > 0 &&
                                order.product_discount_total !== '0원' &&
                                <div className="row">
                                    <div className="name">
                                        상품 할인 금액
                                    </div>
                                    <div className="value">
                                        -{order.product_discount_total}
                                    </div>
                                </div>
                            }
                            {order.subscription_free_first_month_total && (
                                <div className="row">
                                    <div className="name">
                                        첫 달 무료 할인
                                    </div>
                                    <div className="value">
                                        -{order.subscription_free_first_month_total}
                                    </div>
                                </div>
                            )}
                            {order.coupons?.length > 0 &&
                                order.coupons.map((coupon) => {
                                    return (
                                        <div key={coupon.id} className="row">
                                            <div className="name">
                                                {coupon.coupon_name}
                                            </div>
                                            <div className="value">
                                                {coupon.display_amount}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>

                        <div className="priceTotal">
                            <div className="row">
                                <div className="name highlight">
                                    총 결제 금액
                                </div>
                                <div className="value">
                                    {order.display_total}
                                </div>
                            </div>
                            {order.status !== 'cancelled' &&
                                order.status !== 'refunded' && (
                                    <div className="paymentMethod">
                                        {/* 카카오페이 결제 (2020-05-18 19:07) */}
                                        {order.payment_method_title +
                                            ' (' +
                                            paymentDate2 +
                                            ')'}
                                    </div>
                                )}
                        </div>

                        {(order.status === 'cancelled' ||
                            order.status === 'refunded') && (
                            <>
                                <div className="borderMarginTop"/>
                                <div className="priceTotal">
                                    <div className="row">
                                        <div className="name">
                                            {order.status === 'cancelled'
                                                ? '취소'
                                                : '환불'}{' '}
                                            금액
                                        </div>
                                        <div className="value">
                                            {order.display_total}
                                        </div>
                                    </div>
                                    <div className="paymentMethod">
                                        {/* 카카오페이 결제 (2020-05-18 19:07) */}
                                        {order.payment_method_title +
                                            ' (' +
                                            paymentDate2 +
                                            ')'}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="shippingArea">
                        <div className="titleArea">
                            <div className="title">배송지 정보</div>
                            {order.shipping_updable && (
                                <div
                                    className="btn"
                                    onClick={() => {
                                        setIsShippingInfoPopup(true);
                                    }}
                                >
                                    배송지 변경
                                </div>
                            )}
                        </div>

                        <div className="shippingDetailArea">
                            <div className="row">
                                <div className="name">받는 분</div>
                                <div className="value">
                                    {order.shipping.last_name +
                                        order.shipping.first_name}
                                </div>
                            </div>
                            <div className="row">
                                <div className="name">휴대전화 번호</div>
                                <div className="value">{order.phone_num}</div>
                            </div>
                            <div className="row">
                                <div className="name">우편번호</div>
                                <div className="value">
                                    {order.shipping.postcode}
                                </div>
                            </div>
                            <div className="row">
                                <div className="name">기본주소</div>
                                <div className="value">
                                    {order.shipping.address_1}
                                </div>
                            </div>
                            <div className="row">
                                <div className="name">상세주소</div>
                                <div className="value">
                                    {order.shipping.address_2}
                                </div>
                            </div>
                            <div className="row">
                                <div className="name">배송 메시지</div>
                                <div className="value">
                                    {order.customer_note}
                                </div>
                            </div>
                        </div>

                        <div className="bottom-btns">
                            <div
                                className="bottom-btn"
                                onClick={() => {
                                    history.push('/my-account/orders');
                                }}
                            >
                                주문 목록 돌아가기
                            </div>
                        </div>
                    </div>

                    {isShippinInfoPopup && (
                        <MyAccountPopup
                            content={
                                <ShippingInfoForm
                                    orderId={order.id}
                                    shipping={{
                                        ...order.shipping,
                                        phone: order.phone_num,
                                    }}
                                    setIsShowPopup={setIsShippingInfoPopup}
                                    customer_note={order.customer_note}
                                    isOrder={true}
                                    fetchParentData={refreshData}
                                />
                            }
                            setIsShowPopup={setIsShippingInfoPopup}
                            isCustomPadding={false}
                            isFullPopup={false}
                        />
                    )}

                    {isShowConfirmPopup && (
                        <MyAccountPopup
                            content={
                                <CancelConfirm
                                    orderId={order.id}
                                    setIsShowPopup={setIsShowComfirmPopup}
                                    fetchParentData={refreshData}
                                />
                            }
                            setIsShowPopup={setIsShowComfirmPopup}
                            isCustomPadding={false}
                            isFullPopup={false}
                        />
                    )}
                </>
            )}
        </div>
    );
});

export default OrderDetail;
