
const LoginCustomPopup = ({
    data
}) => {
    return (
        <div className="alreadyRegisteredModalBg">
            <div className="alreadyRegisteredContent">
                <div className="alreadyRegisteredMainText">
                    {data.mainText}
                </div>
                {data.userData && <div className="alreadyRegisteredIdContainer">
                    <img
                        src="/img/icon-login-kakao.svg"
                        alt=""
                    />
                    {data.userData.kakao_account.email}
                </div>}

                <div className="alreadyRegisteredButtons">
                    <div
                        className="alreadyRegisteredCancleButton"
                        onClick={() => {
                            data.action();
                        }}
                    >
                        {data.actionBtnText}
                    </div>
                    <div
                        className="alreadyRegisteredLoginButton"
                        onClick={() => {
                            data.action2();
                        }}
                    >
                        {data.actionBtnText2}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginCustomPopup;
