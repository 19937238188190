import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {AppContext} from '../../contexts/AppContext/AppContext';
import SelectableOptionItem from '../SelectableOptionItem/SelectableOptionItem';
import './BBOptionSelector.scss';

const BBOptionSelector = observer(() => {
    const {bbPageStore, cartPageStore} = React.useContext(AppContext);
    // selected snack을 담는 방식,
    // 1.서버에서 받아온데이터를 바탕으로, 1, 6 12 monthly 를 저장한다.
    // 2. 간식을 선택하면,
    // 3.selectedPlan에 따라 적용되는 간식의 종류를 바꾼다.
    const {selectedProduct, showBBOptionBox, isBBOptionChanging} =
        cartPageStore;
    const {
        BBPageData,
        selectedSnack,
        setSelectedSnack,
        selectedPlan,
        setBBPageData,
        funTypeObject,
        selectedBB,
        setSelectedPlan
    } = bbPageStore;
    let bbDetails, bbSnacks, subs, once;
    if (BBPageData) {
        bbDetails = BBPageData.bbDetails;
        bbSnacks = bbDetails?.bbSnacks;

        subs = bbSnacks?.subs.variations;
        once = bbSnacks?.once.variations;
    }
    const [snackData, setSnackData] = useState<any>();
    // 여기서 질문은 각 간식을 클릭했을때 어떤 식으로 데이터를 저장 시킬 것인가
    // 내부 스테이트로 관리할 수 있을지 알아보자
    // snack의 once 와 subs를 까서 개월수 별로 집어 넣는다.
    // 컴포넌트가 가지고 있어야 하나 store 가 가지고 있어야 하나
    // 컴포넌트가 가지고, 이걸 가니고 개월수가 바뀔 때 마다 다시 렌더링을 해주자
    // 간식이 없는 경우의 로직
    // 어차피 0원은 0원임
    // 모든 데이터의 마지막에 0원을 넣으면 됨
    const [oneMonth, setOneMonth] = useState<any>();
    const [sixMonth, setSixMonth] = useState<any>();
    const [twelveMonth, setTwelveMonth] = useState<any>();
    const [monthly, setMonthly] = useState<any>();

    // const [snackVariations, setSnackVariations] = useState();
    useEffect(() => {
        if (selectedBB === 'Fun') {
            if (funTypeObject && funTypeObject.bbSubs && funTypeObject.bbSubs.length >= 3) {
                setSelectedPlan({...funTypeObject.bbSubs[3]});
            } else if (funTypeObject && funTypeObject.bbSubs) {
                setSelectedPlan({...funTypeObject.bbSubs[0]});
            }
        }
    }, []);
    // useEffect(() => {
    //     // 최초는 subs의 12월,
    //     // 변할때 마다 바꿔서 설정
    //     if (BBPageData) {
    //         const noSnack = subs.common[0];
    //         setOneMonth([...once._1month, noSnack]);
    //         setSixMonth([...subs._6months, noSnack]);
    //         setTwelveMonth([...subs._12months, noSnack]);
    //         setMonthly([...subs.monthly, noSnack]);
    //     }
    // }, [selectedPlan, BBPageData]);

    // useEffect(() => {
    //     if (BBPageData) {
    //         const noSnack = subs.common[0];
    //         switch (selectedPlan.slug) {
    //             case '_1month':
    //                 setSnackData([...once._1month, noSnack]);
    //                 break;
    //             case '_6months':
    //                 setSnackData([...subs._6months, noSnack]);
    //                 break;
    //             case '_12months':
    //                 setSnackData([...subs._12months, noSnack]);
    //                 break;
    //             case 'monthly':
    //                 setSnackData([...subs.monthly, noSnack]);
    //                 break;
    //             default:
    //                 setSnackData([...subs._12months, noSnack]);
    //                 break;
    //         }
    //     }
    // }, [selectedPlan, BBPageData]);
    // useEffect(() => {
    //     if (
    //         selectedProduct &&
    //         (oneMonth || sixMonth || twelveMonth || monthly)
    //     ) {
    //         switch (selectedProduct.variation[0].attributes[1].slug) {
    //             case '1month':
    //                 setSelectedSnack({
    //                     slug: selectedProduct.variation[0].attributes[0].slug,
    //                     oneMonth: oneMonth.filter(
    //                         (option) =>
    //                             option.slug ===
    //                             selectedProduct.variation[0].attributes[0].slug
    //                     ),
    //                 });
    //                 break;
    //             case '6months':
    //                 setSelectedSnack({
    //                     slug: selectedProduct.variation[0].attributes[0].slug,
    //                     sixMonth: sixMonth.filter(
    //                         (option) =>
    //                             option.slug ===
    //                             selectedProduct.variation[0].attributes[0].slug
    //                     ),
    //                 });
    //                 break;
    //             case '12months':
    //                 setSelectedSnack({
    //                     slug: selectedProduct.variation[0].attributes[0].slug,
    //                     twelveMonth: twelveMonth.filter(
    //                         (option) =>
    //                             option.slug ===
    //                             selectedProduct.variation[0].attributes[0].slug
    //                     ),
    //                 });
    //                 break;
    //             case 'monthly':
    //                 setSelectedSnack({
    //                     slug: selectedProduct.variation[0].attributes[0].slug,
    //                     monthly: monthly.filter(
    //                         (option) =>
    //                             option.slug ===
    //                             selectedProduct.variation[0].attributes[0].slug
    //                     ),
    //                 });
    //                 break;
    //         }
    //         // setSelectedSnack({
    //         //     slug: selectedProduct.variation[0].attributes[0].slug,

    //         //     twelveMonth: twelveMonth.filter(
    //         //         (option) =>
    //         //             option.slug ===
    //         //             selectedProduct.variation[0].attributes[0].slug
    //         //     ),
    //         // });
    //     }
    // }, [selectedProduct]);

    return (
        <div className="BBOptionSelectorWrapper">
            <div className="BBOptionTitle">
                {isBBOptionChanging
                    ? '베이컨박스'
                    : '함께 구매 시 더 저렴해요.'}
            </div>

            <div className="BBOptionDesc">
                즐거움이 가득한 <br/>
                프리미엄 맞춤 수제 간식.
            </div>
            <div className="BBOptions">
                {snackData &&
                    snackData.map((el, i) => {
                        if (selectedSnack && el.slug === selectedSnack.slug)
                            return (
                                <SelectableOptionItem
                                    key={i}
                                    thumbNail={el.image}
                                    name={el.name}
                                    price={`${el.displayPrice}원`}
                                    regularPrice={`${el.displayRegularPrice}원`}
                                    isOnSale={el.isOnSale}
                                    isSelected
                                    slug={el.slug}
                                    onClick={() => {
                                        // 여기서 클릭할 때, once, sub을 나누어서 등록
                                        // 구분하는 기준은 i 로 나누어서
                                        setSelectedSnack({
                                            slug: el.slug,
                                            oneMonth: oneMonth[i],
                                            sixMonth: sixMonth[i],
                                            twelveMonth: twelveMonth[i],
                                            monthly: monthly[i],
                                        });
                                    }}
                                />
                            );
                        else
                            return (
                                <SelectableOptionItem
                                    key={i}
                                    slug={el.slug}
                                    thumbNail={el.image}
                                    name={el.name}
                                    price={`${el.displayPrice}원`}
                                    regularPrice={`${el.displayRegularPrice}원`}
                                    isOnSale={el.isOnSale}
                                    onClick={() => {
                                        setSelectedSnack({
                                            slug: el.slug,
                                            oneMonth: oneMonth[i],
                                            sixMonth: sixMonth[i],
                                            twelveMonth: twelveMonth[i],
                                            monthly: monthly[i],
                                        });
                                    }}
                                />
                            );
                    })}
                {/* {selectedPlan.slug !== 'bb-term-1month'
                    ? subs &&
                      selectedSnack &&
                      selectedSnack.subs &&
                      subs.map((el, i) => {
                          if (selectedSnack.subs.id === el.id)
                              return (
                                  <SelectableOptionItem
                                      key={i}
                                      thumbNail={el.image}
                                      name={el.name}
                                      price={`${el.displayPrice}원`}
                                      regularPrice={`${el.displayRegularPrice}원`}
                                      isOnSale={el.isOnSale}
                                      isSelected
                                      slug={el.slug}
                                      onClick={() => {
                                          // 여기서 클릭할 때, once, sub을 나누어서 등록
                                          // 구분하는 기준은 i 로 나누어서

                                          setSelectedSnack({
                                              subs: subs[i],
                                              once: once[i],
                                          });
                                      }}
                                  />
                              );
                          else
                              return (
                                  <SelectableOptionItem
                                      key={i}
                                      thumbNail={el.image}
                                      name={el.name}
                                      price={`${el.displayPrice}원`}
                                      regularPrice={`${el.displayRegularPrice}원`}
                                      isOnSale={el.isOnSale}
                                      slug={el.slug}
                                      onClick={() => {
                                          setSelectedSnack({
                                              subs: subs[i],
                                              once: once[i],
                                          });
                                      }}
                                      
                                  />
                              );
                      })
                    : once &&
                      selectedSnack &&
                      selectedSnack.once &&
                      once.map((el, i) => {
                          if (selectedSnack.once.id === el.id)
                              return (
                                  <SelectableOptionItem
                                      key={i}
                                      thumbNail={el.image}
                                      name={el.name}
                                      price={`${el.displayPrice}원`}
                                      regularPrice={`${el.displayRegularPrice}원`}
                                      isOnSale={el.isOnSale}
                                      slug={el.slug}
                                      isSelected
                                      onClick={() => {
                                          // 여기서 클릭할 때, once, sub을 나누어서 등록
                                          // 구분하는 기준은 i 로 나누어서

                                          setSelectedSnack({
                                              subs: subs[i],
                                              once: once[i],
                                          });
                                      }}
                                  />
                              );
                          else
                              return (
                                  <SelectableOptionItem
                                      key={i}
                                      thumbNail={el.image}
                                      name={el.name}
                                      price={`${el.displayPrice}원`}
                                      regularPrice={`${el.displayRegularPrice}원`}
                                      isOnSale={el.isOnSale}
                                      slug={el.slug}
                                      onClick={() => {
                                          setSelectedSnack({
                                              subs: subs[i],
                                              once: once[i],
                                          });
                                      }}
                                  />
                              );
                      })} */}
            </div>
            {/* <div className="BBOptionDesc">
                반려견의 건강한 일상을 위한 <br />
                프리미엄 영양제
            </div>
            <div className="BBOptions" style={{ marginBottom: '40px' }}>
                <SelectableItemSlider />
            </div> */}
        </div>
    );
});

export default BBOptionSelector;
