import React, { useEffect, useState } from 'react';
import './CollectionSortingSelector.scss';
import PreloadImage from 'react-preload-image';
import ContentBox from '../ContentBox/ContentBox';
import { defaultElementProps } from '../../types/types';
import { useTranslation } from 'react-i18next';
import { MIconLinkArrow10, MIconLinkArrow16 } from '../SVGs/Svgs';
import { observer } from 'mobx-react';
import { AppContext } from '../../contexts/AppContext/AppContext';

interface CollectionSortingSelectorProps extends defaultElementProps {
    sortingOptions?: Array<any>;
}

const CollectionSortingSelector: React.FunctionComponent<CollectionSortingSelectorProps> =
    observer(
        ({
            sortingOptions = [
                {
                    name: '추천순',
                    type: 'basic',
                },
                {
                    name: '최신순',
                    type: 'new',
                },
                {
                    name: '인기순',
                    type: 'hot',
                },
                {
                    name: '리뷰순',
                    type: 'review',
                },
                {
                    name: '낮은가격순',
                    type: 'priceAs',
                },
                {
                    name: '높은가격순',
                    type: 'priceDs',
                },
                {
                    name: '높은할인순',
                    type: 'sale',
                },
            ],
        }) => {
            // const [isOpen, setIsOpen] = useState(false);

            const { collectionPageStore } = React.useContext(AppContext);

            const {
                curSort,
                setCurSort,
                isSortingBoxOpen,
                setIsSortingBoxOpen,
            } = collectionPageStore;

            // 액티브 조절,
            // 처음 열었을 때, 액티브한 요소를 밝힌다.
            // 다른 목록에 hover를 하는 경우 다른 쪽으로 포커스가 옮겨진다.
            // hover를 하면, 다른 것들의 active를 없애는 거,

            const removeFocusOnElements = (collectionSelectors: any) => {
                collectionSelectors.forEach((selector) => {
                    selector.classList.remove('focus');
                });
            };

            useEffect(() => {
                const collectionSelectors =
                    document.querySelectorAll('.collectionSort');
                collectionSelectors.forEach((selector) => {
                    selector.addEventListener('mouseover', (e: any) => {
                        removeFocusOnElements(collectionSelectors);
                        if (e) {
                            e.target.classList.add('focus');
                        }
                    });
                });

                const addCloseEventOnBody = (e) => {
                    if (
                        e.target.classList[0] === 'curCollection' ||
                        e.target.classList[0] === 'dropdownlogo' ||
                        e.target.classList[0] === 'collectionSort'
                    ) {
                        return;
                    }

                    // setIsOpen(false);
                };
                if (document.body) {
                    document.body.addEventListener(
                        'click',
                        addCloseEventOnBody
                    );
                }

                return () => {
                    document.body.removeEventListener(
                        'click',
                        addCloseEventOnBody
                    );
                };
            }, []);

            useEffect(() => {
                const collectionSelectors =
                    document.querySelectorAll('.collectionSort');
                removeFocusOnElements(collectionSelectors);
                collectionSelectors.forEach((selector) => {
                    if (selector.id == curSort) {
                        selector.classList.add('focus');
                    }
                });
            }, [isSortingBoxOpen, curSort]);

            return (
                <div
                    className="sortingSelectorWrapper"
                    onClick={() => {
                        setIsSortingBoxOpen(!isSortingBoxOpen);
                    }}
                >
                    <div className="collectionCurSort">
                        <div>
                            {
                                sortingOptions.filter(
                                    (sort) => sort.type === curSort
                                )[0].name
                            }
                        </div>
                        <img
                            className={`expandButton ${
                                isSortingBoxOpen ? 'expanded' : ''
                            } `}
                            src="/img/m-btn-accordion-arrow-open-16.svg"
                            alt=""
                        />
                    </div>

                    <div
                        className={`sortingOptions ${
                            isSortingBoxOpen ? 'open' : ''
                        }`}
                    >
                        {sortingOptions.map((el, i) => {
                            return (
                                <div
                                    className="collectionSort"
                                    key={i}
                                    id={el.name}
                                    onClick={() => {
                                        setCurSort(el.type);
                                    }}
                                >
                                    {el.name}
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
    );

export default CollectionSortingSelector;
