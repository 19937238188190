import React from 'react';

interface IProps {
    imgUrl: string;
    className?:string;
    style?:any;
}

export default function LazyImage(props: IProps) {
    const {imgUrl,className,style} = props
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const imgRef = React.useRef<HTMLImageElement>(null);
    const observer = React.useRef<IntersectionObserver>();
    React.useEffect(() => {
        observer.current = new IntersectionObserver(intersectionObserver);
        imgRef.current && observer.current.observe(imgRef.current);
    }, [])

    const intersectionObserver = (entries: IntersectionObserverEntry[], io: IntersectionObserver) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                io.unobserve(entry.target);
                setIsLoading(true);
            }
        })
    }

    return (
        <img className={className} style={style} ref={imgRef} src={isLoading ? imgUrl : ""} alt={""}/>
    )
}