import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react';
import LoginContent from '../../components/LoginContent/LoginContent';
import './LoginPage.scss';
import SignUpContent from '../../components/LoginContent/SignUpContent';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext/AppContext';

const LoginPage = observer(() => {

    const { rootStore} = React.useContext(AppContext);
    // 첫 화면 노출시 불러올 이미지
    const {
        initData
    } = rootStore;

    //login or singup
    const [isLoginContent, setIsLoginContent] = useState(true);

    let history = useHistory();
    if(initData && initData.isLogin) {
        history.push("");
    } 

    return (
        <>
            <Helmet>
                <title>로그인 | 베이컨박스</title>
            </Helmet>
            <div className="loginPageWrapper">
                {isLoginContent ? <LoginContent
                    setIsLoginContent={setIsLoginContent}

                /> : <SignUpContent 
                    setIsLoginContent={setIsLoginContent}
                />}
            </div>
        </>
    );
});

export default LoginPage;
