import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext/AppContext';
import PreloadImage from 'react-preload-image';
import ContentBox from '../ContentBox/ContentBox';
import { defaultElementProps } from '../../types/types';
import { useTranslation } from 'react-i18next';
import { MIconLinkArrow10 } from '../SVGs/Svgs';
import './ServiceSummaryCard.scss';
import { stringLineBreak } from '../../utils/commonUtils';
import Button from '../../components/Button/Button';
import { BREAKPOINT_DT, BREAKPOINT_TBL } from '../../constants/constants';
import LazyImage from "../../hooks/LazyImage";

interface ServiceSummaryProps extends defaultElementProps {
    imgUrl?: string;
    imgUrl2?: string;
    mainText?: string;
    subText?: string;
    buttonLink?: string;
    buttonText?: string;
    bgColor?: string;
}

const ServiceSummaryCard: React.FunctionComponent<ServiceSummaryProps> = ({
    imgUrl,
    imgUrl2,
    mainText,
    subText,
    buttonLink,
    bgColor,
    buttonText,
    ...otherProps
}) => {
    // ...으로 바뀌는 기능 구현
    // 어떻게  할 수 있지 ?

    // 길이 비교https,
    const { rootStore } = useContext(AppContext);
    const { initData } = rootStore;

    const history = useHistory();

    const { t } = useTranslation();
    return (
        <div className="ServiceSummaryWrapper" {...otherProps}>
            <ContentBox
                withShadow={true}
                className="ServiceSummaryCardBox"
                style={{
                    backgroundColor: bgColor,
                }}
            >
                {/* <img className="ServiceSummaryImage" src={imgUrl} alt="" /> */}
                <picture>
                    <source
                        srcSet={initData.resourcePrefix + imgUrl2}
                        media={`(min-width: ${BREAKPOINT_TBL}px)`}
                    ></source>
                    <LazyImage
                        className="ServiceSummaryImage"
                        imgUrl={initData.resourcePrefix + imgUrl}
                    />
                </picture>
            </ContentBox>
            <div className="ServiceSummaryBottomArea">
                <div className="ServiceSummaryMainText">
                    {mainText && stringLineBreak(mainText)}
                </div>
                <div className="ServiceSummarySubText">
                    {subText && stringLineBreak(subText)}
                </div>

                <Button
                    onClick={() => history.push(buttonLink)}
                    withShadow
                    className="ServiceSummaryButton"
                >
                    {buttonText}
                </Button>
            </div>
        </div>
    );
};

export default ServiceSummaryCard;
