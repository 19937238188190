import React from 'react';
import {observer} from 'mobx-react';
import {AppContext} from '../../contexts/AppContext/AppContext';
import {useCookies} from 'react-cookie';
import './ProductCard.scss';
import CartIcon from '../../assets/m-icon-gnb-btn-cart.svg';
import PreloadImage from 'react-preload-image';
import {defaultElementProps, product} from '../../types/types';
import {isEventPriceProduct, stringLineBreak} from '../../utils/commonUtils';
import {Link, useHistory, useParams} from 'react-router-dom';
import {DOG_FOOD_COLLECTION_SLUG} from '../../constants/constants';
import LazyImage from "../../hooks/LazyImage";

interface productCardProps extends defaultElementProps {
    // productId?: number;
    // images: Array<any>;
    // title: string;
    // regularPrice: string;
    // disCountedPrice: string;
    // disCountRate: string;
    // badgeText?: string;
    rank?: number;
    onTheImageBadgeText?: string;
    // isOnSale?: boolean;
    // reviewCount?: number;
    product?: any;
    isAddCartAvailable?: boolean;
    isSpecialOrderCard?: boolean;
    isHundredShop?: boolean;
}

const ProductCard: React.FunctionComponent<productCardProps> = observer(
    ({
         // productId,
         rank,
         // images,
         // title,
         // regularPrice,
         // disCountedPrice,
         // disCountRate,
         // badgeText,
         onTheImageBadgeText,
         // isOnSale,
         // reviewCount = 60,
         product,
         isAddCartAvailable = true,
         isSpecialOrderCard = false,
         isHundredShop = false,
     }) => {
        const {rootStore, miniCartPopupStore} = React.useContext(AppContext);
        const {initData, showSearch, setShowSearch} = rootStore;
        const {setIsDimBgOn} = rootStore;

        const {setIsMiniCartPopup, setMiniCartProduct, setIsFollowPopup} =
            miniCartPopupStore;

        let history = useHistory();

        const {slug} = useParams<{ slug: string }>();

        const showMiniCart = (e) => {
            e.stopPropagation();
            setMiniCartProduct(product);
            setIsFollowPopup(false);
            setIsMiniCartPopup(true);
            setIsDimBgOn(true);
        };

        // console.log(product);

        if (product.stockStatus === 'outofstock') {
            isAddCartAvailable = false;
        }

        const getDiscountRate = (discountRate) => {
            // console.log(discountRate);
            if (
                discountRate === null ||
                discountRate === undefined ||
                discountRate === '0%'
            ) {
                return '';
            }

            if (Number.isInteger(discountRate)) {
                return discountRate + '%';
            }

            return discountRate;
        };

        return (
            <>
                <div className="productCardWrapper">
                    <div
                        className="productCardThumbNailArea"
                        onClick={
                            isSpecialOrderCard
                                ? () => {
                                    window.open(
                                        'https://baconbox.typeform.com/to/ExEegRyo',
                                        '_blank'
                                    );
                                }
                                : (e) => {
                                    if (showSearch) {
                                        setShowSearch(false);
                                    }

                                    setIsFollowPopup(false);
                                    if (e) {
                                        if (e.metaKey || e.ctrlKey) {
                                            window.open(`/store/${product.slug}`);
                                            return;
                                        }
                                        // else window.location = url;
                                    }
                                    history.push(`/store/${product.slug}`);
                                }
                        }
                    >
                        {rank && <div className="productCardRank">{rank}</div>}
                        {!isSpecialOrderCard &&
                            slug === DOG_FOOD_COLLECTION_SLUG && (
                                <div className="labelForDogFood">
                                    할인 쿠폰 적용
                                </div>
                            )}
                        <LazyImage
                            className="productCardThumbNail"
                            style={{position:"absolute"}}
                            imgUrl={
                                isSpecialOrderCard
                                    ? product.imgSrc
                                    : product.images &&
                                    initData &&
                                    `${initData.resourcePrefix}${
                                        product.images[
                                        product.images.length - 1
                                            ].src
                                    }`
                            }
                        />

                        {isAddCartAvailable && (
                            // <img
                            //     onClick={(e) => showMiniCart(e)}
                            //     className="productCardCartIcon"
                            //     src={CartIcon}
                            //     alt=""
                            // />
                            <div
                                onClick={(e) => showMiniCart(e)}
                                className="productCardCartIcon"
                                // src={CartIcon}
                                // alt=""
                            />
                        )}
                    </div>
                    <div
                        className={`productCardTitle ${
                            slug === DOG_FOOD_COLLECTION_SLUG ? 'dogfood' : ''
                        }`}
                    >
                        {product.productBrand ? (
                            stringLineBreak(
                                `[${product.productBrand}]\n${product.name}`
                            )
                        ) : (
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: product.name,
                                }}
                            />
                        )}
                    </div>
                    {!isHundredShop ? (
                        <div className="productCardPriceWrapper">
                            <div className="productCardMainPrice">
                                {/* {product.price?.length > 0
                                ? `${product.price}원`
                                : ''} */}
                                {product.displayPrice}
                            </div>
                            {product.isOnSale &&
                                product.discountPrice !== '0' && (
                                    <div className="productCardDiscountRate">
                                        {product.discountRate.slice(
                                            2,
                                            product.discountRate.length - 1
                                        )}
                                    </div>
                                )}
                        </div>
                    ) : (
                        <div className="productCardPriceWrapper">
                            <div className="productCardMainPrice hundred">
                                {product.displayPrice}
                            </div>
                            <div className="hundredDiscountedPrice">100원</div>
                        </div>
                    )}
                    {product.isOnSale &&
                        product.discountPrice !== '0' &&
                        !isHundredShop && (
                            <div className="productCardRegularPrice">
                                {product.regularPrice}
                            </div>
                        )}
                    {product.stockStatus === 'outofstock' &&
                        (product.saleInFuture ? (
                            <div className="outofstock">판매예정</div>
                        ) : (
                            <div className="outofstock">일시품절</div>
                        ))}
                    {/* <div className="reviewCountWrapper">
                        <img
                            src="/img/icon-review-srat-10-black.svg"
                            className="reviewStarIcon"
                            alt=""
                        />
                        리뷰 <span className="reviewNumber">{60}</span>
                    </div> */}
                    {/* <div className="reviewCountWrapper">
                        <img
                            src="/img/icon-review-srat-10-black.svg"
                            className="reviewStarIcon"
                            alt=""
                        />
                        리뷰
                        <img
                            className="arrowIcon"
                            src="/img/m-icon-link-arrow-16-black.svg"
                        />
                    </div> */}
                    {product.promoText && (
                        product.promoText === "100원의 행복" ?
                            null :
                            <div className="productCardBadge">
                                {product.promoText}
                            </div>
                    )}
                </div>
            </>
        );
    }
);

export default ProductCard;
