import React, { useContext, useRef, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import SearchAddressPopup from '../SearchAddressPopup/SearchAddressPopup';
import { autoHypenPhone, validatePhonenum } from '../../utils/commonUtils';
import { fetchAndReceiveJSON } from '../../utils/ajaxUtils';
import { UPDATE_USER_INFO_URL } from '../../constants/constants';
import './ChangePersonalInfoPopup.scss';
import MyAccountPopup from '../MyAccountPopup/MyAccountPopup';
import AllSubscriptionShippingChange from '../AllSubscriptionShippingChange/AllSubscriptionShippingChange';

const ChangePersonalInfoPopup = ({
    userInfo,
    setUserInfo,
    openChangeInfoPopup,
    setOpenChangeInfoPopup,
}) => {
    const { rootStore, userInfoStore } = useContext(AppContext);
    const { setIsDimBgOn, setIsSpinnerOn } = rootStore;
    const { userDeliveryInfo, setUserDeliveryInfo } = userInfoStore;

    const [
        isChangeAllSubscriptionShippingPopup,
        setIsChangeAllSubscriptionShippingPopup,
    ] = useState(false);

    const [isAllSubscriptionShipping, setIsAllSubscriptionShipping] =
        useState(null);

    const [userInfoValues, setUserInfoValues] = useState({
        name: '',
        phone: '',
        username: '',
    });
    const [validUserInfo, setValidUserInfo] = useState({
        name: true,
        phone: true,
    });
    const [addressInfoValues, setAddressInfoValues] = useState({
        first_name: '',
        phone: '',
        postcode: '',
        address_1: '',
        address_2: '',
    });
    const [validAddressInfo, setValidAddressInfo] = useState({
        first_name: true,
        phone: true,
        postcode: true,
        address_1: true,
    });
    const [passwordInputValues, setPasswordInputValues] = useState({
        newPassword: '',
        checkNewPassword: '',
    });
    const [validPassword, setValidPassword] = useState(true);
    const [openSearchAddressPopup, setOpenSearchAddressPopup] = useState(false);

    useEffect(() => {
        setUserInfoValues({
            name: userInfo.first_name,
            phone: userInfo.billing.phone,
            username: userInfo.username,
        });
        setAddressInfoValues({
            first_name: userInfo.shipping.first_name,
            phone: userInfo.shipping.phone,
            postcode: userInfo.shipping.postcode,
            address_1: userInfo.shipping.address_1,
            address_2: userInfo.shipping.address_2,
        });
    }, []);

    useEffect(() => {
        if (isAllSubscriptionShipping || isAllSubscriptionShipping == false) {
            changeInfoHandler();
        }
    }, [isAllSubscriptionShipping]);

    // useEffect(() => {
    //     document.body.style.cssText = `
    //   position: fixed;
    //   top: -${window.scrollY}px;
    //   overflow-y: scroll;
    //   width: 100%;`;

    //     setIsDimBgOn(true);
    //     return () => {
    //         setIsDimBgOn(false);

    //         const scrollY = document.body.style.top;
    //         document.body.style.cssText = '';
    //         window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    //     };
    // }, []);

    const closePopupWhenClickDim = (ref) => {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsDimBgOn(false);
                    setOpenChangeInfoPopup({
                        isOpen: false,
                        type: '',
                    });
                }
            }

            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    };

    const wrapperRef = useRef(null);
    closePopupWhenClickDim(wrapperRef);

    const changeInfoHandler = () => {
        const fetchData = async () => {
            setIsSpinnerOn(true);

            const res = await fetchAndReceiveJSON({
                url: UPDATE_USER_INFO_URL,
                method: 'POST',
                body: getBody(),
            });

            if (res) {
                setUserInfo(res);
                setUserDeliveryInfo({
                    firstName: res.first_name,
                    receiver: res.shipping.first_name,
                    phone: res.shipping.phone,
                    email: res.email,
                    postcode: res.shipping.postcode,
                    address_1: res.shipping.address_1,
                    address_2: res.shipping.address_2,
                    deliveryMsg: '',
                });
            }

            setIsSpinnerOn(false);
            setOpenChangeInfoPopup(false);
            setIsDimBgOn(false);
        };

        if (openChangeInfoPopup.type === 'userInfo') {
            if (userInfoValues.name.length < 1) {
                setValidUserInfo({
                    ...validUserInfo,
                    name: false,
                });
            }

            if (!validatePhonenum(userInfoValues.phone)) {
                setValidUserInfo({
                    ...validUserInfo,
                    phone: false,
                });
            }

            if (
                userInfoValues.name.length > 0 &&
                validatePhonenum(userInfoValues.phone)
            ) {
                //update request
                setValidUserInfo({
                    name: true,
                    phone: true,
                });

                fetchData();
            }
        } else if (openChangeInfoPopup.type === 'deliveryAddressInfo') {
            if (addressInfoValues.first_name === '') {
                setValidAddressInfo({
                    ...validAddressInfo,
                    first_name: false,
                });
            } else if (!validatePhonenum(addressInfoValues.phone)) {
                setValidAddressInfo({
                    ...validAddressInfo,
                    phone: false,
                });
            } else if (addressInfoValues.postcode === '') {
                setValidAddressInfo({
                    ...validAddressInfo,
                    postcode: false,
                });
            } else if (addressInfoValues.address_1 === '') {
                setValidAddressInfo({
                    ...validAddressInfo,
                    address_1: false,
                });
            } else if (
                addressInfoValues.first_name !== '' &&
                validatePhonenum(addressInfoValues.phone) &&
                addressInfoValues.postcode !== '' &&
                addressInfoValues.address_1 !== ''
            ) {
                //update request
                setValidAddressInfo({
                    first_name: true,
                    phone: true,
                    postcode: true,
                    address_1: true,
                });

                fetchData();
            }
        } else {
            //password valid
            setValidPassword(
                passwordInputValues.newPassword.length >= 8 &&
                    passwordInputValues.newPassword ===
                        passwordInputValues.checkNewPassword
            );

            fetchData();
        }
    };

    const getBody = () => {
        let body = {};

        if (openChangeInfoPopup.type === 'userInfo') {
            body = {
                last_name: ' ',
                first_name: userInfoValues.name,
                billing: {
                    phone: userInfoValues.phone,
                },
                isAllSubscriptionShipping: isAllSubscriptionShipping,
            };
        } else if (openChangeInfoPopup.type === 'deliveryAddressInfo') {
            body = {
                first_name:
                    addressInfoValues.first_name.length > 0
                        ? addressInfoValues.first_name
                        : ' ',
                last_name: ' ',
                shipping: {
                    first_name: addressInfoValues.first_name,
                    postcode: addressInfoValues.postcode,
                    address_1: addressInfoValues.address_1,
                    address_2: addressInfoValues.address_2
                        ? addressInfoValues.address_2
                        : ' ',
                    phone: addressInfoValues.phone,
                },
                isAllSubscriptionShipping: isAllSubscriptionShipping,
            };
        } else {
            body = {
                last_name: ' ',
                first_name: userInfoValues.name ? userInfoValues.name : ' ',
                password: passwordInputValues.newPassword,
            };
        }

        return body;
    };

    return (
        <>
            {isChangeAllSubscriptionShippingPopup && (
                <MyAccountPopup
                    content={
                        <AllSubscriptionShippingChange
                            setIsChangeAllSubscriptionShipping={
                                setIsAllSubscriptionShipping
                            }
                            setIsShowPopup={setOpenChangeInfoPopup}
                        />
                    }
                    setIsShowPopup={setOpenChangeInfoPopup}
                    isCustomPadding={false}
                    isFullPopup={false}
                />
            )}

            {!isChangeAllSubscriptionShippingPopup && (
                <div className="ChangePersonalInfoPopup" ref={wrapperRef}>
                    <div className="closePopupBtn">
                        <img
                            onClick={() => {
                                setIsDimBgOn(false);
                                setOpenChangeInfoPopup({
                                    isOpen: false,
                                    type: '',
                                });
                            }}
                            src="/img/btn-gnb-popup-close-white.svg"
                            alt="close-popup-button"
                        />
                    </div>
                    <div className="personalInfoDetail">
                        <div className="typeInfo">
                            {openChangeInfoPopup.type === 'userInfo'
                                ? '회원 정보 변경'
                                : openChangeInfoPopup.type ===
                                  'deliveryAddressInfo'
                                ? '배송지 정보 변경'
                                : '비밀번호 변경'}
                        </div>
                        <div className="inputsContainer">
                            {openChangeInfoPopup.type === 'userInfo'
                                ? userInputs.map((input) => {
                                      return (
                                          <div
                                              className={`inputFieldContainer ${
                                                  validUserInfo[input.name] ===
                                                      false && 'alert'
                                              }`}
                                          >
                                              <input
                                                  key={input.name}
                                                  //   ref={inputRef}
                                                  onChange={
                                                      input.name === 'phone'
                                                          ? (evt) => {
                                                                setUserInfoValues(
                                                                    {
                                                                        ...userInfoValues,
                                                                        [evt
                                                                            .target
                                                                            .name]:
                                                                            autoHypenPhone(
                                                                                evt
                                                                                    .target
                                                                                    .value
                                                                            ),
                                                                    }
                                                                );
                                                            }
                                                          : (evt) => {
                                                                setUserInfoValues(
                                                                    {
                                                                        ...userInfoValues,
                                                                        [evt
                                                                            .target
                                                                            .name]:
                                                                            evt
                                                                                .target
                                                                                .value,
                                                                    }
                                                                );
                                                            }
                                                  }
                                                  type={'text'}
                                                  name={input.name}
                                                  placeholder={
                                                      input.placeHolder
                                                  }
                                                  readOnly={
                                                      input.placeHolder ===
                                                      '이메일 아이디'
                                                          ? true
                                                          : false
                                                  }
                                                  value={
                                                      userInfoValues[input.name]
                                                  }
                                              />
                                              {input.name !== 'username' && (
                                                  <div
                                                      onClick={() => {
                                                          setUserInfoValues({
                                                              ...userInfoValues,
                                                              [input.name]: '',
                                                          });
                                                      }}
                                                      className="resetInputFieldBtn"
                                                  />
                                              )}
                                              {validUserInfo[input.name] ===
                                                  false && (
                                                  <div className="alertMsg">
                                                      {input.alert}
                                                  </div>
                                              )}
                                          </div>
                                      );
                                  })
                                : openChangeInfoPopup.type ===
                                  'deliveryAddressInfo'
                                ? deliveryAddressInputs.map((input) => {
                                      if (input.placeHolder === '우편번호') {
                                          return (
                                              <div
                                                  className={`inputFieldContainer ${
                                                      validAddressInfo.postcode ===
                                                          false && 'alert'
                                                  }`}
                                              >
                                                  <div
                                                      key={input.name}
                                                      className="zipcodeLine"
                                                  >
                                                      <input
                                                          onChange={(evt) => {
                                                              setAddressInfoValues(
                                                                  {
                                                                      ...addressInfoValues,
                                                                      [evt
                                                                          .target
                                                                          .name]:
                                                                          evt
                                                                              .target
                                                                              .value,
                                                                  }
                                                              );
                                                          }}
                                                          type="text"
                                                          name={input.name}
                                                          placeholder={
                                                              input.placeHolder
                                                          }
                                                          readOnly
                                                          defaultValue={
                                                              addressInfoValues[
                                                                  input.name
                                                              ]
                                                          }
                                                      />
                                                      <div
                                                          onClick={() => {
                                                              setOpenSearchAddressPopup(
                                                                  true
                                                              );
                                                          }}
                                                          className="searchAddressBtn"
                                                      >
                                                          주소 찾기
                                                      </div>
                                                  </div>
                                                  {validAddressInfo.postcode ===
                                                      false && (
                                                      <div className="alertMsg">
                                                          {input.alert}
                                                      </div>
                                                  )}
                                              </div>
                                          );
                                      } else {
                                          return (
                                              <div
                                                  className={`inputFieldContainer ${
                                                      validAddressInfo[
                                                          input.name
                                                      ] === false && 'alert'
                                                  }`}
                                              >
                                                  <input
                                                      key={input.name}
                                                      onChange={
                                                          input.name === 'phone'
                                                              ? (evt) => {
                                                                    setAddressInfoValues(
                                                                        {
                                                                            ...addressInfoValues,
                                                                            [evt
                                                                                .target
                                                                                .name]:
                                                                                autoHypenPhone(
                                                                                    evt
                                                                                        .target
                                                                                        .value
                                                                                ),
                                                                        }
                                                                    );
                                                                }
                                                              : (evt) => {
                                                                    setAddressInfoValues(
                                                                        {
                                                                            ...addressInfoValues,
                                                                            [evt
                                                                                .target
                                                                                .name]:
                                                                                evt
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    );
                                                                }
                                                      }
                                                      type="text"
                                                      name={input.name}
                                                      placeholder={
                                                          input.placeHolder
                                                      }
                                                      readOnly={
                                                          input.name ===
                                                              'address_1' &&
                                                          true
                                                      }
                                                      value={
                                                          addressInfoValues[
                                                              input.name
                                                          ]
                                                      }
                                                  />
                                                  {input.name !==
                                                      'address_1' && (
                                                      <div
                                                          onClick={() =>
                                                              setAddressInfoValues(
                                                                  {
                                                                      ...addressInfoValues,
                                                                      [input.name]:
                                                                          '',
                                                                  }
                                                              )
                                                          }
                                                          className="resetInputFieldBtn"
                                                      />
                                                  )}
                                                  {validAddressInfo[
                                                      input.name
                                                  ] === false && (
                                                      <div className="alertMsg">
                                                          {input.alert}
                                                      </div>
                                                  )}
                                              </div>
                                          );
                                      }
                                  })
                                : modifyPasswordInputs.map((input, idx) => {
                                      return (
                                          <div
                                              className={`inputFieldContainer ${
                                                  validPassword === false &&
                                                  'alert'
                                              }`}
                                          >
                                              <input
                                                  key={input.name}
                                                  onChange={(evt) => {
                                                      setPasswordInputValues({
                                                          ...passwordInputValues,
                                                          [evt.target.name]:
                                                              evt.target.value,
                                                      });
                                                  }}
                                                  type="password"
                                                  name={input.name}
                                                  placeholder={
                                                      input.placeHolder
                                                  }
                                                  value={
                                                      passwordInputValues[
                                                          input.name
                                                      ]
                                                  }
                                              />
                                              <div
                                                  onClick={() =>
                                                      setPasswordInputValues({
                                                          ...passwordInputValues,
                                                          [input.name]: '',
                                                      })
                                                  }
                                                  className="resetInputFieldBtn"
                                              />
                                              {idx === 1 &&
                                                  validPassword === false && (
                                                      <div className="alertMsg">
                                                          {passwordInputValues
                                                              .newPassword
                                                              .length < 8
                                                              ? '비밀번호를 8자 이상 입력해 주세요'
                                                              : passwordInputValues.newPassword !==
                                                                    passwordInputValues.checkNewPassword &&
                                                                '비밀번호가 일치하지 않습니다'}
                                                      </div>
                                                  )}
                                          </div>
                                      );
                                  })}
                        </div>
                    </div>
                    {openSearchAddressPopup && (
                        <SearchAddressPopup
                            setOpenSearchAddressPopup={
                                setOpenSearchAddressPopup
                            }
                            addressInfoValues={addressInfoValues}
                            setAddressInfoValues={setAddressInfoValues}
                        />
                    )}
                    <div
                        onClick={() => {
                            if (
                                openChangeInfoPopup.type ===
                                'deliveryAddressInfo'
                            ) {
                                setIsChangeAllSubscriptionShippingPopup(true);
                            } else {
                                changeInfoHandler();
                            }
                        }}
                        className="chageInfoBtn"
                    >
                        <div>변경하기</div>
                    </div>
                    {/* <div onClick={changeInfoHandler} className="chageInfoBtn">
                    <div>변경하기</div>
                </div> */}
                </div>
            )}
        </>
    );
};

export default ChangePersonalInfoPopup;

const userInputs = [
    {
        name: 'username',
        placeHolder: '이메일 아이디',
    },
    {
        name: 'name',
        placeHolder: '이름',
        alert: '이름을 입력해주세요',
    },
    {
        name: 'phone',
        placeHolder: '휴대전화 번호',
        alert: '휴대전화 번호를 입력해주세요',
    },
];
const deliveryAddressInputs: Array<{
    name: string;
    placeHolder: string;
    alert?: string;
}> = [
    {
        name: 'first_name',
        placeHolder: '받는 분',
        alert: '받는 분을 입력해주세요',
    },
    {
        name: 'phone',
        placeHolder: '휴대전화 번호',
        alert: '휴대전화 번호를 입력해주세요',
    },
    {
        name: 'postcode',
        placeHolder: '우편번호',
        alert: '우편번호를 입력해주세요',
    },
    {
        name: 'address_1',
        placeHolder: '기본주소',
        alert: '기본주소를 입력해주세요',
    },
    {
        name: 'address_2',
        placeHolder: '상세주소',
    },
];

const modifyPasswordInputs: Array<{
    name: string;
    placeHolder: string;
}> = [
    {
        name: 'newPassword',
        placeHolder: '새 비밀번호',
    },
    {
        name: 'checkNewPassword',
        placeHolder: '새 비밀번호 확인',
    },
];
