import React from "react"
import "./MaintenancePage.scss"

function MaintenancePage() {
    return (<div className="maintenance-page-wrapper">
        사이트 점검중입니다.
    </div>)

}

export default MaintenancePage
