import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { addComma, getSrcUrl } from '../../utils/commonUtils';
import { isDogFoodProduct } from '../../utils/miniCartUtils';

const MinicartSelectedArea = observer(({ optionProducts, miniCartProduct, removeOptionProduct, changeOptionProductQty, optionProductQtyInputHandler }) => {

    const isDogFood = isDogFoodProduct(miniCartProduct);

    return (
        <>
            {optionProducts &&
                optionProducts.length > 0 &&
                miniCartProduct.type !== 'bundle' && (
                    <div className="optionList">
                        {optionProducts &&
                            optionProducts.map((optionProduct) => {
                                return (
                                    <div
                                        key={optionProduct.id}
                                        className="optionItem"
                                    >
                                        <div className="optionItemHeadArea">
                                            <div className="imgAndName">
                                                {optionProduct.option
                                                    .image && (
                                                        <img
                                                            className="optionImg"
                                                            src={getSrcUrl(
                                                                optionProduct
                                                                    .option
                                                                    .image
                                                            )}
                                                            alt="option-image"
                                                        />
                                                    )}

                                                {optionProduct.option
                                                    .thumbnail && (
                                                        <img
                                                            className="optionImg"
                                                            src={getSrcUrl(
                                                                optionProduct
                                                                    .option
                                                                    .thumbnail
                                                            )}
                                                            alt="option-image"
                                                        />
                                                    )}

                                                <div className="optionName">
                                                    {optionProduct.option
                                                        .name?.length > 0 &&
                                                        optionProduct.option
                                                            .name}
                                                    {optionProduct.option.attributes?.map(
                                                        (
                                                            attribute,
                                                            idx
                                                        ) => {
                                                            return (
                                                                <>
                                                                    {isDogFood && idx === optionProduct.option.attributes.length-1}
                                                                    {idx === 0 && attribute.option}
                                                                    {!isDogFood && idx !== 0 && ' / ' + attribute.option}

                                                                </>
                                                            );
                                                        }
                                                    )}

                                                    {/* {console.log("optionProduct" , toJS(optionProduct))} */}
                                                    {isDogFoodProduct(miniCartProduct) &&
                                                        <div className="optionNameDesc">
                                                            {optionProduct.option.billing_period}
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                            <img
                                                className="deleteOptionBtn"
                                                src="/img/common-btn-list-close-16.svg"
                                                alt="delete-button"
                                                onClick={(e) => {
                                                    removeOptionProduct(
                                                        optionProduct
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="optionItemBodyArea">
                                            <div className="controlBox">
                                                <div className="changeQty">
                                                    {miniCartProduct.type !==
                                                        'bundle' && (
                                                            <img
                                                                onClick={() => {
                                                                    changeOptionProductQty(
                                                                        optionProduct,
                                                                        'minus'
                                                                    );
                                                                }}
                                                                className="qtyBtn"
                                                                src="/img/m-btn-count-num-minus.svg"
                                                                alt="minus"
                                                            />
                                                        )}
                                                    <input
                                                        className="qtyInput"
                                                        pattern="\d*"
                                                        type="text"
                                                        onChange={(evt) => {
                                                            optionProductQtyInputHandler(optionProduct, evt);
                                                        }}
                                                        value={optionProduct.quantity}
                                                    />

                                                    {miniCartProduct.type !==
                                                        'bundle' && (
                                                            <img
                                                                onClick={() => {
                                                                    changeOptionProductQty(
                                                                        optionProduct,
                                                                        'plus'
                                                                    );
                                                                }}
                                                                className="qtyBtn"
                                                                src="/img/m-btn-count-num-plus.svg"
                                                                alt="plus"
                                                            />
                                                        )}
                                                </div>
                                            </div>
                                            <div className="priceSection">
                                                <div className="discountPercentage">
                                                    {optionProduct.option
                                                        .originDiscountRate >
                                                        0 &&
                                                        `${optionProduct.option.originDiscountRate}%`}
                                                </div>
                                                <div className="showPrice">
                                                    <div className="displayPrice">
                                                        {miniCartProduct.type !==
                                                            'bundle' &&
                                                            optionProduct
                                                                .option
                                                                .display_price}
                                                        {/* {miniCartProduct.type === 'bundle' && `${addComma(miniCartProduct.bundled_items_discount[optionProduct.option.id])}원`} */}
                                                    </div>
                                                    <div className="regularPrice">
                                                        {addComma(
                                                            optionProduct
                                                                .option
                                                                .regular_price
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                )}
        </>
    )
});

export default MinicartSelectedArea;

