import React, {useEffect, useState, useRef, useCallback, useMemo, Suspense} from 'react';
import {Helmet} from 'react-helmet';
import _, {debounce} from 'lodash';
import {observer} from 'mobx-react';
import {AppContext} from '../../contexts/AppContext/AppContext';
import {useHistory, useLocation} from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import CartCouponSelectContainer from '../../containers/CartCouponSelectContainer/CartCouponSelectContainer';
import CartItemsContainer from '../../containers/CartItemsContainer/CartItemsContainer';
import CartPurchaseBox from '../../containers/CartPurchaseBox/CartPurchaseBox';
import NaverPayButton from '../../components/NaverPayButton/NaverPayButton';
import ChangeOptionPopup from '../../components/ChangeOptionPopup/ChangeOptionPopup';
import './CartPage.scss';
import OrderAndCheckoutContainer from '../../containers/OrderAndCheckoutContainer/OrderAndCheckoutContainer';
import EmptyCartNotice from '../../components/EmptyCartNotice/EmptyCartNotice';
import ChangeBBOptionPopup from '../../components/ChangeBBOptionPopup/ChangeBBOptionPopup';
import {initCheckout, makeNpayButton} from '../../utils/paymentUtils';
import HolidayDeliveryModal from '../../components/HolidayDeliveryModal/HolidayDeliveryModal'
import {
    validateEmail,
    validatePhonenum,
    openChannelIO,
} from '../../utils/commonUtils';
import {fetchAndReceiveJSON} from '../../utils/ajaxUtils';
import {throttle} from 'lodash';
import {
    REMOVE_BUYNOW_URL,
    GET_COUPON_INFO_URL,
    KAKAOPAY_CODE,
    KAKAOPAY_SUBSCRIPTION_CODE,
    KCP_CODE,
    KCP_SUBSCRIPTION_CODE,
    NPAY_BUTTON_KEY,
    BREAKPOINT_TBL,
    BREAKPOINT_DT,
    DOG_FOOD_PRODUCT_CLUSTER,
} from '../../constants/constants';
import {useParams} from 'react-router';
import {npayBtnClick} from '../../utils/npayUtils';

smoothscroll.polyfill();

const CartPage = observer(() => {
    const {rootStore, cartPageStore, userInfoStore} =
        React.useContext(AppContext);

    const [isCartItemsOpen, setIsCartItemsOpen] = useState<boolean>(false);
    // const [goToOrder, setGoToOrder] = useState(false);
    const [currentWidth, setCurrentWidth] = useState<any>(window.innerWidth);
    const {userDeliveryInfo, setUserDeliveryInfo} = userInfoStore;
    const {
        setIsSpinnerOn,
        initData,
        setIsBodyScrollable,
        setIsFixedTopMenu,
        setCommonPopupContents,
    } = rootStore;

    const {
        cartInfoList,
        cartListCount,
        setCartInfoList,
        removeAllOutOfStockItems,
        setCartListCount,
        updateCartItemQty,
        showOptionBox,
        isBBOptionChanging,
        goToOrder,
        paymentMethod,
        payForNpay,
        setPayForNpay,
        updateCartInfoList,
        isCartLoading,
        setIsCartLoading,
        removeCartItem,
        resetCartList,
    } = cartPageStore;

    const [couponList, setCouponList] = useState([]);
    const [viewPurchaseDetail, setViewPurchaseDetail] = useState(false);
    const [processCheckout, setProcessCheckout] = useState(false);
    const [qtyValue, setQtyValue] = useState({
        key: '',
        value: '',
    });


    const history = useHistory();

    const cartInfoListRef = useRef(cartInfoList);


    useEffect(() => {
        cartInfoListRef.current = cartInfoList;
        if (cartInfoList) {
            let cartItems = [];

            if (
                cartInfoList &&
                cartInfoList.items &&
                cartInfoList.items.cartItems &&
                cartInfoList.items.cartItems.length > 0
            ) {
                removeBBItem().then(res => {
                    if (!res) {
                        cartInfoList.items.cartItems.map((item) =>
                            cartItems.push({
                                id: item.product_id,
                                price: item.line_total / item.quantity,
                                quantity: item.quantity,
                                category: item.product.categories[0]?.name,
                                imgUrl: item.product.thumbnail,
                                name: item.product_name,
                                desc: '',
                                link: `https://www.baconbox.co${item.product.url}`,
                            })
                        );

                        const actionField = {
                            revenue: cartInfoList.items.totals.origin_sub_total,
                        };

                        const dataLayer = window.dataLayer || [];

                        dataLayer.push({
                            event: 'ecommerce',
                            ecommerce: {
                                purchase: {
                                    products: cartItems,
                                    actionField: actionField,
                                },
                            },
                        });

                        window.dataLayer.push({
                            event: 'dataLayer.append.cart',
                        });
                    }
                })
            }
            renderNpayButton();
        }

        //GNB margin-top 수정
        const contentArea = document.getElementById('contentArea');
        if (contentArea) {
            if (cartListCount > 0) {
                contentArea.classList.add('noSub');
                contentArea.classList.remove('mobileNoSub');
            } else {
                contentArea.classList.add('mobileNoSub');
                contentArea.classList.remove('noSub');
            }
        }
    }, [cartInfoList, cartListCount]);

    const npayButtonIds = [
        'iamport-naverpay-product-button',
        'iamport-naverpay-product-button2',
        'iamport-naverpay-product-button3',
    ];
    let retryCnt = 0;

    const removeBBItem = async () => {
        if (cartInfoList.items.cartItems.some(item => item.is_bb)) {
            setIsSpinnerOn(true);
            await Promise.all(cartInfoList.items.cartItems.map(async item => {
                if (cartInfoList.items.cartItems.filter(
                    (subItem) =>
                        subItem.parent_cart_item_key ===
                        item.cart_item_key
                ).length > 0) {
                    await removeCartItem(
                        [
                            {
                                cart_item_key:
                                item.cart_item_key,
                            },
                            ...cartInfoList.items.cartItems
                                .filter(
                                    (
                                        optionItem
                                    ) =>
                                        optionItem.parent_cart_item_key ===
                                        item.cart_item_key
                                )
                                .map(
                                    (
                                        removedItem
                                    ) => {
                                        return {
                                            cart_item_key:
                                            removedItem.cart_item_key,
                                        };
                                    }
                                ),
                        ]
                    )
                } else if (item.is_bb) {
                    await removeCartItem(
                        [
                            {
                                cart_item_key:
                                item.cart_item_key,
                            },
                        ]
                    )
                }
            })).then(() => window.location.reload()
            )
            return true
        } else {
            return false
        }
    }

    const renderNpayButton = () => {
        if (retryCnt <= 10) {
            if (document.getElementById(npayButtonIds[0]) == undefined) {
                setTimeout(() => {
                    renderNpayButton();
                    retryCnt++;
                }, 500);
                return;
            }
        }

        makeNpayButton({
            elemIds: npayButtonIds,
            buttonKey: NPAY_BUTTON_KEY,
            buyAction: () => {
                const payData = {
                    pg: 'naverco',
                    method: 'card',
                    wcMethod: 'iamport_naverpay',
                    wcMethodTitle: '네이버페이',
                };

                let userInfoForNpay = {
                    firstName: '',
                    receiver: '',
                    phone: '',
                    email: '',
                    postcode: '',
                    address_1: '',
                    address_2: '',
                    deliveryMsg: '',
                };

                if (initData && initData.isLogin) {
                    userInfoForNpay = {
                        firstName: initData.userInfo.first_name,
                        receiver: initData.userInfo.shipping.first_name,
                        phone: initData.userInfo.shipping.phone,
                        email: initData.userInfo.email,
                        postcode: initData.userInfo.shipping.postcode,
                        address_1: initData.userInfo.shipping.address_1,
                        address_2: initData.userInfo.shipping.address_2,
                        deliveryMsg: '',
                    };
                }

                const cartItems = cartInfoListRef.current.items;

                initCheckout(
                    payData,
                    cartItems,
                    userInfoForNpay,
                    setIsSpinnerOn,
                    null
                );
            },
            getProductsForNpayZzim: '',
            withZzim: false,
        });
    };

    useEffect(() => {
        if (payForNpay) {
            npayBtnClick();

            setPayForNpay(false);
        }
    }, [payForNpay]);

    useEffect(() => {
        if (!isCartLoading) return;

        setIsSpinnerOn(true);

        //componentDidMount 사이클에서 카트 인포 데이터 받아오기
        setCartInfoList()
            .then((res) => {
                if (res.has_out_of_stock_item) {
                    removeAllOutOfStockItems().then(() =>
                        setCartInfoList().then(() => setCartListCount())
                    );
                } else {
                    setCartListCount();
                }
            })
            .then(() => {
                setIsSpinnerOn(false);
                setIsCartLoading(false);
            });
    }, [isCartLoading]);

    useEffect(() => {
        const resizeHandler = () => {
            setCurrentWidth(window.innerWidth);
        };
        const throttleInner = throttle(() => {
            resizeHandler();
        }, 500);
        setIsCartLoading(true);

        window.addEventListener('resize', throttleInner);

        setIsFixedTopMenu(true);
        return () => {
            window.removeEventListener('resize', throttleInner);

            //buynow cartkey remove & cart count refresh
            const fetchData = async () => {
                const res = await fetchAndReceiveJSON({
                    url: REMOVE_BUYNOW_URL,
                });

                setCartListCount();
            };

            fetchData();
            //setCartListCount();
        };
    }, []);

    useEffect(() => {
        if (initData.isLogin) {
            const fetchData = async () => {
                const res = await fetchAndReceiveJSON({
                    url: GET_COUPON_INFO_URL,
                });

                setCouponList(res);
            };

            fetchData();
        }
    }, []);

    const qtyController = (evt, key) => {
        setIsSpinnerOn(true);
        // const currentQty = cartInfoList.items.cartItems.filter(
        //     (item) => item.cart_item_key === key
        // )[0].quantity;
        const itemInfo = cartInfoList.items.cartItems.filter(
            (item) => item.cart_item_key === key
        )[0];
        const isDogfood =
            cartInfoList.items.cartItems.filter(
                (item) => item.cart_item_key === key
            )[0].product.productCluster === DOG_FOOD_PRODUCT_CLUSTER;

        if (evt.target.alt === 'minus') {
            updateCartItemQty(
                key,
                itemInfo.quantity - 1,
                itemInfo.product_id,
                itemInfo.variation_id
            )
                .then(() => setCartListCount())
                .then(() => setCartInfoList())
                .then(() => setIsSpinnerOn(false));
        } else {
            if (itemInfo.price === 100) {
                setIsSpinnerOn(false)
                return window.alert('해당 상품의 최대 구매 가능 갯수는 1개입니다.')
            }
            updateCartItemQty(
                key,
                itemInfo.quantity + 1,
                itemInfo.product_id,
                itemInfo.variation_id
            )
                .then(() => setCartListCount())
                .then(() => setCartInfoList())
                .then(() => setIsSpinnerOn(false));
        }
    };

    const qtyInputHandler = useCallback(
        debounce((evt, key, productId, variationId) => {
            if (evt.target.value === '') return;
            if (evt.target.value === '0') {
                alert('수량은 1개 이상 선택해 주세요.');
                return;
            }
            if (isNaN(parseInt(evt.target.value))) {
                alert('수량은 숫자로만 입력해 주세요.');
                return;
            }

            setIsSpinnerOn(true);

            updateCartItemQty(
                key,
                evt.target.value,
                productId,
                variationId
            )
                .then(() => setCartListCount())
                .then(() => setCartInfoList())
                .then(() => {
                    setIsSpinnerOn(false);
                    setQtyValue({
                        key: '',
                        value: '',
                    });
                });
        }, 1000),
        []
    );

    useEffect(() => {
        if (!processCheckout) {
            return;
        }

        const checkout = () => {
            //validate fields

            if (!userDeliveryInfo.receiver) {
                alert('받는 분을 입력해 주세요.');
                return;
            }
            if (
                !userDeliveryInfo.email ||
                validateEmail(userDeliveryInfo.email) == false
            ) {
                alert('이메일을 정확히 입력해 주세요.');
                return;
            }
            if (!userDeliveryInfo.address_1) {
                alert('주소를 입력해 주세요.');
                return;
            }
            if (!userDeliveryInfo.address_2) {
                alert('상세 주소를 입력해 주세요.');
                return;
            }
            if (
                !userDeliveryInfo.phone ||
                validatePhonenum(userDeliveryInfo.phone) == false
            ) {
                alert('휴대전화 번호를 정확히 입력해 주세요.');
                return;
            }

            let payData;
            if (paymentMethod === 'kakao') {
                payData = {
                    pg: cartInfoList.has_subscription_type_product
                        ? KAKAOPAY_SUBSCRIPTION_CODE
                        : KAKAOPAY_CODE,
                    method: 'card',
                    wcMethod: 'iamport_kakao',
                    wcMethodTitle: cartInfoList.has_subscription_type_product
                        ? '카카오페이 정기결제'
                        : '카카오페이 결제',
                };
            } else if (paymentMethod === 'vbank') {
                payData = {
                    pg: 'kcp',
                    method: 'vbank',
                    wcMethod: 'iamport_vbank',
                    wcMethodTitle: '가상 계좌',
                };
            } else if (paymentMethod === 'card') {
                payData = {
                    pg: cartInfoList.has_subscription_type_product
                        ? KCP_SUBSCRIPTION_CODE
                        : KCP_CODE,
                    method: 'card',
                    wcMethod: cartInfoList.has_subscription_type_product
                        ? 'iamport_subscription_ex'
                        : 'iamport_card',
                    wcMethodTitle: cartInfoList.has_subscription_type_product
                        ? '신용카드 정기결제'
                        : '카드 결제',
                };
            } else if (paymentMethod === 'payco') {
                payData = {
                    pg: 'payco',
                    method: 'card',
                    wcMethod: 'iamport_payco',
                    wcMethodTitle: '페이코 결제',
                };
            }

            initCheckout(
                payData,
                cartInfoList.items,
                userDeliveryInfo,
                setIsSpinnerOn,
                cartInfoList.has_subscription_type_product
                    ? initData.customerUid
                    : null
            );
        };

        checkout();
        setIsBodyScrollable(true);
        setProcessCheckout(false);
    }, [processCheckout]);

    useEffect(() => {
        setIsBodyScrollable(!goToOrder);
    }, [goToOrder]);

    if (!isCartLoading && (!cartInfoList || cartListCount === 0)) {
        //updateCartInfoList(null);

        return (
            <div
                className={`CartPage ${cartListCount === 0 ? 'emptyCart' : ''}`}
            >
                <EmptyCartNotice/>
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Cart | 베이컨박스</title>
            </Helmet>

            {!isCartLoading && cartInfoList && cartInfoList.items && (
                <div
                    className={`CartPage ${
                        cartListCount === 0 ? 'emptyCart' : ''
                    }`}
                >
                    {goToOrder && (
                        <OrderAndCheckoutContainer
                            hasSubscription={
                                cartInfoList.has_subscription_type_product
                            }
                            innerWidth={innerWidth}
                            viewPurchaseDetail={viewPurchaseDetail}
                            // goToOrder={goToOrder}
                            // setGoToOrder={setGoToOrder}
                        />
                    )}
                    <div
                        className={`CartPageWrapper ${
                            goToOrder ? 'fullPage' : ''
                        }`}
                    >
                        <div className="pageTitle">장바구니</div>
                        {cartInfoList.benefit && (
                            <div
                                className="extraInfo"
                                onClick={() =>
                                    history.push(
                                        cartInfoList.benefit.button_link
                                    )
                                }
                            >
                                {cartInfoList.benefit.msg}
                                <div className="extraInfoLinkText">
                                    {/* TODO: 상품 페이지 완성되면 데이터로 버튼 링크 받아서 연결하기 */}
                                    <div>{cartInfoList.benefit.button}</div>
                                    <img
                                        src={`/img/m-icon-link-arrow-${
                                            currentWidth >= BREAKPOINT_DT
                                                ? 16
                                                : 10
                                        }-white.svg`}
                                        alt=""
                                    />
                                </div>
                            </div>
                        )}

                        <div className="cartItemsPositioningWrapper">
                            <CartItemsContainer
                                // setSelectedProduct={setSelectedProduct}
                                isCartItemsOpen={isCartItemsOpen}
                                setIsCartItemsOpen={setIsCartItemsOpen}
                                qtyController={qtyController}
                                qtyInputHandler={qtyInputHandler}
                                qtyValue={qtyValue}
                                setQtyValue={setQtyValue}
                            />
                            <CartCouponSelectContainer
                                couponList={couponList}
                            />

                            {/* 수정 필요 아래 컴포넌트를 좀더 재 사용하기 좋은 형태로 만들면 되지 않을까*/}
                            {/* <MovingContentFromUnder>content</MovingContentFromUnder> */}
                            {cartInfoList.npay_available && <NaverPayButton/>}
                        </div>
                    </div>
                    {currentWidth < BREAKPOINT_TBL && showOptionBox ? (
                        <ChangeOptionPopup
                        />
                    ) : currentWidth < BREAKPOINT_TBL && isBBOptionChanging ? (
                        <ChangeBBOptionPopup currentWidth={currentWidth}/>
                    ) : (
                        <></>
                    )}
                    {currentWidth >= BREAKPOINT_TBL && showOptionBox ? (
                        <ChangeOptionPopup/>
                    ) : (
                        currentWidth >= BREAKPOINT_TBL &&
                        isBBOptionChanging && (
                            <ChangeBBOptionPopup currentWidth={currentWidth}/>
                        )
                    )}

                    <CartPurchaseBox
                        setProcessCheckout={setProcessCheckout}
                        currentWidth={currentWidth}
                        viewPurchaseDetail={viewPurchaseDetail}
                        setViewPurchaseDetail={setViewPurchaseDetail}
                    />
                </div>
            )}
        </>
    );
});

export default CartPage;
