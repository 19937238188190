import {toJS} from 'mobx';
import {observer} from 'mobx-react';
import {useState} from 'react';
import {DOG_FOOD_PERIOD_INTERVALS} from '../../constants/constants';
import {addComma, getSrcUrl} from '../../utils/commonUtils';
import {isDogFoodProduct, isSimpleSubscription} from '../../utils/miniCartUtils';
import DropDown from '../DropDown/DropDown2';

const MinicartDropDownArea = observer(({
                                           miniCartProduct
                                           , changeMiniCartQty
                                           , mainProductQtyInputHandler
                                           , miniCartProductQty
                                           , setNextSelectOption
                                           , getSelectableOptions
                                           , keys
                                           , updateSelectedOption
                                           , updateSelectedDogOption
                                           , optionProducts
                                           , addDataToOption
                                           , tempOptionProduct
                                           , removeOptionProduct
                                       }) => {


    const [dropdownExpandIdx, setDropdownExpandIdx] = useState(null);
    const [isSelectedOption, setIsSelectedOption] = useState(false);

    const isDogFoodProd = isDogFoodProduct(miniCartProduct);
    const isSimpleSubscriptionProd = isSimpleSubscription(miniCartProduct);
    const isMultiOption = miniCartProduct.attributes.length > 1;


    const defaultDogFoodIntevalOptions = [];

    let optionNames = [];

    if (isDogFoodProd) {
        // dogfood
        if (tempOptionProduct && tempOptionProduct?.option?.option) {
            optionNames[0] = tempOptionProduct?.option?.option;
        } else if (tempOptionProduct && tempOptionProduct?.option && tempOptionProduct.option.attributes) {
            optionNames[0] = tempOptionProduct.option.attributes[0].option;
        } else {
            optionNames[0] = "상품 선택";
        }

        for (let i in DOG_FOOD_PERIOD_INTERVALS) {

            defaultDogFoodIntevalOptions.push({
                stock_status: DOG_FOOD_PERIOD_INTERVALS[i].stock_status,
                on_sale: true,
                attributes: [
                    {
                        option: DOG_FOOD_PERIOD_INTERVALS[i].optionName
                    }
                ],
                display_price: tempOptionProduct?.option?.display_price ? tempOptionProduct?.option?.display_price : miniCartProduct.displayPrice,
                regular_price: tempOptionProduct?.option?.regular_price ? tempOptionProduct?.option?.regular_price : miniCartProduct.regularPrice,
                discount_rate: tempOptionProduct?.option?.discount_rate ? tempOptionProduct?.option?.discount_rate : miniCartProduct.discountRate,
                billing_period: DOG_FOOD_PERIOD_INTERVALS[i].billing_period,
                billing_interval: DOG_FOOD_PERIOD_INTERVALS[i].billing_interval,
            });

        }

    } else {
        //not dogfood
        if (isMultiOption) {
            // multi option

            miniCartProduct.attributes.map((attribute, idx) => {
                optionNames[idx] = attribute.name + " 옵션 선택";
            });

            if (keys && keys.length > 0) {

                keys.map((key, idx) => {
                    optionNames[idx] = key;
                })

            }

        } else {
            //single option or bundle , grouped
            if (miniCartProduct.type === 'bundle') {
                optionNames[0] = `함께 구매하면 더 저렴해요!`;
            } else if (miniCartProduct.type == 'grouped') {
                optionNames[0] = `옵션 선택`;
            } else {
                optionNames[0] = miniCartProduct.attributes[0]?.name + " 옵션 선택";
            }
        }

    }

    let itemClickEvent = null;
    if (isDogFoodProd) {
        itemClickEvent = (item) => {

            console.log("isDogFoodProd + clicked item", item);
            updateSelectedDogOption(item);
            setIsSelectedOption(true);
        }
    } else if (isMultiOption) {
        itemClickEvent = setNextSelectOption;

    } else {
        itemClickEvent = (item) => {
            updateSelectedOption(
                item,
            );
        }
    }

    //없어도됨
    let dropDownType = 'selectOption';
    if (isMultiOption) {
        dropDownType = 'multiOption';
    } else if (miniCartProduct.type == 'grouped' || miniCartProduct.type == 'bundle') {
        dropDownType = miniCartProduct.type;
    }

    const dropDownItems = (idx) => {
        if (isMultiOption) {
            return getSelectableOptions(idx);
        } else if (miniCartProduct.type == 'grouped' || miniCartProduct.type == 'bundle') {
            return miniCartProduct.children;
        } else {
            // console.log("dropDownItems??" , dropDownItems);
            return miniCartProduct.productVariation;
        }
    }

    // console.log("dropDownItems" , dropDownItems);


    //process dropdown data
    let dropdownElements = [];


    if (miniCartProduct.attributes.length > 0) {
        //option product
        miniCartProduct.attributes.map((attribute, idx) => {

            dropdownElements.push({
                className: "optionDropdown",
                defaultText: optionNames[idx],
                itemClickEvent: itemClickEvent,
                dropDownType: dropDownType,
                dropDownItems: dropDownItems,
            });

        });
    } else {
        //simple or bundle
        dropdownElements.push({
            className: "optionDropdown",
            defaultText: optionNames[0],
            itemClickEvent: itemClickEvent,
            dropDownType: dropDownType,
            dropDownItems: dropDownItems,
            leftImg: miniCartProduct.type === 'bundle' ? '/img/icon/discount.png' : null,
        });
    }

    // console.log("dropdownElements" , dropdownElements);

    return <div className="dropdownArea">
        {/* simple */}
        {miniCartProduct.type === 'simple' &&
            //main product && qty control area
            <div
                className={`productBox ${miniCartProduct.type === 'bundle'
                    ? 'margin'
                    : ''}`}
            >
                <div className="optionItem">
                    <div className="optionItemBodyArea">
                        <div className="controlBox">
                            <div className="changeQty">
                                <img
                                    onClick={changeMiniCartQty}
                                    className="qtyBtn"
                                    src="/img/m-btn-count-num-minus.svg"
                                    alt="minus"/>
                                <input
                                    className="qtyInput"
                                    pattern="\d*"
                                    type="text"
                                    onChange={(evt) => {
                                        mainProductQtyInputHandler(evt);
                                    }}
                                    value={miniCartProductQty}/>
                                <img
                                    onClick={changeMiniCartQty}
                                    className="qtyBtn"
                                    src="/img/m-btn-count-num-plus.svg"
                                    alt="plus"/>
                            </div>
                        </div>
                        <div className="priceSection">
                            <div className="discountPercentage">
                                {miniCartProduct.discountRate > 0 &&
                                    miniCartProduct.discountRate}
                            </div>
                            <div className="showPrice">
                                <div className="displayPrice">
                                    {miniCartProduct.price}원
                                </div>
                                {miniCartProduct.isOnSale && (
                                    <div className="regularPrice">
                                        {miniCartProduct.regularPrice}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        {dropdownElements.map((dropdownElement, idx) => {

            // console.log("ㅋㅋ" , dropdownElement.dropDownItems(idx));

            return (
                <>
                    <DropDown
                        key={Math.random()}
                        className="optionDropdown"
                        optionName={optionNames[idx]}
                        leftImg={dropdownElement.leftImg}
                        itemClickEvent={dropdownElement.itemClickEvent}
                        dropDownType={dropdownElement.dropDownType}
                        dropDownItems={dropdownElement.dropDownItems(idx)}
                        idx={idx}
                        miniCartProduct={miniCartProduct}
                        dropdownExpandIdx={dropdownExpandIdx}
                        setDropdownExpandIdx={setDropdownExpandIdx}
                    />

                    {isDogFoodProd && (isSimpleSubscriptionProd || (!isSimpleSubscriptionProd && isSelectedOption)) &&
                        <DropDown
                            key={Math.random()}
                            className="optionDropdown"
                            optionName={`결제&배송 주기 선택`}
                            itemClickEvent={(item) => {
                                addDataToOption(item);
                                setIsSelectedOption(false);
                            }}
                            idx={1}
                            dropdownExpandIdx={dropdownExpandIdx}
                            setDropdownExpandIdx={setDropdownExpandIdx}
                            dropDownType={'selectOption'}
                            dropDownItems={defaultDogFoodIntevalOptions}/>}
                </>
            );
        })}

        {
            miniCartProduct.type === 'bundle' && (
                //main product && qty control area
                <div className="productBox margin">
                    <div className="optionItem">
                        <div className="optionItemBodyArea">
                            <div className="controlBox">
                                <div className="changeQty">
                                    <img
                                        onClick={changeMiniCartQty}
                                        className="qtyBtn"
                                        src="/img/m-btn-count-num-minus.svg"
                                        alt="minus"/>
                                    <input
                                        className="qtyInput"
                                        pattern="\d*"
                                        type="text"
                                        onChange={(evt) => {
                                            mainProductQtyInputHandler(evt);
                                        }}
                                        value={miniCartProductQty}/>
                                    <img
                                        onClick={changeMiniCartQty}
                                        className="qtyBtn"
                                        src="/img/m-btn-count-num-plus.svg"
                                        alt="plus"/>
                                </div>
                            </div>
                            <div className="priceSection">
                                <div className="discountPercentage">
                                    {miniCartProduct.discountRate > 0 &&
                                        miniCartProduct.discountRate}
                                </div>
                                <div className="showPrice">
                                    <div className="displayPrice">
                                        {miniCartProduct.price}원
                                    </div>
                                    {miniCartProduct.isOnSale && (
                                        <div className="regularPrice">
                                            {miniCartProduct.regularPrice}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* 번들 옵션 */}
                        {optionProducts && optionProducts.length > 0 && (
                            <div className="bundleOptionProductArea">
                                {optionProducts.map(
                                    (optionProduct) => {
                                        return (
                                            <div
                                                key={optionProduct.id}
                                                className="bundle-optionItemBox"
                                            >
                                                <div className="imgArea">
                                                    {optionProduct
                                                        .option
                                                        .image && (
                                                        <img
                                                            className="optionImg"
                                                            src={getSrcUrl(
                                                                optionProduct
                                                                    .option
                                                                    .image
                                                            )}
                                                            alt="option-image"/>
                                                    )}

                                                    {optionProduct
                                                        .option
                                                        .thumbnail && (
                                                        <img
                                                            className="optionImg"
                                                            src={getSrcUrl(
                                                                optionProduct
                                                                    .option
                                                                    .thumbnail
                                                            )}
                                                            alt="option-image"/>
                                                    )}
                                                </div>
                                                <div className="infoArea">
                                                    <div className="nameAndQty">
                                                        <div className="bundle-optionName">
                                                            {optionProduct
                                                                    .option
                                                                    .name
                                                                    ?.length >
                                                                0 &&
                                                                optionProduct
                                                                    .option
                                                                    .name}
                                                        </div>
                                                        <div className="qtyArea">
                                                            1개
                                                        </div>
                                                    </div>
                                                    <div className="rightArea">
                                                        <img
                                                            className="deleteOptionBtn"
                                                            src="/img/common-btn-list-close-16.svg"
                                                            alt="delete-button"
                                                            onClick={() => {
                                                                removeOptionProduct(
                                                                    optionProduct
                                                                );
                                                            }}/>

                                                        <div className="bundle-optionPrice">
                                                            {addComma(
                                                                miniCartProduct
                                                                    .bundled_items_discount[optionProduct
                                                                    .option
                                                                    .id]
                                                            )}
                                                            <span className="won">
                                                                원
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )
        }
    </div>;
});

export default MinicartDropDownArea;

