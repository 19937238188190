import {observer} from 'mobx-react';
import React, {useEffect, useMemo, useState, useContext} from 'react';
import {AppContext} from '../../../contexts/AppContext/AppContext';
import 'swiper/swiper.scss';
import './NavigationSlider.scss';
import {SwiperSlide} from 'swiper/react';
import {navSliderProps} from '../../../types/types';
import SliderWrapper from '../SliderWrapper';
import {
    BREAKPOINT_TBL,
    HUNDRED_SHOP_SLUG,
} from '../../../constants/constants';
import {throttle} from 'lodash';
import App from '../../../App';
// TODO: 액티브섹션에 언더바 넣기
// onClick을 하면 url을 바꾸고 target도 바꾸도록 한다.

const NavigationSlider: React.FunctionComponent<navSliderProps> = (
    {
        changeTargetFunction = () => {
        },
        withTargetUnderBar,
        target,
        slideData,
        slideOnClickFunction,
        customNavigationClass,
        className,
        withSizeConditionalNavigation,
        getCurrentTargetFunction,
        ...props
    }) => {
    const {rootStore} = useContext(AppContext);
    const {initData} = rootStore;

    function RandId() {
        return Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, '')
            .substr(2, 10);
    }

    const id = useMemo(() => {
        return RandId();
    }, []);
    // const id = useMemo(() => {
    //     return RandId();
    // }, []);

    const [isOverflowed, setIsOverflowed] = useState(false);

    useEffect(() => {
        // 너비를 체크하고 너비 보다 컨텐츠가 크다면 스테이트를 바꾼다.
        const parentDiv = document.querySelector<HTMLDivElement>(
            `.navSlider.${id}`
        );

        const compareContentWidth = () => {
            const parentClientWidth = parentDiv?.clientWidth;
            const parentScrollWidth = parentDiv?.scrollWidth;
            // console.log(parentClientWidth, parentScrollWidth, id);
            if (
                parentClientWidth &&
                parentScrollWidth &&
                parentClientWidth < parentScrollWidth
            ) {
                setIsOverflowed(true);
            } else {
                setIsOverflowed(false);
            }
        };
        setTimeout(compareContentWidth, 200);
        const throttleInner = throttle(() => {
            compareContentWidth();
        }, 500);
        window.addEventListener('resize', throttleInner);

        return () => {
            window.removeEventListener('resize', throttleInner);
        };
    }, []);

    return (
        <SliderWrapper
            className={`navSlider ${className ? className : ''} ${id}
                    ${
                withSizeConditionalNavigation && isOverflowed
                    ? 'withSizeConditionalNavigation'
                    : ''
            }
                    `}
            threshold={10}
            customNavigationClass={
                withSizeConditionalNavigation
                    ? {
                        nextEl: `.navSliderNextNav.${id}`,
                        prevEl: `.navSliderPrevNav.${id}`,
                    }
                    : undefined
            }
            {...props}
            style={{
                overflow: 'visible',
            }}
            observeParents
            observer
        >
            {slideData &&
                slideData.map((el, i) => {
                    //TODO 100원 구매이력이 있는 경우 추가하기
                    // if (
                    //     initData.isLogin &&
                    //     el.slug === HUNDRED_SHOP_SLUG &&
                    //     initData.isWelcomeSpecialPriceCouponUsed
                    // )
                    //     return;
                    return (
                        <React.Fragment key={`navFrag_${i}`}>
                            <SwiperSlide key={`navItem_${i}`}>
                                <div className="navSliderContentWrapper">
                                    {el.icon ? (
                                        <img
                                            className="navSliderContentImg"
                                            src={el.icon}
                                            alt=""
                                            onClick={(e) => {
                                                if (slideOnClickFunction) {
                                                    slideOnClickFunction(
                                                        `${el.link}`,
                                                        e
                                                    );
                                                }

                                                changeTargetFunction(el);
                                            }}
                                        />
                                    ) : el.badge ? (
                                        <div
                                            className={`navSliderContentText badge ${
                                                target &&
                                                target.slug === el.slug
                                                    ? 'targetEl'
                                                    : ''
                                            }`}
                                            onClick={(e) => {
                                                if (slideOnClickFunction) {
                                                    slideOnClickFunction(
                                                        `${el.link}`,
                                                        e
                                                    );
                                                }
                                                changeTargetFunction(el);
                                            }}
                                        >
                                            <img src={el.badge} alt=""/>
                                            <span>{el.name}</span>
                                        </div>
                                    ) : (
                                        <span
                                            className={`navSliderContentText ${
                                                target &&
                                                target.slug === el.slug
                                                    ? 'targetEl'
                                                    : ''
                                            }`}
                                            onClick={(e) => {
                                                if (slideOnClickFunction) {
                                                    slideOnClickFunction(
                                                        `${el.link}`,
                                                        e
                                                    );
                                                }

                                                changeTargetFunction(el);

                                                if (
                                                    className ===
                                                    'CollectionProductsNavSlider' &&
                                                    getCurrentTargetFunction
                                                ) {
                                                    getCurrentTargetFunction(
                                                        el.id
                                                    );
                                                }
                                            }}
                                        >
                                            {el.name}
                                        </span>
                                    )}
                                    {withTargetUnderBar &&
                                        target &&
                                        target.slug === el.slug && (
                                            <div
                                                className="navSliderHighlighterBar"
                                                style={{
                                                    backgroundColor:
                                                    el.highColor,
                                                }}
                                            ></div>
                                        )}
                                </div>
                            </SwiperSlide>
                        </React.Fragment>
                    );
                })}
            {withSizeConditionalNavigation && (
                <div
                    style={{
                        visibility: isOverflowed ? 'visible' : 'hidden',
                    }}
                    className="navSliderNavButtons"
                >
                    <div className={`navSliderPrevNav ${id}`}>
                        <img
                            className="activePrevButton"
                            src="/img/common-btn-arrow-prev-24.svg"
                            alt=""
                        />
                        <img
                            className="disabledPrevButton"
                            src="/img/common-btn-arrow-prev-disable-24.svg"
                            alt=""
                        />
                    </div>
                    <div className={`navSliderNextNav ${id}`}>
                        <img
                            className="activeNextButton"
                            src="/img/common-btn-arrow-next-24.svg"
                            alt=""
                        />
                        <img
                            className="disabledNextButton"
                            src="/img/common-btn-arrow-next-disable-24.svg"
                            alt=""
                        />
                    </div>
                    {/* <div className="fade"></div> */}
                </div>
            )}
        </SliderWrapper>
    );
};

export default NavigationSlider;
