import React, {useContext} from 'react';
import {Helmet} from 'react-helmet';
import {AppContext} from '../../contexts/AppContext/AppContext';
import './PartnerPage.scss';

const PartnerPage = () => {
    const {rootStore} = useContext(AppContext);
    const {setSignUpModalOn} = rootStore;
    return (
        <>
            <Helmet>
                <title>파트너 가입 정보 | 베이컨박스</title>
            </Helmet>
            <section className="PartnerPage">
                <header className="headerSection">
                    <h1>
                        BACON BOX<br/>
                        유통 및 업무 제휴 안내
                    </h1>
                </header>
                <main className="contentsSection">
                    <div className="contentItem">
                        <h3>BACON BOX 업무 제휴 안내</h3>
                        <p>
                            베이컨박스와 함께할 멋진 협업 파트너를 기다립니다.<br/>
                            헙업 및 제휴 내용을 기재하여 <a href="mailto:partner@baconbox.co">partner@baconbox.co</a>로 메일을
                            보내주세요.<br/>
                            감사합니다.<br/>
                            Team. BACON BOX
                        </p>
                    </div>
                    <hr/>
                    <div className="contentItem">
                        <h3>BACON BOX 오프라인 판매 안내</h3>
                        <p>
                            오프라인 판매를 원하시는 B2B 파트너는 현재 웹사이트 회원가입 후,<br className={"dt"}/> <a
                            href="mailto:partner@baconbox.co">partner@baconbox.co</a>로
                            이메일 계정과 사업자등록증을 보내주세요.
                        </p>
                    </div>
                </main>
            </section>
        </>
    );
};

export default PartnerPage;
