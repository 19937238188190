import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './BBSHiddenPage.scss';

const BBSHiddenPage = () => {
    useEffect(() => {
        const chatBtn = document.getElementById('ch-plugin');

        if (chatBtn) {
            chatBtn.classList.add('hide');
        }

        return () => {
            chatBtn.classList.remove('hide');
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    BACON - 사료 최저가 정기배송 멤버십 선착순 회원 모집
                </title>
            </Helmet>
            <section className="BBSHiddenPage">
                <img className="mb" src="/img/bbs/bbs-mb-1.gif" />
                <img className="mb" src="/img/bbs/bbs-mb-2.jpg" />
                <a
                    href="https://baconbox.typeform.com/to/ExEegRyo?typeform-source=baconbox.co&utm_source=hidden&utm_medium=referral&utm_campaign=bacon_bbs&utm_content=cta1/"
                    target="_blank"
                >
                    <img className="mb click" src="/img/bbs/bbs-mb-3.jpg" />
                </a>
                <img className="mb" src="/img/bbs/bbs-mb-4.jpg" />
                <a
                    href="https://baconbox.typeform.com/to/ExEegRyo?typeform-source=baconbox.co&utm_source=hidden&utm_medium=referral&utm_campaign=bacon_bbs&utm_content=cta2/"
                    target="_blank"
                >
                    <img className="click mb" src="/img/bbs/bbs-mb-5.jpg" />
                </a>
                {/* <img className="mb" src="/img/bbs/bbs-mb-6.jpg" /> */}
                {/* <img className="mb" src="/img/bbs/bbs-mb-7.jpg" /> */}
                <img className="pc" src="/img/bbs/bbs-pc-1.gif" />
                <img className="pc" src="/img/bbs/bbs-pc-2.jpg" />
                <a
                    href="https://baconbox.typeform.com/to/ExEegRyo?typeform-source=baconbox.co&utm_source=hidden&utm_medium=referral&utm_campaign=bacon_bbs&utm_content=cta1/"
                    target="_blank"
                >
                    <img className="pc click" src="/img/bbs/bbs-pc-3.jpg" />
                </a>
                <img className="pc" src="/img/bbs/bbs-pc-4.jpg" />
                <a
                    href="https://baconbox.typeform.com/to/ExEegRyo?typeform-source=baconbox.co&utm_source=hidden&utm_medium=referral&utm_campaign=bacon_bbs&utm_content=cta2/"
                    target="_blank"
                >
                    <img className="pc click" src="/img/bbs/bbs-pc-5.jpg" />
                </a>
                <img className="pc" src="/img/bbs/bbs-pc-6.jpg" />
            </section>
        </>
    );
};

export default BBSHiddenPage;
