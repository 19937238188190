import React from 'react';
import './PriceAndDiscountDisplay.scss';

const PriceAndDiscountDisplay = ({
                                     salePrice,
                                     discountRate = '',
                                     regularPrice = '',
                                     right = false,
                                     month = '',
                                     unit = '',
                                 }) => {
    return (
        <div className="PriceAndDiscountWrapper">
            <div className={`top ${right ? 'right' : ''}`}>
                {discountRate && right && (
                    <div className="point">{discountRate}&nbsp;</div>
                )}
                <div className={`basic `}>

                    <span className="displayedPrice">{salePrice}</span>
                    {unit && unit}
                </div>
                {discountRate && !right && (
                    <div className="point">{discountRate}</div>
                )}
            </div>
            <div className={`bottom ${right ? 'right' : ''} `}>
                {regularPrice}
            </div>
        </div>
    );
};

export default PriceAndDiscountDisplay;
