import {makeAutoObservable, runInAction, computed} from 'mobx';
import {fetchAndReceiveJSON} from '../../utils/ajaxUtils';
import {openChannelIO} from '../../utils/commonUtils';
import {
    ADD_CART_URL,
    GET_CART_INFO_URL,
    GET_CART_COUNT_URL,
    REMOVE_CART_ITEM_URL,
    RESET_CART_LIST_URL,
    UPDATE_CART_QTY_URL,
    APPLY_COUPON_URL,
    REMOVE_COUPON_URL,
    BEST_PRODUCTS_LIST_URL,
    REMOVE_ALL_OUT_OF_STOCKS_URL,
    DOG_FOOD_PRODUCT_CLUSTER,
} from '../../constants/constants';
import RootStore from '../RootStore/RootStore';
import {RESPONSE_ALREADY_HAS_DOGFOOD} from '../../constants/errorConstants';

class CartPageStore {
    rootStore: RootStore;
    cartInfoList; //카트 진입시 info data
    cartBestProductsList; //카트 진입시 비어있을때 보여질 인기상품 리스트
    cartListCount; //장바구니 갯수
    showOptionBox; //옵션팝업창 보이는 여부
    selectedProduct; //옵션 변경 클릭시 해당 아이템에 대한 정보
    currentSelectedOption; //옵션변경 팝업에서 현재 선택된 상품 정보
    currentOptionQty; //옵션 변경 팝업에서 현재 선택된 옵션의 수량
    isBundleProduct; //번들 상품 여부 체크
    selectedBundleProducts; //번들 상품의 자식 상품들 정보
    currentSelectedBundleProducts; //옵션 변경 팝업 내에서 현재 선택된 번들 자식 상품 정보
    isBBOptionChanging = false;
    optionDropdownInfo;
    selectedMultiOptions: any = [];
    showWriteCouponCode = false;
    multiOptionsDropdowns;
    orderForNonUser = false;
    goToOrder = false;
    payForNpay = false;
    selectedDogfoodPeriod;

    isCartLoading = false;

    paymentMethod = 'kakao';

    selectedDogfoodOption = null;
    selectedSantaPresentInCart;
    currentPopupFlow = 'option';

    constructor(rootStore) {
        makeAutoObservable(this);

        this.rootStore = rootStore;
    }

    setCurrentPopupFlow = (flow) => {
        this.currentPopupFlow = flow;
    };

    setSelectedSantaPresentInCart = (item) => {
        console.log(item, 'itemmmmm');
        this.selectedSantaPresentInCart = item;
    };

    updateCartInfoList = (data) => {
        this.cartInfoList = data;
    };

    setPaymentMethod = (method) => {
        this.paymentMethod = method;
    };

    setIsCartLoading = (bool) => {
        this.isCartLoading = bool;
    };

    setPayForNpay = (bool) => {
        this.payForNpay = bool;
    };

    setOrderForNonUser = (bool) => {
        this.orderForNonUser = bool;
    };

    setGoToOrder = (bool) => {
        this.goToOrder = bool;
    };

    setIsBBOptionChanging = (bool) => {
        this.isBBOptionChanging = bool;
    };

    //옵션 변경시 옵션박스 나오도록하는 상태값
    setShowOptionBox = (bool) => {
        this.showOptionBox = bool;
    };

    //카트에 아이템 추가하기
    addItemToCart = async (dataInfo) => {
        const res = await fetchAndReceiveJSON({
            url: ADD_CART_URL,
            method: 'POST',
            body: dataInfo,
        });

        // if (res !== []) {
        //     runInAction(() => {
        //         this.setCartListCount();
        //         // this.setCartSpinnerOn(false);
        //     });
        // }

        return res;
    };

    //카트 인기상품 조회
    setCartBestProductsList = async () => {
        const res = await fetchAndReceiveJSON({
            url: BEST_PRODUCTS_LIST_URL,
        });

        if (res) {
            runInAction(() => {
                this.cartBestProductsList = res;
            });
        }

        return res;
    };

    //카트 리스트 인포 불러오기
    setCartInfoList = async () => {
        const res = await fetchAndReceiveJSON({
            url: GET_CART_INFO_URL,
        });

        runInAction(() => {
            this.cartInfoList = res;
        });

        // if (res.has_out_of_stock_item) {
        //     console.log('22');
        //     runInAction(() => {
        //         this.removeAllOutOfStockItems();
        //     });
        // }

        return res;
    };

    removeAllOutOfStockItems = async () => {
        const res = await fetchAndReceiveJSON({
            url: REMOVE_ALL_OUT_OF_STOCKS_URL,
        });

        return res;
    };

    //카트 리스트 갯수 불러오기
    setCartListCount = async () => {
        const res = await fetchAndReceiveJSON({
            url: GET_CART_COUNT_URL,
        });

        runInAction(() => {
            this.cartListCount = res;
        });

        return res;
    };

    //카트 아이템 삭제하기
    removeCartItem = async (keysInfo) => {
        const res = await fetchAndReceiveJSON({
            url: REMOVE_CART_ITEM_URL,
            method: 'POST',
            body: keysInfo,
        });

        return res;
    };

    //카트 리스트 리셋하기
    resetCartList = async () => {
        const res = await fetchAndReceiveJSON({
            url: RESET_CART_LIST_URL,
            method: 'POST',
        });

        return res;
    };

    //카트 수량 수정하기
    updateCartItemQty = async (key, qty, productId, variationId) => {
        const res = await fetchAndReceiveJSON({
            url: UPDATE_CART_QTY_URL,
            method: 'POST',
            body: {
                cart_item_key: key,
                quantity: qty,
                product_id: productId,
                variation_id: variationId,
            },
        });

        return res;
    };

    //쿠폰 적용하기
    applyCoupon = async (couponId) => {
        if (couponId !== '쿠폰 코드 직접 입력') {
            const res = await fetchAndReceiveJSON({
                url: APPLY_COUPON_URL,
                method: 'POST',
                body: {
                    couponId,
                },
            });

            return res;
        } else {
            this.showWriteCouponCode = true;
        }
    };

    //쿠폰 삭제하기
    removeCoupon = async (couponId) => {
        const res = await fetchAndReceiveJSON({
            url: REMOVE_COUPON_URL,
            method: 'POST',
            body: {
                couponId,
            },
        });

        return res;
    };

    //옵션 변경 누를시 해당하는 옵션리스트 업데이트하기 -> 옵션 팝업에서 필요한 데이터 업데이트
    setSelectedProduct = (item) => {
        this.selectedProduct = item;
        this.currentSelectedOption = item.variations;
        this.currentOptionQty = item.quantity;
        //사료 정기배송인 경우 period 값 업데이트 해줘야함
        this.selectedDogfoodPeriod = item.billing_interval;
        //멀티 옵션인 경우
        if (item.variations && item.variations[0].attributes.length > 1) {
            let arr: any = new Array(item.variations[0].attributes.length);
            for (let i = 0; i < item.product.product_variations.length; i++) {
                arr[i] = new Array(
                    item.product.product_variations[i].attributes.length
                );
                for (
                    let j = 0;
                    j < item.product.product_variations[i].attributes.length;
                    j++
                ) {
                    arr[i][j] =
                        item.product.product_variations[i].attributes[j].option;
                }
            }

            this.multiOptionsDropdowns = arr;
        }
    };

    //옵션 드랍다운 아이템 클릭시
    updateSelectedOption = (optionId) => {
        //번들상품이 아닌 경우
        if (!this.isBundleProduct) {
            if (
                this.currentSelectedOption.some(
                    (option) => option.id === optionId
                ) &&
                this.selectedProduct.product.productCluster !==
                DOG_FOOD_PRODUCT_CLUSTER
            ) {
                if (this.currentSelectedOption[0].price === "100") {
                    window.alert('해당 상품의 최대 구매 가능 갯수는 1개입니다.')
                } else {
                    this.currentOptionQty = this.currentOptionQty + 1;
                }
            } else {
                this.currentOptionQty = 1;
                this.currentSelectedOption =
                    this.selectedProduct?.product.product_variations?.filter(
                        (variation) => variation.id === optionId
                    );
            }
        } else {
            //번들상품인 경우
            if (
                this.currentSelectedBundleProducts.some(
                    (product) => product.id === optionId
                )
            ) {
                // this.currentSelectedBundleProducts = this.currentSelectedBundleProducts.map(
                //     (product) => {
                //         if (product.id === optionId) {
                //             product.quantity = product.quantity + 1;
                //         }
                //         return product;
                //     }
                // );
            } else {
                this.currentSelectedBundleProducts = [
                    ...this.currentSelectedBundleProducts,
                    this.selectedProduct.product.children
                        .filter((child) => child.id === optionId)
                        .map((child) => {
                            return {
                                id: child.id,
                                name: child.name,
                                thumbnail: child.thumbnail,
                                on_sale: true,
                                quantity: 1,
                                price: this.selectedProduct.product
                                    .bundled_items_discount[child.id],
                                regular_price: child.regular_price,
                                discount_rate: child.discount_rate,
                            };
                        })[0],
                ];
            }
        }
    };

    //옵션 팝업 내에서 수량조절 (버튼)
    controlCurrentOptionQty = (evt) => {
        if (evt.target.alt === 'minus') {
            if (this.currentOptionQty > 1) {
                this.currentOptionQty = this.currentOptionQty - 1;
            }
        } else {
            if (
                this.selectedProduct.product.productCluster ===
                DOG_FOOD_PRODUCT_CLUSTER &&
                this.currentOptionQty === 1
            ) {
                this.rootStore.setCommonPopupContents(
                    '이미 장바구니에 담은 베이컨 밥 솔루션 제품이 있습니다.',
                    '현재 베이컨 밥 솔루션은 계정 당 1개의 상품 이용을 제공하고 있습니다. 복수 개 상품의 서비스 신청을 원하신다면, 채팅 상담으로 문의해주세요.',
                    [
                        {
                            buttonName: '상담하기',
                            bgColor: '#101010',
                            clickFunc: openChannelIO,
                        },
                    ]
                );
                return;
            }
            if (this.currentSelectedOption[0]?.price === "100") {
                return window.alert('해당 상품의 최대 구매 가능 갯수는 1개입니다.')
            }
            this.currentOptionQty = this.currentOptionQty + 1;
        }
    };

    //옵션 팝업 내 수량 조절(인풋 입력)
    currentOptionQtyInputHandler = (evt) => {
        if (evt.target.value === '0') {
            alert('수량은 1개 이상 선택해 주세요.');
            return;
        }
        if (
            parseInt(evt.target.value) > 1 &&
            this.selectedProduct.product.productCluster ===
            DOG_FOOD_PRODUCT_CLUSTER
        ) {
            this.rootStore.setCommonPopupContents(
                '이미 장바구니에 담은 베이컨 밥 솔루션 제품이 있습니다.',
                '현재 베이컨 밥 솔루션은 계정 당 1개의 상품 이용을 제공하고 있습니다. 복수 개 상품의 서비스 신청을 원하신다면, 채팅 상담으로 문의해주세요.',
                [
                    {
                        buttonName: '상담하기',
                        bgColor: '#101010',
                        clickFunc: openChannelIO,
                    },
                ]
            );
            return;
        }
        if (evt.target.value === '') {
            this.currentOptionQty = 0;
        } else {
            this.currentOptionQty = parseInt(evt.target.value);
        }
    };

    //옵션 팝업에서 변경하기 버튼 클릭할 때 옵션변경
    updateOption = async () => {
        this.rootStore.setIsSpinnerOn(true);
        if (
            this.selectedProduct.product.productCluster ===
            DOG_FOOD_PRODUCT_CLUSTER &&
            this.currentOptionQty > 1
        ) {
            this.rootStore.setCommonPopupContents(
                '이미 장바구니에 담은 베이컨 밥 솔루션 제품이 있습니다.',
                '현재 베이컨 밥 솔루션은 계정 당 1개의 상품 이용을 제공하고 있습니다. 복수 개 상품의 서비스 신청을 원하신다면, 채팅 상담으로 문의해주세요.',
                [
                    {
                        buttonName: '상담하기',
                        bgColor: '#101010',
                        clickFunc: openChannelIO,
                    },
                ]
            );
            this.rootStore.setIsSpinnerOn(false);
            return;
        }
        if (
            (this.selectedProduct.product.type !== 'subscription' &&
                this.selectedProduct.variation_id !==
                this.currentSelectedOption[0].id) ||
            (this.selectedProduct.product.productCluster ===
                DOG_FOOD_PRODUCT_CLUSTER &&
                this.selectedProduct.billing_interval !==
                this.selectedDogfoodPeriod) ||
            (this.selectedProduct.product.productCluster ===
                DOG_FOOD_PRODUCT_CLUSTER &&
                this.selectedProduct.product.type === 'variable-subscription' &&
                this.selectedProduct.variation_id !==
                this.currentSelectedOption[0].id)
        ) {
            this.removeCartItem([
                {
                    cart_item_key: this.selectedProduct.cart_item_key,
                },
            ])
                .then(() =>
                    this.addItemToCart([
                        {
                            product_id: this.selectedProduct.product_id,
                            variation_id:
                                this.selectedProduct.product.productCluster ===
                                DOG_FOOD_PRODUCT_CLUSTER &&
                                this.selectedProduct.product.type ===
                                'subscription'
                                    ? 0
                                    : this.currentSelectedOption[0].id,
                            quantity: this.currentOptionQty,
                            billing_period:
                                this.selectedProduct.product.productCluster ===
                                DOG_FOOD_PRODUCT_CLUSTER
                                    ? 'week'
                                    : '',
                            billing_interval:
                                this.selectedProduct.product.productCluster ===
                                DOG_FOOD_PRODUCT_CLUSTER
                                    ? this.selectedDogfoodPeriod
                                    : '',
                        },
                    ])
                )
                .then((res) => {
                    if (res === RESPONSE_ALREADY_HAS_DOGFOOD) {
                        this.rootStore.setCommonPopupContents(
                            '이미 장바구니에 담은 베이컨 밥 솔루션 제품이 있습니다.',
                            '이미 1개의 사료 정기배송을 이용중이네요. 추가 신청을 원하신다면, 채팅 상담을 통해 확인해주세요.',
                            [
                                {
                                    buttonName: '상담하기',
                                    bgColor: '#101010',
                                    clickFunc: openChannelIO,
                                },
                            ]
                        );
                    }
                    this.setCartListCount();
                })
                .then(() => this.setCartInfoList())
                .then(() => this.rootStore.setIsSpinnerOn(false));
        } else if (
            (this.selectedProduct.product.productCluster ===
                DOG_FOOD_PRODUCT_CLUSTER &&
                this.selectedProduct.billing_interval ===
                this.selectedDogfoodPeriod) ||
            (this.selectedProduct.product.productCluster ===
                DOG_FOOD_PRODUCT_CLUSTER &&
                this.selectedProduct.product.type === 'variable-subscription' &&
                this.selectedProduct.variation_id ===
                this.currentSelectedOption[0].id)
        ) {
            this.rootStore.setIsSpinnerOn(false);
            return;
        } else if (this.currentOptionQty !== 0) {
            this.updateCartItemQty(
                this.selectedProduct.cart_item_key,
                this.currentOptionQty,
                this.selectedProduct.product_id,
                this.selectedProduct.variation_id
            )
                .then(() => this.setCartListCount())
                .then(() => this.setCartInfoList())
                .then(() => this.rootStore.setIsSpinnerOn(false));
        } else {
            alert('수량은 1개 이상 선택해 주세요');
            this.rootStore.setIsSpinnerOn(false);
        }
    };

    //번들상품 여부 체크
    setIsBundleProduct = (bool) => {
        this.isBundleProduct = bool;
    };

    //선택된 번들상품 정보
    setSelectedBundleProducts = (bundles) => {
        this.selectedBundleProducts = bundles;
    };

    //현재 선택된 번들상품 정보 -> 번들상품의 경우 옵션팝업에서 해당 데이터가 렌더됨
    setCurrentSelectedBundleProducts = (bundles) => {
        this.currentSelectedBundleProducts = bundles.map((bundle) => {
            return {
                id: bundle.product_id,
                name: bundle.product_name,
                thumbnail: bundle.product.thumbnail,
                on_sale: true,
                quantity: bundle.quantity,
                price: bundle.price,
                regular_price: bundle.regular_price,
                discount_rate: bundle.discount_rate,
            };
        });
    };

    //번들상품 -> 옵션팝업 -> 삭제가능
    deleteBundleOptionItem = (id) => {
        this.currentSelectedBundleProducts =
            this.currentSelectedBundleProducts.filter(
                (product) => product.id !== id
            );
    };

    //번들상품 -> 옵션팝업 -> currentSelectedBundleProducts 수량 조절 함수
    setCurrentSelectedBundleProductQty = (evt, id) => {
        if (evt.target.alt === 'minus') {
            if (
                this.currentSelectedBundleProducts.filter(
                    (product) => product.id === id
                )[0].quantity > 1
            )
                this.currentSelectedBundleProducts =
                    this.currentSelectedBundleProducts.map((product) => {
                        if (product.id === id) {
                            product.quantity = product.quantity - 1;
                        }
                        return product;
                    });
        } else {
            this.currentSelectedBundleProducts =
                this.currentSelectedBundleProducts.map((product) => {
                    if (product.id === id) {
                        product.quantity = product.quantity + 1;
                    }
                    return product;
                });
        }
    };

    setOptionDropdownInfo = (optionInfos) => {
    };

    //번들상품 -> 옵션팝업 -> 변경하기 클릭
    changeBundleOption = () => {
        this.rootStore.setIsSpinnerOn(true);
        //1. 부모상품이 변경된 경우(수량만) 2. 자식상품이 추가/삭제된경우(length 비교하면안됨) 3.둘다 변경사항이 없는 경우
        if (
            this.currentOptionQty !== this.selectedProduct.quantity ||
            !(
                this.selectedBundleProducts.map((bundle) => bundle.product_id)
                    .length ===
                this.currentSelectedBundleProducts.map(
                    (bundle) => bundle.id
                ).length &&
                this.selectedBundleProducts
                    .map((bundle) => bundle.product_id)
                    .every(
                        (bundle, idx) =>
                            bundle ===
                            this.currentSelectedBundleProducts.map(
                                (bundle) => bundle.id
                            )[idx]
                    )
            )
        ) {
            let stampkeys = [];
            Object.entries(this.selectedProduct.stamp)
                .filter((bundle: any) =>
                    this.currentSelectedBundleProducts
                        .map((bundle) => bundle.id)
                        .includes(bundle[1].product_id)
                )
                .forEach((bundle) => stampkeys.push(bundle[0]));

            let bundledItemsToAdd = {};
            stampkeys.forEach((key) => (bundledItemsToAdd[key] = 1));

            // const removeItems = this.selectedBundleProducts.map((bundle) => {
            //     return {
            //         cart_item_key: bundle.cart_item_key,
            //     };
            // });

            this.removeCartItem([
                {
                    cart_item_key: this.selectedProduct.cart_item_key,
                },
                // ...removeItems,
            ])
                .then(() =>
                    this.addItemToCart(
                        this.currentSelectedBundleProducts.length > 0
                            ? [
                                {
                                    product_id:
                                    this.selectedProduct.product_id,
                                    quantity: this.currentOptionQty,
                                    bundled_items_to_add: bundledItemsToAdd,
                                },
                            ]
                            : [
                                {
                                    product_id:
                                    this.selectedProduct.product_id,
                                    quantity: this.currentOptionQty,
                                },
                            ]
                    )
                )
                .then(() => this.setCartInfoList())
                .then(() => this.setCartListCount())
                .then(() => {
                    this.rootStore.setIsSpinnerOn(false);
                    this.rootStore.setIsDimBgOn(false);
                    this.setShowOptionBox(false);
                    this.setIsBundleProduct(false);
                });
        } else {
            this.setShowOptionBox(false);
            this.rootStore.setIsSpinnerOn(false);
        }
    };

    //멀티옵션 -> 옵션팝업 -> 옵션 아이템 클릭시 현재 선택된 것의 정보가 담기는 곳
    setSelectedMultiOptions = (selectedOption, idx) => {
        if (!this.selectedMultiOptions || this.selectedMultiOptions.length === 0) {
            this.selectedMultiOptions = new Array(
                this.selectedProduct.product.attributes.length
            ).fill(undefined);
        }

        const arr = [...this.selectedMultiOptions];

        arr[idx] = selectedOption;

        this.selectedMultiOptions = arr;

        if (
            this.selectedMultiOptions.length ===
            this.selectedProduct.product.attributes.length
        ) {
            let arr = this.selectedProduct.product.product_variations.filter(
                (variation) =>
                    variation.attributes.every(
                        (attr, idx) =>
                            attr.option === this.selectedMultiOptions[idx]
                    )
            );

            this.currentSelectedOption = arr;
            this.selectedMultiOptions = [];
        }
    };

    setResetSelectedMultiOptions = () => {
        this.selectedMultiOptions = [];
    };

    setSelectedDogfoodPeriod = (item) => {
        //옵션변경 사료상품
        if (this.selectedProduct.product.type === 'variable-subscription') {
            //기존의 옵션과 동일한 옵션/주기 선택한 경우
            if (
                this.currentSelectedOption.some(
                    (option) => option.id === this.selectedDogfoodOption
                ) &&
                Number(this.selectedProduct.billing_interval) === item
            ) {
                // this.currentOptionQty = this.currentOptionQty + 1;
            } else {
                this.selectedDogfoodPeriod = item;
                this.currentSelectedOption =
                    this.selectedProduct?.product.product_variations?.filter(
                        (variation) =>
                            variation.id === this.selectedDogfoodOption
                    );
            }
        } else {
            if (Number(this.selectedProduct.billing_interval) === item) {
            } else {
                this.selectedDogfoodPeriod = item;
            }
        }
    };

    setSelectedDogfoodOption = (item) => {
        this.selectedDogfoodOption = item;
    };
}

export default CartPageStore;
