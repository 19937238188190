
import '../ChangePaymentMethod/ChangePaymentMethod.scss';

const AllSubscriptionShippingChange = (
    {
        setIsShowPopup,
        setIsChangeAllSubscriptionShipping
    }
) => {

    return (
        <div className="skipPaymentMethodWrapper">

            <div className="topArea">
                <div className="title center">
                    앞으로의 정기배송 역시 새로운 주소로 받아보시기를 원하시나요?
                </div>
            </div>

            <div className="cancelBtns">
                <div className="btn marginTop black"
                    onClick={() => {
                        setIsChangeAllSubscriptionShipping(false);
                    }}
                >
                    프로필 주소만 변경
                </div>
                <div className="btn marginTop"
                    onClick={() => {
                        setIsChangeAllSubscriptionShipping(true);
                    }}
                >
                    새 주소로 받기
                </div>
            </div>

        </div>
    );
}

export default AllSubscriptionShippingChange;