import React, {useContext, useEffect, useState} from 'react';
import './DropDown2.scss';
import {defaultElementProps} from '../../types/types';
import {addComma, displayOptionNameWithSquareBracket, getSrcUrl} from '../../utils/commonUtils';
import {AppContext} from "../../contexts/AppContext/AppContext";
import AlarmSvg from "../../assets/icon-alarm-white-20.svg"
interface DropDownProps extends defaultElementProps {
    dropDownItems?: Array<any>;
    optionName: string;
    itemClickEvent: any;
    dropDownType: string;
    idx?: number;
    leftImg?: string;
    dropdownExpandIdx?: number;
    setDropdownExpandIdx?: any;
    miniCartProduct?: any;
    className?: string;
}

const DropDown: React.FunctionComponent<DropDownProps> = ({
                                                              itemClickEvent,
                                                              optionName,
                                                              className,
                                                              dropDownItems,
                                                              dropDownType,
                                                              idx,
                                                              leftImg,
                                                              dropdownExpandIdx,
                                                              setDropdownExpandIdx,
                                                              miniCartProduct,
                                                              ...othreProps
                                                          }) => {

    // console.log(idx , dropdownExpandIdx , idx === dropdownExpandIdx);
    // console.log(dropDownType , dropDownItems , itemClickEvent);

    let isInitExpand = false;
    if (idx != undefined && dropdownExpandIdx != undefined) {
        isInitExpand = idx === dropdownExpandIdx;
    }
    const {modalStore} =
        useContext(AppContext);
    const [isExpanded, setIsExpanded] = useState<boolean>(isInitExpand);
    const {setIsRestockingModalVisible, setIsRestockingModalProductId} = modalStore

    return (
        <>
            {dropDownItems && dropDownItems.length > 0 && (
                <div
                    className={`dropdownWrapper ${isExpanded ? 'isExpanded' : ' '
                    } ${className ? className : ''}`}
                    {...othreProps}
                >
                    <div className="dropDownContentArea">
                        <div
                            className="defaultTextArea"
                            onClick={() => {
                                if (setDropdownExpandIdx) {
                                    setDropdownExpandIdx(isExpanded ? null : idx);
                                }
                                setIsExpanded((prev) => !prev);

                            }}
                        >
                            <div className="left">
                                {leftImg && leftImg.length > 0 && (
                                    <div className="flex">
                                        <img
                                            className="leftImg"
                                            src={leftImg}
                                            style={{width: 24}}
                                        />
                                    </div>
                                )}
                                <div>
                                    <div className="selectedText">
                                        {optionName}
                                    </div>
                                </div>
                            </div>
                            <img
                                className={`expandButton ${isExpanded ? '' : 'expanded'
                                } `}
                                src="/img/m-btn-icon-dropdown-open-24.svg"
                                alt=""
                            />
                        </div>
                        <div
                            className={`dropDowns2 ${isExpanded ? 'isExpanded' : ''
                            }`}
                        >

                            {/* all */}
                            {dropDownItems && dropDownItems.map((item) => {

                                const discountRate = item.discount_rate?.replace(/\(/g, '').replace(/\)/g, '');

                                let optionName = "";
                                if (dropDownType === 'bundle' || dropDownType === 'grouped') {
                                    optionName = item.name;
                                } else if (dropDownType === 'multiOption') {
                                    optionName = item.option;
                                } else {
                                    optionName = item.attributes[0]?.option;
                                }

                                let displayPrice: any = 0;

                                if (item.stock_status !== 'instock') {
                                    displayPrice = '일시품절';
                                } else if (dropDownType !== 'bundle') {

                                    if (dropDownType === 'multiOption') {
                                        displayPrice = addComma(item.price);
                                    } else {
                                        displayPrice = item.display_price;
                                    }

                                } else {
                                    if (miniCartProduct.bundled_items_discount) {
                                        displayPrice = addComma(miniCartProduct.bundled_items_discount[item.id]) + "원";
                                    }
                                }

                                return (
                                    <div
                                        key={item.id}
                                        onClick={() => {
                                            if (dropDownType === 'multiOption') {
                                                itemClickEvent(item.option, idx);
                                            } else {
                                                itemClickEvent(item);
                                            }

                                            if (setDropdownExpandIdx) {
                                                setDropdownExpandIdx(null);
                                            }
                                            setIsExpanded(false);
                                        }}
                                        className="miniCart-dropdownItems options"
                                    >
                                        {dropDownType !== 'multiOption' && item.image && (
                                            <img
                                                src={getSrcUrl(item.image)}
                                            />
                                        )}
                                        <div className="optionInfo">
                                            <div
                                                className={`optionName ${item.stock_status ===
                                                'instock'
                                                    ? ' '
                                                    : 'soldout'
                                                }`}
                                                dangerouslySetInnerHTML={{
                                                    __html: displayOptionNameWithSquareBracket(optionName, "dropdownOptionSpan")
                                                }}
                                            >
                                            </div>
                                            <div className="optionPrice">
                                                <div className="optionPrice-wrapper">
                                                    <div
                                                        className={`displayPrice options ${item.stock_status ===
                                                        'instock'
                                                            ? ' '
                                                            : 'soldout'
                                                        }`}
                                                    >
                                                        {displayPrice}
                                                        {dropDownType === 'multiOption' &&
                                                            <span
                                                                className="won">원{item.isMultiPrice ? '~' : ''}</span>}
                                                    </div>
                                                    {item.on_sale && (
                                                        <div className="regularPrice">
                                                            {
                                                                addComma(item.regular_price)
                                                            }
                                                            {item.isMultiRegularPrice ? '~' : ''}
                                                        </div>
                                                    )}
                                                    {item.discount_rate && item.stock_status === 'instock' && (
                                                        <div className="discountRate">
                                                            {discountRate !== '-0%' && discountRate}
                                                        </div>
                                                    )}
                                                </div>
                                                {item.stock_status === "outofstock" &&
                                                    <div className="restocking-wrapper" onClick={() => {
                                                        setIsRestockingModalVisible(true);
                                                        setIsRestockingModalProductId(item.id);
                                                        event.stopPropagation();
                                                    }}>
                                                        <div className="restocking-icon">
                                                            <img src={AlarmSvg} />
                                                        </div>
                                                        <div className="restocking-description">
                                                            재입고 알림 신청
                                                        </div>
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DropDown;
