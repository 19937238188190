import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { fetchAndReceiveJSON } from '../../utils/ajaxUtils';
import { BB_PORTFOLIO_DATA_URL } from '../../constants/constants';
import './BBPortfolioPage.scss';

const BBPortfolioPage = () => {
    const [portfolioContent, setPortfolioContent] = useState<string>('');

    const { slug } = useParams<{ slug: string }>();

    const fetchBBPortfolioPageData = async () => {
        const res = await fetchAndReceiveJSON({
            url: `${BB_PORTFOLIO_DATA_URL}/${slug}`,
        });

        setPortfolioContent(res);
    };

    useEffect(() => {
        fetchBBPortfolioPageData();
    }, [slug]);

    return (
        <>
            <Helmet>
                <title>베이컨박스 | 베이컨박스</title>
            </Helmet>
            <div
                className="BBPortfolioPage"
                dangerouslySetInnerHTML={{ __html: portfolioContent }}
            />
        </>
    );
};

export default BBPortfolioPage;
