import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import { useHistory, useLocation } from 'react-router-dom';
import { logout } from '../../utils/ajaxUtils';
import './AccountNav.scss';

const AccountNav = observer(() => {
    const { rootStore } = React.useContext(AppContext);
    const { initData , setIsSpinnerOn } = rootStore;

    const history = useHistory();
    const location = useLocation();

    return (
        <div className="desktopNavigation">
            <div className="paddingWrapper">
                <div className="userInfoArea">
                    <div className="userName">
                        {initData?.userFirstName +
                            initData?.userLastName}
                        님
                    </div>
                    <div className="userBtns">
                        <div
                            onClick={() => {
                                history.push('/my-account/profile');
                            }}
                            className="userDataUpdateBtn"
                        >
                            <img
                                className="updateIcon"
                                src="/img/icon/update.png"
                            />
                            기본정보 수정
                        </div>
                        <div className="logoutBtn" onClick={() => {
                            setIsSpinnerOn(true);
                            logout();
                        }}>
                            로그아웃
                        </div>
                    </div>
                </div>

                <div className="taps">
                    <div
                        className={`tap ${location.pathname.includes(
                            '/my-account/orders'
                        )
                                ? 'on'
                                : ''
                            }`}
                        onClick={() => {
                            history.push('/my-account/orders');
                        }}
                    >
                        주문배송 조회
                    </div>
                </div>
            </div>
        </div>
    );
});

export default AccountNav;
