import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import ThumbnailPaginationSlider from '../../components/Sliders/ThumbnailPaginationSlider/ThumbnailPaginationSlider';
import { defaultElementProps, sliderContainerProps } from '../../types/types';
import { stringLineBreak } from '../../utils/commonUtils';
import './HomeSocialInteractContainer.scss';

const HomeSocialInteractContainer: React.FunctionComponent<sliderContainerProps> =
    observer(({ icon, slideData, className, title, ...otherProps }) => {
        const { rootStore } = useContext(AppContext);
        const { initData } = rootStore;
        return (
            <div
                className={`SocialInteractContainerWrapper ${
                    className ? className : ''
                }`}
            >
                <div className="SocialInteractContainerTopArea">
                    <div className="SocialInteractMainTex">
                        {stringLineBreak(title)}
                    </div>

                    <img
                        className="SocialInteractImg"
                        src={initData.resourcePrefix + icon}
                        alt=""
                    />
                </div>

                <ThumbnailPaginationSlider
                    slideData={slideData}
                    className="SocialInteractSlider"
                />
            </div>
        );
    });

export default HomeSocialInteractContainer;
