import React from "react"
import HolidayDeliveryModal from "../../components/HolidayDeliveryModal/HolidayDeliveryModal";
import RestockingModal from "../../components/RestockingModal/RestockingModal";

function ModalPage() {
    return (
        <>
            <HolidayDeliveryModal/>
            <RestockingModal/>
        </>
    )

}

export default ModalPage
