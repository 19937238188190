import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import { observer } from 'mobx-react';
import './ProductMiniCartPopup.scss';
import ProductBottomMiniCart from './ProductBottomMiniCart';

const ProductMiniCartPopup = observer(({ }) => {
    const { rootStore, miniCartPopupStore } = useContext(AppContext);
    const { setIsDimBgOn, showSearch } = rootStore;

    const { isExpand, setIsExpand, isFollowPopup } = miniCartPopupStore;

    // console.log("render popupminicart popup ");

    useEffect(() => {

        if (isExpand) {
            document.body.style.overflow = 'hidden';
            setIsDimBgOn(true);
        } else if (isFollowPopup == false) {

            document.body.style.overflow = 'unset';
            setIsDimBgOn(false);
        } else {

            // document.body.style.overflow = 'unset';
            setIsDimBgOn(true);
        }

        return () => {
        };
    }, [isExpand]);

    const wrapperRef = useRef(null);

    return (
        <div
            ref={wrapperRef}
            className={`miniCartPopup2 ${isExpand ? 'expand' : ''} ${showSearch ? 'hide' : ''}`}
        >
            <div
                onClick={() => {
                    setIsExpand(false);
                }}
                className="closeMiniCartPopupBtn"
            >
                <img
                    src="/img/btn-gnb-popup-close-white.svg"
                    alt="close-popup-button"
                />
            </div>

            <ProductBottomMiniCart />
        </div>

    );
});

export default ProductMiniCartPopup;
