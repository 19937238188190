import React from 'react';
import './NaverPayButton.scss';

const NaverPayButton = () => {
    return (
        <>
        <div id="iamport-naverpay-product-button" className="NaverPayButtonMobile"></div>
        {/* <div className="NaverPayButton">
            <div />
        </div> */}
        </>
    );
};

export default NaverPayButton;
