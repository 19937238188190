// import 'isomorphic-fetch';
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
// import 'core-js/stable';
// import 'regenerator-runtime/runtime';
import HolidayDeliveryModal from '../../components/HolidayDeliveryModal/HolidayDeliveryModal'

import React, {useState, useEffect, useMemo, Suspense, useRef} from 'react';
import {Helmet} from 'react-helmet';
import {observer} from 'mobx-react';
import {AppContext} from '../../contexts/AppContext/AppContext';
import {useHistory, useParams} from 'react-router-dom';
import './ProductPage.scss';
import NavigationTab from '../../components/NavigationTab/NavigationTab.component.jsx';
import PurchaseMenu from '../../components/PurchaseMenu/PurchaseMenu.component';
import DetailImages from '../../components/DetailImages/DetailImages.component.jsx';
import smoothscroll from 'smoothscroll-polyfill';

import {
    BREAKPOINT_TBL,
    COLLECTION_PREFIX_URL,
    defaultDogFoodDescription,
    DOG_FOOD_COLLECTION_SLUG,
    DOG_FOOD_EVENT_IMG_PATH,
    GET_PRODUCT_INFO_URL,
    HUNDRED_SHOP_SLUG,
    NO_DATA,
    STATIC_EVENT_BANNER,
} from '../../constants/constants';

import {
    addDenom,
    getSrcUrl,
    isEventPriceProduct,
} from '../../utils/commonUtils';
import {fetchAndReceiveJSON} from '../../utils/ajaxUtils';
import {
    buyNowNpayAction,
    getProductsForNpayZzim,
    makeNpayButton,
} from '../../utils/paymentUtils';
import {BREAKPOINT_DT, NPAY_BUTTON_KEY} from '../../constants/constants';
//import PreloadImage from 'react-preload-image';
import {throttle} from 'lodash';
import {isDogFoodProduct} from '../../utils/miniCartUtils';
import ProductEventBanner from '../../components/ProductEventBanner/ProductEventBanner';

const CURRENT_MENU_POSITION_RELATIVE = 'relative';
const CURRENT_MENU_POSITION_FIXED = 'fixed';
const CLASS_MENU_POSITION_RELATIVE = 'scroll';

let lastScrollPosition = 0;
smoothscroll.polyfill();

const dogFoodDefaultEvent = {
    img: DOG_FOOD_EVENT_IMG_PATH,
    link: '/store/collection/' + DOG_FOOD_COLLECTION_SLUG,
};

const PRODUCT_EVENT_DESC = {
    bgColor: '#49946c',
    fontColor: '#ffffff',
    text: '지금 신규 가입하면 100원!',
};

const DOGFOOD_PRODUCT_EVENT_DESC = {
    bgColor: '#0138DD',
    fontColor: '#ffffff',
    text: '할인 쿠폰 적용 상품',
};

const priceEventCollectionEvent = {
    eventTitle: '100원 할인',
    img: '/img/banner/group-9@2x.png',
    link: COLLECTION_PREFIX_URL + HUNDRED_SHOP_SLUG,
};


const ProductPage = observer((props) => {
    const {rootStore, miniCartPopupStore} = React.useContext(AppContext);
    const {setMiniCartProduct, setIsExpand, miniCartProductQty} =
        miniCartPopupStore;

    const {initData, setIsDimBgOn} = rootStore;

    let {slug} = useParams<{
        slug: string;
    }>();
    // scrollPolyfill();
    const [productData, setProductData] = useState<any>(null);
    const [eventBannerData, setEventBannerData] = useState<any>();
    // const [isShowEventPrice, setIsShowEventPrice] = useState<boolean>(false);

    const [isDogFood, setIsDogFood] = useState<boolean>(false);

    const [tabInfo, setTabInfo] = useState<any>([
        {
            tabName: '제품 정보',
            targetDomId: 'detail',
            moveWhenClicked: true,
        },
        {
            tabName: '리뷰',
            targetDomId: 'review',
            moveWhenClicked: true,
        },
        // {
        //   tabName: '연관상품',
        //   targetDomId: 'related',
        //   moveWhenClicked: true,
        // },
    ]);

    const [reviewSectionFlag, setReviewSectionFlag] = useState(false)
    const [productDescription, setProductDescription] = useState(null);
    const [pageInfo, setPageInfo] = useState<any>({
        scrollParent: null,
        altScrollParent: null,
        scrollPosition: () => {
            return 0;
        },
    });
    const [currentMenuPosition, setCurrentMenuPosition] = useState(
        CURRENT_MENU_POSITION_FIXED
    );
    const [isVisibleHolidayModal, setIsVisibleHolidayModal] = useState(false)
    const currentMenuPositionRef = useRef(currentMenuPosition);
    useEffect(() => {
        currentMenuPositionRef.current = currentMenuPosition;
    }, [currentMenuPosition]);

    // const scrollBeforeRef = useRef(scrollBefore);


    const DetailInfo = useMemo(
        () =>
            React.lazy(
                () => import('../../components/DetailInfo/DetailInfo.jsx')
            ),
        [productData]
    );
    //   const Reviews = useMemo(
    //     () => React.lazy(() => import('../../components/Reviews/Reviews.component.jsx')),
    //     [productData]
    //   );


    const RelatedProducts = useMemo(
        () =>
            React.lazy(
                () =>
                    import(
                        '../../components/RelatedProducts/RelatedProducts.jsx'
                        )
            ),
        [productData?.relatedProducts]
    );

    let routerHistory = useHistory();

    const desktopMenuTopMargin = 88;
    const npayBoxHeight = 85;

    const pageInfoRef = useRef(pageInfo);

    // let isShowEventPriceData = false;

    const checkReviewSectionPosition = () => {
        if (!reviewSectionFlag) {
            const reviewElementPosition = document.getElementById('review')
            if(reviewElementPosition){
                if (reviewElementPosition.offsetTop / 2 <= window.scrollY) {
                    setReviewSectionFlag(true)
                }
            }
        }
    }

    useEffect(() => {
        if (reviewSectionFlag) {
            loadReviewScript()
        }
    }, [reviewSectionFlag, productData])

    const definePageInfo = () => {
        //dt
        defineCurrentMenuPosition();

        if (
            window.innerWidth > BREAKPOINT_DT &&
            currentMenuPositionRef.current === CURRENT_MENU_POSITION_RELATIVE
        ) {

            setPageInfo({
                scrollParent: document.querySelector('.content-area'),
                altScrollParent: window,
                scrollPosition: function () {
                    const obj: HTMLDivElement =
                        document.querySelector('.content-area');
                    return obj ? obj.scrollTop : 0;
                },
            });
        } else {
            //mb
            setPageInfo({
                scrollParent: window,
                altScrollParent: document.querySelector('.content-area'),
                scrollPosition: function () {
                    return window.scrollY;
                }
            });
        }
    };

    useEffect(() => {
        pageInfoRef.current = pageInfo;
    }, [pageInfo]);

    useEffect(() => {
        setTimeout(() => {
            definePageInfo();
        }, 500);
    }, []);


    const defineCurrentMenuPosition = () => {
        if (window.innerWidth <= BREAKPOINT_DT) return;

        const bfMargin = 0;

        const menuInner: HTMLElement = document.querySelector(
            '.menu-area .menu-area-inner'
        );
        const menuHeight: number = menuInner
            ? menuInner.getBoundingClientRect().height +
            desktopMenuTopMargin +
            bfMargin
            : 0;
        const menu: HTMLElement = document.querySelector('.menu-area');
        const content: HTMLElement = document.querySelector('.content-area');
        const page: HTMLElement = document.querySelector(
            '.product-page-wrapper'
        );

        if (window.innerHeight > menuHeight) {
            // console.log('to fixed');

            if (
                currentMenuPositionRef.current !== CURRENT_MENU_POSITION_FIXED
            ) {
                //console.log('okok fixed');
                setCurrentMenuPosition(CURRENT_MENU_POSITION_FIXED);

                //console.log('oko add class!');
                if (menuInner)
                    menuInner.classList.add(CURRENT_MENU_POSITION_FIXED);
                // if (page) page.classList.remove(CLASS_MENU_POSITION_RELATIVE);
                // if (menu) menu.classList.remove(CLASS_MENU_POSITION_RELATIVE);
                // if (content)
                //     content.classList.remove(CLASS_MENU_POSITION_RELATIVE);
            }
        } else {
            //   console.log('to relative');
            if (
                currentMenuPositionRef.current !==
                CURRENT_MENU_POSITION_RELATIVE
            ) {
                // console.log('okok relative');
                setCurrentMenuPosition(CURRENT_MENU_POSITION_RELATIVE);

                if (menuInner)
                    menuInner.classList.remove(CURRENT_MENU_POSITION_FIXED);
                // if (page) page.classList.add(CLASS_MENU_POSITION_RELATIVE);
                // if (menu) menu.classList.add(CLASS_MENU_POSITION_RELATIVE);
                // if (content)
                //     content.classList.add(CLASS_MENU_POSITION_RELATIVE);
            }
        }
    };


    useEffect(() => {
        // console.log('update check');
        // callTopBannerInfo();

        if (history.scrollRestoration) {
            window.history.scrollRestoration = 'manual';
        }

        // const menu = document.querySelector('.menu-area');
        // const content = document.querySelector('.content-area');

        const throttleInner = throttle(() => {
            definePageInfo();
        }, 500);
        window.addEventListener('scroll', checkReviewSectionPosition)
        window.addEventListener('resize', throttleInner);
        //  if (menu) menu.addEventListener('mousewheel', menuAreaHandler);
        //  if (content) content.addEventListener('mousewheel', contentHandler);

        return () => {
            window.removeEventListener('scroll', checkReviewSectionPosition)
            window.removeEventListener('resize', throttleInner);
            // if (menu)  menu.removeEventListener('mousewheel', menuAreaHandler);
            // if (content)  content.removeEventListener('mousewheel', contentHandler);
        };
    }, []);

    useEffect(() => {
        const menuInner = document.querySelector('.menu-area .menu-area-inner');

        const menu = document.querySelector('.menu-area');
        const content = document.querySelector('.content-area');
        const page = document.querySelector('.product-page-wrapper');

        if (currentMenuPositionRef.current !== currentMenuPosition) {
            if (
                currentMenuPositionRef.current ===
                CURRENT_MENU_POSITION_RELATIVE
            ) {
                //console.log("ok relative position");
                menuInner.classList.remove(CURRENT_MENU_POSITION_FIXED);
                // page.classList.add('scroll');
                // menu.classList.add('scroll');
                // content.classList.add('scroll');
            } else {
                // console.log("ok fixed position");
                menuInner.classList.add(CURRENT_MENU_POSITION_FIXED);
                // page.classList.remove('scroll');
                // menu.classList.remove('scroll');
                // content.classList.remove('scroll');
            }
        }

        return () => {
            //  menu.removeEventListener('mousewheel', menuAreaHandler);
            // content.removeEventListener('mousewheel', contentHandler);
        };
    }, [currentMenuPosition]);

    useEffect(() => {
        defineCurrentMenuPosition();
    }, [productData]);

    useEffect(() => {
        if (productData) {
            //miniCart
            setMiniCartProduct(productData.product);

            //GTM DataLayer 생성
            let categories = [];

            if (
                productData.product.categories &&
                productData.product.categories.length > 0
            ) {
                productData.product.categories.map((item) =>
                    categories.push(item.name)
                );
            }

            const dlProduct = {
                name: productData.product.name,
                id: productData.product.id,
                // price: productData.product.regularPrice.replace(/,/g, ''),
                price: productData.product.price.replace(/,/g, ''),
                salePrice: productData.product.price.replace(/,/g, ''),
                thumbnail:
                    'https://fly.gitt.co/baconbox' +
                    productData.product.images[0].src,
                category: categories,
                quantity: miniCartProductQty,
                imgUrl:
                    'https://fly.gitt.co/baconbox' +
                    productData.product.images[0].src,
                desc: '',
                link: `https://www.baconbox.co/store/${productData.product.slug}`,
            };

            //console.log('dlProduct : ', dlProduct);

            const actionField = {
                revenue: productData.product.price.replace(/,/g, ''),
            };

            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
                product: dlProduct,
                currencyCode: 'KRW',
                ecommerce: {
                    detail: {
                        products: [dlProduct],
                        actionField: actionField,
                    },
                },
            });

            window.dataLayer.push({
                event: 'dataLayer.append.product',
            });
        }
    }, [productData]);

    let productDescriptionStyle = {};

    // useEffect(() => {
    //     // console.log('productDescriptionStyle', productDescription);

    //     if (productDescription) {
    //         productDescriptionStyle = {
    //             backgroundColor: productDescription?.bgColor,
    //             color: productDescription?.fontColor,
    //         };

    //         // console.log(productDescriptionStyle);
    //     }
    // }, [productDescription]);

    const menuAreaHandler = (e) => {
        if (currentMenuPositionRef.current === CURRENT_MENU_POSITION_FIXED) {
            return;
        }
        //console.log('e.deltaY : ' + e.deltaY);

        //const menuBottom = menuArea.getBoundingClientRect().bottom;
        const menu: HTMLElement = document.querySelector('.menu-area');
        const content: HTMLElement = document.querySelector('.content-area');

        const deltaY = e.deltaY;

        if (
            menu.scrollTop === 0 &&
            menu.scrollHeight <= menu.offsetHeight + menu.scrollTop
        ) {
            content.scrollTop += deltaY;
            e.preventDefault();
        } else if (menu.scrollTop === 0) {
            if (deltaY < 0) {
                content.scrollTop += deltaY;

                if (content.scrollTop > 0) {
                    e.preventDefault();
                }
            }
        } else if (menu.scrollHeight <= menu.offsetHeight + menu.scrollTop) {
            if (deltaY > 0) {
                content.scrollTop += deltaY;

                if (
                    content.scrollHeight <=
                    content.offsetHeight + content.scrollTop !==
                    true
                ) {
                    e.preventDefault();
                }
            }
        }
    };

    const contentHandler = (e) => {
        if (currentMenuPositionRef.current === CURRENT_MENU_POSITION_FIXED) {
            return;
        }
        //const menuBottom = menuArea.getBoundingClientRect().bottom;
        const menu: HTMLElement = document.querySelector('.menu-area');
        const content: HTMLElement = document.querySelector('.content-area');

        if (window.innerWidth <= BREAKPOINT_DT) {
            return;
        }
        const deltaY = e.deltaY;

        if (
            content.scrollTop === 0 &&
            content.scrollHeight <= content.offsetHeight + content.scrollTop
        ) {
            menu.scrollTop += deltaY;
            e.preventDefault();
        } else if (content.scrollTop === 0) {
            if (deltaY < 0) {
                menu.scrollTop += deltaY;

                if (menu.scrollTop > 0) {
                    e.preventDefault();
                }
            }
        } else if (
            content.scrollHeight <=
            content.offsetHeight + content.scrollTop
        ) {
            if (deltaY > 0) {
                menu.scrollTop += deltaY;

                if (
                    menu.scrollHeight <= menu.offsetHeight + menu.scrollTop !==
                    true
                ) {
                    e.preventDefault();
                }
            }
        }
    };
    const loadReviewScript = () => {
        const wiremoScript = document.getElementById('wiremoScript');

        if (wiremoScript) {
            document.body.removeChild(wiremoScript);
        }

        const script = document.createElement('script');
        script.src = `/js/wiremo.js?${Math.random().toString(36).substring(7)}`;
        script.id = 'wiremoScript';
        document.body.appendChild(script);
    };

    useEffect(() => {
        const callInfo = async () => {
            const data = await fetchAndReceiveJSON({
                url: `${GET_PRODUCT_INFO_URL}${slug}`,
                method: 'GET',
            });

            if (!data) {
                routerHistory.push('/404');
                return;
            }

            setProductData(data);

            // const isShowEventPriceConst =
            //     !initData.isWelcomeSpecialPriceCouponUsed &&
            //     isEventPriceProduct(data.product);

            let productDescription = null;
            //productDescription
            // if (initData.isLogin) {
            //     PRODUCT_EVENT_DESC.text = '[100원의 행복] 이벤트 상품입니다.';
            // }

            // if (isDogFood) {
            //     // productDescription = defaultDogFoodDescription;
            //     // productDescription = DOGFOOD_PRODUCT_EVENT_DESC;
            //     // eventBannerSrc = eventBannerData?.img;
            // } else if (isShowEventPriceConst) {
            //     productDescription = PRODUCT_EVENT_DESC;
            // }

            setProductDescription(productDescription);

            //set tabs
            let tabs: any = [...tabInfo];

            //relatedProduct tab add
            if (
                data.relatedProducts &&
                data.relatedProducts.length > 0 &&
                !tabInfo.some((tab) => tab.targetDomId === 'related')
            ) {
                const relatedProductsTab = {
                    tabName: '연관상품',
                    targetDomId: 'related',
                    moveWhenClicked: true,
                };

                tabs = [...tabs, relatedProductsTab];
            }

            //static productEvent 입력
            //로그인 여부 / 100원 쿠폰 사용 여부에 따른 배너 추가 ( 우선순위는 wp설정 보다 높다 )
            // if (isShowEventPriceConst) {
            //     const staticProductEvent = STATIC_EVENT_BANNER.find(
            //         (obj) => obj.slug === data.product.slug
            //     );
            //     if (staticProductEvent) {
            //         data.productEvent = staticProductEvent;
            //     }
            // }

            // console.log(data.productEvent);

            //event tab add
            if (
                data.productEvent &&
                !tabInfo.some((tab) => tab.targetDomId === 'event')
            ) {
                const benefitTab = {
                    tabName: '구매 혜택',
                    targetDomId: 'event',
                    moveWhenClicked: true,
                };

                tabs = [benefitTab, ...tabs];
                // tabs = new Set([benefitTab, ...tabs]);
                // tabs = [...tabs];
            }
            setTabInfo(tabs);

            // const isDogFoodProd = isDogFoodProduct(data.product);
            // setIsDogFood(isDogFoodProd);

            // let eventBanner = {};
            // if (isDogFoodProd) {
            //     eventBanner = dogFoodDefaultEvent;
            // } else if (isShowEventPriceConst) {
            //     eventBanner = priceEventCollectionEvent;
            // }
            // else if (data.collectionEvent.eventTitle !== "100원의 행복") {
            //     eventBanner = data.collectionEvent;
            // }
            // setEventBannerData(eventBanner);
            // loadReviewScript();
        };

        callInfo();

        const throttleInner = throttle(() => {
            defineCurrentMenuPosition();
        }, 500);

        window.addEventListener('resize', throttleInner);

        return () => {
            window.removeEventListener('resize', throttleInner);
        };
        // smooth 스크롤 폴리필
    }, [slug]);

    useEffect(() => {
        if (isDogFood) {
            setProductDescription(DOGFOOD_PRODUCT_EVENT_DESC);
        }
    }, [isDogFood]);

    // 스크롤에 따른 헤더 스타일 변화
    useEffect(() => {
        const handleNavigationPositionForScroll = () => {
            const navigation: HTMLElement =
                document.getElementById('NavigationTab');

            const topNav: HTMLElement = document.getElementById('navContainer');

            const header: HTMLDivElement =
                document.querySelector('.headerArea');
            const appTopArea: HTMLDivElement =
                document.querySelector('.appTopArea');
            const content: HTMLDivElement =
                document.querySelector('.content-area');
            const headerWrapper: HTMLDivElement =
                document.querySelector('.headerWrapper');

            // const curScroll = pageInfoRef.current.scrollPosition();
            const curScroll = window.scrollY;
            const adjustPoint = 2;

            if (lastScrollPosition < curScroll) {
                if (topNav.clientHeight > 0) {
                    if (navigation) {
                        navigation.style.top = `${
                            appTopArea.clientHeight -
                            header.clientHeight -
                            adjustPoint
                        }px`;
                    }
                } else {
                    if (navigation) {
                        navigation.style.top = `${
                            appTopArea.clientHeight - adjustPoint
                        }px`;
                    }
                }
            } else {
                if (navigation) {
                    navigation.style.top = `${
                        appTopArea.clientHeight - adjustPoint
                    }px`;
                }
            }

            lastScrollPosition = curScroll;
        };

        const throttleInner = throttle(() => {
            handleNavigationPositionForScroll();
        }, 500);

        //window.addEventListener('resize', handleNavigationPositionForResize);
        window.addEventListener('scroll', throttleInner);

        return () => {
            window.removeEventListener('scroll', throttleInner);
            //window.removeEventListener('resize', handleNavigationPositionForResize);
        };
    }, []);

    // const infoRef = useRef(optionInfo);
    // useEffect(() => {
    //     infoRef.current = optionInfo;

    //     setTimeout(() => {
    //         definePageInfo();
    //     }, 500);

    //     // console.log(optionInfo);
    // }, [optionInfo]);

    useEffect(() => {
        window.onload = () => {
            // 네이버 페이 버튼 만들기
            // console.log('--- onload  makeNpayButton~~ -- ');
            // console.log({ productData : productData,
            // optionInfo : optionInfo} );
            // makeNpayButton({
            //     elemIds: [
            //         // 'iamport-naverpay-product-button',
            //         // 'iamport-naverpay-product-button2',
            //         // 'iamport-naverpay-product-button3',
            //     ],
            //     buttonKey: NPAY_BUTTON_KEY,
            //     buyAction: () => {
            //         const currentTotalPrice = totalPriceRef.current;
            //         if (C_PARTNER_INFO) {
            //             if (
            //                 currentTotalPrice < C_PARTNER_INFO.minimumOrderPrice
            //             ) {
            //                 setIsMinAmountNotMet(true);
            //                 return;
            //             }
            //         }

            //         buyNowNpayAction({
            //             dataToSend: makeDataForCart(infoRef.current),
            //         });
            //     },
            //     getProductsForNpayZzim: () => {
            //         if (productData) {
            //             //IMP.init(C_IAMPORT_CODE);
            //             return getProductsForNpayZzim({
            //                 id: productData.product.id,
            //                 name: productData.product.name,
            //                 uprice: productData.product.price,
            //                 slug: productData.product.slug,
            //                 image: productData.product.thumbnail,
            //             });
            //         }
            //     },
            //     withZzim: true,
            // });

            // 히든네비게이션에 카트 넣기
            // 히든네비게이션에 카트 넣기
            const menuCartIconWidthNumberDt: HTMLElement =
                document.querySelector('.menuCartIconWidthNumberDt');
            const menuCartIconWidthNumberMo: HTMLElement =
                document.querySelector('.menuCartIconWidthNumberMo');
            const menuMoHeaderCartArea: HTMLElement = document.querySelector(
                '#header_menu_mo .menu-popup-control-mo'
            );
            const menuDtHeaderCartArea: HTMLElement = document.querySelector(
                '#header_menu_dt .header-acc'
            );

            if (menuCartIconWidthNumberDt)
                menuCartIconWidthNumberDt.classList.remove('hidden');
            if (menuCartIconWidthNumberMo)
                menuCartIconWidthNumberMo.classList.remove('hidden');
            if (menuMoHeaderCartArea)
                menuMoHeaderCartArea.insertBefore(
                    menuCartIconWidthNumberMo,
                    menuMoHeaderCartArea.firstChild
                );
            if (menuDtHeaderCartArea)
                menuDtHeaderCartArea.appendChild(menuCartIconWidthNumberDt);
        };

        //console.log('--- ok makeNpayButton -- ')
    }, [productData]);

    const [width, setWidth] = useState(window.innerWidth);
    const handleResize = () => {
        // width = ${window.innerWidth};
        setWidth(window.innerWidth);
        // console.log(
        //     `브라우저 화면 사이즈 x: ${window.innerWidth}, y: ${window.innerHeight}`
        // );
    };

    useEffect(() => {
        // console.log(tapStatus);
        const throttleInner = throttle(() => {
            handleResize();
        }, 500);

        window.addEventListener('resize', throttleInner);
        return () => {
            // /console.log('remove event');
            window.removeEventListener('resize', throttleInner);
        };
    }, []);

    useEffect(() => {
        if (width > BREAKPOINT_DT) {
            setIsDimBgOn(false);
            setIsExpand(false);
        }
    }, [width]);


    return (
        <>
            <Helmet>
                <title>
                    {productData &&
                    productData.product &&
                    productData.product.name
                        ? `${productData.product.name} | 베이컨박스`
                        : '페이지를 찾을 수 없습니다 | 베이컨박스'}
                </title>
            </Helmet>
            {/*<Suspense fallback={<div></div>}>*/}
            {/*    <HolidayDeliveryModal isVisible={isVisibleHolidayModal}*/}
            {/*                          setIsVisible={(visible) => setIsVisibleHolidayModal(visible)}*/}
            {/*                          sessionStorageId={'2023-9-28-holiday-delivery-product'}/>*/}
            {/*</Suspense>*/}
            <div className="product-page-wrapper">
                <div className="content-area">
                    {productData && (
                        <DetailImages
                            imageData={productData?.product?.images}
                        />
                    )}
                    {/* {collectionEvent && collectionEvent.img && (
                        <div className="evnetBannerUnerSlider dtBanner"> */}
                    {/* <img
              // style = {{
              //   cursor: 'pointer',

              // }}
              // onClick = {()=>{
              //   moveToLink(`${eventBannerData.link}`);
              // }}
              src = {}
            /> */}

                    {/* <PreloadImage
                  className="eventImgContent"
                  style={{
                    backgroundColor: '#f7f7ed',
                    // paddingTop: '75%',
                    // height: sliderHeight
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '1200px',
                  }}
                  src={getSrcUrl(eventBannerData.img)}

                /> */}
                    {/* </div> */}
                    {/* )} */}

                    <ProductEventBanner
                        className="product-page-banner-mo"
                        productDescription={productDescription}
                    />
                    <div className="info-wrapper">
                        <div className="mobile-menu">
                            {productData && (
                                <PurchaseMenu
                                    productData={productData}
                                    isDesktop={false}
                                    isInProductpage={true}
                                    productDescription={productDescription}
                                    eventBannerData={eventBannerData}
                                />
                            )}

                            {eventBannerData && eventBannerData.img && (
                                <div
                                    className="evnetBanner"
                                    onClick={() => {
                                        routerHistory.push(
                                            eventBannerData.link
                                        );
                                    }}
                                >
                                    <img
                                        src={
                                            isDogFood
                                                ? eventBannerData?.img
                                                : getSrcUrl(
                                                    eventBannerData?.img
                                                )
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <div className="navigationLine"/>
                        <div id="NavigationTab">
                            <NavigationTab
                                tabInfo={tabInfo}
                                isVisibleInDt={true}
                                pageInfo={pageInfo}
                            />
                        </div>
                        <div>
                            <Suspense fallback={<div></div>}>
                                <DetailInfo productData={productData}/>
                            </Suspense>
                        </div>

                        <div id="review" className="infoSection">
                            {productData && (
                                <>
                                    <div
                                        id="wiremo-widget"
                                        data-identifier={
                                            productData.product?.id
                                        }
                                        data-review-source={
                                            productData.product?.id
                                        }
                                        data-review-type="product | company"
                                        data-product-url={`https://www.baconbox.co/store/${productData.product?.slug}/`}
                                        data-product-title={
                                            productData.product?.name
                                        }
                                        data-product-image=""
                                        data-product-sku={`${productData.product?.slug}`}
                                    ></div>
                                    <div id="wiremo-script"></div>
                                </>
                            )}
                        </div>

                        <div id="related" className="infoSection">
                            <Suspense fallback={<div></div>}>
                                {reviewSectionFlag && <RelatedProducts
                                    relatedProducts={
                                        productData?.relatedProducts
                                    }
                                />}
                            </Suspense>
                        </div>
                    </div>
                </div>

                <div className="menu-area">
                    <div className="menu-area-inner fixed">
                        {productData && (
                            <PurchaseMenu
                                productData={productData}
                                isDesktop={true}
                                isInProductpage={true}
                                productDescription={productDescription}
                                eventBannerData={eventBannerData}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
});

export default ProductPage;
