import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './AccountPage.scss';
import { fetchAndReceiveJSON, logout } from '../../utils/ajaxUtils';
import { AppContext } from '../../contexts/AppContext/AppContext';
import MyOrders from '../../components/Orders/MyOrders';
import OrderDetail from '../../components/OrderDetail/OrderDetail';
import MySubscriptions from '../../components/Subscriptions/MySubscriptions';
import SubscriptionDetail from '../../components/SubscriptionDetail/SubscriptionDetail';
import PersonalInfo from '../../components/PersonalInfo/PersonalInfo';
import AccountNav from '../../components/AccountNav/AccountNav';
import {
    BREAKPOINT_TBL,
    GET_SUBSCRIPTIONS_URL,
    MY_ORDERS_URL,
} from '../../constants/constants';
import { throttle } from 'lodash';

const AccountPage = observer(({}) => {
    //rootStore.. user data
    const { rootStore } = React.useContext(AppContext);
    const { initData } = rootStore;
    const { setIsSpinnerOn } = rootStore;

    const history = useHistory();
    const location = useLocation();
    
    const isOrdersPage =
        location.pathname === '/my-account/orders' ||
        location.pathname === '/my-account/orders/';

    const isOrderDetailPage =
        !isOrdersPage && location.pathname.includes('/orders/');
    const isDetailPage = isOrderDetailPage

    const [width, setWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(false);

    const defaultSubscription = 'active';

    //비로그인 시, 로그인 페이지로 이동
    if (!initData.isLogin) {
        history.push('/login');
    }

    useEffect(() => {

        if(location.pathname + location.search === '/my-account/subscriptions?error=change') {
            alert("결제 수단 변경에 실패하였습니다. 잠시후 다시 시도 부탁드립니다. ");
        }

        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        const throttleInner = throttle(() => {
            handleResize();
        }, 500);
        window.addEventListener('resize', throttleInner);
        return () => {
            window.removeEventListener('resize', throttleInner);
        };
    }, []);

    useEffect(() => {
        if (!isDetailPage && isOrdersPage && !orderData) {
            fetchOrderData();
        } else if (
            !isDetailPage &&
            !activeSubscriptionData
        ) {
            fetchSubscriptionData(defaultSubscription);
        }
    }, [location.pathname]);

    useEffect(() => {

        setIsMobile(width <= BREAKPOINT_TBL);
        
    }, [width]);

    const [orderData, setOrderData] = useState<any>(undefined);
    const [activeSubscriptionData, setActiveSubscriptionData] =
        useState<any>(undefined);
    const [nonActiveSubscriptionData, setNonActiveSubscriptionData] =
        useState<any>(undefined);

    const fetchOrderData = async () => {
        // console.log("call api");

        setIsSpinnerOn(true);

        const resData = await fetchAndReceiveJSON({
            url: MY_ORDERS_URL,
        });

        setOrderData(resData);

        setIsSpinnerOn(false);
    };

    const fetchSubscriptionData = async (status) => {
        setIsSpinnerOn(true);

        const resData = await fetchAndReceiveJSON({
            url: GET_SUBSCRIPTIONS_URL + '?page=1&status=' + status,
        });

        setActiveSubscriptionData(resData);

        setIsSpinnerOn(false);
    };

    const fetchAllSubscription = () => {
        fetchSubscriptionData(defaultSubscription);
        fetchSubscriptionData('nonactive');
    };

    return (
        <>
            <Helmet>
                <title>내 계정 | 베이컨박스</title>
            </Helmet>
            <div className="accountPageDtPositioning" id="accountPageDtPositioning">
                <div className="accountPageWrapper">
                    {
                        <>
                            {isMobile && isDetailPage == false && (
                                <div
                                    className={`paddingWrapper ${
                                        location.pathname ==
                                        '/my-account/profile'
                                            ? 'personalInfoPage'
                                            : ''
                                    }`}
                                >
                                    <div className="userInfoArea">
                                        <div className="userName">
                                            {initData?.userFirstName +
                                                initData?.userLastName}
                                            님
                                        </div>
                                        <div className="userBtns">
                                            <div
                                                onClick={() => {
                                                    history.push(
                                                        '/my-account/profile'
                                                    );
                                                }}
                                                className="userDataUpdateBtn"
                                            >
                                                <img
                                                    className="updateIcon"
                                                    src="/img/icon/update.png"
                                                />
                                                기본정보 수정
                                            </div>
                                            <div
                                                className="logoutBtn"
                                                onClick={() => {

                                                    setIsSpinnerOn(true);
                                                    logout();
                                                }}
                                            >
                                                로그아웃
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mobileTaps">
                                        <div
                                            className={`tap ${
                                                location.pathname ==
                                                '/my-account/orders'
                                                    ? 'on'
                                                    : ''
                                            }`}
                                            onClick={() => {
                                                // console.log("order..")
                                                history.push(
                                                    '/my-account/orders'
                                                );
                                            }}
                                        >
                                            주문배송 조회
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!isMobile && <AccountNav />}

                            <div
                                className={`contentArea ${
                                    location.pathname == '/my-account/profile'
                                        ? 'personalInfoPage'
                                        : ''
                                }`}
                            >
                                {!isDetailPage && isOrdersPage && orderData && (
                                    <MyOrders
                                        orderData={orderData}
                                        setOrderData={setOrderData}
                                    />
                                )}
                                {location.pathname == '/my-account/profile' && (
                                    <PersonalInfo />
                                )}
                                {isOrderDetailPage && (
                                    <OrderDetail fetchData={fetchOrderData} />
                                )}
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    );
});
export default AccountPage;
