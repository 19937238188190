
import './BBChildItemBox.scss';
import { useHistory } from 'react-router-dom';
import Moment from 'moment';

const BBChildItemBox = (
    {
        type,
        saleDate
    }
) => {

    let history = useHistory();

    const targetDate = Moment(saleDate);
    const saleDateStr = targetDate.format('MM월 DD일');
    const saleBeforeDateStr = targetDate.subtract(1, 'days').format('MM월 DD일');
    const prevMonth = targetDate.subtract(1, 'months');
    const prevMonthStr = prevMonth.format('MM');
    // const prevMonthLastDayStr = prevMonth.endOf('month').format('DD');


    return (
        <div className="bBChildItemBox">
            <div className="boxChildItemTitle">
                {saleDateStr}부터 개별 판매 예정
            </div>

            {type === 'baconbox' &&
                <>
                    <div className="boxChildItemDesc">
                        {prevMonthStr}월 26일~{saleBeforeDateStr}은 <br />
                        베이컨박스로만 주문 가능해요!
                    </div>
                    <div className="bbChildItemBtn"
                        onClick={() => {
                            history.push(
                                '/box#start'
                            );
                        }}
                    >
                        <img className="bbIcon" src="/img/bitmap-group-mask.png" />
                        <div className="btnText">
                            베이컨박스 시작하기
                        </div>
                        <img className="pathArrow" src="/img/m-icon-link-arrow-16-white.svg" />
                    </div>
                </>}



        </div>
    );
}

export default BBChildItemBox;