import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { defaultElementProps } from '../../types/types';
import './TopBanner.scss';
import { CloseIcon } from '../SVGs/Svgs';
import useSessionstorage from '@rooks/use-sessionstorage';
import { AppContext } from '../../contexts/AppContext/AppContext';

const TopBanner: React.FunctionComponent<defaultElementProps> = observer(() => {
    const [sessionValue, setSession, remove] = useSessionstorage(
        'baconTopBanner',
        true
    );

    const { rootStore } = React.useContext(AppContext);
    const { initData, setEntireAppTopPadding } = rootStore;

    const topBannerRef = useRef<HTMLDivElement>(null);

    //변수에 탑배너 값 할당
    let topBannerContent;
    if (initData && initData.topLineBanner) {
        topBannerContent = initData.topLineBanner;
    }

    let history = useHistory();

    useEffect(() => {
        const topBanner = topBannerRef.current;

        //세션스토리지의 값이 트루인 경우(최상단 띠가 보여지는 경우) 해당 영역만큼 전체영역에 패딩값주기
        if (sessionValue) {
            setEntireAppTopPadding(topBanner?.scrollHeight);
        } else {
            setEntireAppTopPadding(0);
        }
    }, [sessionValue]);

    //1. x 누름
    //2. topLineBanner data x && 파트너 계정 x
    // if (
    //     sessionValue ||
    //     (!data &&
    //         initData &&
    //         initData.partnerStatus !== 'registered' &&
    //         initData.partnerStatus !== 'pending')
    // ) {
    //     return <></>;
    // }

    // const divStyle = {
    //     color: data?.textColor,
    //     backgroundColor: data?.bgColor,
    // };

    // let topBannerText = '';

    // if (initData && initData.partnerStatus === 'registered') {
    //     topBannerText =
    //         '파트너 계정으로 로그인했습니다. 파트너 특가 구매가 가능합니다.';
    // } else if (initData && initData.partnerStatus === 'pending') {
    //     topBannerText =
    //         '파트너 계정 승인 대기중입니다. (승인 완료 이후 파트너 조건 구매 가능)';
    // } else if (data) {
    //     topBannerText = data.text;
    // }

    let topBannerText = '';

    if (initData && initData.partnerStatus === 'registered') {
        topBannerText =
            '파트너 계정으로 로그인했습니다. 파트너 특가 구매가 가능합니다.';
    } else if (initData && initData.partnerStatus === 'pending') {
        topBannerText =
            '파트너 계정 승인 대기중입니다. 승인 완료 이후 파트너 조건 구매 가능합니다.';
    }

    const isShowBanner =
        (sessionValue && topBannerContent && topBannerContent.enabled) ||
        (sessionValue && initData && initData.isPartner);

    return (
        <>
            {isShowBanner && (
                <div
                    className={`topBannerWrapper ${
                        topBannerContent && !initData.isPartner ? 'banner' : ''
                    }`}
                    ref={topBannerRef}
                    style={{
                        backgroundColor:
                            topBannerContent && initData && !initData.isPartner
                                ? topBannerContent.background_color
                                : '',
                        color:
                            topBannerContent && initData && !initData.isPartner
                                ? topBannerContent &&
                                  topBannerContent.text_color
                                : '',
                    }}
                >
                    <div
                        onClick={
                            topBannerContent && initData && !initData.isPartner
                                ? () => {
                                      history.push(topBannerContent.url);
                                  }
                                : undefined
                        }
                    >
                        {topBannerContent && initData && !initData.isPartner
                            ? topBannerContent.title
                            : topBannerText}
                    </div>
                    <CloseIcon
                        className="topBannerCloseIcon"
                        onClick={() => {
                            setSession(false);
                        }}
                    />
                </div>
            )}
        </>
    );
});

export default TopBanner;
