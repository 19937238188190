export const MIconLinkArrow10 = ({ color = 'black', ...otherProps }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            {...otherProps}
        >
            <g fill="none" fill-rule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <path
                                            d="M0 0H10V10H0z"
                                            transform="translate(-151 -4922) translate(32 4423) translate(0 166) translate(0 222) translate(20 108) translate(99 3)"
                                        />
                                        <path
                                            stroke={color}
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M1 7.5L4.75 3 8.5 7.5"
                                            transform="translate(-151 -4922) translate(32 4423) translate(0 166) translate(0 222) translate(20 108) translate(99 3) rotate(90 4.75 5.25)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export const MIconLinkArrow16 = ({ color = 'black', ...otherProps }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            {...otherProps}
        >
            <g fill="none" fill-rule="evenodd">
                <g>
                    <g>
                        <g>
                            <path
                                d="M0 0H16V16H0z"
                                transform="translate(-302 -571) translate(32 547) translate(270 24)"
                            />
                            <path
                                stroke={color}
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                d="M3 11L8 5 13 11"
                                transform="translate(-302 -571) translate(32 547) translate(270 24) rotate(90 8 8)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export const Star = ({ color = '#DDDf', size = 12 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 12 11"
        >
            <g fill="none" fill-rule="evenodd">
                <g fill={color}>
                    <g>
                        <g>
                            <g>
                                <path
                                    d="M48 9L44.473 10.854 45.147 6.927 42.294 4.146 46.237 3.573 48 0 49.763 3.573 53.706 4.146 50.853 6.927 51.527 10.854z"
                                    transform="translate(-282 -4140) translate(32 3988) translate(0 57) translate(208 95)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export const CloseIcon = ({ color = '#FFF', size = 12, ...otherProps }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            style={{ cursor: 'pointer' }}
            viewBox="0 0 12 12"
            {...otherProps}
        >
            <g fill="none" fill-rule="evenodd" stroke-linecap="round">
                <g stroke={color} stroke-width="1.322">
                    <g>
                        <g>
                            <path
                                d="M0.292 0.292L9.042 9.042"
                                transform="translate(-334 -14) translate(332 12) translate(3.333 3.333)"
                            />
                            <path
                                d="M0.292 0.292L9.042 9.042"
                                transform="translate(-334 -14) translate(332 12) translate(3.333 3.333) matrix(-1 0 0 1 9.333 0)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export const CircleSelection = ({ color = '#DDD' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
        >
            <g fill="none" fill-rule="evenodd">
                <g stroke={color} stroke-width="4">
                    <g>
                        <g transform="translate(-296 -244) translate(32 224) translate(264 20)">
                            <circle cx="8" cy="8" r="6" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
