import React, { useEffect } from 'react';
import { defaultElementProps } from '../../types/types';
import TopBanner from '../TopBanner/TopBanner';
import './Header.scss';

interface HeaderProps extends defaultElementProps {
    leftEl?: any;
    centerEl?: any;
    rightEl?: any;
}

const Header: React.FunctionComponent<HeaderProps> = ({
    leftEl,
    centerEl,
    rightEl,
    ...otherProps
}) => {

    
    return (
        <>
            <TopBanner />
            <div className="headerWrapper" {...otherProps}>
                <div className="leftEl">{leftEl}</div>
                <div className="centerEl">{centerEl}</div>
                <div className="rightEl">{rightEl}</div>
            </div>
        </>
    );
};

export default Header;
