import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import './MyOrders.scss';
import { fetchAndReceiveJSON } from '../../utils/ajaxUtils';
import { MY_ORDERS_URL } from '../../constants/constants';
import OrderCard from '../OrderCard/OrderCard';
import EmptyOrderNotice from '../EmptyOrderNotice/EmptyOrderNotice';

const MyOrders = ({ orderData, setOrderData }) => {
    const { rootStore } = useContext(AppContext);
    const { setIsSpinnerOn } = rootStore;

    // console.log("render myOrders" , orderData);

    let hasNextPage = orderData.pageinfo.page < orderData.pageinfo.totalPageCnt;
    const fetchOrderData = async () => {
        // console.log("call api");

        const nextpage = orderData.pageinfo.page + 1;

        setIsSpinnerOn(true);

        const resData = await fetchAndReceiveJSON({
            url: MY_ORDERS_URL + '?page=' + nextpage,
        });

        if (resData?.data?.length > 0) {
            resData.data = [...orderData.data , ...resData.data ];
            setOrderData(resData);
        } 

        setIsSpinnerOn(false);
    };

    const getNextPage = async () => {
        fetchOrderData();
    };

    useEffect(() => {
        // console.log("init");
        // fetchOrderData();

        return () => {};
    }, []);

    if (!orderData) {
        return <div style={{ height: '100vh' }}></div>;
    }

    return (
        <>
            {orderData && orderData.data?.length > 0 && (
                <>
                    <div className="pc-title">주문배송 조회</div>
                    <div className="cardArea">
                        {orderData.data.map((order) => {
                            // {console.log(order)}
                            return <OrderCard key={order.id} order={order} />;
                        })}

                        {hasNextPage && (
                            <div
                                className="seeMoreBtn"
                                onClick={() => {
                                    getNextPage();
                                }}
                            >
                                더 보기
                            </div>
                        )}
                    </div>
                </>
            )}

            {!orderData ||
                (orderData.data?.length < 1 && (
                    <div
                        className="ordersEmpty"
                        style={{ backgroundColor: '#ffffff' }}
                    >
                        <EmptyOrderNotice />
                    </div>
                ))}
        </>
    );
};

export default MyOrders;
