import { observer } from 'mobx-react';
import React from 'react';
import 'swiper/swiper.scss';
import { SwiperSlide } from 'swiper/react';
import { multiProductsSliderProps, sliderProps } from '../../../types/types';
import SliderWrapper from '../SliderWrapper';
import ContentBox from '../../ContentBox/ContentBox';
import { AppContext } from '../../../contexts/AppContext/AppContext';
import Swiper from 'swiper';
import './MultiProductsSlider.scss';
import ProductCard from '../../ProductCard/ProductCard';

const MultiProductSlider: React.FunctionComponent<multiProductsSliderProps> = observer(
    ({ slideData, withRank, ...props }) => {
        return (
            <SliderWrapper
                className="multiProductSlider"
                {...props}
                spaceBetween={16}
            >
                {slideData.map((product, i) => {
                    return (
                        <SwiperSlide key={product.name}>
                            <ProductCard
                                rank={withRank ? i + 1 : undefined}
                                // title={data.name}
                                // regularPrice={data.regularPrice}
                                // disCountedPrice={`${data.price}원`}
                                // disCountRate={data.discountRate}
                                // images={data.images}
                                // badgeText={data.promoText && data.promoText}
                                // isOnSale={data.isOnSale}
                                product = { product }
                            />
                        </SwiperSlide>
                    );
                })}
            </SliderWrapper>
        );
    }
);

export default MultiProductSlider;
