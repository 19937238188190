import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {AppContext} from '../../contexts/AppContext/AppContext';
import {fetchAndReceiveJSON} from '../../utils/ajaxUtils';
import {GET_ORDER_URL, NO_DATA} from '../../constants/constants';
import OrderDetails from '../../components/OrderDetails/OrderDetails';
import EnrollDogInfo from '../../components/EnrollDogInfo/EnrollDogInfo';
import AddDogInfo from '../../components/AddDogInfo/AddDogInfo';
import './CompleteOrderContainer.scss';

const CompleteOrderContainer = () => {
    const {rootStore} = useContext(AppContext);
    const {setIsSpinnerOn} = rootStore;

    const [orderInfo, setOrderInfo] = useState<any>();
    const [openAddDogInfoPopup, setOpenAddDogInfoPopup] = useState(false);
    const [dogId, setDogId] = useState(0);

    const history = useHistory();
    const orderId = location.search.split('=')[1];

    useEffect(() => {
        setIsSpinnerOn(true);
        const fetchData = async () => {
            const res = await fetchAndReceiveJSON({
                url: `${GET_ORDER_URL}/${orderId}`,
            });

            setOrderInfo(res);

            return res;
        };

        fetchData().then(() => {
            setIsSpinnerOn(false);
        });
    }, []);

    useEffect(() => {
        if (orderInfo) {
            let orderItems = [];
            let totalQuantity = 0;
            let totalShipping = 0;
            orderInfo.line_items.map((item) => {
                totalQuantity += item.quantity;

                orderItems.push({
                    id: item.product_id,
                    price: Number(item.total) / item.quantity,
                    quantity: item.quantity,
                    category: item.product.categories[0].name,
                    imgUrl: item.product.thumbnail,
                    name: item.display_name,
                    desc: '',
                    link: `https://www.baconbox.co${item.product.url}`,
                });
            });

            orderInfo.shipping_lines.map((item) => {
                totalShipping = item.total;
            });

            const actionField = {
                id: orderInfo.id,
                revenue: orderInfo.total,
                shipping: totalShipping,
                quantity: totalQuantity,
            };

            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
                event: 'ecommerce',
                ecommerce: {
                    purchase: {
                        actionField: actionField,
                        products: orderItems,
                    },
                },
            });

            window.dataLayer.push({
                event: 'dataLayer.append.order',
            });
        }
    }, [orderInfo]);
    let bbProductList;

    if (orderInfo && orderInfo.line_items) {
        bbProductList = orderInfo.line_items.filter(
            (item) =>
                item.sku === 'bb_v2_monthly' ||
                item.sku === 'bb_v2_1month' ||
                item.sku === 'bb_v2_6month' ||
                item.sku === 'bb_v2_12month' ||
                item.sku === 'bb_v2_lite_monthly' ||
                item.sku === 'bb_v2_lite_6month' ||
                item.sku === 'bb_v2_lite_12month'
        );
    }

    // let santaPresentProduct;
    // if (orderInfo && orderInfo.line_items) {
    //     santaPresentProduct =
    //         orderInfo.line_items.filter(
    //             (product) =>
    //                 product.meta_data.filter(
    //                     (meta) => meta.key === 'is_present'
    //                 ).length > 0
    //         ).length > 0
    //             ? orderInfo.line_items.filter(
    //                   (product) =>
    //                       product.meta_data.filter(
    //                           (meta) => meta.key === 'is_present'
    //                       ).length > 0
    //               )[0].display_name
    //             : '';
    // }
    console.log(orderInfo)
    return (
        <>
            {orderInfo && orderInfo.id && (
                <>
                    <section className="CompleteOrderContainer">
                        {orderInfo && orderInfo.id && (
                            <>
                                <OrderDetails
                                    bbProductList={bbProductList}
                                    orderInfo={orderInfo}
                                />
                                {bbProductList && bbProductList.length > 0 && (
                                    <EnrollDogInfo
                                        bbProductList={bbProductList}
                                        setOpenAddDogInfoPopup={
                                            setOpenAddDogInfoPopup
                                        }
                                        setDogId={setDogId}
                                    />
                                )}
                                <div className="buttonContainers">
                                    <div
                                        onClick={() =>
                                            history.push(
                                                `/my-account/orders/${orderId}`
                                            )
                                        }
                                    >
                                        주문 내역 확인
                                    </div>
                                    <div onClick={() => history.push('/')}>
                                        홈으로 가기
                                    </div>
                                </div>
                            </>
                        )}
                    </section>
                    {openAddDogInfoPopup && (
                        <AddDogInfo
                            closeAddDogInfoPopup={setOpenAddDogInfoPopup}
                            targetId={orderInfo.id}
                            dogId={dogId}
                            type={'order'}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default CompleteOrderContainer;
