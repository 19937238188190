import React, { useContext, useEffect, useRef } from 'react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import { observer } from 'mobx-react';
import DropDown from '../DropDown/DropDown';
import {
    addComma,
    displayOptionNameWithSquareBracket,
} from '../../utils/commonUtils';
import './ChangeOptionPopup.scss';
import {
    DOG_FOOD_PRODUCT_CLUSTER,
    DOG_FOOD_PERIOD_INTERVALS,
} from '../../constants/constants';

const ChangeOptionPopup = observer(() => {
    const { rootStore, cartPageStore } = useContext(AppContext);
    const { setIsDimBgOn, initData, commonPopupContetns } = rootStore;
    const {
        isBundleProduct,
        setIsBundleProduct,
        setShowOptionBox,
        updateSelectedOption,
        updateOption,
        selectedProduct,
        currentSelectedOption,
        currentOptionQty,
        controlCurrentOptionQty,
        currentOptionQtyInputHandler,
        currentSelectedBundleProducts,
        deleteBundleOptionItem,
        setSelectedMultiOptions,
        selectedMultiOptions,
        multiOptionsDropdowns,
        changeBundleOption,
        setSelectedDogfoodPeriod,
        selectedDogfoodPeriod,
        selectedDogfoodOption,
    } = cartPageStore;

    useEffect(() => {
        document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;`;

        setIsDimBgOn(true);
        return () => {
            setIsDimBgOn(false);

            const scrollY = document.body.style.top;
            document.body.style.cssText = '';
            window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
        };
    }, []);

    // const closePopupWhenClickDim = (ref) => {
    //     useEffect(() => {
    //         function handleClickOutside(event) {
    //             if (ref.current && !ref.current.contains(event.target)) {
    //                 setIsDimBgOn(false);
    //                 setShowOptionBox(false);
    //             }
    //         }

    //         // Bind the event listener
    //         document.addEventListener('mousedown', handleClickOutside);
    //         return () => {
    //             // Unbind the event listener on clean up
    //             document.removeEventListener('mousedown', handleClickOutside);
    //         };
    //     }, [ref]);
    // };

    // const wrapperRef = useRef(null);
    // closePopupWhenClickDim(wrapperRef);

    const isDogFood =
        selectedProduct.product.productCluster === DOG_FOOD_PRODUCT_CLUSTER;

    return (
        <div
            className={`ChangeOptionPopupContainer ${
                commonPopupContetns !== null ? 'underTheDimmer' : ''
            }`}
            // ref={wrapperRef}
        >
            <div
                onClick={() => {
                    setIsDimBgOn(false);
                    setShowOptionBox(false);
                    setIsBundleProduct(false);
                }}
                className="closeOptionPopupBtn"
            >
                <img
                    src="/img/btn-gnb-popup-close-white.svg"
                    alt="close-popup-button"
                />
            </div>
            <div className={`ChangeOptionPopup ${isBundleProduct && 'bundle'}`}>
                <div className="headArea">
                    <img
                        className="productImg"
                        src={selectedProduct.product.thumbnail}
                        alt={selectedProduct.product_name}
                    />
                    <div className="OptionTitle">
                        {selectedProduct.product.productBrand ? (
                            <>
                                {`[${selectedProduct.product.productBrand}]`}
                                <br />
                                {selectedProduct.product.name}
                            </>
                        ) : (
                            selectedProduct.product.name
                        )}
                    </div>
                </div>
                {selectedProduct.product.attributes.length > 1 ? (
                    selectedProduct.product.attributes.map((attr, idx) => {
                        let dropdownItemList: any = [];
                        // 1. 한번도 선택안된 첫번째 드랍다운일 경우 / 2. 앞의 것이 선택되어 있는 경우
                        if (idx === 0) {
                            for (
                                let i = 0;
                                i < multiOptionsDropdowns.length;
                                i++
                            ) {
                                dropdownItemList.push(
                                    multiOptionsDropdowns[i][0]
                                );
                            }
                            dropdownItemList = new Set(dropdownItemList);

                            dropdownItemList = [...dropdownItemList].map(
                                (item) => {
                                    const itemInfo =
                                        selectedProduct.product.product_variations.filter(
                                            (variation) =>
                                                variation.attributes[0]
                                                    .option === item
                                        );
                                    return {
                                        optionName: item,
                                        price: Math.min(
                                            ...itemInfo.map((item) =>
                                                Number(item.price)
                                            )
                                        ),
                                        regularPrice: Math.min(
                                            ...itemInfo.map((item) =>
                                                Number(item.regular_price)
                                            )
                                        ),
                                    };
                                }
                            );
                        } else {
                            const arr = multiOptionsDropdowns.filter(
                                (item) =>
                                    item[idx - 1] ===
                                    selectedMultiOptions[idx - 1]
                            );
                            for (let i = 0; i < arr.length; i++) {
                                dropdownItemList.push(arr[i][idx]);
                            }

                            dropdownItemList = new Set(dropdownItemList);
                            dropdownItemList = [...dropdownItemList].map(
                                (item) => {
                                    const itemInfo =
                                        selectedMultiOptions.length === idx
                                            ? selectedProduct.product.product_variations.filter(
                                                  (variation) =>
                                                      variation.attributes[idx]
                                                          .option === item &&
                                                      selectedMultiOptions.every(
                                                          (option, i) =>
                                                              option ===
                                                              variation
                                                                  .attributes[i]
                                                                  .option
                                                      )
                                              )
                                            : selectedProduct.product.product_variations.filter(
                                                  (variation) =>
                                                      variation.attributes[idx]
                                                          .option === item &&
                                                      variation.attributes.map(
                                                          (attr, i) => {
                                                              if (
                                                                  i === idx &&
                                                                  attr.option ===
                                                                      selectedMultiOptions[
                                                                          i
                                                                      ]
                                                              ) {
                                                                  return true;
                                                              }
                                                          }
                                                      )
                                              );

                                    return {
                                        optionName: item,
                                        price: Math.min(
                                            ...itemInfo.map((item) =>
                                                Number(item.price)
                                            )
                                        ),
                                        regularPrice: Math.min(
                                            ...itemInfo.map((item) =>
                                                Number(item.regular_price)
                                            )
                                        ),
                                        stockStatus:
                                            selectedProduct.product.attributes
                                                .length -
                                                1 ===
                                            idx
                                                ? itemInfo[0].stock_status
                                                : '',
                                    };
                                }
                            );
                        }

                        return (
                            <DropDown
                                key={idx}
                                dropDownIdx={idx}
                                className="optionDropdown"
                                defaultText={
                                    isBundleProduct
                                        ? '함께 구매하면 더 저렴해요!'
                                        : `${attr.name} 선택해 주세요`
                                }
                                defaultTextIcon={
                                    isBundleProduct && '/img/percentage.svg'
                                }
                                itemClickEvent={
                                    selectedProduct.product.attributes.length >
                                    1
                                        ? setSelectedMultiOptions
                                        : updateSelectedOption
                                }
                                dropDownType="selectOption"
                                dropDownItems={
                                    selectedProduct.product && isBundleProduct
                                        ? selectedProduct.product.children
                                        : dropdownItemList
                                }
                                optionProductId={
                                    selectedProduct &&
                                    selectedProduct.product_id
                                }
                                isBundleProduct={isBundleProduct}
                                isMultiOptions={
                                    selectedProduct.product.attributes.length >
                                    1
                                        ? true
                                        : false
                                }
                                selectedMultiOptions={selectedMultiOptions}
                            />
                        );
                    })
                ) : isDogFood &&
                  selectedProduct.product.attributes.length === 1 ? (
                    [...new Array(2)].map((_, idx) => {
                        let dropdownItemList: any = [];
                        if (idx === 0) {
                            dropdownItemList =
                                selectedProduct.product.product_variations;
                        } else if (idx !== 0 && selectedDogfoodOption) {
                            dropdownItemList = DOG_FOOD_PERIOD_INTERVALS;
                        }
                        return (
                            <DropDown
                                key={idx}
                                dropDownIdx={idx}
                                className="optionDropdown"
                                defaultText={
                                    idx === 0
                                        ? '상품 선택'
                                        : '결제&배송 주기 선택'
                                }
                                itemClickEvent={
                                    idx === 0
                                        ? updateSelectedOption
                                        : setSelectedDogfoodPeriod
                                }
                                dropDownType="selectOption"
                                dropDownItems={dropdownItemList}
                                optionProductId={
                                    selectedProduct &&
                                    selectedProduct.product_id
                                }
                                isMultiOptions={false}
                            />
                        );
                    })
                ) : isDogFood &&
                  selectedProduct.product.attributes.length === 0 ? (
                    <DropDown
                        // key={idx}
                        dropDownIdx={2}
                        className="optionDropdown"
                        defaultText={'결제&배송 주기 선택'}
                        itemClickEvent={setSelectedDogfoodPeriod}
                        dropDownType="selectOption"
                        dropDownItems={DOG_FOOD_PERIOD_INTERVALS}
                        optionProductId={
                            selectedProduct && selectedProduct.product_id
                        }
                        isMultiOptions={false}
                    />
                ) : (
                    <DropDown
                        className="optionDropdown"
                        defaultText={
                            isBundleProduct
                                ? '함께 구매하면 더 저렴해요!'
                                : '옵션을 선택해 주세요'
                        }
                        defaultTextIcon={
                            isBundleProduct && '/img/percentage.svg'
                        }
                        itemClickEvent={updateSelectedOption}
                        dropDownType="selectOption"
                        dropDownItems={
                            selectedProduct.product && isBundleProduct
                                ? selectedProduct.product.children
                                : selectedProduct.product.product_variations
                        }
                        optionProductId={
                            selectedProduct && selectedProduct.product_id
                        }
                        isBundleProduct={isBundleProduct}
                    />
                )}
                {(currentSelectedOption || currentSelectedBundleProducts) && (
                    <div className="optionList">
                        {!isBundleProduct ? (
                            currentSelectedOption.map((option) => {
                                return (
                                    <div key={option.id} className="optionItem">
                                        <div className="optionItemHeadArea">
                                            <div className="imgAndName">
                                                {option.image && (
                                                    <img
                                                        className="optionImg"
                                                        src={option.image}
                                                        alt="option-image"
                                                    />
                                                )}
                                                <div className="optionName">
                                                    {isDogFood ? (
                                                        <>
                                                            <div
                                                                className="dogFoodName"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: displayOptionNameWithSquareBracket(
                                                                        option
                                                                            .attributes[0]
                                                                            ?.option,
                                                                        ''
                                                                    ),
                                                                }}
                                                            ></div>
                                                            <div className="dogFoodPeriod">
                                                                {`${selectedDogfoodPeriod}주 마다 `}
                                                            </div>
                                                        </>
                                                    ) : option.attributes
                                                          .length > 1 ? (
                                                        option.attributes.map(
                                                            (attr, idx) => {
                                                                let optionName =
                                                                    '';
                                                                optionName += `${
                                                                    attr.option
                                                                }${
                                                                    idx + 1 !==
                                                                    option
                                                                        .attributes
                                                                        .length
                                                                        ? `, `
                                                                        : ''
                                                                }`;
                                                                return optionName;
                                                            }
                                                        )
                                                    ) : (
                                                        option.attributes[0]
                                                            .option
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="optionItemBodyArea">
                                            <div className="controlBox">
                                                <div className="changeQty">
                                                    <img
                                                        onClick={
                                                            controlCurrentOptionQty
                                                        }
                                                        className="qtyBtn"
                                                        src="/img/m-btn-count-num-minus.svg"
                                                        alt="minus"
                                                    />
                                                    <input
                                                        className="qtyInput"
                                                        pattern="\d*"
                                                        type="text"
                                                        onChange={
                                                            currentOptionQtyInputHandler
                                                        }
                                                        value={currentOptionQty}
                                                    />
                                                    <img
                                                        onClick={
                                                            controlCurrentOptionQty
                                                        }
                                                        className="qtyBtn"
                                                        src="/img/m-btn-count-num-plus.svg"
                                                        alt="plus"
                                                    />
                                                </div>
                                            </div>
                                            <div className="priceSection">
                                                {option.on_sale && (
                                                    <div className="discountPercentage">
                                                        {initData.isPartner
                                                            ? option.discount_rate.slice(
                                                                  1,
                                                                  option
                                                                      .discount_rate
                                                                      .length -
                                                                      1
                                                              )
                                                            : `${option.originDiscountRate}%`}
                                                    </div>
                                                )}

                                                <div className="showPrice">
                                                    <div className="displayPrice">
                                                        {`${addComma(
                                                            option.price
                                                        )}원`}
                                                    </div>
                                                    {option.on_sale && (
                                                        <div className="regularPrice">
                                                            {`${addComma(
                                                                option.regular_price
                                                            )}원`}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="selectedProductBox">
                                <div className="optionItemHeadArea">
                                    <div className="bundleProductName">
                                        {selectedProduct.product_name}
                                    </div>
                                </div>
                                <div className="optionItemBodyArea">
                                    <div className="controlBox">
                                        <div className="changeQty">
                                            <img
                                                onClick={
                                                    controlCurrentOptionQty
                                                }
                                                className="qtyBtn"
                                                src="/img/m-btn-count-num-minus.svg"
                                                alt="minus"
                                            />
                                            <input
                                                className="qtyInput"
                                                pattern="\d*"
                                                type="text"
                                                onChange={
                                                    currentOptionQtyInputHandler
                                                }
                                                value={currentOptionQty}
                                            />
                                            <img
                                                onClick={
                                                    controlCurrentOptionQty
                                                }
                                                className="qtyBtn"
                                                src="/img/m-btn-count-num-plus.svg"
                                                alt="plus"
                                            />
                                        </div>
                                    </div>
                                    <div className="priceSection">
                                        {selectedProduct.on_sale && (
                                            <div className="discountPercentage">
                                                {initData.isPartner
                                                    ? selectedProduct.discount_rate.slice(
                                                          1,
                                                          selectedProduct
                                                              .discount_rate
                                                              .length - 1
                                                      )
                                                    : selectedProduct.discount_rate}
                                            </div>
                                        )}
                                        <div className="showPrice">
                                            <div className="displayPrice">
                                                {selectedProduct.display_price}
                                            </div>
                                            {selectedProduct.on_sale && (
                                                <div className="regularPrice">
                                                    {
                                                        selectedProduct.display_regular_price
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {currentSelectedBundleProducts.map((option) => {
                                    return (
                                        <div
                                            key={option.id}
                                            className="optionItem bundle"
                                        >
                                            <div className="optionItemHeadArea">
                                                <img
                                                    className="optionImg"
                                                    src={option.thumbnail}
                                                    alt="option-image"
                                                />
                                                <div className="rightSide">
                                                    <div className="upperSide">
                                                        <div className="optionName">
                                                            {option.name}
                                                        </div>
                                                        <img
                                                            onClick={() => {
                                                                deleteBundleOptionItem(
                                                                    option.id
                                                                );
                                                            }}
                                                            className="deleteOptionBtn"
                                                            src="/img/common-btn-list-close-16.svg"
                                                            alt="delete-button"
                                                        />
                                                    </div>
                                                    <div className="bottomSide">
                                                        <div className="qty">
                                                            {`${currentOptionQty}개`}
                                                        </div>
                                                        <div className="priceSection">
                                                            <div className="showPrice">
                                                                <div className="displayPrice">
                                                                    {`${addComma(
                                                                        parseInt(
                                                                            option.price
                                                                        )
                                                                    )}원`}
                                                                </div>
                                                                {option.on_sale && (
                                                                    <div className="regularPrice">
                                                                        {`${addComma(
                                                                            parseInt(
                                                                                option.regular_price
                                                                            )
                                                                        )}원`}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="changeOptionBtnContainer">
                <div className="bundleProductTotals">
                    <div className="priceTitle">총 결제하실 금액</div>
                    <div className="price">
                        {isBundleProduct
                            ? `${addComma(
                                  currentSelectedBundleProducts
                                      .map(
                                          (bundle) =>
                                              bundle.price * currentOptionQty
                                      )
                                      .reduce((a, b) => {
                                          return a + b;
                                      }, selectedProduct.price * currentOptionQty)
                              )}원`
                            : selectedProduct.product.productCluster ===
                                  DOG_FOOD_PRODUCT_CLUSTER &&
                              selectedProduct.product.type === 'subscription'
                            ? selectedProduct.display_price
                            : `${addComma(
                                  parseInt(currentSelectedOption[0].price) *
                                      currentOptionQty
                              )}원`}
                    </div>
                </div>

                <div
                    onClick={
                        isBundleProduct
                            ? () => {
                                  changeBundleOption();
                              }
                            : () => {
                                  updateOption();
                                  setShowOptionBox(false);
                                  setIsDimBgOn(false);
                              }
                    }
                    className="changeOptionBtn"
                >
                    변경하기
                </div>
            </div>
        </div>
    );
});

export default ChangeOptionPopup;
