import { Certificate } from 'crypto';
import React from 'react';
import { contentBoxProps } from '../../types/types';
import './ContentBox.scss';

const ContentBox: React.FunctionComponent<contentBoxProps> = ({
    children,
    className,
    isSharpBorder,
    isBottomSharp,
    isTopSharp,
    style,
    withShadow,
    ...otherProps
}) => {
    return (
        <div
            {...otherProps}
            style={{
                // borderRadius: isSharpBorder ? 0 : 8,
                // borderBottomRightRadius: isBottomSharp ? 0 : 8,
                // borderBottomLeftRadius: isBottomSharp ? 0 : 8,
                // borderTopRightRadius: isTopSharp ? 0 : 8,
                // borderTopLeftRadius: isTopSharp ? 0 : 8,
                // background: 'white',

                // justifyContent: 'center',
                // alignItems: 'center',
                ...style,
            }}
            className={`contentBox ${className ? className : ''}  ${
                withShadow ? 'contentBoxWithShadow' : ''
            }
                ${isSharpBorder ? 'isSharpBorder' : ''}
                ${isBottomSharp ? 'isBottomSharp' : ''}
                ${isTopSharp ? 'isTopSharp' : ''}
                

            
            
            
                `}
        >
            {children instanceof Array
                ? children.map((el, i) => (
                      <React.Fragment key={i}>{el}</React.Fragment>
                  ))
                : children}
        </div>
    );
};

export default ContentBox;
