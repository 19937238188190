import {observer} from 'mobx-react';
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useTranslation} from 'react-i18next';
import {Route, Switch, useHistory, Redirect, useLocation} from 'react-router-dom';
import './App.scss';
import Header from './components/Header/Header';
import NavContainer from './containers/NavContainer/NavContainer';
import {AppContext} from './contexts/AppContext/AppContext';
import {fetchAndReceiveJSON, logout} from './utils/ajaxUtils';
import AccountPage from './pages/AccountPage/AccountPage';
// import OrderPage from './pages/OrderPage/OrderPage';
import HomePage from './pages/HomePage/HomePage';
import ErrorNotice from './components/ErrorNotice/ErrorNotice';
// import NotFound from './pages/NotFoundPage/NotFoundPage';
import CollectionPage from './pages/CollectionPage/CollectionPage';
import CollectionPage2 from './pages/CollectionPage/CollectionPage2';
import ProductPage from './pages/ProductPage/ProductPage';
import homeIcon from './assets/m-icon-gnb-btn-home.svg';
import cartIcon from './assets/m-icon-gnb-btn-cart.svg';
import menuIcon from './assets/m-icon-gnb-btn-menu.svg';
import searchIcon from './assets/m-icon-gnb-btn-search.svg';
import {
    INIT_DATA_URL,
    COOKIE_BUYNOW_CART_NAME,
    COOKIE_LANG_NAME,
    SHOW_BACK_BTN_PATHS,
    GNB_NO_SUB_PATH,
    GNB_MOBILE_NO_SUB_PATH,
} from './constants/constants';
import StaticModal from './components/Modals/StaticModal/StaticModal';
import BBPage from './pages/BBPage/BBPage';
import smoothscroll from 'smoothscroll-polyfill';
// import { throttle } from './utils/commonUtils';
import LoginModal from './components/LoginModal/LoginModal';
import AgreementModal from './components/AgreementModal/AgreementModal';
import LoginPage from './pages/LoginPage/LoginPage';
import CartPage from './pages/CartPage/CartPage';
import BBPortfolioPage from './pages/BBPortfolioPage/BBPortfolioPage';
import CartAddCompletePopup from './components/FollowPopup/CartAddCompletePopup';
import CompleteOrderContainer from './containers/CompleteOrderContainer/CompleteOrderContainer';
import Search from './components/Search/Search';
import {NavNextArrow16P} from './assets/Arrows';
import HamburgerMenu from './components/HamburgerMenu/HamburgerMenu';
import MiniCartPopup from './components/MiniCart/MiniCartPopup';
import MaintenancePage from "./pages/MaintenancePage/MaintenancePage"
import Footer from './components/Footer/Footer';
import PartnerPage from './pages/PartnerPage/PartnerPage';
import WelcomePage from './pages/WelcomePage/WelcomePage';
import BBSHiddenPage from './pages/BBSHiddenPage/BBSHiddenPage';
import ScrollToTop from 'react-router-scroll-top';
import CustomHtmlPage from './pages/CustomHtmlPage/CustomHtmlPage';
import LGPromotionPage from './pages/LGPromotionPage/LGPromotionPage';
import {throttle} from 'lodash';
import {hideChatBtn} from './utils/commonUtils';
import CommonPopup from './components/CommonPopup/CommonPopup';
import {idText} from 'typescript';
import BBSantaPresentPage from './pages/BBSantaPresentPage/BBSantaPresentPage';
import TermsPage from "./pages/TermsPage/TermsPage";
import ModalPage from "./pages/ModalPage/ModalPage";

smoothscroll.polyfill();

const App = observer(() => {
    const {t} = useTranslation();
    let pageY = 0;

    const {rootStore, cartPageStore, miniCartPopupStore, homeStore} =
        React.useContext(AppContext);
    // 첫 화면 노출시 불러올 이미지
    const {
        languageBasedOnLocation,
        setLanguageBasedOnLocation,
        initData,
        setInitData,
        isBodyScrollable,
        setIsBodyScrollable,
        isDimBgOn,
        isSpinnerOn,
        setIsLoginModalOn,
        setSignUpModalOn,
        isAgreementModalOn,
        setIsDimBgOn,
        showSearch,
        setShowSearch,
        setCurrentTarget,
        isGlobalError,
        setIsGlobalError,
        isFixedTopMenu,
        setIsFixedTopMenu,
        commonPopupContetns,
    } = rootStore;
    const {cartListCount, setCartListCount, isCartLoading, setIsCartLoading} =
        cartPageStore;
    const {isMiniCartPopup, miniCartProduct, isFollowPopup} =
        miniCartPopupStore;
    const {
        setIsAgreementModalOn,
        setAgreementType,

    } = rootStore;
    //const { openHamburgerPopup, setOpenHamburgerPopup } = homeStore;

    const {i18n} = useTranslation();
    const [cookies, setCookie, removeCookie] = useCookies([COOKIE_LANG_NAME]);

    //const [pageY, setPageY] = useState(0);
    // const documentRef = useRef(document);
    // const headerRef = useRef<HTMLDivElement>(null);
    // const topAreaRef = useRef<HTMLDivElement>(null);
    let history = useHistory();
    const {pathname} = useLocation();
    const [isScrollDown, setIsScrollDown] = useState(false);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [openMenu, setOpenMenu] = useState(false);
    // const [timer, setTimer] = useState<any>(0);
    const [contentAreaExtraClassType, setContentAreaExtraClassType] = useState('')

    if (location.pathname.includes('/wp-admin')) {
        window.location.href = `https://wp-bacon.gitt.co/${location.pathname}${location.search}`;
        return <></>;
    }
    if (location.pathname.includes('/terms_hdcard')) {
        window.location.href = `https://drive.google.com/file/d/15RwloSkJ3fa9fRp6kYHq4KZ7ZwDgMqah/view?usp=share_link`;
        return <></>;
    }
    // useEffect(() => {
    //     const
    //     window.addEventListener('scroll', throttle());
    // }, []);

    // useEffect(() => {
    //     const handleScroll = throttle(() => {

    //         const { pageYOffset } = window;
    //         const deltaY = pageYOffset - pageY;
    //         const isScrollDown = pageYOffset !== 0 && deltaY >= 0;
    //         pageY = pageYOffset;

    //         const header = headerRef.current;
    //         const headerHeight = header?.scrollHeight;
    //         const topArea = topAreaRef.current;
    //         if (header && headerHeight && topArea) {
    //             if (isScrollDown) {
    //                 topArea.style.transform = `translateY(${-headerHeight}px)`;
    //             } else {
    //                 topArea.style.transform = `unset`;
    //             }
    //         }
    //     }, 0);

    //     if (isFixedTopMenu) {
    //         documentRef.current.removeEventListener('scroll', handleScroll);
    //         topAreaRef.current.style.transform = `unset`;
    //     }else {
    //         documentRef.current.addEventListener('scroll', handleScroll);
    //     }

    //     return () =>
    //         documentRef.current.removeEventListener('scroll', handleScroll);
    // }, [isFixedTopMenu]);

    const checkTermsModalOn = () => {
        const params = new URLSearchParams(location.search);

        const termsStatus = params.get("terms");
        if (termsStatus === "true") {
            setIsAgreementModalOn(true)
            setAgreementType('terms');
        }
    }


    useEffect(() => {
        const $body = document.querySelector('body');
        let scrollPosition = 0;

        if (isBodyScrollable) {
            $body.style.removeProperty('overflow');
            $body.style.removeProperty('position');
            $body.style.removeProperty('top');
            $body.style.removeProperty('width');
            window.scrollTo(0, scrollPosition);
        } else {
            scrollPosition = window.pageYOffset;
            $body.style.overflow = 'hidden';
            $body.style.position = 'fixed';
            $body.style.top = `-${scrollPosition}px`;
            $body.style.width = '100%';
        }
    }, [isBodyScrollable]);

    // useEffect(() => {

    // }, [isScrollDown]);
    useEffect(() => {
        if (!cookies.lng) {
            setLngAsLocationLng();
        }
        if (!window.Kakao.isInitialized()) {
            window.Kakao.init(process.env.REACT_APP_KAKAO_APP_KEY);
        }
        setTimeout(() => checkTermsModalOn(), 1000)
    }, []);



    // 기본적으로 languageBasedOnLocation 은 ko 로 설정되어 있음
    // 추후 다국어 지원시 init api 콜에서 온 데이터를 기반으로 쿠키를 갈아 끼워야 함
    // setLanguageBasedOnLocation 활용 권장
    // 쿠키가 바뀌면 프론트엔드 설정도 바뀌어서 전반적으로 언어가 바뀌게 되어 있다.
    useEffect(() => {
        i18n.changeLanguage(cookies.lng);
        // history.go(0);
    }, [cookies.lng]);

    const setLngAsLocationLng = () => {
        setCookie('lng', languageBasedOnLocation);
    };
    // const changeLanguageToUse = (lng) => {
    //     setCookie('lng', lng);
    // };
    useEffect(() => {
        setLngAsLocationLng();
    }, [languageBasedOnLocation]);

    const callInitData = async () => {
        const initData = await fetchAndReceiveJSON({
            url: INIT_DATA_URL,
            method: 'GET',
        });

        if (!initData) {
            setIsGlobalError(true);
            return;
        }

        // addHappyCollectionMenuIfAvail(initData);

        setInitData(initData);
    };

    const happyCollection = {
        name: '100원의 행복',
        slug: 'happy-collection',
        icon: '/img/100-shop.svg',
        badge: '',
        link: '/store/collection/happy-collection',
        highColor: '#101010',
    };

    const addHappyCollectionMenuIfAvail = (initData) => {
        let avail = true;
        if (initData && initData.topMenu && initData.topMenu.length > 2) {
            if (initData.isLogin && initData.isWelcomeSpecialPriceCouponUsed) {
                avail = false;
            }
        } else {
            avail = false;
        }

        if (avail) {
            initData.topMenu.splice(2, 0, happyCollection);
        }
    };

    useEffect(() => {
        callInitData();

        window.addEventListener('resize', controllHeight);

        return () => {
            window.removeEventListener('resize', controllHeight);
        };
    }, []);

    useEffect(() => {
        //console.log('window layer ', data);
        // const data = {
        //     email: initData && initData.userInfo ? initData.userInfo.email : '',
        //     zipcode:
        //         initData && initData.userInfo && initData.userInfo.billing
        //             ? initData.userInfo.billing.postcode
        //             : '',
        // };

        if (initData) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                email:
                    initData && initData.userInfo
                        ? initData.userInfo.email.toLowerCase()
                        : '',
                zipcode:
                    initData && initData.userInfo && initData.userInfo.billing
                        ? initData.userInfo.billing.postcode
                        : '',
            });
        }

        // TagManager.dataLayer({
        //     dataLayer: data
        // })

        // //content height 조정
        controllHeight();
    }, [initData]);

    const controllHeight = () => {
        const contentArea = document.getElementById('contentArea');

        if (topHeaderRef && contentArea) {
            const topHeaderHeight =
                topHeaderRef?.current?.getBoundingClientRect().height;
            if (topHeaderHeight) {
                const contentAreaHeight = window.innerHeight - topHeaderHeight;
                contentArea.style.minHeight = contentAreaHeight + 'px';
            }
        }
    };

    useEffect(() => {
        setIsBodyScrollable(!showSearch);
    }, [showSearch]);

    // function _ScrollToTop(props) {
    //     const { pathname } = useLocation();
    //     useEffect(() => {
    //         window.scrollTo(0, 0);
    //     }, [pathname]);
    //     return props.children
    // }
    // const ScrollToTop = withRouter(_ScrollToTop)

    const path = SHOW_BACK_BTN_PATHS.find((path) =>
        pathname.includes(path)
    );
    const isHideNavPage =
        history.length > 0 &&
        path &&
        !pathname.includes('/store/collection/');

    //카트는 state가 복잡하여 바로 이동하지 않고 상태변경으로 이동한다.
    useEffect(() => {
        if (isCartLoading) {
            if (location.search === '?error') {
                alert(
                    '결제에 실패하였습니다. 문제가 지속될 시 채팅상담으로 문의 부탁드립니다.'
                );
            } else {
                setShowSearch(false);
                history.push('/checkout');
            }
        }
    }, [isCartLoading]);

    useLayoutEffect(() => {
        setCartListCount();
    }, []);

    //nav 스크롤 이벤트
    let lastScrollY = 0;

    const headerRef = useRef(null);
    const topHeaderRef = useRef(null);

    const checkScrollPosition = () => {
        const minScrollY = 0;
        const maxScrollY = document.body.scrollHeight - window.innerHeight;
        const currentScrollY = window.scrollY;

        if (currentScrollY > lastScrollY) {
            setIsScrollDown(true);
        } else {
            setIsScrollDown(false);
        }

        lastScrollY =
            currentScrollY > maxScrollY || currentScrollY < minScrollY
                ? 0
                : currentScrollY;
    };

    useEffect(() => {
        const throttleInner = throttle(() => {
            checkScrollPosition();
        }, 500);

        window.addEventListener('scroll', throttleInner);

        return () => {
            window.removeEventListener('scroll', throttleInner);
        };
    }, []);

    let retryCnt = 0;
    useEffect(() => {
        const hideChannelIOBtn = () => {
            const chatBtn = document.getElementById('ch-plugin');
            if (!chatBtn) {
                if (retryCnt <= 3) {
                    setTimeout(() => {
                        retryCnt++;
                        hideChannelIOBtn();
                    }, 500);
                }

                return;
            }

            //데스크탑에서만 표시
            if (pathname === '/checkout') {
                chatBtn.classList.add('mobileHide');
            } else {
                chatBtn.classList.remove('mobileHide');
            }

            //상품 상세 - mobile - desktop 분기 기준이 다름 1024
            if (
                pathname.includes('/store') &&
                !pathname.includes('/store/collection')
            ) {
                chatBtn.classList.add('hide');
            } else {
                chatBtn.classList.remove('hide');
            }
        };

        hideChannelIOBtn();
    }, [pathname, isMiniCartPopup]);

    useEffect(() => {
        hideChatBtn(isMiniCartPopup);
    }, [isMiniCartPopup]);

    useEffect(() => {
        hideChatBtn(openMenu);
    }, [openMenu]);

    useEffect(() => {
        const navCon = document.getElementById('navContainer');
        const topHeader = document.getElementById('topHeader');

        if (!navCon) return;

        if (navCon.clientHeight > 0) {
            if (headerRef.current) {
                if (isScrollDown) {
                    const height =
                        headerRef.current.getBoundingClientRect().height;
                    topHeader.style.top = `-${height}px`;
                } else {
                    topHeader.style.top = `0px`;
                }
            }
        } else {
            topHeader.style.top = `0px`;
        }
    }, [isScrollDown]);


    useEffect(() => {
        if (GNB_NO_SUB_PATH.includes(pathname)) {
            setContentAreaExtraClassType('noSub');
        } else if (GNB_MOBILE_NO_SUB_PATH.includes(pathname)) {
            setContentAreaExtraClassType('mobileNoSub');
        } else {
            //my-account
            if (pathname.includes('/my-account')) {
                setContentAreaExtraClassType('mobileNoSub');
            } else if (
                //productDetail
                pathname.includes('/store/') &&
                !pathname.includes('/store/collection/')
            ) {
                setContentAreaExtraClassType('mobileNoSub');
            } else {
                setContentAreaExtraClassType("")
            }
        }
    }, [pathname])

    return (
        <div
            className="projectWrapper"
            style={{
                paddingTop: rootStore.entireAppTopPadding,
            }}
            // onClick={() => {
            //     setIsLoginModalOn(true);
            // }}
        >
            <ModalPage/>
            <Search
                keyword=""
                topSearchedList={initData ? initData.topSearchedList : null}
            />
            {initData && pathname !== '/bbshidden' && (
                <div id="topHeader" className="appTopArea" ref={topHeaderRef}>
                    <div className="headerArea" ref={headerRef}>
                        <Header
                            // id={'header'}
                            leftEl={
                                <>
                                    <img
                                        className={`logoInHeader ${
                                            isHideNavPage ? 'mobileHide' : ''
                                        }`}
                                        src={homeIcon}
                                        // src="../m-icon-gnb-btn-home.svg"
                                        alt=""
                                        onClick={() => {
                                            // setCurrentTarget({ slug: HOME_SLUG});
                                            history.push('/');
                                        }}
                                    />
                                    {isHideNavPage && (
                                        <div className="backBtn">
                                            <img
                                                className="backBtn"
                                                src="/img/icon/m-icon-gnb-btn-back.svg"
                                                alt=""
                                                onClick={() => {
                                                    history.goBack();
                                                }}
                                            />
                                        </div>
                                    )}

                                    <div>
                                        {/* 다국어 설정을 위한 임시 버튼 */}
                                        {/* <div
                                        className="ko"
                                        onClick={() => {
                                            setLanguageBasedOnLocation('ko');
                                        }}
                                    >
                                        한글
                                    </div> */}
                                        {/* <div
                                        onClick={() => {
                                            setLanguageBasedOnLocation('en');
                                        }}
                                        className="en"
                                    >
                                        english
                                    </div> */}
                                    </div>
                                </>
                            }
                            rightEl={
                                <>
                                    {initData && (
                                        <>
                                            {initData.isLogin ? (
                                                <div className="headerAccount headerDtVisible">
                                                    <div
                                                        className="textAccount"
                                                        onClick={() => {
                                                            history.push(
                                                                '/my-account/orders'
                                                            );
                                                        }}
                                                    >
                                                        {initData?.userFirstName +
                                                            initData?.userLastName}
                                                        님
                                                    </div>

                                                    <NavNextArrow16P/>
                                                </div>
                                            ) : (
                                                <>
                                                    <div
                                                        className="textInHeader headerDtVisible signup"
                                                        onClick={() => {
                                                            setSignUpModalOn(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        {t('signup')}
                                                        {(!isScrollDown ||
                                                            pathname ===
                                                            '/checkout') && (
                                                            <div className="labelForSignup">
                                                                무료배송+1000원 할인
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div
                                                        className="textInHeader headerDtVisible"
                                                        onClick={() => {
                                                            setIsLoginModalOn(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        {t('login')}
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                    <img
                                        className="logoInHeader"
                                        onClick={() => {
                                            setShowSearch(true);
                                        }}
                                        src={searchIcon}
                                        alt=""
                                    />
                                    {cartListCount ? (
                                        <div
                                            onClick={() => {
                                                //카트 이동은 상태변경으로
                                                setIsCartLoading(true);
                                            }}
                                            className="cartCountIcon"
                                        >
                                            {cartListCount}
                                        </div>
                                    ) : (
                                        <img
                                            className="logoInHeader"
                                            src={cartIcon}
                                            alt=""
                                            onClick={() => {
                                                //카트 이동은 상태변경으로
                                                setIsCartLoading(true);
                                            }}
                                        />
                                    )}

                                    {
                                        <img
                                            onClick={() => {
                                                setIsDimBgOn(true);
                                                setOpenMenu(true);
                                            }}
                                            className="logoInHeader hamburger"
                                            src={menuIcon}
                                            alt=""
                                        />
                                    }
                                </>
                            }
                        />
                    </div>

                    {!isGlobalError && pathname !== '/bbshidden' && (
                        <NavContainer/>
                    )}
                </div>
            )}
            <div
                id="contentArea"
                className={`AppContentArea ${contentAreaExtraClassType}`}
                style={{
                    minHeight: `${window.innerHeight}px`,
                    marginTop: pathname === '/bbshidden' && '0',
                }}
            >
                {isGlobalError && (
                    <Route component={() => <ErrorNotice code={'500'}/>}/>
                )}
                {initData && !isGlobalError && (
                    <ScrollToTop>
                        <Switch>
                            <Redirect from="/playkit" to="/page/playkit"/>
                            <Redirect from="/eatsfun" to="/page/eatsfun"/>
                            <Redirect from="/box" to="/store/collection/all-collection"/>
                            <Redirect exact from="/store" to="/"/>

                            {/* bbs -> bbs hidden redirect */}
                            {/* <Redirect from="/store/collection/dogfood-collection" to="/bbshidden"/> */}
                            <Route path="/" exact={true} component={HomePage}/>
                            <Route
                                path="/box/:defaultBoxType?"
                                exact={true}
                                component={CollectionPage}
                            />
                            <Route
                                path="/portfolio/:slug"
                                exact={true}
                                component={BBPortfolioPage}
                            />
                            <Route
                                path="/portfolio-item/:slug"
                                exact={true}
                                component={BBPortfolioPage}
                            />
                            <Route
                                path="/page/:slug"
                                exact={true}
                                component={CustomHtmlPage}
                            />
                            <Route
                                path="/store/collection/:slug"
                                exact={true}
                                component={CollectionPage}
                            />

                            <Route
                                path="/store/:slug"
                                exact={true}
                                component={ProductPage}
                            />
                            <Route
                                path="/terms"
                                component={TermsPage}
                            />
                            <Route
                                path="/my-account/"
                                render={() => <AccountPage/>}
                            />
                            <Route
                                path="/checkout/order-received"
                                exact={true}
                                component={CompleteOrderContainer}
                            />
                            <Route
                                path="/login"
                                exact={true}
                                component={LoginPage}
                            />
                            <Route
                                path="/checkout"
                                exact={true}
                                component={CartPage}
                            />
                            <Route
                                path="/partner"
                                exact={true}
                                component={PartnerPage}
                            />
                            <Route
                                path="/welcome"
                                exact={true}
                                component={WelcomePage}
                            />
                            <Route
                                path="/bbshidden"
                                exact={true}
                                component={BBSHiddenPage}
                            />
                            <Route
                                path="/lg-promotion"
                                exact={true}
                                component={LGPromotionPage}
                            />
                            {/* <Route
                                path="/santa2021"
                                exact={true}
                                component={BBSantaPresentPage}
                            /> */}
                            <Route
                                path="/maintenance"
                                exact={true}
                                component={MaintenancePage}
                            />
                            <Route
                                component={() => <ErrorNotice code={'404'}/>}
                            />

                        </Switch>
                    </ScrollToTop>
                )}

                {openMenu && (
                    <HamburgerMenu
                        openMenu={openMenu}
                        setOpenMenu={setOpenMenu}
                    />
                )}
            </div>
            <StaticModal/>
            <div className={`WrapperDimBg ${isDimBgOn ? 'dimBgOn' : ''}`}></div>

            <div className={`SpinnerWrapper ${isSpinnerOn ? 'SpinnerOn' : ''}`}>
                <div className="spinner"></div>
            </div>
            <div className="minicartpopup">
                {isMiniCartPopup && <MiniCartPopup/>}
                {isFollowPopup && (
                    <CartAddCompletePopup addedProduct={miniCartProduct}/>
                )}
            </div>
            {commonPopupContetns !== null && <CommonPopup/>}
            <LoginModal/>
            <AgreementModal/>
            {pathname !== '/bbshidden' && <Footer/>}
        </div>
    );
});

export default App;
