import { Certificate } from 'crypto';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import './LoginModal.scss';
import LoginContent from '../LoginContent/LoginContent';
import SignUpContent from '../LoginContent/SignUpContent';

const LoginModal: React.FunctionComponent = observer(() => {
    const { rootStore } = React.useContext(AppContext);
    // 첫 화면 노출시 불러올 이미지
    const {
        isLoginModalOn,
        isLoginModalSignUpOn,
        setIsBodyScrollable,
    } = rootStore;

    //isLoginContent == true -> loginContent
    //false => signupContent 
    const [isLoginContent , setIsLoginContent] = useState(isLoginModalSignUpOn == false);

    useEffect(() => {

        if(isLoginModalSignUpOn) {
            setIsLoginContent(false);
        } else{
            setIsLoginContent(true);
        }
        setIsBodyScrollable(!isLoginModalOn);
    }, [isLoginModalOn , isLoginModalSignUpOn]);

    return (
        <div
            className={`loginModalWrapper  ${
                isLoginModalOn ? 'logInModalVisible' : ''
            }`}
        >
            {isLoginContent ? <LoginContent
                setIsLoginContent  = {setIsLoginContent}/> 
                : <SignUpContent 
                setIsLoginContent={setIsLoginContent}
            />}
        </div>
    );
});
export default LoginModal;
