import {Certificate} from 'crypto';
import React, {useState} from 'react';
// import { TermsContentProps } from '../../types/types';
// import './TermsContent.scss';

const TermsContent: React.FunctionComponent<{
    userAgreementType: 'registeredUser' | 'nonRegisteredUser';
}> = ({userAgreementType}) => {
    return (
        <div>
            {userAgreementType === 'registeredUser' ? (
                <div id="tab-1" className="tab-content current">
                    <p>
                        <strong>1장 총칙</strong>
                        <br/>
                        제 1조 (목적)
                        <br/>
                        본 약관은 쇼핑몰을 운영하는 회사 (이하
                        ‘사이트’이라 한다)에서 제공하는 인터넷 관련 서비스(이하
                        ‘서비스’라 하며, 접속 가능한 유,무선 단말기의 종류와
                        상관없이 이용 가능한 사이트가 제공하는 모든 서비스를
                        의미합니다.)를 이용함에 있어 회원의 권리•의무 및
                        책임사항을 규정함을 목적으로 합니다.
                    </p>
                    <p>
                        <br/>
                        제 2조 (정의)
                        <br/>
                        ① ‘사이트’ 란 ‘회사’가 상품을 회원에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 ‘상품 등’을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 사이버몰을
                        운영하는 사업자의 의미로도 사용합니다. 현재 ‘회사’가 운영하는 ‘사이트’는 https://www.baconbox.co 이며, 더불어 서비스 하는 안드로이드, iOS 환경의
                        서비스를 포함한 모바일웹과 앱을 포함 합니다.<br/>
                        ② ‘회원’이라 함은 ‘사이트’에 개인정보를 제공하여 회원등록을 한 자로서, ‘사이트’에 정해진 회원 가입 방침에 따라 ‘사이트’의 정보를 지속적으로 제공받으며,
                        ‘사이트’가 제공하는 ‘서비스’를 계속적으로 이용할 수 있는 자를 말합니다.<br/>
                        ③ ‘비밀번호’라 함은 회원의 동일성 확인과 회원의 권익 및 비밀보호를 위하여 회원 스스로가 설정하여 ‘사이트’에 등록한 영문과 숫자의 조합을 말합니다.<br/>
                        ④ 본 약관에서 정의되지 않은 용어는 관계법령이 정하는 바에 따르며, 그 외에는 일반적인 상관례에 의합니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 3조 (약관의 명시와 설명 및 개정)
                        <br/>
                        ① ‘회사’는 이 약관의 내용과 상호 및 대표자 이름, 소재지 주소, 전화번호, 전자우편주소, 사업자등록번호 등을 회원이 쉽게 알 수 있도록 ‘사이트’의 초기 ‘서비스’
                        화면에 게시합니다. 다만, 약관의 구체적 내용은 회원이 연결화면을 통하여 볼 수 있습니다.<br/>
                        ② ‘회사’는 『전자상거래 등에서의 소비자보호에 관한 법률』, 『약관의 규제 등에 관한 법률』, 『전자거래 기본법』, 『전자 서명법』, 『정보통신망 이용촉진 등에 관한
                        법률』, 『소비자기본법』 등 관계법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.<br/>
                        ③ ‘회사’가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 ‘사이트’의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지
                        공지합니다.<br/>
                        ④ ‘회사’가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정전의 약관조항이 그대로
                        적용됩니다. 다만 이미 계약을 체결한 회원이 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 ‘회사’에 송신하여 ‘회사’의 동의를 받은
                        경우에는 개정약관 조항이 적용됩니다.<br/>
                        ⑤ 제3항에 따라 공지된 적용일자 이후에 회원이 ‘회사’의 ‘서비스’를 계속 이용하는 경우에는 개정된 약관에 동의하는 것으로 봅니다. 개정된 약관에 동의하지 아니하는 회원은
                        언제든지 자유롭게 ‘서비스’ 이용계약을 해지할 수 있습니다.<br/>
                    </p>
                    <p>
                        <strong>제 2장 회사의 서비스</strong>
                        <br/>
                        제 4조 (서비스의 제공 및 변경)
                        <br/>
                        ① 회사는 다음과 같은 업무를 수행합니다.<br/>
                        (i) 재화 또는 용역에 대한 정보 제공 및 구매 계약의 체결<br/>
                        (ii) 구매 계약이 체결된 재화 또는 용역의 배송<br/>
                        (iii) 기타 회사가 정하는 업무<br/>
                        ② 회사는 재화 또는 용역이 품절되거나 기술적 사양의 변경등으로 더 이상 제공할 수 없는 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수
                        있습니다.<br/>
                        ③ 회사가 이용자에게 제공하는 정보 서비스 등 용역 중에서 재화의 제휴업체가 직접 회사에게 제공한 정보와 관련하여 이용자가 입을 수 있는 손해 일체에 대한 책임은 해당 재화
                        또는 용역을 제공한 제휴업체에 있으며, 이와 관련하여 회사는 어떠한 책임도 부담하지 않습니다.<br/>
                        ④ 회사가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 방법으로
                        즉시 통지합니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 5조 (서비스의 중단)
                        <br/>
                        ① 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.<br/>
                        ② 회사는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, 회사가 고의 또는 과실이 없음을 입증하는
                        경우에는 그러하지 아니합니다.<br/>
                        ③ 사업종목의 전환, 사업의 종료 또는 포기, 회사의 폐업, 합병, 분할, 영업양도 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 회사는 제8조에 정한 방법으로
                        이용자에게 통지하고 당초 회사에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, 회사가 보상기준 등을 고지하지 아니한 경우에는 이용자들의 포인트 등을 회사에서 통용되는
                        통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.<br/>
                    </p>
                    <p>
                        <strong>제 3장 서비스 이용계약</strong>
                        <br/>
                        제 6조 (회원가입)
                        <br/>
                        ① 이용자는 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.<br/>
                        ② 회사는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각호에 해당하지 않는 한 회원으로 등록합니다.<br/>
                        (i) 가입신청자가 이 약관 제7조 제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조 제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 베이컨의
                        회원 재가입 승낙을 얻은 경우에는 예외로 합니다.<br/>
                        (ii) 등록 내용에 허위, 기재누락, 오기가 있는 경우<br/>
                        (iii) 기타 회원으로 등록하는 것이 회사의 기술상 현저히 지장이 있다고 판단되는 경우<br/>
                        (iv) 가입신청자가 만 14세 미만의 아동으로서 부모 등 법정대리인의 동의를 얻지 아니한 경우<br/>
                        (v) 이미 회원으로 등록된 계정이 존재할 경우<br/>
                        ③ 회원가입계약의 성립시기는 회사의 승낙이 회원에게 도달한 시점으로 합니다.<br/>
                        ④ 회원은 회사에 등록한 회원정보에 변경이 있는 경우, 즉시 회사에서 정하는 방법에 따라 해당 변경사항을 반영하여야 합니다. 회원정보 수정을 하지 않음으로써 발생하는 회원의
                        손해에 대하여 회사는 아무런 책임을 지지 않습니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제7조(회원 탈퇴 및 자격 상실 등)
                        <br/>
                        ① 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며, 회사는 회원의 회원탈퇴 요청 즉시 회원탈퇴를 처리합니다. 회원탈퇴 시 회원자격이 상실되며, 회사가 제공하는 각종
                        할인쿠폰, 포인트, 이벤트 혜택 등이 소멸됩니다. 단, 해지의사를 통지하기 전에 현재 진행중인 모든 상품의 거래를 완료하거나 철회 또는 취소하여야 하며, 거래의 철회 또는
                        취소로 인한 불이익은 회원 본인이 부담하여야 합니다. 회원 탈퇴로 인해 발생한 불이익에 대한 책임은 회원 본인이 져야 하며, 이용계약이 종료되면 회사는 회원에게 부가적으로
                        제공한 각종 혜택을 회수할 수 있습니다.<br/>
                        ② 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원 자격을 제한 및 정지시킬 수 있습니다.<br/>
                        (i) 가입 신청 시에 허위 내용을 등록한 경우<br/>
                        (ii) 회사의 서비스를 이용하여 구입한 재화 등의 대금, 기타 회사의 서비스 이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우<br/>
                        (iii) 타인의 ID와 비밀번호 또는 그 개인정보를 도용한 경우<br/>
                        (iv) 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우<br/>
                        (v) 회사를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우<br/>
                        (vi) 기타 서비스 운영을 방해하는 행위를 하거나 시도한 경우<br/>
                        (vii) 기타 회사가 합리적인 판단에 기하여 서비스의 제공을 거부할 필요가 있다고 인정할 경우<br/>
                        ③ 회사가 회원 자격을 제한/정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는 회원자격을 상실시킬 수
                        있습니다.<br/>
                        ④ 회사가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를
                        부여합니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제8조(회원에 대한 통지)
                        <br/>
                        ① 회사가 회원에 대한 통지를 하는 경우, 회원이 회사와 미리 약정하여 지정한 전자우편(또는 우편), 문자, 전화, 팩스 등의 방법으로 할 수 있습니다.<br/>
                        ② 회사는 불특정다수 회원에 대한 통지의 경우 7일 이상 회사 웹사이트 등에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을
                        미치는 사항에 대하여는 개별 통지합니다.<br/>
                    </p>
                    <p>
                        <strong>제 4장 구매계약 및 대금 결제</strong>
                        <br/>
                        제9조 (구매신청)
                        <br/>
                        ① 베이컨 이용자는 베이컨에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, 회사는 이용자가 구매 신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야
                        합니다.<br/>
                        (i) 재화 등의 검색 및 선택<br/>
                        (ii) 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력<br/>
                        (iii)약관내용, 청약 철회권이 제한되는 서비스, 배송료, 설치비 등의 비용부담과 관련한 내용에 대한 확인<br/>
                        (iv) 재화 등의 구매신청 및 이에 관한 확인 또는 회사의 확인에 대한 동의<br/>
                        (v) 결제 방법의 선택<br/>
                        ② 회사가 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우<br/>
                        1) 개인정보를 제공받는 자, 2)개인정보를 제공받는 자의 개인정보 이용 목적, 3)제공하는 개인정보의 항목, 4)개인정보를 제공받는 자의 개인정보 보유 및 이용 기간을
                        구매자에게 알리고 동의를 받아야 합니다.<br/>
                        ③ 회사가 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1)개인정보 취급위탁을 받는 자, 2)개인정보 취급위탁을 하는 업무의 내용을 구매자에게
                        알리고 동의를 받아야 합니다. 다만, 서비스 제공에 관한 계약 이행을 위해 필요하고, 구매자의 편의 증진과 관련된 경우에는 『정보통신망 이용촉진 및 정보보호 등에 관한
                        법률』에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제10조 (구매계약의 성립)
                        <br/>
                        ① 회사는 제9조와 같은 구매신청에 대하여 다음 각호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면
                        미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.<br/>
                        (i) 신청 내용에 허위, 기재누락, 오기가 있는 경우<br/>
                        (ii) 제7조에 따라 회원 자격이 제한 또는 상실된 회원이 구매신청한 경우<br/>
                        (iii) 기타 이용신청에 승낙하는 것이 회사 기술상 현저히 지장이 있다고 판단하는 경우<br/>
                        ② 회사의 승낙이 제12조 제1항의 수신확인통지 형태로 이용자에게 도달한 시점에 구매계약이 성립한 것으로 봅니다.<br/>
                        ③ 회사의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제11조 (지급방법)
                        <br/>
                        ① 베이컨에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다. 단, 회사는 이용자의 지급방법에 대하여 어떠한 명목의
                        수수료도 추가하여 징수할 수 없습니다.<br/>
                        (i) 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체<br/>
                        (ii) 선불카드, 직불카드, 신용카드 등의 각종 카드 결제<br/>
                        (iii) 온라인 무통장입금<br/>
                        (iv) 포인트/쿠폰 등 회사가 지급한 포인트에 의한 결제<br/>
                        (v) 회사와 계약을 맺었거나 회사가 인정한 상품권에 의한 결제<br/>
                        (vi) 기타 전자적 지급 방법에 의한 대금 지급 등<br/>
                        ② 이용자가 구매 대금의 결제와 관련하여 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로 이용자에게 있으며, 재화 또는 용역의 청약 후 일정 기간 내에
                        결제가 이루어 지지 않는 경우 회사는 이에 해당 주문을 취소할 수 있습니다.<br/>
                        ③ 회사는 구매자의 결제 수단에 대하여 정당한 사용권한 보유여부를 확인할 수 있으며, 필요한 경우 해당 거래진행의 정지 및 소명자료의 제출을 요청할 수 있습니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제12조 (수신 확인 통지/구매신청 변경 및 취소)
                        <br/>
                        ① 회사는 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.<br/>
                        ② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고, 회사는 배송 전에 이용자의
                        요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이용자가 이미 대금을 지불한 경우에는 제15조의 청약철회 등에 관한 규정에 따릅니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제13조 (재화 등의 공급)
                        <br/>
                        ① 회사가 제공하는 서비스의 특성상, 이미 재화 배송이 시작된 경우 이용자는 서비스 이용을 취소할 수 없습니다. 이 때 회사는 이용자가 재화 등의 공급 절차 및 진행 사항을
                        확인할 수 있도록 적절한 조치를 합니다.<br/>
                        ② 배송 소요기간은 입금 확인일 익일을 기산일로 하여 배송완료 되기까지의 기간을 말합니다. 공휴일 및 기타 휴무일 또는 천재지변 등의 불가항력적 사유가 발생하는 경우 그
                        해당 기간은 배송소요기간에서 제외합니다.<br/>
                        ③ 배송과 관련하여 배송업체, 금융기관 등과의 사이에 분쟁이 발생한 경우에는 관련 당사자간에 해결하여야 합니다. 회사는 이에 대하여 관여하지 않으며, 어떠한 책임도 부담하지
                        않습니다.<br/>
                        ④ 회사는 이용자와 재화 등의 공급시기에 관하여 이용자가 청약을 한 날부터 7일 이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다.
                        다만, 회사가 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 조치를 취합니다.<br/>
                        ⑤ 회사는 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다. 만약 회사가 약정 배송기간을 초과한 경우에는 그로 인한 이용자의
                        손해를 배상하여야 합니다. 다만 회사가 고의, 과실이 없음을 입증한 경우에는 그러하지 아니합니다.<br/>
                        ⑥ 회사는 국내 배송만 가능합니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 14조 (반품/교환/환불/취소)
                        <br/>
                        ① 회사는 제공하는 서비스의 특성상, 발송 완료 후 이용자의 변심 또는 훼손에 의한 반품/교환을 할 수 없습니다.<br/>
                        ② 회사는 결제 대금의 환불이 발생하는 경우 거래가 취소된 날로부터 3영업일 이내에 이용자에게 환불에 필요한 조치를 합니다. 카드 결제를 통한 서비스 이용건의 환불은
                        카드결제 취소를 통해서만 가능합니다.<br/>
                        ③ 회사는 이용자가 구매 신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고, 사전에 재화 등의 대금을 받은
                        경우에는 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.<br/>
                        ④ 회원은 상품이 발송되기 하루 전까지 구매를 취소할 수 있습니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제15조 (청약철회 등)
                        <br/>
                        ① 회사와 재화 등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한 법률」 제13조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을
                        받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을 공급 받거나 재화 등의 공급이 시작된 날을 말합니다) 부터 7일 이내에는 청약의 철회를 할 수 있습니다.
                        단, 재화 배송이 시작된 경우에는 청약 철회가 불가능합니다.<br/>
                        ② 이용자가 재화를 배송 받은 후, 다음 각호에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.<br/>
                        (i) 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우는 제외함)<br/>
                        (ii) 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 감소한 경우<br/>
                        (iii) 시간의 경과에 의하여 재판매가 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우<br/>
                        (iv) 회사가 청약철회 등의 제한에 관해 사전에 고지한 경우<br/>
                        ③ 이용자는 회사가 사전에 청약 철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이
                        제한되지 않습니다.<br/>
                        ④ 이용자는 제1항 및 제2항의 규정에도 불구하고 재화 등의 내용이 표시/광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 해당 재화 등을 공급받은 날부터 3개월 이내,
                        그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제16조 (청약철회 등의 효과)
                        <br/>
                        ① 회사는 이용자로부터 재화 등을 반환 받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다. 이 경우 회사가 이용자에게 재화 등의 환급을 지연한 때에는 그
                        지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」 제21조의2에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.<br/>
                        ② 회사는 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금
                        재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.<br/>
                        ③ 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. 회사는 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만
                        재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 회사가 부담합니다.<br/>
                        ④ 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 회사는 청약철회 시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.<br/>
                    </p>
                    <p>
                        <strong>제 5장 정기 배송 이용</strong>
                        <br/>
                        제 17조 (서비스 이용 및 신청 방법)
                        <br/>
                        ① 이 서비스는 회사가 회원에게 선택한 기간 동안 자동결제방식을 통하여 상품을 배송 받을 수 있도록 편의를 제공하는 서비스입니다.<br/>
                        ② 서비스 신청 가능한 상품은 베이컨에서 지정한 상품 ’베이컨박스’와’베이컨박스 라이트’에 한해 운영됩니다.<br/>
                        ③ 서비스 신청 및 결제 시에는 자동결제 가능한신용카드 및 간편 결제(카카오페이 등)으로 신청 가능합니다.<br/>
                        ④ 이 서비스 특성상 정기배송 상품의 가격은 지속적으로 변경될 수 있습니다.<br/>
                        ⑤ 1개의 계정당 월 1개의 정기배송 서비스를 이용할 수 있습니다.<br/>
                        ⑥ 복수개의 정기배송 서비스는 상담을 통해서 신청할 수 있습니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제18조 (정기배송 이용 계약 성립)
                        <br/>
                        ① 서비스 이용계약은 회원이 이 이용안내에 명시된 사항에 동의한다는 의사표시(정기백송 신청하기)와 함께 이용신청을 하고 회사가 이를 승낙함으로써 성립합니다.<br/>
                        ② 회원이 이 이용안내에 대하여 “정기배송 신청하기” 버튼을 클릭한 경우, 여기에 명시된 내용을 충분히 이해하고 그 적용에 동의한 것으로 간주합니다.<br/>
                        ③ 회사와 회원 간 서비스 이용계약은 회사의 승낙이 회원에게 도달한 시점(정기배송서비스의 “구매/결제완료 등”의 표시가 회원에게 절차상 표시된 시점)에 성립합니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 19조 (자동결제 및 알림톡/LMS 발송)
                        <br/>
                        ① 회원은 상시 서비스를 신청할 수 있으며, 서비스 신청은 1회차 상품에 대한 결제일에 완료됩니다.<br/>
                        ② 2회차 배송 상품에 대한 결제는 매월 1일에 결제됩니다.<br/>
                        ③ 기간 약정상품의 이용 횟수를 모두 이용하신 경우 자동으로 정기배송이 연장되며, 제품의 가격 변동이 있을 수 있습니다.<br/>
                        ④ 12회 이용 이후 정기배송 유지 시, 해지 및 건너뛰기에 제한 없이 이용 가능합니다.<br/>
                        ⑤ 카카오 알림톡 혹은 LMS 발송문자에 대한 확인 의무는 회원에게 있으며, 발송문자 미확인에 대한 손해는 회사에서 책임을 부담하지 않습니다.<br/>
                        ⑥ 회원은 자동결제수단에 관한 결제정보를 회사에 정확히 제공하여야 하며, 변경사항 발생 시 회원은 지체 없이 회사가 정한 절차에 따라 이를 고지, 반영하여야
                        합니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 20조 (배송)
                        <br/>
                        ① 첫 달 정기배송은 신청 후 즉시 출발합니다. (* 단, 매월 26일~말일 신청 시, 다음 달 1일 또는 첫 영업일에 배송 출발합니다.)<br/>
                        ② 두 번째 달부터는 신청 시 등록한 결제 수단으로 매월 1일에 결제되며, 첫 주에 배송됩니다.<br/>
                        ③ 회사는 회원이 상품 등의 공급절차 및 진행사항을 확인할 수 있도록 적절한 조치를 합니다.<br/>
                        ④ 각 회차 별 배송지 변경은 베이컨 홈페이지 내 ‘마이페이지 &gt; 기본정보 수정 &gt; 배송지 정보’에서 회원이 직접 진행할 수 있으며, 상품출고 이후에는 배송지
                        변경이 불가합니다.<br/>
                        ⑤ 제품 품절/단종 등의 이슈로 상품 발송이 힘들 경우 회사는 고객에게 고지를 하고 고객 확인 후 상품 변경 혹은 주문 취소 등의 조치를 합니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 21조 (매월 결제 정기배송상품의 청약철회)
                        <br/>
                        ① 이 서비스에 대한 청약철회는 베이컨 홈페이지 내 ‘마이페이지-정기배송 관리’에서 회원이 직접 진행할 수 있습니다. 이 경우 청약철회 시점부터 이 서비스를 전부 해지한
                        것으로 인정됩니다.<br/>
                        ② 회원은 각 1회차 이후에는 회차 별 정기결제 전에는 별도 비용 없이 해당 정기 배송 상품 구매에 대한 청약을 철회할 수 있습니다.<br/>
                        ③ 특정 월에 이용을 원치 않을 경우 '건너뛰기'가 가능합니다.<br/>
                        ④ 회원이 각 회차 별 배송 상품 출고 후 청약을 철회하는 경우에는 베이컨 홈페이지 교환/반품프로세스에 의해 처리됩니다.<br/>
                        ⑤ 기타 청약철회 및 효과 등에 관한 사항은 제 15조 약관의 규정이 그대로 적용됩니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 22조 (기간 약정 정기배송상품의 청약철회 및 반환금)
                        <br/>
                        ① 12개월, 6개월 ‘약정 정기배송’에 대한 청약철회는 베이컨 홈페이지, 카카오 친구톡 상담을 통해서만 청약철회 신청을 할 수 있습니다.<br/>
                        ② 회원이 각 1회차 이후, 이용 횟수 충족(12개월 약정은 12회, 6개월 약정은 6회) 전에 청약을 철회하고자 할 경우 이미 받아보신 첫 달 무료 혜택에 대한 반환금이
                        청구됩니다.<br/>
                        ③ 특정 월에 이용을 원치 않을 경우 '건너뛰기'가 가능하며, 12월 약정은 이용 횟수 충족 전 4회, 6개월 약정은 이용 회수 충족 전 2회 건너뛰기가
                        가능합니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 23조 (서비스 이용 제한)
                        <br/>
                        ① 회사는 카드 이용한도 초과 또는 이용 정지•중단 등의 사유로 상품의 결제가 이루어 지지 않을 경우 해당 회차의 서비스 이용은 제한됩니다.<br/>
                        ② 회사는 카드 이용한도 초과 또는 이용 정지•중단 등의 사유로 상품의 결제가 이루어 지지 않은 기 신청된 정기배송 상품이 있는 경우 신규 정기배송 상품의 주문등 서비스
                        이용은 제한됩니다.<br/>
                        ③ 회사는 회원이 상품을 재판매 할 가능성이 있는 경우 또는 불법적이거나 부당한 행위와 관련된 경우 이 서비스 이용을 제한할 수 있습니다.<br/>
                        ④ 제3항의 서비스 이용제한에 관한 구체적인 기준은 회사 내부운영정책 및 지침에 따릅니다.<br/>
                        ⑤ 회사는 특정상품에 대하여 구매 수량을 제한할 수 있습니다.<br/>
                        ⑥ 회원은 회사에게 통지함으로써 정기배송 서비스를 종료시킬 수 있습니다.<br/>
                        ⑦ 회사는 정기배송 대상 상품을 더 이상 판매할 수 없거나 해당 상품을 정기배송 서비스로 제공할 수 없는 사유가 있는 경우, 해당 상품의 정기배송 서비스를 중지하거나 해당
                        회차의 상품을 공급하지 않을 수 있습니다.<br/>
                    </p>
                    <p>
                        <strong>제 6장 개인정보</strong>
                        <br/>
                        제 24조 (개인정보의 변경)
                        <br/>
                        회원은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제25조 (개인정보의 보호)
                        <br/>
                        ① ‘회사’는 회원의 개인정보를 보호하기 위하여 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』 등 관계법령에서 정하는 바를 준수합니다.<br/>
                        ② ‘회사’는 회원의 개인정보를 보호하기 위하여 개인정보취급방침을 제정, ‘서비스’ 초기화면에 게시합니다. 다만, 개인정보취급방침의 구체적 내용은 연결화면을 통하여 볼 수
                        있습니다.<br/>
                        ③ ‘회사’는 개인정보취급방침에 따라 회원의 개인정보를 최대한 보호하기 위하여 노력합니다.<br/>
                        ④ ‘회사’의 공식 ‘사이트’ 이외의 링크된 사이트에서는 ‘회사’의 개인정보취급방침이 적용되지 않습니다. 링크된 사이트 및 ‘상품 등’을 제공하는 제3자의 개인정보 취급과
                        관련하여는 해당 ‘사이트’ 및 제3자의 개인정보취급방침을 확인할 책임이 회원에게 있으며, ‘회사’는 이에 대하여 책임을 부담하지 않습니다.<br/>
                        ⑤ ‘회사’는 다음과 같은 경우에 법이 허용하는 범위 내에서 회원의 개인정보를 제3자에게 제공할 수 있습니다.<br/>
                        (i) 수사기관이나 기타 정부기관으로부터 정보제공을 요청 받은 경우<br/>
                        (ii) 회원의 법령 또는 약관의 위반을 포함하여 부정행위확인 등의 정보보호업무를 위해 필요한 경우<br/>
                        (iii) 기타 법률에 의해 요구되는 경우<br/>
                        (iv)판매자 또는 배송업체 등에게 거래 및 배송 등에 필요한 최소한의 회원의 정보(성명, 주소, 전화번호)를 제공하는 경우<br/>
                        (v)구매가 성사된 때에 그 이행을 위하여 필요한 경우와 구매가 종료된 이후에도 반품, 교환, 환불, 취소 등을 위하여 필요한 경우<br/>
                    </p>
                    <p>
                        <br/>
                        제 26조 (회원의 ID및 비밀번호에 대한의무)
                        <br/>
                        ① ID(전자우편번호 및 소셜네트워크 연동으로 인한 ID)와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 소홀히 하여 발생한 모든 민∙형사상의 책임은 회원 자신에게
                        있습니다.<br/>
                        ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.<br/>
                        ③ 회원이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 즉시 ‘회사’에 통보하고 ‘회사’의 조치가 있는 경우에는 그에 따라야
                        합니다.<br/>
                        ④ 회원이 제3항에 따른 통지를 하지 않거나 ‘회사’의 조치에 응하지 아니하여 발생하는 모든 불이익에 대한 책임은 회원에게 있습니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 27조 (회원의 게시물)
                        <br/>
                        ①회원이 작성한 게시물에 대한 모든 권리 및 책임은 이를 게시한 회원에게 있으며, ‘회사’는 회원이 게시하거나 등록하는 ‘서비스’의 내용물이 다음 각 항에 해당한다고
                        판단되는 경우에 사전통지 없이 삭제할 수 있고, 이에 대하여 ‘회사’는 어떠한 책임도 지지 않습니다.<br/>
                        (i) 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우<br/>
                        (ii) 공서양속에 위반되는 내용일 경우<br/>
                        (iii) 범죄적 행위에 결부된다고 인정되는 경우<br/>
                        (iv) ‘회사’의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우<br/>
                        (v) 회원이 ‘사이트’와 게시판에 음란물을 게재하거나 음란사이트를 링크하는 경우<br/>
                        (vi) ‘회사’로부터 사전 승인 받지 아니한 상업광고, 판촉내용을 게시하는 경우<br/>
                        (vii) 해당 상품과 관련 없는 내용인 경우<br/>
                        (viii) 정당한 사유 없이 당사의 영업을 방해하는 내용을 기재하는 경우<br/>
                        (ix) 기타 관계법령에 위반된다고 판단되는 경우<br/>
                    </p>
                    <p>
                        <strong>7장 면책조항 및 분쟁의 해결</strong>
                        <br/>
                        제 28 조 (면책조항)
                        <br/>
                        ① ‘회사’는 천재지변 또는 이에 준하는 불가항력으로 인하여 ‘서비스’를 제공할 수 없는 경우에는 ‘서비스’ 제공에 관한 책임이 면제됩니다.<br/>
                        ② ‘회사’는 회원의 귀책사유로 인한 ‘서비스’ 이용의 장애에 대하여 책임을 지지 않습니다.<br/>
                        ③ ‘회사’는 회원이 ‘서비스’를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며, 그 밖의 ‘서비스’를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지
                        않습니다.<br/>
                        ④ ‘회사’는 회원이 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관해서는 책임을 지지 않습니다.<br/>
                        ⑤ 회원이 발송한 메일 내용에 대한 법적인 책임은 사용자에게 있습니다.<br/>
                        ⑥ ‘회사’는 회원간 또는 회원과 제3자 상호간에 ‘서비스’를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.<br/>
                        ⑦ ‘회사’는 무료로 제공되는 ‘서비스’ 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 29 조 (분쟁해결)
                        <br/>
                        ① ‘회사’는 회원이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위해서 피해보상처리 기구를 설치, 운영합니다.<br/>
                        ② ‘회사’는 회원으로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 즉시 통보합니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 30 조 (준거법 및 관할법원)
                        <br/>
                        ① 이 약관의 해석 및 ‘회사’와 회원간의 분쟁에 대하여는 대한민국의 법을 적용합니다.<br/>
                        ② ‘서비스’ 이용 중 발생한 회원과 ‘회사’간의 소송은 민사소송법에 의한 관할법원에 제소합니다.<br/>
                    </p>
                    <p>
                        <br/>
                        <strong>부칙</strong>
                        현재 이용약관 고지일자 : 2023년 2월 n일<br/>
                        현재 이용약관 시행일자 : 2023년 2월 n+7일<br/>
                    </p>
                </div>
            ) : (
                <div id="tab-2" className="tab-content">
                    <p>
                        <strong>1장 총칙</strong>
                        <br/>
                        제 1조 (목적)
                        <br/>
                        본 약관은 쇼핑몰을 운영하는 회사 (이하 ‘사이트’이라 한다)에서 제공하는 인터넷 관련 서비스(이하 ‘서비스’라 하며, 접속 가능한 유,무선 단말기의 종류와 상관없이 이용
                        가능한 사이트가 제공하는 모든 서비스를 의미합니다.)를 이용함에 있어 회원의 권리•의무 및 책임사항을 규정함을 목적으로 합니다.
                    </p>
                    <p>
                        <strong>제 2장 회사의 서비스</strong>
                        <br/>
                        제 2조 (서비스의 제공 및 변경)
                        <br/>
                        ① 회사는 다음과 같은 업무를 수행합니다.<br/>
                        (i) 재화 또는 용역에 대한 정보 제공 및 구매 계약의 체결<br/>
                        (ii) 구매 계약이 체결된 재화 또는 용역의 배송<br/>
                        (iii) 기타 회사가 정하는 업무<br/>
                        ② 회사는 재화 또는 용역이 품절되거나 기술적 사양의 변경등으로 더 이상 제공할 수 없는 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수
                        있습니다.<br/>
                        ③ 회사가 이용자에게 제공하는 정보 서비스 등 용역 중에서 재화의 제휴업체가 직접 회사에게 제공한 정보와 관련하여 이용자가 입을 수 있는 손해 일체에 대한 책임은 해당 재화
                        또는 용역을 제공한 제휴업체에 있으며, 이와 관련하여 회사는 어떠한 책임도 부담하지 않습니다.<br/>
                        ④ 회사가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 방법으로
                        즉시 통지합니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 3조 (서비스의 중단)
                        <br/>
                        ① 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.<br/>
                        ② 회사는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, 회사가 고의 또는 과실이 없음을 입증하는
                        경우에는 그러하지 아니합니다.<br/>
                        ③ 사업종목의 전환, 사업의 종료 또는 포기, 회사의 폐업, 합병, 분할, 영업양도 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 회사는 제8조에 정한 방법으로
                        이용자에게 통지하고 당초 회사에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, 회사가 보상기준 등을 고지하지 아니한 경우에는 이용자들의 포인트 등을 회사에서 통용되는
                        통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 4 조 (‘서비스’ 이용시간)
                        <br/>
                        ‘서비스’의 이용은 ‘회사’의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다. 다만 정기점검 등의 필요로 ‘회사’가 정한 날이나 시간은
                        제외됩니다. 정기점검시간은 ‘서비스’제공화면에 공지한 바에 따릅니다.
                    </p>
                    <p>
                        <strong>제 3장 구매계약 및 대금 결제</strong>
                        <br/>
                        제 5조 (구매신청)
                        <br/>
                        ① 베이컨 이용자는 베이컨에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, 회사는 이용자가 구매 신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야
                        합니다.<br/>
                        (i) 재화 등의 검색 및 선택<br/>
                        (ii) 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력<br/>
                        (iii)약관내용, 청약 철회권이 제한되는 서비스, 배송료, 설치비 등의 비용부담과 관련한 내용에 대한 확인<br/>
                        (iv) 재화 등의 구매신청 및 이에 관한 확인 또는 회사의 확인에 대한 동의<br/>
                        (v) 결제 방법의 선택<br/>
                        ② 회사가 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우<br/>
                        1) 개인정보를 제공받는 자, 2)개인정보를 제공받는 자의 개인정보 이용 목적, 3)제공하는 개인정보의 항목, 4)개인정보를 제공받는 자의 개인정보 보유 및 이용 기간을
                        구매자에게 알리고 동의를 받아야 합니다.<br/>
                        ③ 회사가 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1)개인정보 취급위탁을 받는 자, 2)개인정보 취급위탁을 하는 업무의 내용을 구매자에게
                        알리고 동의를 받아야 합니다. 다만, 서비스 제공에 관한 계약 이행을 위해 필요하고, 구매자의 편의 증진과 관련된 경우에는 『정보통신망 이용촉진 및 정보보호 등에 관한
                        법률』에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 6조 (구매계약의 성립)
                        <br/>
                        ① 회사는 제9조와 같은 구매신청에 대하여 다음 각호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.<br/>
                        (i) 신청 내용에 허위, 기재누락, 오기가 있는 경우<br/>
                        (ii) 기타 이용신청에 승낙하는 것이 회사 기술상 현저히 지장이 있다고 판단하는 경우<br/>
                        ② 회사의 승낙이 제8조 제1항의 수신확인통지 형태로 이용자에게 도달한 시점에 구매계약이 성립한 것으로 봅니다.<br/>
                        ③ 회사의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 7조 (지급방법)
                        <br/>
                        ① 베이컨에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다. 단, 회사는 이용자의 지급방법에 대하여 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.<br/>
                        (i) 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체<br/>
                        (ii) 선불카드, 직불카드, 신용카드 등의 각종 카드 결제<br/>
                        (iii) 온라인 무통장입금<br/>
                        (iv) 포인트/쿠폰 등 회사가 지급한 포인트에 의한 결제<br/>
                        (v) 회사와 계약을 맺었거나 회사가 인정한 상품권에 의한 결제<br/>
                        (vi) 기타 전자적 지급 방법에 의한 대금 지급 등<br/>
                        ② 이용자가 구매 대금의 결제와 관련하여 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로 이용자에게 있으며, 재화 또는 용역의 청약 후 일정 기간 내에 결제가 이루어 지지 않는 경우 회사는 이에 해당 주문을 취소할 수 있습니다.<br/>
                        ③ 회사는 구매자의 결제 수단에 대하여 정당한 사용권한 보유여부를 확인할 수 있으며, 필요한 경우 해당 거래진행의 정지 및 소명자료의 제출을 요청할 수 있습니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 8조 (수신 확인 통지/구매신청 변경 및 취소)
                        <br/>
                        ① 회사는 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.<br/>
                        ② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고, 회사는 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이용자가 이미 대금을 지불한 경우에는 제15조의 청약철회 등에 관한 규정에 따릅니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 9조 (재화 등의 공급)
                        <br/>
                        ① 회사가 제공하는 서비스의 특성상, 이미 재화 배송이 시작된 경우 이용자는 서비스 이용을 취소할 수 없습니다. 이 때 회사는 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.<br/>
                        ② 배송 소요기간은 입금 확인일 익일을 기산일로 하여 배송완료 되기까지의 기간을 말합니다. 공휴일 및 기타 휴무일 또는 천재지변 등의 불가항력적 사유가 발생하는 경우 그 해당 기간은 배송소요기간에서 제외합니다.<br/>
                        ③ 배송과 관련하여 배송업체, 금융기관 등과의 사이에 분쟁이 발생한 경우에는 관련 당사자간에 해결하여야 합니다. 회사는 이에 대하여 관여하지 않으며, 어떠한 책임도 부담하지 않습니다.<br/>
                        ④ 회사는 이용자와 재화 등의 공급시기에 관하여 이용자가 청약을 한 날부터 7일 이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만, 회사가 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 조치를 취합니다.<br/>
                        ⑤ 회사는 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다. 만약 회사가 약정 배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다. 다만 회사가 고의, 과실이 없음을 입증한 경우에는 그러하지 아니합니다.<br/>
                        ⑥ 회사는 국내 배송만 가능합니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 10조 (반품/교환/환불/취소)
                        <br/>
                        ① 회사는 제공하는 서비스의 특성상, 발송 완료 후 이용자의 변심 또는 훼손에 의한 반품/교환을 할 수 없습니다.<br/>
                        ② 회사는 결제 대금의 환불이 발생하는 경우 거래가 취소된 날로부터 3영업일 이내에 이용자에게 환불에 필요한 조치를 합니다. 카드 결제를 통한 서비스 이용건의 환불은 카드결제 취소를 통해서만 가능합니다.<br/>
                        ③ 회사는 이용자가 구매 신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고, 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.<br/>
                        ④ 이용자는 상품이 발송되기 하루 전까지 구매를 취소할 수 있습니다.<br/>
                        ⑤ 상품의 반품에 따른 환불은 반품하는 상품이 판매자에게 도착되고, 반품 사유 및 반품 배송비 부담자가 확인된 이후에 이루어집니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 11조 (청약철회 등)
                        <br/>
                        ① 회사와 재화 등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한 법률」 제13조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을 공급 받거나 재화 등의 공급이 시작된 날을 말합니다) 부터 7일 이내에는 청약의 철회를 할 수 있습니다. 단, 재화 배송이 시작된 경우에는 청약 철회가 불가능합니다.<br/>
                        ② 이용자가 재화를 배송 받은 후, 다음 각호에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.<br/>
                        (i) 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우는 제외함)<br/>
                        (ii) 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 감소한 경우<br/>
                        (iii) 시간의 경과에 의하여 재판매가 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우<br/>
                        (iv) 회사가 청약철회 등의 제한에 관해 사전에 고지한 경우<br/>
                        ③ 이용자는 회사가 사전에 청약 철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지 않습니다.<br/>
                        ④ 이용자는 제1항 및 제2항의 규정에도 불구하고 재화 등의 내용이 표시/광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 해당 재화 등을 공급받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제12조 (청약철회 등의 효과)
                        <br/>
                        ① 회사는 이용자로부터 재화 등을 반환 받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다. 이 경우 회사가 이용자에게 재화 등의 환급을 지연한 때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」 제21조의2에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.<br/>
                        ② 회사는 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.<br/>
                        ③ 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. 회사는 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 회사가 부담합니다.<br/>
                        ④ 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 회사는 청약철회 시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.<br/>
                    </p>
                    <p>
                        <strong>4장 면책조항 및 분쟁의 해결</strong>
                        <br/>
                        제 13조 (게시물의 관리)
                        <br/>
                        ① 구매회원의 게시물이 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』 및 『저작권』등 관계법령에 위반되는 내용을 포함하는 경우, 권리자는 관계법령이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, ‘회사’는 관계법령에 따라 조치를 취하여야 합니다.<br/>
                        ② ‘회사’는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 ‘회사’ 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 ‘게시물’에 대해 임시조치 등을 취할 수 있습니다.<br/>
                        ③ 본 조에 따른 세부절차는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』 및 『저작권법』이 규정한 범위 내에서 ‘회사’가 정한 ‘게시중단요청서비스’에 따릅니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 14 조 (면책조항)
                        <br/>
                        ① ‘회사’는 천재지변 또는 이에 준하는 불가항력으로 인하여 ‘서비스’를 제공할 수 없는 경우에는 ‘서비스’ 제공에 관한 책임이 면제됩니다.<br/>
                        ② ‘회사’는 회원의 귀책사유로 인한 ‘서비스’ 이용의 장애에 대하여 책임을 지지 않습니다.<br/>
                        ③ ‘회사’는 회원이 ‘서비스’를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며, 그 밖의 ‘서비스’를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.<br/>
                        ④ ‘회사’는 회원이 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관해서는 책임을 지지 않습니다.<br/>
                        ⑤ 회원이 발송한 메일 내용에 대한 법적인 책임은 사용자에게 있습니다.<br/>
                        ⑥ ‘회사’는 회원간 또는 회원과 제3자 상호간에 ‘서비스’를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다<br/>
                        ⑦ ‘회사’는 무료로 제공되는 ‘서비스’ 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 15 조 (분쟁해결)
                        <br/>
                        ① ‘회사’는 회원이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위해서 피해보상처리 기구를 설치, 운영합니다.<br/>
                        ② ‘회사’는 회원으로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 즉시 통보합니다.<br/>
                    </p>
                    <p>
                        <br/>
                        제 16 조 (준거법 및 관할법원)
                        <br/>
                        ① 이 약관의 해석 및 ‘회사’와 회원간의 분쟁에 대하여는 대한민국의 법을 적용합니다.<br/>
                        ② ‘서비스’ 이용 중 발생한 회원과 ‘회사’간의 소송은 민사소송법에 의한 관할법원에 제소합니다.<br/>
                    </p>
                    <p>
                        <br/>
                        <strong>부칙</strong>
                        현재 이용약관 고지일자 : 2023년 2월 n일<br/>
                        현재 이용약관 시행일자 : 2023년 2월 n+7일<br/>
                    </p>
                </div>
            )}{' '}
        </div>
    );
};

export default TermsContent;
