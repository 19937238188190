import React, { useContext, useState } from 'react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import '../ChangePaymentMethod/ChangePaymentMethod.scss';
import { fetchAndReceiveJSON } from '../../utils/ajaxUtils';
import { CANCEL_ORDER_URL } from '../../constants/constants';
import { openChannelIO } from '../../utils/commonUtils';

const CancelConfirm = ({ setIsShowPopup, orderId, fetchParentData }) => {
    const { rootStore, accountStore } = useContext(AppContext);
    const { setIsSpinnerOn } = rootStore;
    const { requestFetch } = accountStore;

    const [isStepTwo, setIsStepTwo] = useState(false);

    const cancelReqeust = async () => {
        setIsSpinnerOn(true);

        const resData = await fetchAndReceiveJSON({
            url: CANCEL_ORDER_URL,
            method: 'POST',
            body: {
                orderId: orderId,
                reason: '',
            },
        });

        //성공 시
        if (resData) {
            setIsStepTwo(true);

            fetchParentData();

        } else {
            alert(
                '취소 요청에 실패 했습니다. 채팅 상담 문의 부탁합니다.'
            );
            
            //실패 처리
            openChannelIO();

            setIsShowPopup(false);
        }

        setIsSpinnerOn(false);
    };

    return (
        <div className="cancelConfirmMethodWrapper">
            <div className="topArea">
                {!isStepTwo && (
                    <div className="title center">
                        주문을 환불 처리하시겠습니까?
                    </div>
                )}

                {isStepTwo && (
                    <div className="title center">
                        환불 처리가 완료되었습니다. 최종 환급은 카드사 또는 결제
                        대행사의 동작에 의해 영업일 기준 2~5일 소요될 수
                        있습니다.
                    </div>
                )}
            </div>

            <div className="cancelBtns">
                {!isStepTwo && (
                    <>
                        <div
                            className="btn marginTop black"
                            onClick={() => {
                                setIsShowPopup(false);
                            }}
                        >
                            취소
                        </div>
                        <div className="btn marginTop" onClick={cancelReqeust}>
                            확인
                        </div>
                    </>
                )}

                {isStepTwo && (
                    <div
                        className="btn marginTop"
                        onClick={() => {
                            setIsShowPopup(false);
                        }}
                    >
                        확인
                    </div>
                )}
            </div>
        </div>
    );
};

export default CancelConfirm;
