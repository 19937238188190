import { DOG_FOOD_CATEGORY_ID } from '../constants/constants';

export const isDogFoodProduct = (product) => {
    // console.log(product);

    const dogFoodCategory = product?.categories?.find(
        (category) => category.id === DOG_FOOD_CATEGORY_ID
    );
    if (isSubscriptionProduct(product) && dogFoodCategory) {
        return true;
    }
    return false;
};

export const isSubscriptionProduct = (product) => {
    return (
        product?.type === 'variable-subscription' ||
        isSimpleSubscription(product)
    );
};

export const isSimpleSubscription = (product) => {
    return product?.type === 'subscription';
};
