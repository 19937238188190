export const BB_ITEMS = {
    name: '행동 발달 토이 1개, 미니 토이 1개, 테마 생활용품 1개',
};
export const BB_LITE_ITEMS = {
    name: '행동 발달 토이 1개, 반려견 맞춤 간식 1개',
};

export const SANTA_BB_ITEMS = {
    name: '산타의 선물 1개, 미니 토이 1개, 테마 생활용품 1개',
};

export const SANTA_BB_LITE_ITEMS = {
    name: '산타의 선물 1개, 반려견 맞춤 간식 1개',
};
