import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {observer} from 'mobx-react';
import {AppContext} from '../../contexts/AppContext/AppContext';
import {useParams, useHistory} from 'react-router-dom';
import CollectionProductsContainer from '../../containers/CollectionProductsContainer/CollectionProductsContainer';
import CollectionTopContentContainer
    from '../../containers/CollectionTopContentContainer/CollectionTopContentContainer';
import './CollectionPage.scss';
import {fetchAndReceiveJSON} from '../../utils/ajaxUtils';
import {isAddedSubcollectionParameter} from '../../utils/commonUtils';
import {
    COLLECTION_DETAIL_DATA_URL,
    DOG_FOOD_COLLECTION_SLUG,
    HUNDRED_SHOP_SLUG,
    SANTA_COLLECTION_SLUG,
} from '../../constants/constants';
import {throttle} from 'lodash';

let lastSort;
let currentPaging = 1;
let lastSelectedCategoryId = 0;

const CollectionPage = observer(() => {
    let history = useHistory();
    const {rootStore, collectionPageStore} = React.useContext(AppContext);
    const {initData, setCurrentTarget} = rootStore;
    const {
        collectionData,
        setCollectionData,
        curSort,
        setCurSort,
        currentSelectedCategory,
        setCurrentSelectedCategory,
        setIsSortingBoxOpen,
        currentTargetCategoryId,
        setCurrentTargetCategoryId,
    } = collectionPageStore;

    const [lastSlug, setLastSlug] = useState<string>(null);
    const [isSubcollectionParameter, setIsSubcollectionParameter] = useState(
        // history.location.search.split('=')[0] === '?subcollection'
        isAddedSubcollectionParameter(history.location.search)
    );

    let lastScrollPosition = 0;
    let {slug} = useParams<{
        slug: string;
    }>();

    const fetchSubCollectionData = (page) => {
        const fetchInitData = async (page) => {
            const res = await fetchAndReceiveJSON({
                url: `${COLLECTION_DETAIL_DATA_URL}?slug=${slug}&page=${page}${
                    lastSort === 'basic' ? '' : `&type=${lastSort}`
                }`,
            });

            if (!res) {
                history.push('/404');
                return;
            }

            const categoryId =
                res &&
                res.contents.subCollections.filter(
                    (sub) =>
                        sub.slug ===
                        history.location.search
                            .split('?')[1]
                            .split('&')
                            .filter(
                                (param) =>
                                    param.split('=')[0] === 'subcollection'
                            )[0]
                            .split('=')[1]
                )[0];

            if (!categoryId) {
                history.push('/404');
                return;
            }

            // setIsSubcollectionParameter(true);
            setCurrentSelectedCategory(categoryId);
            // setCurrentTargetCategoryId(categoryId.id);
            lastSelectedCategoryId = categoryId.id;

            return categoryId.id;
        };

        fetchInitData(page).then(async (categoryId) => {
            const res = await fetchAndReceiveJSON({
                url: `${COLLECTION_DETAIL_DATA_URL}?slug=${slug}&page=${page}${
                    lastSort === 'basic' ? '' : `&type=${lastSort}`
                }&categoryId=${categoryId}`,
            });
            if (!res) {
                history.push('/404');
                return;
            }
            if (currentPaging === 1) {
                setCollectionData(res);
            } else {
                if (collectionData && slug === collectionData.slug) {
                    setCollectionData({
                        ...collectionData,
                        contents: {
                            ...collectionData.contents,
                            products: [
                                ...collectionData.contents.products,
                                ...res.contents.products,
                            ],
                        },
                        pageinfo: {
                            ...collectionData.pageinfo,
                            ...res.pageinfo,
                        },
                    });
                }
            }
        });

        setIsSubcollectionParameter(false);
    };

    const fetchData = async (page) => {
        if (lastSelectedCategoryId !== 0) {
            const res = await fetchAndReceiveJSON({
                url: `${COLLECTION_DETAIL_DATA_URL}?slug=${slug}&page=${page}${
                    lastSort === 'basic' ? '' : `&type=${lastSort}`
                }&categoryId=${lastSelectedCategoryId}`,
            });

            if (!res) {
                history.push('/404');
                return;
            }

            if (currentPaging === 1) {
                setCollectionData(res);
            } else {
                if (collectionData && slug === collectionData.slug) {
                    setCollectionData({
                        ...collectionData,
                        contents: {
                            ...collectionData.contents,
                            products: [
                                ...collectionData.contents.products,
                                ...res.contents.products,
                            ],
                        },
                        pageinfo: {
                            ...collectionData.pageinfo,
                            ...res.pageinfo,
                        },
                    });
                }
            }

            return res;
        } else {
            const res = await fetchAndReceiveJSON({
                url: `${COLLECTION_DETAIL_DATA_URL}?slug=${slug}&page=${page}${
                    lastSort === 'basic' ? '' : `&type=${lastSort}`
                }`,
            });

            if (!res) {
                history.push('/404');
                return;
            }
            if (currentPaging === 1) {
                setCollectionData(res);
            } else {
                if (collectionData && slug === collectionData.slug) {
                    setCollectionData({
                        ...collectionData,
                        contents: {
                            ...collectionData.contents,
                            products: [
                                ...collectionData.contents.products,
                                ...res.contents.products,
                            ],
                        },
                        pageinfo: {
                            ...collectionData.pageinfo,
                            ...res.pageinfo,
                        },
                    });
                }
            }

            return res;
        }
    };

    useEffect(() => {
        if (lastSlug !== slug) {
            currentPaging = 1;
            setLastSlug(slug);
            setCurrentTarget({slug});

            lastSort = curSort;
            lastSelectedCategoryId = 0;
            setIsSortingBoxOpen(false);
            setCurrentSelectedCategory({
                id: 0,
                name: '전체',
                slug: 'all',
            });
        } else {
            if (lastSort !== curSort) {
                currentPaging = 1;
                lastSort = curSort;
                // lastSelectedCategoryId = 0;
            } else if (lastSelectedCategoryId !== currentSelectedCategory.id) {
                currentPaging = 1;
                lastSelectedCategoryId = currentSelectedCategory.id;
                //subCategory에 따른 url 변경
                if (currentSelectedCategory.id !== 0) {
                    history.location.search = `?subcollection=${currentSelectedCategory.slug}`
                    window.history.pushState("", "",
                        `${history.location.pathname}?subcollection=${currentSelectedCategory.slug}`,
                    )
                } else {
                    history.location.search = '';
                    window.history.pushState("", "", `${history.location.pathname}`)
                }
                const topHeader = document.getElementById('topHeader')
                const collectionProductsContainer = document.querySelector('.CollectionTopContentContainer').clientHeight
                window.scrollTo(0, collectionProductsContainer - topHeader.offsetTop)
            }
        }

        if (
            history.location.search.split('=')[0] === '?subcollection' &&
            isSubcollectionParameter
        ) {
            fetchSubCollectionData(currentPaging);
        } else {
            fetchData(currentPaging);
        }
        window.history.scrollRestoration = 'auto';

    }, [slug, curSort, currentTargetCategoryId]);

    const handleNavigationPositionForScroll = () => {
        const navigation: HTMLElement =
            document.getElementById('subCollectionNav');
        const header: HTMLDivElement = document.querySelector('.headerArea');
        const appTopArea: HTMLDivElement =
            document.querySelector('.appTopArea');

        const curScroll = window.scrollY;
        const adjustPoint = 2;
        if (navigation !== undefined && navigation !== null) {
            navigation.style.top =
                lastScrollPosition < curScroll
                    ? `${
                        appTopArea.getBoundingClientRect().height -
                        header.getBoundingClientRect().height -
                        adjustPoint
                    }px`
                    : `${appTopArea.scrollHeight - adjustPoint}px`;
        }

        lastScrollPosition = curScroll;
    };

    useEffect(() => {
        const throttleInner = throttle(() => {
            handleNavigationPositionForScroll();
        }, 500);

        window.addEventListener('scroll', throttleInner);

        return () => {
            window.removeEventListener('scroll', throttleInner);
        };
    }, [slug]);

    useEffect(() => {
        return () => {
            setCollectionData(null);
        };
    }, []);

    useEffect(() => {
        if (collectionData) {
            const productIds = [];

            for (let i = 0; i < collectionData.contents.products.length; i++) {
                const product = collectionData.contents.products[i];

                if (i <= 2 && product) {
                    productIds.push(product.id);
                } else {
                    break;
                }
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                productList: productIds,
            });

            window.dataLayer.push({
                event: 'dataLayer.append.collection',
            });
        }
    }, [collectionData]);

    return (
        <>
            <Helmet>
                <title>
                    {initData &&
                    initData.collectionList &&
                    initData.collectionList.filter((item) => item.url === slug)
                        .length > 0
                        ? `BACON Store - ${
                            initData.collectionList.filter(
                                (item) => item.url === slug
                            )[0].title
                        }`
                        : slug === DOG_FOOD_COLLECTION_SLUG
                            ? 'BACON Store - 사료 정기배송'
                            : slug === HUNDRED_SHOP_SLUG
                                ? 'BACON Store - 100원의 행복'
                                : slug === SANTA_COLLECTION_SLUG
                                    ? '베이컨이 준비한 산타의 선물'
                                    : '페이지를 찾을 수 없습니다 | 베이컨박스'}
                </title>
            </Helmet>
            {collectionData && (
                <div className="collectionPageDtPositioning">
                    <div className="CollectionPageWrapper">
                        <CollectionTopContentContainer
                            collectionData={collectionData}
                        />
                        {collectionData && (
                            <CollectionProductsContainer
                                collectionData={collectionData}
                            />
                        )}
                    </div>
                    {currentPaging < collectionData.pageinfo.totalPageCnt && (
                        <div
                            onClick={() => {
                                currentPaging = currentPaging + 1;
                                if (
                                    history.location.search.split('=')[0] ===
                                    '?subcollection' &&
                                    isSubcollectionParameter
                                ) {
                                    fetchSubCollectionData(currentPaging);
                                } else {
                                    fetchData(currentPaging);
                                }
                            }}
                            className="moreDataBtn"
                        >
                            더보기
                        </div>
                    )}
                </div>
            )}
        </>
    );
});

export default CollectionPage;
