import { observer } from 'mobx-react';
import React from 'react';
import HomeTopSlider from '../../components/Sliders/HomeTopSlider/HomeTopSlider';
import HomeTopTextArea from '../../components/HomeTopTextArea/HomeTopTextArea';
import { defaultElementProps } from '../../types/types';
import './HomeTopContainer.scss';
import { AppContext } from '../../contexts/AppContext/AppContext';

const HomeTopContainer: React.FunctionComponent<defaultElementProps> = observer(
    ({ className, ...otherProps }) => {
        const { homeStore } = React.useContext(AppContext);
        const { homeData } = homeStore;
        const { homeBanner } = homeData;

        return (
            <div
                className={`homeTopContainerWrapper ${className}`}
                {...otherProps}
            >
                <HomeTopTextArea className={'homeTopTextPositioning'} />
                {/* {console.log(homeBanner.length, 'length')} */}
                <HomeTopSlider
                    // style={{
                    //     height: '100%',
                    // }}
                    // loop={true
                    // width={null}
                    withProgressBar={true}
                    withProgressIndex={true}
                    // totalSlide={homeBanner.length}
                    spaceBetween={16}
                    overFlowVisible={true}
                    // withDefaultPagination={true}
                    // withImagePagination={true}
                    // withDefaultNavigation={true}
                    // customNavigationClass={{ nextEl: '.abc', prevEl: '.bcd' }}
                    // isNavigationAroundProgressBar={true}
                />
            </div>
        );
    }
);

export default HomeTopContainer;
