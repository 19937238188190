import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import NavigationSlider from '../../components/Sliders/NavigationSlider/NavigationSlider';
import {
    BREAKPOINT_DT,
    HIDE_SUB_COLLECTION_PAGE,
    HOME_SLUG,
    SHOW_BACK_BTN_PATHS,
} from '../../constants/constants';
import { AppContext } from '../../contexts/AppContext/AppContext';
import { defaultElementProps } from '../../types/types';
// import { Redirect, withRouter } from 'react-router-dom';
import './NavContainer.scss';
import { useHistory, useLocation } from 'react-router-dom';

const NavContainer: React.FunctionComponent<defaultElementProps> = observer(
    ({ ...otherProps }) => {
        const { rootStore, cartPageStore } = React.useContext(AppContext);
        const { initData, currentTarget, setCurrentTarget } = rootStore;
        const { isCartLoading, cartInfoList, cartListCount } = cartPageStore;
        //const [target, setTarget] = useState({ slug: 'home' });
        let history = useHistory();
        let location = useLocation();
        // 현재 URL을 기준으로 어디에 하이라이트를 주어야 할지 결정
        // useEffect(() => {
        //     //const path = location.pathname.replace(/\//gi, '');
        //     const currentPath = location.pathname;

        //     if (currentPath == '/') {
        //         setCurrentTarget({ slug: HOME_SLUG });
        //     }else {
        //         const lastPath = currentPath.substr(currentPath.lastIndexOf('/')+1);
        //         setCurrentTarget({ slug: lastPath });
        //     }

        //     // if (initData) {
        //     //     if (path === '') {
        //     //         // TOODO: 홈 으로 가는 탭의 slug에 맞추어 줘야 함
        //     //         setTarget({ slug: '/' });
        //     //     } else {
        //     //         const targetFromUrl = initData.topMenu.find((el) => {
        //     //            // console.log(path, el.link, 'lnik');
        //     //             return path.includes(el.link);
        //     //         });

        //     //         console.log("targetFromUrl path : ", targetFromUrl?.slug);
        //     //         setTarget({ slug: targetFromUrl?.slug });
        //     //     }
        //     // }
        // }, []);

        const path = SHOW_BACK_BTN_PATHS.find((path) =>
            location.pathname.includes(path)
        );
        const isHideNavPage =
            history.length > 0 &&
            path &&
            !location.pathname.includes('/store/collection/');

        //checkout , login page
        const isHide = HIDE_SUB_COLLECTION_PAGE.find((path) =>
            location.pathname.includes(path)
        );

        const isEmptyCart =
            !isCartLoading && (!cartInfoList || cartListCount === 0);

        return (
            <div
                className={`navContainerWrapper ${
                    isHideNavPage ? 'mobileHide' : ''
                } ${
                    (location.pathname === '/checkout' && !isEmptyCart) ||
                    location.pathname === '/login'
                        ? 'hide'
                        : ''
                }`}
                id="navContainer"
            >
                {initData && (
                    <div className="navContainerPosition">
                        {/* {console.log(initData.topMenu, 'topmenu')} */}
                        <NavigationSlider
                            className="navContainerSlider"
                            //slideTargetOnResize={0}
                            withTargetUnderBar={true}
                            target={currentTarget}
                            changeTargetFunction={setCurrentTarget}
                            slideOnClickFunction={(arg, e) => {
                                history.push(arg);
                            }}
                            slideData={initData.topMenu}
                            //overFlowVisible={true}
                            spaceBetween={20}
                            breakpoints={{
                                [BREAKPOINT_DT]: { spaceBetween: 32 },
                            }}
                        />
                        <div className="fade" />
                    </div>
                )}
            </div>
        );
    }
);

export default NavContainer;
