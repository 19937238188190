import React, { useState } from 'react';
// import { useParams } from 'react-router';
import DaumPostcode from 'react-daum-postcode';
import { defaultElementProps } from '../../types/types';
import './SearchAddressPopup.scss';

interface ISearchAddressPopup extends defaultElementProps {
    setOpenSearchAddressPopup: any;
    addressInfoValues?: any;
    setAddressInfoValues?: any;
    userInfoValues?: any;
    setUserInfoValues?: any;
    isCartComponent?: boolean;
    loginModal?: boolean;
    setCompanyAddress1?: any;
    setCompanyPostcode?: any;
}

const SearchAddressPopup: React.FunctionComponent<ISearchAddressPopup> = ({
    setOpenSearchAddressPopup,
    addressInfoValues,
    setAddressInfoValues,
    userInfoValues,
    setUserInfoValues,
    isCartComponent,
    loginModal,
    setCompanyAddress1,
    setCompanyPostcode,
}) => {
    // const { slug } = useParams<{ slug: string }>();

    const onCompletePost = (data) => {
        let fullAddr = data.address;
        let extraAddr = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddr += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddr +=
                    extraAddr !== ''
                        ? `, ${data.buildingName}`
                        : data.buildingName;
            }
            fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
        }

        // if (slug === '/my-account/profile') {

        // } else {

        // }

        if (isCartComponent) {
            setUserInfoValues({
                ...userInfoValues,
                postcode: data.zonecode,
                address_1: fullAddr,
            });
        }

        if (loginModal) {
            setCompanyPostcode(data.zonecode);
            setCompanyAddress1(fullAddr);
        }

        if (!isCartComponent && !loginModal) {
            setAddressInfoValues({
                ...addressInfoValues,
                postcode: data.zonecode,
                address_1: fullAddr,
            });
        }

        setOpenSearchAddressPopup(false);
    };

    const postCodeStyle = {
        marginTop: '42px',
        display: 'block',
        width: '100%',
        height: '100%',
    };

    return (
        <div
            className={`postcodeWrapper ${
                isCartComponent ? 'cart' : loginModal ? 'login' : ''
            }`}
        >
            <img
                onClick={() => setOpenSearchAddressPopup(false)}
                src="/img/common-btn-popup-close.svg"
                alt="close-popup-button"
            />
            <DaumPostcode
                style={postCodeStyle}
                autoClose
                onComplete={onCompletePost}
            />
        </div>
    );
};

export default SearchAddressPopup;
