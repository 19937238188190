import {observer} from 'mobx-react';
import {useEffect, useState} from 'react';
import PriceAndDiscountDisplay from '../PriceAndDiscountDisplay/PriceAndDiscountDisplay';
import {CircleSelection} from '../SVGs/Svgs';
import './SelectableOptionItem.scss';

const SelectableOptionItem = observer(
    ({
         thumbNail,
         name,
         price,
         regularPrice,
         slug,
         isSelected = false,
         isOnSale = true,
         ...otherProps
     }) => {
        const [verifiedThumbNail, setVerifiedThumbnail] = useState(thumbNail);

        useEffect(() => {
            if ((slug === "bb-snack-count-two" || slug === "snack_two") && !thumbNail) {
                setVerifiedThumbnail("/img/2snack-thumbnail@2x.png");
            } else if ((slug === "bb-snack-count-four" || slug === "snack_four") && !thumbNail) {
                setVerifiedThumbnail("img/4snack-thumbnail@2x.png");
            } else if (slug === "bb-snack-count-zero") {
                setVerifiedThumbnail("img/remove-img@2x.png");
            }
        }, [slug])

        return (
            <div
                className={`selectableOption ${isSelected ? 'selected' : ''}`}
                {...otherProps}
            >
                <img
                    className="selectableOptionThumbnail"
                    src={verifiedThumbNail}
                    alt=""
                />
                <div className={`optionName ${isSelected ? 'selected' : ''}`}>
                    {name}
                </div>
                <div className="stickToRight">
                    {price !== '0원' && (
                        <PriceAndDiscountDisplay
                            salePrice={price}
                            regularPrice={isOnSale ? regularPrice : ''}
                            right
                        />
                    )}

                    <div className="circleSelection">
                        <CircleSelection
                            color={isSelected ? '#111111' : '#DDD'}
                        />
                    </div>
                </div>
            </div>
        );
    }
);


export default SelectableOptionItem;
