import React, { useContext, useState } from 'react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import '../ChangePaymentMethod/ChangePaymentMethod.scss';
import { fetchAndReceiveJSON, logout } from '../../utils/ajaxUtils';
import { openChannelIO } from '../../utils/commonUtils';
import { DELETE_ACCOUNT_URL } from '../../constants/constants';
import { RESPONSE_UNAVAILABLE_UNSUBSCRIPTION_HAS_ACTIVE_SUBSCRIPTION } from '../../constants/errorConstants';
import { useHistory } from 'react-router-dom';

const DeleteAccount = ({ setIsShowPopup }) => {
    const { rootStore, accountStore } = useContext(AppContext);
    const { setIsSpinnerOn } = rootStore;
    // const { requestFetch } = accountStore;
    const [isAgree, setIsAgree] = useState(false);
    const [deleteAccountPopupFlow, setDeleteAccountPopupFlow] =
        useState('wait');

    let history = useHistory();

    const deleteAccount = async () => {
        setIsSpinnerOn(true);

        const res = await fetchAndReceiveJSON({
            url: DELETE_ACCOUNT_URL,
            method: 'POST',
        });

        if (
            res === RESPONSE_UNAVAILABLE_UNSUBSCRIPTION_HAS_ACTIVE_SUBSCRIPTION
        ) {
            setIsSpinnerOn(false);
            return setDeleteAccountPopupFlow('subscribedUserInfo');
        }

        //성공
        if (res !== undefined) {
            //탈퇴 후 동작

            //로그 아웃
            logout();

            //홈으로 이동
            // history.push('/');
        }

        // setIsShowPopup(false);
        setIsSpinnerOn(false);
    };

    return (
        <div className="deleteAccountMethodWrapper">
            <div className="topArea">
                <div className="title center">
                    {deleteAccountPopupFlow === 'wait' && `고객님 잠깐만요!!`}
                    {deleteAccountPopupFlow === 'deleteAccountInfo' &&
                        `회원 탈퇴 안내`}
                </div>

                <div
                    className={`description ${
                        deleteAccountPopupFlow === 'deleteAccountInfo'
                            ? ''
                            : 'center'
                    }`}
                >
                    {deleteAccountPopupFlow === 'wait' && (
                        <span>
                            만약 정기배송의 해지를 원하신다면, <br />
                            '내 정기배송' 메뉴에서 취소가 가능합니다. <br />
                            회원 탈퇴 시 향후 일정 기간 내 <br />
                            동일한 아이디로 재가입이 불가능하니, 참고해주세요!
                        </span>
                    )}
                    {deleteAccountPopupFlow === 'deleteAccountInfo' && (
                        <span>
                            [회원탈퇴 약관]
                            <br />
                            회원탈퇴 신청 전 안내 사항을 확인 해 주세요.
                            <br />
                            회원탈퇴를 신청하시면 현재 로그인 된 아이디는
                            사용하실 수 없습니다.
                            <br />
                            회원탈퇴를 하더라도, 서비스 약관 및 개인정보
                            취급방침 동의하에 따라 일정 기간동안 회원 개인정보를
                            보관합니다.
                            <br />
                            - 회원 정보
                            <br />
                            - 상품 구입 및 대금 결제에 관한 기록
                            <br />
                            - 상품 배송에 관한 기록
                            <br />
                            - 소비자 불만 또는 처리 과정에 관한 기록
                            <br />
                            - 게시판 작성 및 사용후기에 관한 기록
                            <br />※ 상세한 내용은 사이트 내 개인정보 취급방침을
                            참고 해 주세요.
                        </span>
                    )}
                    {deleteAccountPopupFlow === 'subscribedUserInfo' && (
                        <span>
                            현재 고객님께서는 약정 기간이 포함된 정기배송을
                            이용중이며,
                            <br />
                            해당 정기배송의 종료 또는 해지 이후 회원 탈퇴가
                            가능합니다.
                            <br />
                            정기배송 및 계정 탈퇴와 관련하여 궁금하다면,
                            <br />
                            채팅 상담으로 문의해주세요.
                        </span>
                    )}
                </div>

                <div className="cancelReasonArea">
                    {deleteAccountPopupFlow === 'deleteAccountInfo' && (
                        <div className="cancelReasons">
                            <div
                                className={`cancelReason ${
                                    isAgree ? 'on' : ''
                                }`}
                            >
                                <div
                                    className="cancelReasonBtn"
                                    onClick={() => {
                                        setIsAgree(!isAgree);
                                    }}
                                />
                                <div className="cancelReasonTexts">
                                    <div className="cancelReasonText">
                                        동의합니다.
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="cancelBtns">
                {deleteAccountPopupFlow === 'subscribedUserInfo' ? (
                    <div
                        className="btn marginTop"
                        onClick={() => {
                            openChannelIO();
                        }}
                    >
                        상담 문의하기
                    </div>
                ) : (
                    <>
                        <div
                            className="btn marginTop black"
                            onClick={() => {
                                if (isAgree) {
                                    deleteAccount();
                                } else {
                                    setDeleteAccountPopupFlow(
                                        'deleteAccountInfo'
                                    );
                                }
                            }}
                        >
                            탈퇴하기
                        </div>
                        <div
                            className="btn marginTop"
                            onClick={() => {
                                setIsShowPopup(false);
                            }}
                        >
                            패밀리 유지하기
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default DeleteAccount;
