import {computed, makeAutoObservable, observable, runInAction} from 'mobx';
import {addComma} from '../../utils/commonUtils';
import {optionProduct, product} from '../../types/types';
import {fetchAndReceiveJSON} from '../../utils/ajaxUtils';
import {
    ADD_CART_URL,
    ADD_CART_BUY_NOW,
    // COOKIE_BUYNOW_CART_NAME,
    DOG_FOOD_CART_MAX_CNT,
    CUSTOM_POPUP_BTN_OPEN_CHANNEL_IO,
    CUSTOM_POPUP_ALREADY_HAS_DOGFOOD_IN_CART_TITLE,
    CUSTOM_POPUP_DOG_FOOD_OVER_MAX_CNT_OVER_TITLE,
    CUSTOM_POPUP_DOG_FOOD_OVER_MAX_CNT_OVER_DESC,
    CUSTOM_POPUP_ALREADY_HAS_DOGFOOD_IN_CART_DESC,
    REMOVE_SLUG_ITEM_AND_ADD_CART_URL,
    DOG_FOOD_PRODUCT_CLUSTER,
    CUSTOM_POPUP_ALREADY_HAS_DOGFOOD_SUBSCIRPTION_TITLE,
    CUSTOM_POPUP_ALREADY_HAS_DOGFOOD_SUBSCIRPTION_DESC,
    PRODUCT_TYPE_VARIABLE_SUBSCRIPTION,
    PRODUCT_TYPE_SUBSCRIPTION,
} from '../../constants/constants';
import rootStore from '../RootStore/RootStore';
import {toJS} from 'mobx';
import {isDogFoodProduct} from '../../utils/miniCartUtils';
import {RESPONSE_ALREADY_HAS_DOGFOOD, RESPONSE_DOGFOOD_PRODUCT_LIMIT_COUNT} from '../../constants/errorConstants';

const defaultTempOptionProduct = {
    option: {},
    quantity: 1
};
;

class MiniCartStore {
    rootStore: rootStore;
    isMiniCartPopup: Boolean = false;
    isNpayClicked: Boolean = false;
    miniCartProduct: product = {
        id: 0,
        type: 'simple',
        name: '',
        regularPrice: '',
        price: '',
        salePrice: '',
        discountPrice: 0,
        discountRate: '',
        isOnSale: false,
        promoText: '',
        customText: '',
        images: [],
        categories: [],
        productVariation: [],
        attributes: [],
        isNpayAvailable: false,
        subscriptionDiscountInfo: null,
    };

    optionMap: Map<string, any> = new Map<string, any>();
    miniCartProductQty = 1;
    optionProducts: Array<optionProduct> = [];
    tempOptionProduct: optionProduct = defaultTempOptionProduct;
    miniCartPrice: number = 0;
    cartSpinnerOn: boolean = false;
    isFollowPopup: boolean = false;
    keys: Array<string> = [];
    isExpand: boolean = false;

    subscriptionDiscountInfo: any = null;

    constructor(rootStore) {
        makeAutoObservable(this);

        this.rootStore = rootStore;
    }

    setIsMiniCartPopup = (isMiniCartPopup) => {
        this.isMiniCartPopup = isMiniCartPopup;
    };

    setIsNpayClicked = (isNpayClicked) => {
        this.isNpayClicked = isNpayClicked;
    };

    setMiniCartProduct = (miniCartProduct) => {

        //add comma
        //TODO 수정
        miniCartProduct.price = addComma(miniCartProduct.price);
        miniCartProduct.regularPrice = addComma(miniCartProduct.regularPrice);

        if (!miniCartProduct.isNpayAvailable) {
            miniCartProduct.isNpayAvailable = false;
        }

        this.miniCartProduct = miniCartProduct;
        this.setMiniCartProductQty(1);

        this.setOptionProducts([]);

        //init optionMap
        this.initOptionMap();

        //init Keys
        this.setKeys([]);

        this.setSubscriptionDiscountInfo(null);

        // if(miniCartProduct.type === "subscription") {
        //     this.setSubscriptionDiscountInfo(miniCartProduct.subscriptionDiscountInfo);
        // }
    };

    setSubscriptionDiscountInfo = (subscriptionDiscountInfo) => {
        this.subscriptionDiscountInfo = subscriptionDiscountInfo;
    }

    initOptionMap = () => {

        let optionMap = new Map<string, any>();

        if (this.miniCartProduct?.type.includes('variable')) {

            this.miniCartProduct.productVariation?.map((variation) => {
                // console.log("variation" , variation);
                let prevMap: Map<string, any> = optionMap;
                variation.attributes.map((attribute, idx) => {

                    //create Node
                    if (!prevMap.has(attribute.option)) {

                        let defaultVirtualProduct = {
                            stock_status: 'instock',
                            option: attribute.option,
                        };

                        let node = {
                            product: defaultVirtualProduct,
                            node: null
                        }

                        if (idx !== variation.attributes.length - 1) {

                            //마지막 노드가 아니면 node 가짐
                            node.node = new Map();

                        } else {

                            //마지막 노드의 product = product_variation
                            variation.option = variation.attributes[
                            variation.attributes.length - 1]?.option;
                            node.product = variation;

                        }

                        prevMap.set(attribute.option, node);
                    }

                    prevMap = prevMap.get(attribute.option).node;
                });

            });

            //최저가 세팅
            this.miniCartProduct.productVariation?.map((variation) => {

                let prevMap: any = optionMap;
                // console.log(toJS(optionMap));

                variation.attributes.map((attribute) => {

                    // console.log(prevMap);
                    const node = prevMap.node ? prevMap.node.get(attribute.option) : prevMap.get(attribute.option);
                    // console.log(attribute.option , toJS(node));
                    if (node && node.product) {

                        // console.log(attribute.option , toJS(node));

                        if (node.product.price && node.product.price !== variation.price) {
                            node.product.isMultiPrice = true;
                        }

                        if (!node.product.price || node.product.price > variation.price) {
                            node.product.price = variation.price;
                        }

                        if (node.product.regular_price && node.product.regular_price !== variation.regular_price) {
                            node.product.isMultiRegularPrice = true;
                        }

                        if (!node.product.regular_price || node.product.regular_price > variation.regular_price) {
                            node.product.regular_price = variation.regular_price;
                        }
                    }

                    prevMap = node;
                });

            });

        }

        this.optionMap = optionMap;
        // console.log("final optionMap : ", toJS(this.optionMap));
    };

    setMiniCartProductQty = (qty) => {
        // console.log(qty);
        this.miniCartProductQty = qty;

        if (this.miniCartProduct.type === 'bundle') {
            this.setOptionProducts(this.getSynchronizeOptionProducts());
        }
    };

    getSynchronizeOptionProducts = () => {
        let qty = this.miniCartProductQty;
        let newOptionProducts = Array<any>();
        this.optionProducts.map((option) => {
            option.quantity = qty;

            newOptionProducts.push(option);
        });

        return newOptionProducts;
    };

    setOptionProducts = (optionProucts) => {
        console.log("setOptionProducts !!");
        if (this.miniCartProduct.type === 'bundle') {
            let newOptionProucts: Array<any> = [];
            optionProucts.map((optionProduct) => {
                optionProduct.quantity = this.miniCartProductQty;

                newOptionProucts.push(optionProduct);
            });

            this.optionProducts = newOptionProucts;
        } else {
            this.optionProducts = optionProucts;
        }

        this.calculatePrice();

        // console.log(this.miniCartProduct.type);

        //단순 , 옵션 정기결제 2회차 데이터 처리
        if (this.miniCartProduct.type === PRODUCT_TYPE_VARIABLE_SUBSCRIPTION && optionProucts) {
            this.setSubscriptionDiscountInfo(optionProucts[0]?.option.subscriptionDiscountInfo);
        } else if (this.miniCartProduct.type === PRODUCT_TYPE_SUBSCRIPTION) {
            this.setSubscriptionDiscountInfo(this.miniCartProduct.subscriptionDiscountInfo);
        }
    };

    changeOptionProductQty = (targetOptionProduct, operator) => {

        let newOptionProducts = [];
        this.optionProducts.map((optionProduct) => {
            // console.log(optionProduct);

            if (optionProduct === targetOptionProduct) {
                if (optionProduct.option.price === "100" && operator === "plus") {
                    window.alert('해당 상품의 최대 구매 가능 갯수는 1개입니다.')
                } else {
                    let afterQty = operator === 'plus' ? optionProduct.quantity + 1 : optionProduct.quantity - 1;

                    if (isDogFoodProduct(this.miniCartProduct)) {
                        afterQty = this.validDogFoodQty(afterQty);
                    }

                    if (this.validMiniCartQty(afterQty)) {
                        // console.log("valid Qty ")
                        optionProduct.quantity = afterQty;
                    }
                }
            }

            newOptionProducts.push(optionProduct);
        });

        this.setOptionProducts(newOptionProducts);
        this.calculatePrice();
    };

    removeOptionProduct = (targetOptionProduct) => {
        let targetIdx = 0;

        let idx = 0;
        this.optionProducts.forEach((optionProduct) => {
            if (optionProduct.option.id === targetOptionProduct.option.id) {
                targetIdx = idx;
            }
            idx++;
        });

        // console.log(targetIdx);
        this.optionProducts.splice(targetIdx, 1);
        this.calculatePrice();

        if (this.miniCartProduct.type === PRODUCT_TYPE_VARIABLE_SUBSCRIPTION || this.miniCartProduct.type === PRODUCT_TYPE_SUBSCRIPTION) {
            this.setSubscriptionDiscountInfo(null);
        }
    };

    setMiniCartPrice = (price) => {
        this.miniCartPrice = price;
    };

    setIsFollowPopup = (isFollowPopup) => {
        this.isFollowPopup = isFollowPopup;
    };

    changeMiniCartQty = (event) => {
        // console.log("changeMiniCartQty", event);
        if (event.target.alt === "plus" && this.miniCartProduct.price === "100") {
            return window.alert('해당 상품의 최대 구매 가능 갯수는 1개입니다.')
        }
        let afterQty =
            event.target.alt === 'minus'
                ? this.miniCartProductQty - 1
                : this.miniCartProductQty + 1;

        if (isDogFoodProduct(this.miniCartProduct)) {

            //사료 maxcnt
            if (afterQty > DOG_FOOD_CART_MAX_CNT) {
                return;
            }

        } else if (this.validMiniCartQty(afterQty) === false) {
            return;
        }

        this.setMiniCartProductQty(afterQty);
        this.calculatePrice();
    };

    calculatePrice = () => {
        // console.log('cal !', toJS(this.miniCartProduct));
        let price: number = 0;

        //main
        if (
            !this.miniCartProduct.type.includes('variable') && (this.miniCartProduct.type !== 'subscription' && isDogFoodProduct) &&
            this.miniCartProduct.type !== 'grouped'
        ) {
            if (
                this.miniCartProduct &&
                this.miniCartProduct.price
            ) {
                if (typeof this.miniCartProduct.price == 'string') {
                    price +=
                        parseFloat(
                            this.miniCartProduct.price.replace(/,/g, '')
                        ) * this.miniCartProductQty;

                } else if (typeof this.miniCartProduct.price == 'number') {
                    price +=
                        this.miniCartProduct.price * this.miniCartProductQty;
                }
            }
        }

        //options
        this.optionProducts.forEach((optionProduct) => {
            // console.log("selectedOptiopns" , toJS(optionProduct));

            if (this.miniCartProduct.type === 'bundle') {
                price +=
                    this.miniCartProduct.bundled_items_discount[
                        optionProduct.option.id
                        ] * optionProduct.quantity;
            } else {

                let optionPrice = optionProduct.option.display_price ? optionProduct.option.display_price : optionProduct.option.price;
                if (typeof optionPrice == 'string') {

                    price +=
                        parseFloat(
                            optionPrice.replace(/,/g, '').replace("원", "")
                        ) * optionProduct.quantity;
                } else if (typeof optionPrice == 'number') {

                    price += optionPrice * optionProduct.quantity;
                }

            }
        });

        // console.log("calculated " , addComma(price));

        this.setMiniCartPrice(addComma(price));
    };

    validMiniCartQty = (qty: number) => {

        if (isDogFoodProduct(this.miniCartProduct)) {

            //사료 maxcnt
            if (qty > DOG_FOOD_CART_MAX_CNT) {
                return false;
            }

        }

        return qty > 0 && qty < 100;
    };

    removeDogFoodAndAddDogFood = async () => {

        let dataInfo: Array<any> = this.getCartAddData();

        if (dataInfo && dataInfo.length > 0) {

            this.rootStore.setIsSpinnerOn(true);

            let res = await fetchAndReceiveJSON({
                url: REMOVE_SLUG_ITEM_AND_ADD_CART_URL + DOG_FOOD_PRODUCT_CLUSTER,
                method: 'POST',
                body: dataInfo,
            });

            this.setIsMiniCartPopup(false);
            this.rootStore.setIsSpinnerOn(false);

            if (res && res.length > 0) {
                //성공
                this.setIsFollowPopup(true);
                this.setOptionProducts([]);
            }
        }

    }

    addItemToCart = async () => {
        //const [cookies, setCookie, removeCookie] = useCookies([COOKIE_BUYNOW_CART_NAME]);

        let dataInfo: Array<any> = this.getCartAddData();

        // console.log(toJS(dataInfo));

        if (dataInfo && dataInfo.length > 0) {
            //카트에 아이템 추가하기
            this.rootStore.setIsSpinnerOn(true);

            //removeCookie(COOKIE_BUYNOW_CART_NAME);

            let res = await fetchAndReceiveJSON({
                url: ADD_CART_URL,
                method: 'POST',
                body: dataInfo,
            });

            // console.log(res);

            this.setIsMiniCartPopup(false);
            this.rootStore.setIsSpinnerOn(false);

            if (res === RESPONSE_DOGFOOD_PRODUCT_LIMIT_COUNT) {
                //custom alert
                this.rootStore.setCommonPopupContents(
                    CUSTOM_POPUP_ALREADY_HAS_DOGFOOD_IN_CART_TITLE,
                    CUSTOM_POPUP_ALREADY_HAS_DOGFOOD_IN_CART_DESC,
                    [
                        CUSTOM_POPUP_BTN_OPEN_CHANNEL_IO,
                        {
                            buttonName:
                                '이 제품 구매하기',
                            bgColor:
                                '#ed2e1b',
                            clickFunc: this.removeDogFoodAndAddDogFood,
                            customText:
                                '기존 담은 제품 제거하고 이 제품으로 구매하시겠어요?'
                        }
                    ]
                );

            } else if (res === RESPONSE_ALREADY_HAS_DOGFOOD) {
                this.rootStore.setCommonPopupContents(
                    CUSTOM_POPUP_ALREADY_HAS_DOGFOOD_SUBSCIRPTION_TITLE,
                    CUSTOM_POPUP_ALREADY_HAS_DOGFOOD_SUBSCIRPTION_DESC,
                    [
                        CUSTOM_POPUP_BTN_OPEN_CHANNEL_IO
                    ]
                );
            } else if (res && res.length > 0) {
                //성공
                this.setIsFollowPopup(true);
                this.setOptionProducts([]);
            }


        } else {
            alert('상품을 담아주세요.');
        }

        this.setIsExpand(false);
    };

    addItemToCartBuyNow = async () => {
        this.rootStore.setIsSpinnerOn(true);

        let dataInfo: Array<any> = this.getCartAddData();

        if (dataInfo.length > 0) {
            // console.log(dataInfo);
            const res = await fetchAndReceiveJSON({
                url: ADD_CART_BUY_NOW,
                method: 'POST',
                body: dataInfo,
            });

            if (res != false && res.length > 0) {

                document.body.style.overflow = 'unset';
                // this.rootStore.setIsSpinnerOn(false);
                this.rootStore.setIsDimBgOn(false);

                this.rootStore.miniCartPopupStore.setIsMiniCartPopup(false);
                this.rootStore.cartPageStore.setIsCartLoading(true);
            }
        } else {
            alert('상품을 담아주세요.');
        }

        this.rootStore.setIsSpinnerOn(false);
    };

    getCartAddData = () => {
        let dataInfo: Array<any> = [];

        if (this.miniCartProductQty < 1) {
            // alert("수량은 1개 이상으로 설정해주세요");
            return;
        }

        const qtyInvalidOptionProduct = this.optionProducts.find((optionProduct) => optionProduct.quantity < 1);
        if (qtyInvalidOptionProduct) {
            // alert("수량은 1개 이상으로 설정해주세요");
            return;
        }

        //simple
        if (
            this.miniCartProduct.type === 'simple' ||
            this.miniCartProduct.type === 'bundle'
        ) {
            let addCartData = {
                product_id: this.miniCartProduct.id,
                quantity: this.miniCartProductQty,
            };

            if (this.miniCartProduct.type === 'bundle') {
                let bundled_items_to_add_value = {};
                this.optionProducts.map((optionProduct) => {
                    if (
                        this.miniCartProduct.stamp[optionProduct.option.id] !==
                        undefined
                    ) {
                        let stamp =
                            this.miniCartProduct.stamp[optionProduct.option.id];

                        bundled_items_to_add_value[stamp] = 1;
                        // this.miniCartProductQty;
                    }
                });

                addCartData['bundled_items_to_add'] =
                    bundled_items_to_add_value;
            }

            dataInfo.push(addCartData);
        } else if (this.miniCartProduct.type !== 'bundle') {
            if (this.miniCartProduct.type === 'grouped') {
                this.optionProducts.map((optionProduct) => {

                    dataInfo.push({
                        product_id: optionProduct.option.id,
                        quantity: optionProduct.quantity,
                    });
                });
            } else {
                this.optionProducts.map((optionProduct) => {

                    const data: any = {
                        product_id: this.miniCartProduct.id,
                        variation_id: optionProduct.option.id,
                        quantity: optionProduct.quantity,
                    };

                    if (optionProduct.billing_period && optionProduct.billing_interval) {
                        data.billing_period = optionProduct.billing_period;
                        data.billing_interval = optionProduct.billing_interval;
                    }
                    dataInfo.push(data);
                });
            }
        }

        return dataInfo;
    };

    setNextSelectOption = (slug, insertIdx) => {

        let newKeys: Array<string> = this.keys.slice();
        // console.log("setNextSelectOption newKeys", newKeys);

        newKeys.splice(insertIdx);
        newKeys.splice(insertIdx, 0, slug);

        // console.log(newKeys);
        this.setKeys(newKeys);

        //select check
        this.updateSelected();
    };

    updateSelected = () => {

        let prevMap = this.optionMap;
        // console.log("this.optionMap" , this.optionMap);
        this.keys.map((key) => {

            let node = prevMap.get(key);
            if (node?.node) {
                //마지막 옵션 아님
                prevMap = node.node;
            } else {

                //마지막 옵션 선택
                this.updateSelectedOption(node.product);

                //keys 초기화
                this.setKeys([]);
            }

        })

    }

    setKeys = (keys) => {
        this.keys = keys;
    };

    setIsExpand = (isExpand) => {
        this.isExpand = isExpand;
    };

    //옵션 팝업 내 수량 조절(인풋 입력)
    mainProductQtyInputHandler = (evt) => {

        if (evt.target.value === '0') {
            alert('수량은 1개 이상 선택해 주세요.');
            return;
        }

        let qty = evt.target.value === '' ? 0 : parseInt(evt.target.value);

        // console.log(qty);

        this.setMiniCartProductQty(qty);

    };

    optionProductQtyInputHandler = (targetOptionProduct, evt) => {

        if (evt.target.value === '0') {
            alert('수량은 1개 이상 선택해 주세요.');
            return;
        }

        let copiedOptionProducts = [];

        this.optionProducts.forEach((optionProduct) => {
            // console.log(optionProduct);
            if (optionProduct === targetOptionProduct) {

                let qty = evt.target.value === '' ? 0 : parseInt(evt.target.value);

                if (isDogFoodProduct(this.miniCartProduct)) {
                    qty = this.validDogFoodQty(qty);
                }

                optionProduct.quantity = qty;

            }

            copiedOptionProducts.push(optionProduct);
        });

        this.setOptionProducts(copiedOptionProducts);
    }

    updateSelectedOption = (targetOptionProduct) => {

        // console.log("store updateSelectedOption" , targetOptionProduct);
        // 구매 가능 여부 판단
        if (targetOptionProduct?.stock_status !== 'instock') {
            return;
        }

        //이미 selected 된 옵션 선택 -> 개수 증가
        const registedOptionProduct = this.optionProducts.find((optionProduct) => optionProduct.option.id === targetOptionProduct.id);
        if (registedOptionProduct && registedOptionProduct.option) {
            if (registedOptionProduct.option.price === "100") {
                return window.alert('해당 상품의 최대 구매 가능 갯수는 1개입니다.')
            }
            const qty = this.miniCartProduct.type === 'bundle' ? this.miniCartProductQty : registedOptionProduct.quantity + 1;

            if (this.validMiniCartQty(qty) == false) {
                return;
            }

            //qty 처리
            registedOptionProduct.quantity = qty;

            let copiedOptionProducts = [];

            for (let idx in this.optionProducts) {

                let targetOptionProduct = null;
                if (this.optionProducts[idx].option.id !== registedOptionProduct.option.id) {
                    targetOptionProduct = this.optionProducts[idx];
                } else {
                    targetOptionProduct = registedOptionProduct;
                }

                copiedOptionProducts.push(targetOptionProduct);

            }

            this.setOptionProducts(copiedOptionProducts);
            return;
        }

        //새로 선택
        let qty = this.miniCartProduct.type === 'bundle' ? this.miniCartProductQty : 1;

        let newOptionProduct = {
            option: targetOptionProduct,
            quantity: qty
        }

        // console.log("store !! added new : " , this.optionProducts);

        if (isDogFoodProduct(this.miniCartProduct)) {

            console.log("isDogFoodProduct!!", isDogFoodProduct);

            this.tempOptionProduct.option = targetOptionProduct;
            this.tempOptionProduct.quantity = 1;
            return;
        }

        this.setOptionProducts([...this.optionProducts, newOptionProduct]);

        //옵션 정기결제권의 경우 선택 시, 2회차 할인율 표시
        // console.log("???");
        if (this.miniCartProduct.type === 'variable-subscription') {
            this.setSubscriptionDiscountInfo(targetOptionProduct.subscriptionDiscountInfo);
        }

    }

    updateSelectedDogOption = (targetOptionProduct) => {

        // console.log("updateSelectedDogOption" , toJS(targetOptionProduct));


        if (targetOptionProduct?.stock_status !== 'instock') {
            return;
        }

        if (isDogFoodProduct(this.miniCartProduct)) {

            console.log("selected!!", targetOptionProduct);
            this.tempOptionProduct.option = targetOptionProduct;
            this.tempOptionProduct.quantity = 1;
            return;
        }
    }

    getSelectableOptions = (idx) => {

        // console.log("getSelectableOptions in store", idx , toJS(this.keys));

        let options: Array<any> = [];

        if (this.keys.length < idx || !this.optionMap) {
            return options;
        }

        // before last options
        // console.log("getSelectableOptions : this.optionMap" , toJS(this.optionMap));
        let prevMap = this.optionMap;
        if (this.keys.length == 0) {

            // console.log("prevMap" , prevMap);
            prevMap.forEach((value) => {
                options.push(value.product);
            })

            // console.log("getSelectableOptions options!!" , options);
            return options;
        }

        let depth = 0;
        this.keys.map((key) => {

            // console.log(toJS(prevMap));

            if (depth < idx) {
                prevMap = prevMap.get(key).node;
            }

            depth++;
        });

        prevMap.forEach((value) => {
            options.push(value.product);
        })

        // console.log("getSelectableOptions", options);
        return options;
    }

    addDataToOption = (item) => {

        console.log(this.optionProducts, item);

        if (this.optionProducts.length > 0) {
            //기존 아이템 존재

            let registedOptionProduct = this.optionProducts[0];

            if (registedOptionProduct.option.id === this.tempOptionProduct.option.id
                && registedOptionProduct.billing_interval
                && registedOptionProduct.billing_period
                && registedOptionProduct.billing_interval === item.billing_interval
                && registedOptionProduct.billing_period === item.billing_period) {

                // console.log("same !!! qty ++");

                //동일한 아이템 일 시 qty + 1
                const qty = registedOptionProduct.quantity + 1;

                if (this.validMiniCartQty(qty) == false) {
                    return;
                }

                //qty 처리
                registedOptionProduct.quantity = qty;
                this.setOptionProducts([registedOptionProduct]);

                return;
            }

        }

        // console.log(this.tempOptionProduct);

        if (this.tempOptionProduct && JSON.stringify(this.tempOptionProduct) !== JSON.stringify(defaultTempOptionProduct)) {
            //no tempOption..
        } else {
            this.tempOptionProduct.option.billing_period = item.attributes[0].option;
            this.tempOptionProduct.option.display_price = this.miniCartProduct.price.includes("원") ? this.miniCartProduct.price : this.miniCartProduct.price + "원";
            this.tempOptionProduct.option.regular_price = this.miniCartProduct.regularPrice;
        }
        this.tempOptionProduct.option.billing_period = item.attributes[0].option;
        this.tempOptionProduct.billing_period = item.billing_period;
        this.tempOptionProduct.billing_interval = item.billing_interval;

        this.setOptionProducts([this.tempOptionProduct]);
        this.tempOptionProduct = defaultTempOptionProduct;

    }

    validDogFoodQty = (qty) => {
        //사료 maxcnt
        if (qty > DOG_FOOD_CART_MAX_CNT) {
            qty = DOG_FOOD_CART_MAX_CNT;
            // alert(DOG_FOOD_MAX_CNT_OVER_TEXT);
            this.rootStore.setCommonPopupContents(
                CUSTOM_POPUP_DOG_FOOD_OVER_MAX_CNT_OVER_TITLE,
                CUSTOM_POPUP_DOG_FOOD_OVER_MAX_CNT_OVER_DESC,
                [
                    CUSTOM_POPUP_BTN_OPEN_CHANNEL_IO,
                ]
            );
        }

        return qty;
    }

}


export default MiniCartStore;
