import React from 'react';
import { useHistory } from 'react-router-dom';
import './ErrorNotice.scss';

const ErrorNotice = ({
    code
}
) => {
    const history = useHistory();
    return (
        <section className="ErrorNotice">
            <div className="dtLayout">
                <img src="/img/errorImg.png" />
                <h5 className="errorMsg">앗, 뭔가 잘못 됐어요!</h5>
                <p className="errorMsgDetail">
                    요청하신 페이지를 찾지 못했어요.
                    <br />
                    불편을 드리게 되어 대단히 죄송합니다.
                    <br />
                    (에러 코드: {code})
                </p>
                <button
                    onClick={() => history.push('/')}
                    className="moveToHomeBtn"
                    type="button"
                >
                    홈으로 이동하기
                </button>
                <div className="BtnGroup">
                    <button onClick={() => history.goBack()} type="button">
                        이전 페이지로
                    </button>
                    |<button type="button">상담 문의하기</button>
                </div>
            </div>
        </section>
    );
};

export default ErrorNotice;
