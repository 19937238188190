import { makeAutoObservable } from 'mobx';
import { fetchAndReceiveJSON } from '../../utils/ajaxUtils';
import { GET_USER_INFO_URL } from '../../constants/constants';

class UserInfoStore {
    userDeliveryInfo = {
        firstName: '',
        receiver: '',
        phone: '',
        email: '',
        postcode: '',
        address_1: '',
        address_2: '',
        deliveryMsg: '',
    };

    constructor() {
        makeAutoObservable(this);
    }

    // setUserInfo = async () => {
    //     const res = await fetchAndReceiveJSON({
    //         url: GET_USER_INFO_URL,
    //     });

    //     this.userInfo = res;

    //     return res;
    // };
    setUserDeliveryInfo = (info) => {
        this.userDeliveryInfo = info;
    };

    // updateUserInfo = (res) => {
    //     this.userInfo = res;
    // };
}

export default UserInfoStore;
