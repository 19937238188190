import React, {
    FunctionComponent,
    ReactNode,
    useMemo,
} from 'react';
import './InputWithValidation.scss';

const InputWithValidation: FunctionComponent<{
    setStateFunction: CallableFunction;
    targetState: string;
    placeHolder: string;
    errMsg?: string;
    inputType?: string;
    addiTionalObj?: ReactNode;
    readOnly?: boolean;
    onClick?: CallableFunction;
    inputId?: string;
    className?: string;
}> = ({
    setStateFunction,
    targetState,
    placeHolder,
    errMsg,
    inputType,
    addiTionalObj,
    readOnly,
    onClick,
    inputId,
    className,
}) => {

    const type = useMemo(() => {
        if (!inputType || inputType === 'phone' || inputType === 'corpNumber') {
            return 'text';
        }

        if (inputType == 'email') {
            inputType = 'text';
        }

        return inputType;
    }, [inputType]);

    return (
        <>
            <div
                onClick={() => {
                    if (onClick) {
                        onClick();
                    }
                }}
                className={`inputWithValidationWrapper ${
                    className ? className : ''
                }`}
            >
                <div className="relpositionMaker">
                    <input
                        className={`inputWithValidation ${
                            errMsg ? 'inputError' : ''
                        }  ${readOnly ? 'readOnly' : ''}`}
                        type={type}
                        placeholder={placeHolder}
                        readOnly={readOnly}
                        value={targetState}
                        id={inputId && inputId}
                        onChange={(e) => {
                            setStateFunction(e.target.value);
                        }}
                        autoCapitalize="off"
                    />
                    <img
                        className="inputDeleteContetnsButton"
                        src="/img/m-btn-text-field-delete.svg"
                        alt=""
                        onClick={() => {
                            setStateFunction('');
                        }}
                    />
                </div>
                {addiTionalObj && <div>{addiTionalObj}</div>}
            </div>

            {errMsg && <span className="errMsg">{errMsg}</span>}
        </>
    );
};

export default InputWithValidation;
