import { makeAutoObservable } from 'mobx';

class AccountStore {
    isSkipPaymentPopup:boolean = false;

    // requestFetchSubscriptionCnt:number = 0;
    fetchDataCnt:number = 0;

    constructor() {
        makeAutoObservable(this);

    }

    setIsSkipPaymentPopup = (isSkipPaymentPopup) => {
        this.isSkipPaymentPopup = isSkipPaymentPopup;
    }

    // requestSubscriptionFetch = () => {
    //     this.requestFetchSubscriptionCnt = this.requestFetchSubscriptionCnt + 1;
    // }

    requestFetch = () => {
        this.fetchDataCnt = this.fetchDataCnt + 1;
    }


}

export default AccountStore;
