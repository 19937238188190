import React from 'react';
import {observer} from 'mobx-react';
import {AppContext} from '../../contexts/AppContext/AppContext';
import {addComma} from '../../utils/commonUtils';
import {getLineItemCnt} from '../../utils/orderUtils';
import {useHistory} from 'react-router-dom';
import moment from 'moment';

const OrderCard = observer(({order}) => {
    let history = useHistory();

    let statusColor = '';

    if (
        order.status === 'order-received' ||
        order.status === 'process' ||
        order.status === 'shipping' ||
        order.status === 'preorder-received'
    ) {
        statusColor = 'red';
    } else if (order.status === 'on-hold') {
        statusColor = 'mint';
    }

    let createdDate = moment(order.date_created);
    let date = createdDate.format('YYYY.MM.DD');

    return (
        <div className="cardWrapper">
            <div
                className="cardInfoArea"
                onClick={() => {
                    history.push('/my-account/orders/' + order.id);
                }}
            >
                <div className="cardTop">
                    <div className={`orderStatus ${statusColor}`}>
                        {order.status === 'preorder-received'? "예약판매 발송 대기":order.status_kr}
                    </div>
                    <div className="orderCardInfo">
                        <div className="orderDate">{date}</div>
                        <div className="line"></div>
                        <div className="orderId">
                            #{order.id}
                            <img
                                className="pathArrow"
                                src="/img/icon/arrow.svg"
                            />
                        </div>
                    </div>
                </div>
                <div className="orderDescription">
                    {order.line_items?.length > 0 && (
                        <div className="productName">
                            {order.line_items[0].product.productBrand && <div className="">
                                [{order.line_items[0].product.productBrand}]
                            </div>}
                            {getLineItemCnt(order?.line_items) > 1 ? (
                                    `${order.line_items[0].name} 외 ${getLineItemCnt(order.line_items) - 1} 건`
                                )
                                : <>{order.line_items[0].name}</>
                            }
                        </div>
                    )}

                    <div className="price">
                        {addComma(order.total)}
                        <div className="won">원</div>
                    </div>
                </div>
            </div>

            <div className="btns">
                {order.msex_dlv_url?.length > 0 && (
                    <div
                        className="btn"
                        onClick={() => {
                            window.open(order.msex_dlv_url, '_blank');
                        }}
                    >
                        배송 조회
                    </div>
                )}

            </div>
        </div>
    );
});

export default OrderCard;
