import { Certificate } from 'crypto';
import React, { useState } from 'react';
// import { PersonalInfoContentProps } from '../../types/types';
// import './PersonalInfoContent.scss';

const PersonalInfoContent: React.FunctionComponent = () => {
    return (
        <div>
            <div className="privacy">
                <h3>
                    <strong>[개인정보 수집이용 목적]</strong>
                </h3>
                <p>
                    회사의 개인정보 수집 목적은 최적화된 맞춤화 서비스를
                    제공하기 위함이며, 회사는 서비스 제공을 원활하게 하기 위해
                    필요한 최소한의 정보만을 수집하고 있습니다.
                    <br />
                    서비스 이용에 따른 대금결제, 물품배송 및 환불 등에 필요한
                    정보를 추가로 수집할 수 있습니다.
                    <br />
                    회사는 개인정보를 수집, 이용목적 이외에 다른 용도로
                    이용하거나 회원의 동의 없이 제3자에게 이를 제공하지
                    않습니다.
                </p>
                <h3>
                    <strong>[개인정보 수집항목]</strong>
                </h3>
                <p>
                    회사는 회원가입, 쇼핑몰 이용, 서비스 신청 및 제공 등을 위해
                    다음과 같은 개인정보를 수집하고 있습니다.
                </p>
                <p>
                    회사는 개인의 주민등록번호 및 아이핀 정보를 받지
                    않습니다.가. 개인정보 항목회원 필수항목: 전자우편주소,
                    페이스북 ID 및 페이스북에서 제공하는 정보, 트위터 ID 및
                    트위터에서 제공하는 정보, 구글+ ID 및 구글에서 제공하는
                    정보, 웨이보 ID 및 웨이보에서 제공하는 정보, 네이버 ID 및
                    네이버에서 제공하는 정보, 라인 ID 및 라인에서 제공하는 정보,
                    카카오톡 ID 및 카카오톡에서 제공하는 정보비회원 필수항목:
                    주문자 이름, 주문 결제자 주소, 수취인 이름, 배송지 정보,
                    연락처, 고객메모부가항목: 주문자 이름, 주문 결제자 주소,
                    수취인 이름, 배송지 정보, 연락처, 환불요청 시
                    환불계좌번호다만, 서비스 이용과정에서 서비스 이용기록, 접속
                    로그, 쿠키, 접속 IP 정보, 결제기록 등이 생성되어 수집될 수
                    있습니다.나. 수집방법: 쇼핑몰 회원가입 시의 회원가입 정보 및
                    고객센터를 통한 전화 및 온라인 상담
                </p>
                <h3>
                    <strong>[개인정보 보유 및 이용기간]</strong>
                </h3>
                <p>
                    회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는
                    해당 개인정보를 지체 없이 파기합니다.단, 관계법령의 규정에
                    의하여 보존할 필요가 있는 경우 회사는 아래와 같이
                    관계법령에서 정한 일정한 기간 동안 회원 개인정보를
                    보관합니다.
                </p>
                <p>가. 상법 등 법령에 따라 보존할 필요성이 있는 경우</p>
                <p>
                    ① 표시 • 광고에 관한 기록보존근거: 전자상거래 등에서의
                    소비자보호에 관한 법률보존기간: 6개월
                </p>
                <p>
                    ② 계약 또는 청약철회 등에 관한 기록보존근거: 전자상거래
                    등에서의 소비자보호에 관한 법률보존기간: 5년
                </p>
                <p>
                    ③ 대금결제 및 재화 등의 공급에 관한 기록보존근거: 전자상거래
                    등에서의 소비자보호에 관한 법률보존기간: 5년
                </p>
                <p>
                    ④ 소비자의 불만 또는 분쟁처리에 관한 기록보존근거:
                    전자상거래 등에서의 소비자보호에 관한 법률보존기간: 3년
                </p>
                <p>
                    ⑤ 신용정보의 수집, 처리 및 이용 등에 관한 기록보존근거:
                    신용정보의 이용 및 보호에 관한 법률보존기간: 3년
                </p>
                <p>
                    ⑥ 본인확인에 관한 기록보존보존근거: 정보통신망 이용촉진 및
                    정보보호에 관한 법률 제44조의5 및 시행령 제29조보존기간:
                    6개월
                </p>
                <p>
                    ⑦ 접속에 관한 기록보존보존근거: 통신비밀보호법 제15조의2 및
                    시행령 제41조보존기간: 3개월
                </p>
                <p>
                    나. 기타, 회원의 개별적인 동의가 있는 경우에는 개별 동의에
                    따른 기간까지 보관합니다.
                </p>
                <h3>
                    <strong>[개인정보 제3자 제공]</strong>
                </h3>
                <p>
                    가. 회사는 회원들의 개인정보를 개인정보의 수집이용 목적에서
                    고지한 범위 내에서 사용하며, 회원의 사전 동의 없이 동 범위를
                    초과하여 이용하거나 원칙적으로 회원의 개인정보를 제 3자에게
                    제공하지 않습니다. 단, 아래의 경우에는 예외로 합니다.①
                    회원들이 사전에 공개 또는 제3자 제공에 동의한 경우② 법령의
                    규정에 의거하나, 수사, 조사 목적으로 법령에 정해진 절차와
                    방법에 따라 수사기관 및 감독 당국의 요구가 있는 경우
                </p>
                <p>
                    나. 회사가 제공하는 서비스를 통하여 주문 및 결제가 이루어진
                    경우, 상담 등 거래 당사자간 원활한 의사소통 및 배송 등
                    거래이행을 위하여 관련된 정보를 필요한 범위 내에서 거래
                    당사자에게 제공합니다.① OO택배사: 주문자 이름, 수취인 이름
                    배송지 정보, 연락처 그 밖에 개인정보 제3자 제공이 필요한
                    경우에는 합당한 절차를 통한 회원의 동의를 얻어 제3자에게
                    개인정보를 제공할 수 있습니다.
                </p>
                <h3>
                    <strong>
                        [이용자 및 법정대리인의 권리와 그 행사방법 ]
                    </strong>
                </h3>
                <p>
                    ① 정보주체(이용자 및 법정대리인)는 회사에 대해 언제든지 다음
                    각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
                    <br />
                    1) 개인정보 열람 요구
                    <br />
                    2) 오류 등이 있을 경우 정정 요구
                    <br />
                    3) 삭제 요구
                    <br />
                    4) 처리정지 요구
                </p>
                <p>
                    ②이용자들의 개인정보 조회, 수정을 위해서는 ‘내 계정’
                    페이지에서 가능하며, 가입해지(동의철회)를 위해서는
                    ‘회원탈퇴’페이지에서 회원탈퇴 약관 확인 및 동의 후 탈퇴가
                    가능합니다.
                </p>
                <p>
                    ③ 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편
                    등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이
                    조치하겠습니다.
                </p>
                <p>
                    ④ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를
                    요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해
                    개인정보를 이용하거나 제공하지 않습니다.
                    <br />
                    ⑤ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을
                    받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우
                    개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을
                    제출하셔야 합니다.
                    <br />⑥ 정보주체는 개인정보보호법 등 관계법령을 위반하여
                    회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및
                    사생활을 침해하여서는 아니 됩니다.
                </p>
                <p>
                    <strong>
                        [ 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한
                        사항]
                    </strong>
                </p>
                <p>
                    쿠키를 설치, 운영하고 있고 이용자는 이를 거부할 수
                    있습니다.쿠키는 이용자에게 보다 빠르고 편리한 웹사이트
                    사용을 지원하고 맞춤형 서비스를 제공하기 위해 사용됩니다.
                </p>
                <p>1.쿠키란?</p>
                <p>
                    쿠키란 웹사이트를 운영하는데 이용되는 서버가 이용자의
                    브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의컴퓨터에
                    저장됩니다.
                </p>
                <p>2.회사의 쿠키 사용 목적</p>
                <p>
                    쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 이용자에게
                    보다 빠른 웹 환경을 지원하며, 편리한이용을 위해 서비스
                    개선에 활용합니다. 이를 통해 이용자는 보다 손쉽게 서비스를
                    이용할 수 있게 됩니다.
                </p>
                <p>3.쿠키 거부 방법</p>
                <p>
                    이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서,
                    이용자는 웹 브라우저에서 옵션을 설정함으로써모든 쿠키를
                    허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든
                    쿠키의 저장을 거부할 수도 있습니다.다만 쿠키 설치를 거부할
                    경우 웹 사용이 불편해지며 로그인이 필요한 일부 서비스 이용에
                    어려움이 있을 수 있습니다.
                </p>
                <p>
                    <strong>설정 방법의 예</strong>
                </p>
                <p>
                    1. Internet Explorer의 경우 :웹 브라우저 상단의 도구 메뉴
                    &gt; 인터넷 옵션 &gt; 개인정보 &gt; 설정
                </p>
                <p>
                    2. Chrome의 경우 :웹 브라우저 우측의 설정 메뉴 &gt; 화면
                    하단의 고급 설정 표시 &gt; 개인정보의 콘텐츠 설정 버튼 &gt;
                    쿠키
                </p>
                <h3>
                    <strong>[개인정보보호 책임자 및 담당부서]</strong>
                </h3>
                <p>
                    홈페이지 등을 이용하시는 과정에서 개인정보보호 관련 문의,
                    불만, 조언이나 기타 사항은 개인정보보호책임자 및 담당부서로
                    연락해 주시기 바랍니다.
                </p>
                <p>
                    <strong>개인정보보호책임자 및 담당부서</strong>
                </p>
                <p>책임자: 이광배 전화: 82)1522-8067</p>
                <p>담당부서: 개인정보보호파트</p>
                <p>
                    문의:&nbsp;
                    <a
                        href="http://bacon.channel.io"
                        target="_blank"
                        rel="noopener"
                    >
                        고객센터
                    </a>
                </p>
            </div>
        </div>
    );
};

export default PersonalInfoContent;
