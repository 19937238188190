import React, { useContext, useState, useEffect, useRef } from 'react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import { observer } from 'mobx-react';
import './CartAddCompletePopup.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { fetchAndReceiveJSON } from '../../utils/ajaxUtils';
import ProductCard from '../ProductCard/ProductCard';
import {
    BREAKPOINT_DT,
    COLLECTION_DETAIL_DATA_URL,
} from '../../constants/constants';
import { toJS } from 'mobx';
import { useLocation, useHistory } from 'react-router-dom';

const CartAddCompletePopup = observer(({ addedProduct }) => {
    SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
    const { rootStore, miniCartPopupStore, cartPageStore } =
        useContext(AppContext);
    const { setIsDimBgOn } = rootStore;
    const { setIsFollowPopup } = miniCartPopupStore;
    const { setIsCartLoading } = cartPageStore;
    const [bestProducts, setBestProducts] = useState<Array<any>>(null);

    const location = useLocation();
    // const history = useHistory();

    const fetchBestProductData = async () => {
        const resData = await fetchAndReceiveJSON({
            url: `${COLLECTION_DETAIL_DATA_URL}?slug=all-collection&type=hot&page=1&perpage=8`,
        });

        if (resData &&
            resData.contents?.products !== undefined
        ) {
            setBestProducts(resData.contents?.products);
        }
    };
    useEffect(() => {
        fetchBestProductData();

        // document.body.style.cssText = `
        //     position: fixed;
        //     top: -${window.scrollY}px;
        //     overflow-y: scroll;
        //     width: 100%;`;

        document.body.style.overflow = 'hidden';

        setIsDimBgOn(true);
        return () => {
            setIsDimBgOn(false);
            // const scrollY = document.body.style.top;
            // document.body.style.cssText = '';
            // window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);

            document.body.style.overflow = 'unset';
        };
    }, []);

    function closePopupWhenClickDim(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsDimBgOn(false);
                    setIsFollowPopup(false);
                    // initCartData();
                }
            }

            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    // closePopupWhenClickDim(wrapperRef);

    const isProductPage =
        location.pathname.includes('/store/') &&
        !location.pathname.includes('/store/collection/');

    return (
        <div
            ref={wrapperRef}
            className={`FollowPopup ${isProductPage ? 'productPage' : ''}`}
        >
            <div
                onClick={() => {
                    setIsDimBgOn(false);
                    setIsFollowPopup(false);
                    // initCartData();
                }}
                className="closeOptionPopupBtn"
            >
                <img
                    src="/img/btn-gnb-popup-close-white.svg"
                    alt="close-popup-button"
                />
            </div>
            <div className="topArea">
                <div className="headText">
                    {addedProduct.name}
                    <div>상품이 장바구니에 담겼습니다.</div>
                </div>
                <div className="descriptionText">
                    요즘 인기 제품, 함께 담아보세요
                </div>

                <div className="slideWrapper">
                    {bestProducts && bestProducts.length > 0 && (
                        <Swiper
                            spaceBetween={16}
                            slidesPerView={2}
                            slidesPerGroup={2}
                            navigation={{
                                nextEl: '.swiper-button-next-content.followPopupSlider',
                                prevEl: '.swiper-button-prev-content.followPopupSlider',
                            }}
                            breakpoints={{
                                [BREAKPOINT_DT]: {
                                    // width: 180,
                                    slidesPerView: 4,
                                    slidesPerGroup: 4,
                                },
                                768: {
                                    slidesPerView: 4,
                                    slidesPerGroup: 4,
                                },
                            }}
                        >
                            {bestProducts.map((bestProduct, i) => {
                                return (
                                    <SwiperSlide key={`product_${i}`}>
                                        <ProductCard
                                            key={bestProduct.id}
                                            rank={i + 1}
                                            product={bestProduct}
                                            isAddCartAvailable={!isProductPage}
                                        />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    )}
                    <div className="swiper-button-next-content followPopupSlider"></div>
                    <div className="swiper-button-prev-content followPopupSlider"></div>
                </div>
            </div>
            <div className="bottom-area">
                <div className="cta-btns">
                    <div
                        className="cta-btn"
                        onClick={() => {
                            setIsDimBgOn(false);
                            setIsFollowPopup(false);
                            // initCartData();
                        }}
                    >
                        계속 쇼핑하기
                    </div>
                    <div
                        className="cta-btn red"
                        onClick={() => {
                            setIsDimBgOn(false);
                            setIsFollowPopup(false);
                            //move to cart
                            setIsCartLoading(true);
                        }}
                    >
                        장바구니 이동
                    </div>
                </div>
            </div>
        </div>
    );
});
export default CartAddCompletePopup;
