import React, {
    FunctionComponent,
    ReactNode,
    useEffect,
    useState,
} from 'react';
import './CheckWithValidation.scss';

const CheckWithValidation: FunctionComponent<{
    setStateFunction: CallableFunction;
    errMsg: string;
    targetState: boolean;
    text: string | ReactNode;
    className?: string;
}> = ({ setStateFunction, errMsg, targetState, text, className }) => {
    const [isClicked, setIsClicked] = useState(targetState);

    return (
        <div className={`${className ? className : ''} `}>
            <div className="check-container">
                <img
                    style={{
                        cursor: 'pointer',
                    }}
                    src={isClicked ? '/img/m-btn-check-active-16.svg' : `/img/m-btn-check-default-16.svg`}
                    onClick={() => {
                        setStateFunction(!isClicked);
                        setIsClicked(!isClicked);
                    }}
                />
                <div className={`text ${isClicked ? 'clicked' : ''} `}>
                    {text}
                </div>
            </div>

            {errMsg && <span className="errMsg">{errMsg}</span>}
        </div>
    );
};

export default CheckWithValidation;
