import React, {useEffect, useState} from 'react';
//import { moveScrollToId } from '../../utils/utils';
import './NavigationTab.scss';

const NavigationTab = ({tabInfo, isVisibleInDt, pageInfo}) => {
    const [currentPosition, setCurrentPosition] = useState(0);

    const positionCheck = (tab, i) => {
        const tabEl = document.getElementById(tab.targetDomId);

        const tabPosition = tabEl ? tabEl.offsetTop : 0;

        if (pageInfo.scrollPosition() < tabPosition - 146) {
            setCurrentPosition(i === 0 ? 0 : i - 1);
            // setCurrentPosition(i-1)
            return true;
        } else if (i === tabInfo.length - 1) {
            setCurrentPosition(i);
            return true;
        }
    };

    const scrollHighlightHandler = () => {
        // 스크롤 위치에 따른 탭 이동
        tabInfo.some(positionCheck);
    };

    useEffect(() => {
        if (tabInfo[0].moveWhenClicked) {
            window.addEventListener('load', () => {
                scrollHighlightHandler();
            });
        }

        if (pageInfo && pageInfo.scrollParent && scrollHighlightHandler) {
            pageInfo.scrollParent.addEventListener(
                'scroll',
                scrollHighlightHandler
            );
            pageInfo.altScrollParent.addEventListener(
                'scroll',
                scrollHighlightHandler
            );
        }

        // pageInfo.scrollParent.addEventListener('scroll', scrollHighlightHandler);

        return () => {
            if (pageInfo && pageInfo.scrollParent && scrollHighlightHandler) {
                pageInfo.scrollParent.removeEventListener(
                    'scroll',
                    scrollHighlightHandler
                );
                pageInfo.altScrollParent.removeEventListener(
                    'scroll',
                    scrollHighlightHandler
                );
            }
        };
    }, [pageInfo]);

    return (
        <div
            className={`navigation-wrapper  ${
                isVisibleInDt ? 'visible-dt' : ''
            }`}
        >
            <div className="control-wrapper">
                {tabInfo.map((tab, i) => {
                    return (
                        <div
                            key={i}
                            className={`navigation-control  ${
                                currentPosition == i && 'active'
                            }`}
                            onClick={() => {
                                if (tab.moveWhenClicked) {
                                    //moveScrollToId(tab.targetDomId);

                                    let target = document.getElementById(
                                        tab.targetDomId
                                    );
                                    if (target) {
                                        pageInfo.scrollParent.scroll({
                                            behavior: 'smooth',
                                            left: 0,
                                            top: target.offsetTop,
                                        });
                                    }
                                }

                                if (tab.tabAction) {
                                    tab.tabAction();
                                    setCurrentPosition(i);
                                }
                            }}
                        >
                            {tab.tabName}
                        </div>
                    );
                })}
            </div>
            <div className="bar">
                <div
                    className="bar-current"
                    style={{
                        transform: `translate(${currentPosition * 100}%, 0)`,
                        width: `${100 / tabInfo.length}%`,
                    }}
                ></div>
            </div>
        </div>
    );
};

export default NavigationTab;
