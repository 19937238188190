import React, { useContext, useState, useEffect, useRef } from 'react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import { observer } from 'mobx-react';
import './MiniCartPopup.scss';
// import {toJS} from 'mobx';
import MiniCart from './MiniCart';

const MiniCartPopup = observer(({}) => {
    const { rootStore, miniCartPopupStore } = useContext(AppContext);
    const { setIsDimBgOn } = rootStore;
    const { setIsMiniCartPopup, setIsFollowPopup, miniCartProduct } =
        miniCartPopupStore;

    const [isCloseAction, setIsCloseAction] = useState(false);

    //팝업 뒤 영역 스크롤 안되게
    useEffect(() => {

        document.body.style.overflow = 'hidden';

        setIsFollowPopup(false);
        setIsDimBgOn(true);

        function handleAnimationDone(event) {
            if (
                event.animationName == 'off' ||
                event.animationName == 'offTa'
            ) {
                setIsMiniCartPopup(false);
            }
        }

        document.addEventListener('animationend', handleAnimationDone, false);

        return () => {
            setIsDimBgOn(false);

            document.body.style.overflow = 'unset';

            document.removeEventListener(
                'animationend',
                handleAnimationDone,
                false
            );
        };
    }, []);

    function closePopupWhenClickDim(ref) {
        useEffect(() => {
            function handleClickOutsidePopup(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsDimBgOn(false);
                    // setIsMiniCartPopup(false);
                    setIsCloseAction(true);
                }
            }

            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutsidePopup);

            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener(
                    'mousedown',
                    handleClickOutsidePopup
                );
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    // closePopupWhenClickDim(wrapperRef);

    // useEffect(()=> {

    // },[isCloseAction]);

    return (
        <div
            ref={wrapperRef}
            className={`miniCartPopup ${isCloseAction ? '' : 'on'}`}
        >
            <div
                onClick={() => {
                    setIsDimBgOn(false);
                    // setIsMiniCartPopup(false);
                    setIsCloseAction(true);
                }}
                className="closeMiniCartPopupBtn"
            >
                <img
                    src="/img/btn-gnb-popup-close-white.svg"
                    alt="close-popup-button"
                />
            </div>

            <MiniCart
                isShowNpayBtn={miniCartProduct.isNpayAvailable}
                isProductInfo={false}
            />
        </div>
    );
});

export default MiniCartPopup;
