export const KAKAO_AUTH_URL = (REDIRECT_URI, state) => `https://kauth.kakao.com/oauth/authorize?client_id=
${process.env.REACT_APP_KAKAO_REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code&state=${state}`;


import React, {useEffect} from 'react';
import {
    BACON_WP_API,
    HUNDRED_SHOP_CATEGORY_SLUG,
} from '../constants/constants';

export const moveToLink = (url, e) => {
    if (e) {
        if (e.metaKey || e.ctrlKey) {
            window.open(url);
            return;
        }
        // else window.location = url;
    }
    window.location = url;
};

export const stringLineBreak = (str) => {
    if (str === undefined || str === null || typeof str !== 'string') {
        return (
            <React.Fragment key={`text`}>
                {str}
                <br/>
            </React.Fragment>
        );
    }

    return str.split('\n').map((line, i) => (
        <React.Fragment key={`text${i}`}>
            {line}
            <br/>
        </React.Fragment>
    ));
};

export const moveScrollToId = (id) => {
    let target = document.querySelector(id);
    if (target) {
        window.scroll({
            behavior: 'smooth',
            left: 0,
            top: target.offsetTop,
        });
    }
};

export const addComma = (num) => {
    if (num === undefined || num === null) return 0;

    if (num.toString().indexOf(',') > 0) {
        return num;
    }

    return (
        num
            .toString() // transform the number to string
            .split('') // transform the string to array with every digit becoming an element in the array
            .reverse() // reverse the array so that we can start process the number from the least digit
            .map((digit, index) =>
                index != 0 && index % 3 === 0 ? `${digit},` : digit
            ) // map every digit from the array.
            // If the index is a multiple of 3 and it's not the least digit,
            // that is the place we insert the comma behind.
            .reverse() // reverse back the array so that the digits are sorted in correctly display order
            .join('')
    ); // transform the array back to the string
};

export const throttle = (callback = () => {
}, limit) => {
    let timer;
    return () => {
        if (!timer) {
            timer = setTimeout(() => {
                timer = null;
                callback();
            }, limit);
        }
    };
};

export const autoHypenPhone = (str) => {
    if (!str) {
        return '';
    }
    str = str.replace(/[^0-9]/g, '');
    var tmp = '';
    if (str.length < 4) {
        return str;
    } else if (str.length < 7) {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3);
        return tmp;
    } else if (str.length < 11) {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 3);
        tmp += '-';
        tmp += str.substr(6);
        return tmp;
    } else {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 4);
        tmp += '-';
        tmp += str.substr(7, 4);
        return tmp;
    }
};

export const autoHypenCorpNum = (str) => {
    str = str.replace(/[^0-9]/g, '');
    var tmp = '';
    if (str.length < 4) {
        return str;
    } else if (str.length < 6) {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3);
        return tmp;
    } else if (str.length < 11) {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 2);
        tmp += '-';
        tmp += str.substr(5);
        return tmp;
    } else {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 2);
        tmp += '-';
        tmp += str.substr(5, 5);
        return tmp;
    }
};

export const formatPhoneNumber = (value) => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
        3,
        7
    )}-${phoneNumber.slice(7, 11)}`;
};

export function validatePhonenum(num) {
    var phoneReg = /^\d{3}-\d{3,4}-\d{4}$/;
    return phoneReg.test(num);
}

export function validateCorpNum(num) {
    var phoneReg = /^\d{3}-\d{2}-\d{5}$/;
    return phoneReg.test(num);
}

export function phone_format(num) {
    var idx = -1;
    if (num.indexOf('+82') == 0) {
        idx = 3;
    }
    if (num.indexOf('82') == 0) {
        idx = 2;
    }
    if (idx > 0) {
        num = num.substr(idx);
        num = num.replace(/-/g, '');
        num = num.trim();
    }
    if (num.indexOf('0') != 0) {
        num = '0' + num;
    }
    return num.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
        '$1-$2-$3'
    );
}

export function validateEmail(email) {
    if (email === '') {
        return false;
    }

    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    return emailReg.test(email);
}

export function getSrcUrl(url) {
    if (!url) {
        return url;
    }

    if (url.includes('wp-content') && !url.includes('http')) {
        return BACON_WP_API + url;
    }

    // if (url.includes('http')) {
    //     return url;
    // }

    return url;
}

export const addDenom = (int) => {
    let res = int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원';

    return res;
};

export const fetchAndReceiveJSON = async ({url, method, body}) => {
    if (method === 'GET') {
        const res = await fetch(url, {method: 'GET', credentials: 'include'});
        // console.log(await res.text(), url, '!@#!@#@!');
        if ((await res.status) !== 200) {
            const resBody = await res.json();
            alert(resBody.message);
            return false;
        }
        try {
            const data = await res.json();
            return data;
        } catch (err) {
            // alert(err)
            // console.log(err);
            return false;
        }
    } else if (method === 'POST') {
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(body),
        });

        if (res.status !== 200) {
            const resBody = await res.json();
            // console.log(resBody);
            if (res.status === 400) {
                if (url === '/api/login') {
                    return resBody.message;
                } else {
                    alert(resBody.message);
                    return false;
                }
            }
            alert(resBody.message);
            return false;
        }
        try {
            const data = await res.json();
            return data;
        } catch (err) {
            // alert(err)
            console.error(err);
            return false;
        }
    }
};

export const moveToAnchor = (delay = 0) => {
    let curUrl = window.location.href.split('#');
    let position = curUrl[curUrl.length - 1];
    // console.log(window.location.href)
    // console.log(curUrl);
    // console.log(position);
    if (curUrl.length === 1) {
        return;
    }

    if (delay) {
        setTimeout(() => {
            moveScrollToId(position);
        }, delay);
        return;
    }
    if (position) {
        moveScrollToId(position);
        return;
    }
};

export const checkCSSHasSafeArea = () => {
    if (
        getComputedStyle(document.documentElement)
            .getPropertyValue('--sabc')
            .trim() !== '0px'
    ) {
        return true;
    } else if (
        getComputedStyle(document.documentElement)
            .getPropertyValue('--sab')
            .trim() !== '0px'
    ) {
        return true;
    } else {
        return false;
    }
};

export const getTenDigitNum = (num: number) => {
    if (num === null || num === undefined || isNaN(Number(num))) {
        return '00';
    }

    if (num < 10) {
        return '0' + num;
    } else if (num > 99) {
        return num % 100;
    }

    return num;
};

interface Window {
    ChannelIO: any;
}


export const openChannelIO = () => {
    const chPluginElement = document.getElementById('ch-plugin')
    if (chPluginElement) {
        chPluginElement.style.opacity = '1'
    }
    // @ts-ignore
    window.ChannelIO('showMessenger')

};

export const hideChatBtn = (hideCondition) => {
    const chatBtn = document.getElementById('ch-plugin');

    if (!chatBtn) {
        return;
    }
    if (hideCondition) {
        chatBtn.classList.add('hide');
    } else {
        chatBtn.classList.remove('hide');
    }
};

export const getTextsInBrakets = (target) => {
    if (!target) {
        return '';
    }
    return target.match(/\[(.*?)\]/g);
};

export const displayOptionNameWithSquareBracket = (optionName, className) => {
    //get [text] array
    let texts = getTextsInBrakets(optionName);

    if (texts) {
        let result = '';
        for (let i in texts) {
            result += optionName.replace(
                texts[i],
                `<span class=${className}>` + texts[i] + '</span>'
            );
        }

        return result;
    }

    return optionName;
};

export const isEventPriceProduct = (product) => {
    const hasEventPriceCategory = product?.categories?.some(
        (category) => category.slug === HUNDRED_SHOP_CATEGORY_SLUG
    );

    return hasEventPriceCategory;
};

export const isAddedSubcollectionParameter = (search) => {
    if (search === '') {
        return false;
    }
    const isSubcollectionParam =
        search
            .split('?')[1]
            .split('&')
            .filter((param) => param.split('=')[0] === 'subcollection').length >
        0;

    return isSubcollectionParam;
};

export const getSessionStorage = (key) => {
    return window.sessionStorage.getItem(key)
}

export const setSessionStorage = (key, item) => {
    window.sessionStorage.setItem(key, item)
}
