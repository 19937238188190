import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import {useClientRect} from '../../hooks/useClientRect';
import {Helmet} from 'react-helmet';
import {AppContext} from '../../contexts/AppContext/AppContext';
import {useHistory} from 'react-router-dom';
import {
    COLLECTION_DETAIL_DATA_URL,
    BEST_PRODUCTS_LIST_URL,
    HUNDRED_SHOP_SLUG,
} from '../../constants/constants';
import ProductCard from '../../components/ProductCard/ProductCard';
import HomeProductListsContainer from '../../containers/HomeProductListsContainer/HomeProductListsContainer';
import {throttle} from 'lodash';
import VisibilitySensor from 'react-visibility-sensor';
import './WelcomePage.scss';
import {moveScrollToId} from '../../utils/commonUtils';
import {fetchAndReceiveJSON} from '../../utils/ajaxUtils';

const WelcomePage = () => {
    const {rootStore} = useContext(AppContext);
    const {initData, setIsLoginModalOn, setSignUpModalOn} = rootStore;
    const [currentScrollPosition, setCurrentScrollPosition] =
        useState('kakaoBenefit');
    const [productsList, setProductsList] = useState([]);
    const [bestProductsList, setBestProductsList] = useState(null);

    const [menuTabRect, menuTabRef] = useClientRect();
    const [kakaoBenefitRect, kakaoBenefitRef] = useClientRect();
    const [deliveryBenefitRect, deliveryBenefitRef] = useClientRect();

    const history = useHistory();

    //TODO 100원의 행복 콜렉션 세팅되면 해당 슬러그로 데이터 호출하기
    const fetchHundredShopCollectionData = async () => {
        const res = await fetchAndReceiveJSON({
            url: `${COLLECTION_DETAIL_DATA_URL}?slug=${HUNDRED_SHOP_SLUG}`,
        });

        setProductsList(res.contents.products);
    };

    const fetchBestProductsListData = async () => {
        const res = await fetchAndReceiveJSON({
            url: BEST_PRODUCTS_LIST_URL,
        });

        setBestProductsList(res);
    };

    //100원의 행복 상품 콜렉션 데이터 받아오기
    useEffect(() => {
        // fetchHundredShopCollectionData();
    }, []);

    //인기상품 데이터 호출하기
    useEffect(() => {
        fetchBestProductsListData();
    }, []);

    //메뉴탭 스크롤 이벤트
    let lastScrollPosition = 0;

    useEffect(() => {
        const topNav: HTMLElement = document.getElementById('navContainer');
        const header: HTMLDivElement = document.querySelector('.headerArea');
        const appTopArea: HTMLDivElement =
            document.querySelector('.appTopArea');

        if (menuTabRect !== null) {
            const scrollHandler = throttle(() => {
                const curScroll = window.scrollY;
                if (lastScrollPosition < curScroll) {
                    if (topNav.clientHeight > 0) {
                        menuTabRect.style.top = `${
                            appTopArea.clientHeight - header.clientHeight
                        }px`;
                    } else {
                        menuTabRect.style.top = `${appTopArea.clientHeight}px`;
                    }
                } else {
                    menuTabRect.style.top = `${appTopArea.clientHeight}px`;
                }
                lastScrollPosition = curScroll;
            }, 500);

            window.addEventListener('scroll', scrollHandler);
            return () => {
                window.removeEventListener('scroll', scrollHandler);
            };
        }
    }, [menuTabRect]);

    //현재 스크롤링 되는 메뉴탭 위치 활성화 이벤트
    useEffect(() => {
        if (
            kakaoBenefitRect !== null &&
            deliveryBenefitRect !== null
        ) {
            const scrollHandler = throttle(() => {
                if (
                    window.scrollY > kakaoBenefitRect.offsetTop &&
                    window.scrollY < deliveryBenefitRect.offsetTop - 200
                ) {
                    setCurrentScrollPosition('kakaoBenefit');
                } else if (
                    window.scrollY >
                    deliveryBenefitRect.offsetTop - 200
                ) {
                    setCurrentScrollPosition('deliveryBenefit');
                }
            }, 500);

            window.addEventListener('scroll', scrollHandler);

            return () => {
                window.removeEventListener('scroll', scrollHandler);
            };
        }
    }, [kakaoBenefitRect, deliveryBenefitRect]);

    //웰컴페이지 내에선 채팅버튼 안보이게 하기
    // useEffect(() => {
    //     const chatBtn = document.getElementById('ch-plugin-launcher');

    //     if (!chatBtn) {
    //         return;
    //     }

    //     chatBtn.classList.add('hide');

    //     return () => {
    //         chatBtn.classList.remove('hide');
    //     };
    // }, []);

    return (
        <>
            <Helmet>
                <title>똑똑한 혜택 | 베이컨박스</title>
            </Helmet>
            <section className="WelcomePageWrapper">
                <section className="WelcomePage">
                    <header
                        className={`welcomePageHeaderSection`}
                    />
                    <div ref={menuTabRef} className="menuTab">
                        <div
                            className={`menuItem ${
                                currentScrollPosition === 'kakaoBenefit'
                                    ? 'active kakao'
                                    : ''
                            }`}
                            onClick={() => {
                                setCurrentScrollPosition('kakaoBenefit');
                                if (kakaoBenefitRect !== null) {
                                    window.scroll({
                                        behavior: 'smooth',
                                        left: 0,
                                        top: kakaoBenefitRect.offsetTop - 100,
                                    });
                                }
                            }}
                        >
                            {' '}
                            1,000원 <br/>
                            할인 쿠폰
                        </div>
                        <div
                            className={`menuItem ${
                                currentScrollPosition === 'deliveryBenefit'
                                    ? 'active delivery'
                                    : ''
                            }`}
                            onClick={() => {
                                setCurrentScrollPosition('deliveryBenefit');
                                if (deliveryBenefitRect !== null) {
                                    window.scroll({
                                        behavior: 'smooth',
                                        left: 0,
                                        top:
                                            deliveryBenefitRect.offsetTop - 100,
                                    });
                                }
                            }}
                        >
                            {' '}
                            무료배송 <br/>쿠폰
                        </div>
                    </div>
                    <main className="welcomeBodySection">
                        <div
                            ref={kakaoBenefitRef}
                            className="welcomeCard kakao"
                            id="kakaoBenefit"
                        >
                            <img
                                className="number"
                                src="/img/welcome-numbering-1.svg"
                                alt="first welcome benefit"
                            />
                            <div className="benefitTitle">
                                카카오 특별 혜택 <br/>
                                <VisibilitySensor
                                    partialVisibility={true}
                                    onChange={(isVisible) => {
                                        if (isVisible) {
                                            document
                                                .getElementById('underline2')
                                                .classList.add(
                                                'underlineAnimation'
                                            );
                                        }
                                    }}
                                >
                                    <span id="underline2" className="kakao">
                                        1,000원
                                    </span>
                                </VisibilitySensor>{' '}
                                할인 쿠폰
                            </div>
                            <div className="desc">
                                카카오로 회원 가입하고 1천원 할인 받으세요!
                            </div>
                            <div className="descComment">
                                2만원 이상 구매 시 적용 가능합니다.
                            </div>
                            <img
                                src="/img/kakao-coupon1000.png"
                                alt="kakao coupon"
                                className="kakaoCouponImg"
                            />
                            <a
                                href="https://pf.kakao.com/_YWDxnj"
                                target="_blank"
                            >
                                <div className="kakaoCouponBtn">
                                    1천원 쿠폰 받기
                                </div>
                            </a>
                        </div>
                        <div
                            ref={deliveryBenefitRef}
                            id="deliveryBenefit"
                            className="welcomeCard delivery"
                        >
                            <img
                                className="number"
                                src="/img/welcome-numbering-2.svg"
                                alt="first welcome benefit"
                            />
                            <div className="benefitTitle">
                                무료배송{' '}
                                <VisibilitySensor
                                    partialVisibility={true}
                                    onChange={(isVisible) => {
                                        if (isVisible) {
                                            document
                                                .getElementById('underline3')
                                                .classList.add(
                                                'underlineAnimation'
                                            );
                                        }
                                    }}
                                >
                                    <span id="underline3" className="delivery">
                                        쿠폰
                                    </span>
                                </VisibilitySensor>
                            </div>
                            <div className="desc">
                                언제든 사용 가능한 무료 배송 쿠폰을 <br/>
                                장바구니에서 확인해 보세요!
                            </div>
                            <img
                                src="/img/free-delivery@2x.png"
                                alt="free delivery"
                                className="freeDeliveryImg mb"
                            />
                            <img
                                src="/img/free-delivery-dt@2x.png"
                                alt="free delivery"
                                className="freeDeliveryImg dt"
                            />
                        </div>
                    </main>
                </section>
                <section className="categoryAndBestProductsListSection">
                    <div className="wrapper">
                        <h3 className="categoryTitle">
                            베이컨의 특별한 제품을 <br/>
                            만나보세요.
                        </h3>
                        <div className="categoryWrapper">
                            {categoriesInfo.map((category) => {
                                return (
                                    <div
                                        key={category.id}
                                        onClick={() =>
                                            history.push(category.url)
                                        }
                                        className="categoryItem"
                                    >
                                        <div className="imgWrapper">
                                            <img
                                                src={category.imgSrc}
                                                alt={category.name}
                                            />
                                        </div>
                                        <span>{category.name}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="wrapper">
                        <h3 className="categoryTitle">베이컨 인기상품</h3>
                        <div className="bestProductsWrapper">
                            {bestProductsList !== null && (
                                <HomeProductListsContainer
                                    // key={i}
                                    slideCartegoryData={
                                        bestProductsList.categories
                                    }
                                    slideData={bestProductsList.products}
                                    // title={cartBestProductsList.title}
                                    filterType={bestProductsList.filterType}
                                    className="homeContainers"
                                    type={'fix'}
                                    linkText={'none'}
                                    isRank={
                                        bestProductsList.filterType ===
                                        'best' &&
                                        bestProductsList.isVisibleBadge
                                            ? true
                                            : false
                                    }
                                />
                            )}
                        </div>
                    </div>
                </section>
                {!initData.isLogin && (
                    <div className="mobileBtn">
                        <div
                            onClick={
                                initData.isLogin
                                    ? () => history.push('/')
                                    : () => setSignUpModalOn(true)
                            }
                            className="button"
                        >
                            {initData.isLogin
                                ? '혜택 받고 구매하기'
                                : '가입하고 혜택받기'}
                        </div>
                    </div>
                )}
            </section>
        </>
    );
};

export default WelcomePage;

const categoriesInfo = [
    {
        id: 1,
        name: '노즈워크/터그',
        imgSrc: '/img/category1.png',
        url: '/store/collection/nosework_hunting-collection',
    },
    {
        id: 2,
        name: '산책/아웃도어',
        imgSrc: '/img/category2.png',
        url: '/store/collection/gooutclub-collection',
    },
    {
        id: 3,
        name: '장난감',
        imgSrc: '/img/category3.png',
        url: '/store/collection/toys-collection',
    },
    {
        id: 4,
        name: '생활/용품',
        imgSrc: '/img/category4.png',
        url: '/store/collection/care-collection',
    },
    {
        id: 5,
        name: '의류/액세서리',
        imgSrc: '/img/category5.png',
        url: '/store/collection/acc-collection',
    },
    {
        id: 6,
        name: '간식',
        imgSrc: '/img/category6.png',
        url: '/store/collection/eatsfun-collection',
    },
    {
        id: 7,
        name: '스페셜 콜라보',
        imgSrc: '/img/category7.png',
        url: '/store/collection/collaboration-collection',
    },
];
