
import React from 'react';
import './DetailImages.scss'
import ImageGallery from '../ImageGallery/ImageGallery.component.jsx';


const DetailImages = ({ imageData }) => {




    return (

            <div className="detail-image-wrapper">
                <div className="detail-inner-position" >
                    {
                        imageData &&
                        <ImageGallery data={imageData} sliderHeight="100%" isImageThumbnail={true} isHoverCursorEnabled={true} />
                    }
                </div>
            </div>

    );
};



export default DetailImages
