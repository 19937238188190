import React, { useContext, useState } from 'react';
import { matchPath } from 'react-router';
import { AppContext } from '../../contexts/AppContext/AppContext';
import './EnrollDogInfo.scss';

const EnrollDogInfo = ({ bbProductList, setOpenAddDogInfoPopup, setDogId }) => {
    const { rootStore } = useContext(AppContext);
    const { setIsDimBgOn } = rootStore;

    return (
        <div className="EnrollDogInfo">
            <h3 className="enrollDogInfoTitle">
                잠깐! 반려견 정보를 <br />
                등록해주세요!
            </h3>
            <p className="enrollDogInfoSubTitle">
                반려견에게 더욱 꼭 맞는 제품들로 보내드리겠습니다.
            </p>
            {bbProductList.map((item) => {
                let dog_id = 0;
                const dogIdMeta = item.meta_data.find(
                    (meta) => meta.key === 'dog_id'
                );
                if (dogIdMeta) {
                    dog_id = dogIdMeta.value;
                }

                return (
                    <div className="baconboxInfos">
                        <div className="describeBox">
                            <img
                                alt="baconbox"
                                src={
                                    item.product.type ===
                                    'variable-subscription'
                                        ? '/img/baconbox-multi.jpg'
                                        : '/img/baconbox-1.jpg'
                                }
                            />
                            <div className="info">
                                <div className="productName">
                                    {item.display_name}
                                </div>
                                <div className="optionName">
                                    {item.sku === 'bb_v2_lite_monthly' ||
                                    item.sku === 'bb_v2_monthly'
                                        ? '매 달 정기배송'
                                        : item.sku === 'bb_v2_lite_6month' ||
                                          item.sku === 'bb_v2_6month'
                                        ? '6개월 정기배송'
                                        : item.sku === 'bb_v2_lite_12month' ||
                                          item.sku === 'bb_v2_12month'
                                        ? '12개월 정기배송'
                                        : ''}
                                </div>
                                <div
                                    onClick={() => {
                                        setDogId(dog_id);
                                        setOpenAddDogInfoPopup(true);
                                        setIsDimBgOn(true);
                                    }}
                                    className="enrollDogInfoBtn"
                                >
                                    반려견 정보 등록
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="detailBox">
                            {item.product.sku === 'bb_v2_lite'
                                ? `행동 발달 토이 1개, 반려견 맞춤 간식 1종`
                                : `행동 발달 토이 1개, 애착 토이 1개, 테마 생활용품 1개`}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default EnrollDogInfo;
