import React from 'react';
import { buttonProps } from '../../types/types';
import './Button.scss';

// 버튼이 가져야 하는 옵션
// 색깔을 어떤 걸로 채우느냐
// 그림자가 있냐 없냐
// short-cut style을 대표로 몇개 지정해 놓으면 좋지 않을까?

const Button: React.FunctionComponent<buttonProps> = ({
    children,
    className,
    onClick,
    isSharpBorder,
    withShadow,
    reversed,
    highlighted,
    type,
    ...otherProps
}) => {
    return (
        <button
            className={`styleReset buttonDefault ${
                reversed ? 'reversed' : ''
            } ${highlighted ? 'highlighted' : ' '} ${className ? className : ''}
            ${withShadow ? 'shadow' : ''}
                `}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: isSharpBorder ? 0 : '',
            }}
            onClick={onClick}
            type={type}
            {...otherProps}
        >
            {children}
        </button>
    );
};

export default Button;
