import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { defaultElementProps } from '../../types/types';
import ContentBox from '../../components/ContentBox/ContentBox';
import Button from '../../components/Button/Button';
import RootStore from '../../stores/RootStore/RootStore';
import { AppContext } from '../../contexts/AppContext/AppContext';
import './PersonalizedContainer.scss';
import { MIconLinkArrow16 } from '../../components/SVGs/Svgs';

interface personalizedContainerProps extends defaultElementProps {
    promoData?: any;
}

const PersonalizedContainer: React.FunctionComponent<personalizedContainerProps> = observer(
    ({ promoData, className, ...otherProps }) => {
        const { rootStore, homeStore } = React.useContext(AppContext);
        const { initData, setIsLoginModalOn } = rootStore;
        let promo;
        if (initData) {
            promo = initData.promo;
        }

        const history = useHistory();

        return (
            <div
                className={`${className} personalizedContainer`}
                style={{ backgroundColor: promo && promo.bgColor }}
                {...otherProps}
            >
                {promo && (
                    <>
                        {' '}
                        {initData && initData.login ? (
                            <div>login</div>
                        ) : (
                            // 동적 데이터로 바꾸어야 함
                            <div
                                onClick={() => history.push(promo.link)}
                                className="noLogInWrapper"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <img
                                        className="noLogInImg"
                                        src={promo.icon}
                                        alt="promotion icon"
                                    />
                                    <div
                                        className="noLogInText"
                                        style={{
                                            color: promo.fontColor,
                                        }}
                                    >
                                        {promo.text}
                                    </div>
                                </div>

                                <MIconLinkArrow16
                                    className="linkIcon"
                                    color={promo.fontColor}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }
);

export default PersonalizedContainer;
