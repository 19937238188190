import React, {useContext, useEffect} from 'react';
import {AppContext} from '../../contexts/AppContext/AppContext';
import {hideChatBtn, stringLineBreak} from '../../utils/commonUtils';
import './CommonPopup.scss';

const CommonPopup = () => {
    const {rootStore} = useContext(AppContext);
    const {
        setIsDimBgOn,
        commonPopupContetns,
        setCommonPopupContents,
        resetCommonPopupContents,
    } = rootStore;


    //마운트 되었을 때 디머 온되고 전체 바디 스크롤 기능 막기
    useEffect(() => {
        document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;

        // setIsDimBgOn(true);
        hideChatBtn(true);

        return () => {
            // setIsDimBgOn(false);
            hideChatBtn(false);

            const scrollY = document.body.style.top;
            document.body.style.cssText = '';
            window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
        };
    }, []);

    //챗봇 숨기기
    useEffect(() => {
        hideChatBtn(commonPopupContetns);
        return () => {
            hideChatBtn(false)
        }
    }, [commonPopupContetns]);

    return (
        <div className="CommonPopupContainer">
            <div className="CommonPopup">
                <img
                    onClick={() => resetCommonPopupContents(null)}
                    src="/img/btn-gnb-popup-close-white.svg"
                    alt="close popup button"
                    className="commonPopupCloseIcon"
                />
                <div className="commonPopupWrapper">
                    <div className="title">{commonPopupContetns.title}</div>
                    <div className="content">{stringLineBreak(commonPopupContetns.content)}</div>
                </div>
                <div className="commonPopupBtnWrapper">
                    {commonPopupContetns.btnInfo.map((btn) => {
                        return (
                            <>
                                {btn.customText && (
                                    <div className="btnExtraInfo">
                                        {btn.customText}
                                    </div>
                                )}
                                <div
                                    onClick={() => {
                                        btn.clickFunc();
                                        resetCommonPopupContents(null);
                                    }}
                                    className="btn"
                                    style={{
                                        backgroundColor: `${btn.bgColor}`,
                                        borderColor: `${btn.bgColor}`,
                                    }}
                                >
                                    {btn.buttonName}
                                </div>
                            </>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default CommonPopup;
