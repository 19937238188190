import React, { useRef } from 'react';
import './OrderDetails.scss';

const OrderDetails = ({ orderInfo, bbProductList }) => {
    const accountInfoRef = useRef(null);

    return (
        <>
            {orderInfo && (
                <div
                    className={`OrderDetails ${
                        bbProductList &&
                        bbProductList.length > 0 &&
                        'subscription'
                    }`}
                >
                    <div className="orderDetailsContainer">
                        <h3 className="orderDetailTitle">
                            주문이
                            <br />
                            완료되었습니다!
                        </h3>
                        <p className="orderDetailSubTitle">
                            조금만 기다려 주세요! <br />
                            배송이 시작되면 다시 한번 알려드릴게요.
                        </p>
                        <div className="orderInfos">
                            <table>
                                <tbody>
                                    <tr>
                                        <th scope="row">주문번호</th>
                                        <td>#{orderInfo && orderInfo.id}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">주문상품</th>
                                        <td>
                                            {orderInfo &&
                                            orderInfo.line_items &&
                                            orderInfo.line_items.length > 1
                                                ? `${
                                                      orderInfo.line_items[0]
                                                          .display_name
                                                  } 외 ${
                                                      orderInfo.line_items
                                                          .length - 1
                                                  }개 상품`
                                                : orderInfo.line_items[0]
                                                      .display_name}
                                        </td>
                                    </tr>
                                    {/* 무통장 입금 외 */}
                   
                                       <tr>
                                       <th scope="row">
                                           {orderInfo &&
                                           orderInfo.iamport_vbank_num 
                                               ? '입금 금액'
                                               : '결제금액'}
                                       </th>
                                       <td>
                                           {orderInfo &&
                                               orderInfo.display_total}
                                       </td>
                                   </tr>
                                   {orderInfo &&
                                       orderInfo.iamport_vbank_num && (
                                               <>
                                           <tr>
                                               <th scope="row">결제수단</th>
                                               <td>
                                                   {
                                                       orderInfo.payment_method_title
                                                   }
                                               </td>
                                           </tr>
                         
                                               <tr>
                                                   <th scope="row">
                                                       입금계좌
                                                   </th>
                                                   <td>
                                                       <span
                                                           ref={accountInfoRef}
                                                       >
                                                           {`${orderInfo.iamport_vbank_name} ${orderInfo.iamport_vbank_num}`}
                                                       </span>
                                                       <span
                                                           onClick={() => {
                                                               const textArea =
                                                                   document.createElement(
                                                                       'textarea'
                                                                   );
                                                               textArea.value =
                                                                   accountInfoRef.current.innerText;
                                                               document.body.appendChild(
                                                                   textArea
                                                               );
                                                               textArea.select();
                                                               document.execCommand(
                                                                   'Copy'
                                                               );
                                                               textArea.remove();
                                                               alert('계좌번호를 복사하였습니다.')
                                                           }}
                                                           className="copyBtn"
                                                       >
                                                           복사
                                                       </span>
                                                   </td>
                                               </tr>
                                               {/* <tr>
                                                   <th scope="row">
                                                       입금기한
                                                   </th>
                                                   <td>
                                                       {orderInfo &&
                                                           orderInfo.iamport_vbank_date
                                                               .split(' ')[0]
                                                               .split('-')[0]}
                                                       년{' '}
                                                       {orderInfo &&
                                                           orderInfo.iamport_vbank_date
                                                               .split(' ')[0]
                                                               .split('-')[1]}
                                                       월{' '}
                                                       {orderInfo &&
                                                           orderInfo.iamport_vbank_date
                                                               .split(' ')[0]
                                                               .split('-')[2]}
                                                       일
                                                   </td>
                                               </tr> */}
                                           
                                     </>   
                                     )
                                    }
                                   
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default OrderDetails;
