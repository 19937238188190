import React, { useContext, useEffect, useRef } from 'react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import './MyAccountPopup.scss';
// import {toJS} from 'mobx';

const MyAccountPopup = ({
    setIsShowPopup,
    content,
    isCustomPadding,
    isFullPopup,
}) => {
    const { rootStore } = useContext(AppContext);
    const { setIsDimBgOn } = rootStore;

    //팝업 뒤 영역 스크롤 안되게
    useEffect(() => {
        // document.body.style.cssText = `
        // position: fixed;
        // top: -${window.scrollY}px;
        // overflow-y: scroll;
        // width: 100%;`;

        document.body.style.overflow = 'hidden';

        // setIsFollowPopup(false);
        setIsDimBgOn(true);
        return () => {
            setIsDimBgOn(false);

            // const scrollY = document.body.style.top;
            // document.body.style.cssText = '';
            // window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);

            document.body.style.overflow = 'unset';
        };
    }, []);

    function closePopupWhenClickDim(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsDimBgOn(false);
                    setIsShowPopup(false);
                }
            }

            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    // closePopupWhenClickDim(wrapperRef);

    return (
        <div ref={wrapperRef}>
            <div
                className={`myAccountPopup ${
                    isCustomPadding ? 'customPadding' : ''
                } ${isFullPopup ? 'full' : ''}`}
            >
                <div
                    onClick={() => {
                        setIsDimBgOn(false);
                        setIsShowPopup(false);
                    }}
                    className="outterCloseBtn"
                >
                    <img
                        src="/img/btn-gnb-popup-close-white.svg"
                        alt="close-popup-button"
                    />
                </div>

                {isFullPopup && (
                    <div
                        onClick={() => {
                            setIsDimBgOn(false);
                            setIsShowPopup(false);
                        }}
                        className="innerCloseBtn"
                    >
                        <img
                            src="/img/btn-gnb-popup-close.svg"
                            alt="close-popup-button"
                        />
                    </div>
                )}
                <div className="popupContent">{content}</div>
            </div>
        </div>
    );
};

export default MyAccountPopup;
