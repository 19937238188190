import React, { useState, useEffect } from 'react';
import './SurveyDogFoodPreference.scss';

const SurveyDogFoodPreference = ({
    type = 'shape',
    preferenceValues,
    setPreferenceValues,
}) => {
    const [openDetailContent, setOpenDetailContent] = useState(false);

    //고기 전체 선택 여부
    useEffect(() => {
        const allSelected =
            preferenceValues.quadruped.all &&
            preferenceValues.poultry.all &&
            preferenceValues.meatEtc.organ;
        setPreferenceValues({
            ...preferenceValues,
            allMeats: allSelected ? true : false,
        });
    }, [
        preferenceValues.quadruped.all,
        preferenceValues.poultry.all,
        preferenceValues.meatEtc.organ,
    ]);

    //네발고기 전체 선택 여부
    useEffect(() => {
        const allSelected =
            preferenceValues.quadruped.cow &&
            preferenceValues.quadruped.pig &&
            preferenceValues.quadruped.kangaroo &&
            preferenceValues.quadruped.deer &&
            preferenceValues.quadruped.sheep;
        setPreferenceValues({
            ...preferenceValues,
            quadruped: {
                ...preferenceValues.quadruped,
                all: allSelected ? true : false,
            },
        });
    }, [
        preferenceValues.quadruped.cow,
        preferenceValues.quadruped.pig,
        preferenceValues.quadruped.kangaroo,
        preferenceValues.quadruped.deer,
        preferenceValues.quadruped.sheep,
    ]);

    //가금류 전체 선택 여부
    useEffect(() => {
        const allSelected =
            preferenceValues.poultry.chicken &&
            preferenceValues.poultry.dock &&
            preferenceValues.poultry.turkey &&
            preferenceValues.poultry.chickenfeet;
        setPreferenceValues({
            ...preferenceValues,
            poultry: {
                ...preferenceValues.poultry,
                all: allSelected ? true : false,
            },
        });
    }, [
        preferenceValues.poultry.chicken,
        preferenceValues.poultry.dock,
        preferenceValues.poultry.turkey,
        preferenceValues.poultry.chickenfeet,
    ]);

    //생선 전체 선택 여부
    useEffect(() => {
        const isFishAllSelected = Object.values(preferenceValues.fish).every(
            (value) => value === true
        );

        setPreferenceValues({
            ...preferenceValues,
            allfish: isFishAllSelected ? true : false,
        });
    }, [
        preferenceValues.fish.pollack,
        preferenceValues.fish.salmon,
        preferenceValues.fish.anchovy,
        preferenceValues.fish.shark,
        preferenceValues.fish.tuna,
    ]);

    //곡물 전체선택 여부
    useEffect(() => {
        const isGrainAllSelected = Object.values(preferenceValues.grain).every(
            (value) => value === true
        );

        setPreferenceValues({
            ...preferenceValues,
            allgrain: isGrainAllSelected ? true : false,
        });
    }, [
        preferenceValues.grain.wheat,
        preferenceValues.grain.corn,
        preferenceValues.grain.rice,
        preferenceValues.grain.oatmeal,
    ]);

    //과일 전체선택 여부
    useEffect(() => {
        const isFruitsAllSelected = Object.values(
            preferenceValues.fruits
        ).every((value) => value === true);

        setPreferenceValues({
            ...preferenceValues,
            allfruits: isFruitsAllSelected ? true : false,
        });
    }, [
        preferenceValues.fruits.banana,
        preferenceValues.fruits.blueberry,
        preferenceValues.fruits.peach,
    ]);

    // let isAlerted = false;

    // const ingredientAlert = () => {
    //     const count =
    //         Object.values(preferenceValues.quadruped).filter(
    //             (value) => value === true
    //         ).length +
    //         Object.values(preferenceValues.poultry).filter(
    //             (value) => value === true
    //         ).length +
    //         Object.values(preferenceValues.meatEtc).filter(
    //             (value) => value === true
    //         ).length +
    //         Object.values(preferenceValues.fish).filter(
    //             (value) => value === true
    //         ).length +
    //         Object.values(preferenceValues.grain).filter(
    //             (value) => value === true
    //         ).length +
    //         Object.values(preferenceValues.fruits).filter(
    //             (value) => value === true
    //         ).length +
    //         Object.values(preferenceValues.vegetables).filter(
    //             (value) => value === true
    //         ).length +
    //         Object.values(preferenceValues.etc).filter(
    //             (value) => value === true
    //         ).length;

    //     console.log(count);

    //     if (count > 0 && !isAlerted) {
    //         alert(
    //             '기피 사항 많으면 배송이 지연될 수 있습니다. \n 반려견이 꼭 피해야할 형태/성분만 골라주세요.'
    //         );
    //         isAlerted = true;
    //     }

    //     console.log(isAlerted);
    // };

    return (
        <div className="SurveyDogFoodPreference">
            <div
                onClick={() => setOpenDetailContent(!openDetailContent)}
                className={`titleContainer ${openDetailContent && 'opened'}`}
            >
                <h3 className="title">
                    {type === 'shape'
                        ? '기피하는 식품 형태가 있나요?'
                        : '기피하는 식품의 성분이 있나요?'}
                </h3>
                <img
                    src={
                        openDetailContent
                            ? '/img/m-icon-dropdown-close-24.svg'
                            : '/img/m-icon-dropdown-open-24.svg'
                    }
                />
            </div>
            {type === 'shape' &&
                openDetailContent &&
                Object.entries(foodShapeInputs).map((type) => {
                    return (
                        <div key={type[0]} className="inputBox">
                            <div className="inputTitle">
                                {type[0] === 'sizeOfSnack'
                                    ? '간식 크기'
                                    : type[0] === 'textureOfSnack'
                                    ? '제형'
                                    : '형태'}
                            </div>
                            <div className="inputContainer">
                                {type[1].map((input) => {
                                    return (
                                        <div
                                            key={input.name}
                                            onClick={
                                                type[0] === 'sizeOfSnack'
                                                    ? () => {
                                                          if (
                                                              (input.name ===
                                                                  'small' &&
                                                                  preferenceValues
                                                                      .sizeOfSnack
                                                                      .big) ||
                                                              (input.name ===
                                                                  'big' &&
                                                                  preferenceValues
                                                                      .sizeOfSnack
                                                                      .small)
                                                          ) {
                                                              alert(
                                                                  '큰 간식과 작은 간식을 동시에 제외할 수 없습니다.'
                                                              );
                                                          } else {
                                                              setPreferenceValues(
                                                                  {
                                                                      ...preferenceValues,
                                                                      [type[0]]: {
                                                                          ...preferenceValues[
                                                                              type[0]
                                                                          ],
                                                                          [input.name]: !preferenceValues[
                                                                              type[0]
                                                                          ][
                                                                              input
                                                                                  .name
                                                                          ],
                                                                      },
                                                                  }
                                                              );
                                                          }
                                                      }
                                                    : () => {
                                                          if (
                                                              (input.name ===
                                                                  'hard' &&
                                                                  preferenceValues
                                                                      .textureOfSnack
                                                                      .soft) ||
                                                              (input.name ===
                                                                  'soft' &&
                                                                  preferenceValues
                                                                      .textureOfSnack
                                                                      .hard)
                                                          ) {
                                                              alert(
                                                                  '물렁한 간식과 딱딱한 간식을 동시에 제외할 수 없습니다.'
                                                              );
                                                          } else {
                                                              setPreferenceValues(
                                                                  {
                                                                      ...preferenceValues,
                                                                      [type[0]]: {
                                                                          ...preferenceValues[
                                                                              type[0]
                                                                          ],
                                                                          [input.name]: !preferenceValues[
                                                                              type[0]
                                                                          ][
                                                                              input
                                                                                  .name
                                                                          ],
                                                                      },
                                                                  }
                                                              );
                                                          }
                                                      }
                                            }
                                            className={`inputItem ${
                                                preferenceValues[type[0]][
                                                    input.name
                                                ]
                                                    ? 'selected'
                                                    : ''
                                            }`}
                                        >
                                            <input
                                                type="checkbox"
                                                name={input.name}
                                            />
                                            <span>{input.value}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            {type !== 'shape' &&
                openDetailContent &&
                ingredientInputs.map((ingredient) => {
                    return (
                        <div
                            key={ingredient.type}
                            className="ingredientInputBox"
                        >
                            <div className="ingredientType">
                                <div className="iconWrapper">
                                    <img
                                        src={ingredient.icon}
                                        alt={ingredient.title}
                                    />
                                    <div className="ingredientTitle">
                                        {ingredient.title}
                                    </div>
                                </div>
                                {ingredient.title !== '기타' &&
                                    ingredient.title !== '채소류' && (
                                        <div
                                            onClick={
                                                ingredient.type === 'meats'
                                                    ? () => {
                                                          setPreferenceValues({
                                                              ...preferenceValues,
                                                              allMeats: !preferenceValues.allMeats,
                                                              quadruped: {
                                                                  all: !preferenceValues.allMeats,
                                                                  cow: !preferenceValues.allMeats,
                                                                  pig: !preferenceValues.allMeats,
                                                                  kangaroo: !preferenceValues.allMeats,
                                                                  deer: !preferenceValues.allMeats,
                                                                  sheep: !preferenceValues.allMeats,
                                                              },
                                                              poultry: {
                                                                  all: !preferenceValues.allMeats,
                                                                  chicken: !preferenceValues.allMeats,
                                                                  dock: !preferenceValues.allMeats,
                                                                  turkey: !preferenceValues.allMeats,
                                                                  chickenfeet: !preferenceValues.allMeats,
                                                              },
                                                              meatEtc: {
                                                                  organ: !preferenceValues.allMeats,
                                                              },
                                                          });
                                                      }
                                                    : () => {
                                                          setPreferenceValues({
                                                              ...preferenceValues,
                                                              [`all${ingredient.type}`]: !preferenceValues[
                                                                  `all${ingredient.type}`
                                                              ],
                                                              [ingredient.type]:
                                                                  ingredient.type ===
                                                                  'fish'
                                                                      ? {
                                                                            pollack: !preferenceValues.allfish,
                                                                            salmon: !preferenceValues.allfish,
                                                                            anchovy: !preferenceValues.allfish,
                                                                            shark: !preferenceValues.allfish,
                                                                            tuna: !preferenceValues.allfish,
                                                                        }
                                                                      : ingredient.type ===
                                                                        'grain'
                                                                      ? {
                                                                            wheat: !preferenceValues.allgrain,
                                                                            corn: !preferenceValues.allgrain,
                                                                            rice: !preferenceValues.allgrain,
                                                                            oatmeal: !preferenceValues.allgrain,
                                                                        }
                                                                      : {
                                                                            banana: !preferenceValues.allfruits,
                                                                            blueberry: !preferenceValues.allfruits,
                                                                            peach: !preferenceValues.allfruits,
                                                                        },
                                                          });
                                                      }
                                            }
                                            className={`selectAllButton ${
                                                (ingredient.type === 'meats' &&
                                                    preferenceValues.allMeats) ||
                                                (ingredient.type !== 'meats' &&
                                                    preferenceValues[
                                                        `all${ingredient.type}`
                                                    ])
                                                    ? 'selected'
                                                    : ''
                                            }`}
                                        >
                                            <img
                                                src={
                                                    (ingredient.type ===
                                                        'meats' &&
                                                        preferenceValues.allMeats) ||
                                                    (ingredient.type !==
                                                        'meats' &&
                                                        preferenceValues[
                                                            `all${ingredient.type}`
                                                        ])
                                                        ? '/img/m-btn-radio-active-16.svg'
                                                        : '/img/m-btn-radio-defualt-16.svg'
                                                }
                                                alt="select all inputs"
                                            />
                                            전체를 못 먹거나 싫어해요
                                        </div>
                                    )}
                            </div>
                            {ingredient.title !== '고기류' ? (
                                <div className="inputBox">
                                    <div className="inputContainer">
                                        {ingredient.all.map((input) => {
                                            return (
                                                <div
                                                    key={input.name}
                                                    onClick={() => {
                                                        setPreferenceValues({
                                                            ...preferenceValues,
                                                            [ingredient.type]: {
                                                                ...preferenceValues[
                                                                    ingredient
                                                                        .type
                                                                ],
                                                                [input.name]: !preferenceValues[
                                                                    ingredient
                                                                        .type
                                                                ][input.name],
                                                            },
                                                        });
                                                    }}
                                                    className={`inputItem ${
                                                        preferenceValues[
                                                            ingredient.type
                                                        ][input.name] &&
                                                        'selected'
                                                    }`}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        name={input.name}
                                                    />
                                                    <span>{input.value}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : (
                                ingredient.all.map((inputs, idx) => {
                                    return (
                                        <div key={idx} className="inputBox">
                                            <div className="inputTitle">
                                                {idx === 0
                                                    ? '네 발 동물'
                                                    : idx === 1
                                                    ? '가금류'
                                                    : '특수부위'}
                                            </div>
                                            <div className="inputContainer">
                                                {Object.entries(inputs).map(
                                                    (input: any) =>
                                                        input[1].map(
                                                            (value) => {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            value.name
                                                                        }
                                                                        onClick={
                                                                            value.name ===
                                                                            'all'
                                                                                ? () => {
                                                                                      setPreferenceValues(
                                                                                          {
                                                                                              ...preferenceValues,
                                                                                              [input[0]]:
                                                                                                  input[0] ===
                                                                                                  'quadruped'
                                                                                                      ? {
                                                                                                            all: !preferenceValues[
                                                                                                                input[0]
                                                                                                            ]
                                                                                                                .all,
                                                                                                            cow: !preferenceValues[
                                                                                                                input[0]
                                                                                                            ]
                                                                                                                .all,
                                                                                                            pig: !preferenceValues[
                                                                                                                input[0]
                                                                                                            ]
                                                                                                                .all,
                                                                                                            kangaroo: !preferenceValues[
                                                                                                                input[0]
                                                                                                            ]
                                                                                                                .all,
                                                                                                            deer: !preferenceValues[
                                                                                                                input[0]
                                                                                                            ]
                                                                                                                .all,
                                                                                                            sheep: !preferenceValues[
                                                                                                                input[0]
                                                                                                            ]
                                                                                                                .all,
                                                                                                        }
                                                                                                      : {
                                                                                                            all: !preferenceValues[
                                                                                                                input[0]
                                                                                                            ]
                                                                                                                .all,
                                                                                                            chicken: !preferenceValues[
                                                                                                                input[0]
                                                                                                            ]
                                                                                                                .all,
                                                                                                            dock: !preferenceValues[
                                                                                                                input[0]
                                                                                                            ]
                                                                                                                .all,
                                                                                                            turkey: !preferenceValues[
                                                                                                                input[0]
                                                                                                            ]
                                                                                                                .all,
                                                                                                            chickenfeet: !preferenceValues[
                                                                                                                input[0]
                                                                                                            ]
                                                                                                                .all,
                                                                                                        },
                                                                                          }
                                                                                      );
                                                                                  }
                                                                                : () => {
                                                                                      setPreferenceValues(
                                                                                          {
                                                                                              ...preferenceValues,
                                                                                              [input[0]]: {
                                                                                                  ...preferenceValues[
                                                                                                      input[0]
                                                                                                  ],
                                                                                                  [value.name]: !preferenceValues[
                                                                                                      input[0]
                                                                                                  ][
                                                                                                      value
                                                                                                          .name
                                                                                                  ],
                                                                                              },
                                                                                          }
                                                                                      );
                                                                                  }
                                                                        }
                                                                        className={`inputItem ${
                                                                            preferenceValues[
                                                                                input[0]
                                                                            ][
                                                                                value
                                                                                    .name
                                                                            ] &&
                                                                            'selected'
                                                                        }`}
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            name={
                                                                                value.name
                                                                            }
                                                                        />
                                                                        <span>
                                                                            {
                                                                                value.value
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                );
                                                            }
                                                        )
                                                )}
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    );
                })}
        </div>
    );
};

export default SurveyDogFoodPreference;

const foodShapeInputs = {
    sizeOfSnack: [
        { name: 'big', value: '크기가 큰' },
        { name: 'small', value: '크기가 작은' },
    ],
    textureOfSnack: [
        {
            name: 'hard',
            value: '딱딱하고 질긴',
        },
        {
            name: 'soft',
            value: '물렁한',
        },
        {
            name: 'oilly',
            value: '기름진',
        },
        {
            name: 'wet',
            value: '습식 간식',
        },
        {
            name: 'mixed',
            value: '혼합 재료',
        },
    ],
    shapeOfSnack: [
        {
            name: 'stick',
            value: '스틱',
        },
        {
            name: 'crispy',
            value: '바삭한 과자',
        },
        {
            name: 'cookie',
            value: '쿠키/비스킷',
        },
        {
            name: 'gum',
            value: '껌',
        },
        {
            name: 'bone',
            value: '뼈/가시',
        },
        {
            name: 'powder',
            value: '파우더',
        },
        {
            name: 'driedMeat',
            value: '육포',
        },
        {
            name: 'dried',
            value: '동결건조',
        },
    ],
};

const ingredientInputs: any = [
    {
        title: '고기류',
        type: 'meats',
        icon: '/img/common-icon-food-allergy-meat.svg',
        all: [
            {
                quadruped: [
                    {
                        name: 'all',
                        value: '전체',
                    },
                    {
                        name: 'cow',
                        value: '소',
                    },
                    {
                        name: 'pig',
                        value: '돼지',
                    },
                    {
                        name: 'kangaroo',
                        value: '캥거루',
                    },
                    {
                        name: 'deer',
                        value: '사슴',
                    },
                    {
                        name: 'sheep',
                        value: '양',
                    },
                ],
            },
            {
                poultry: [
                    {
                        name: 'all',
                        value: '전체',
                    },
                    {
                        name: 'chicken',
                        value: '닭',
                    },
                    {
                        name: 'dock',
                        value: '오리',
                    },
                    {
                        name: 'turkey',
                        value: '칠면조',
                    },
                    {
                        name: 'chickenfeet',
                        value: '닭발',
                    },
                ],
            },
            {
                meatEtc: [
                    {
                        name: 'organ',
                        value: '껍데기, 내장',
                    },
                ],
            },
        ],
    },
    {
        title: '해산물',
        type: 'fish',
        icon: '/img/common-icon-food-allergy-fish.svg',
        all: [
            {
                name: 'pollack',
                value: '황태',
            },
            {
                name: 'salmon',
                value: '연어',
            },
            {
                name: 'anchovy',
                value: '멸치',
            },
            {
                name: 'shark',
                value: '상어',
            },
            {
                name: 'tuna',
                value: '참치',
            },
        ],
    },
    {
        title: '곡물류',
        type: 'grain',
        icon: '/img/common-icon-food-allergy-grain.svg',
        all: [
            {
                name: 'wheat',
                value: '밀',
            },
            {
                name: 'corn',
                value: '옥수수',
            },
            {
                name: 'rice',
                value: '쌀',
            },
            {
                name: 'oatmeal',
                value: '오트밀',
            },
        ],
    },
    {
        title: '과일류',
        type: 'fruits',
        icon: '/img/common-icon-food-allergy-fruit.svg',
        all: [
            {
                name: 'banana',
                value: '바나나',
            },
            {
                name: 'blueberry',
                value: '블루베리',
            },
            {
                name: 'peach',
                value: '복숭아',
            },
        ],
    },
    {
        title: '채소류',
        type: 'vegetables',
        icon: '/img/common-icon-food-allergy-vegetable.svg',
        all: [
            {
                name: 'main',
                value: '야채 주재료',
            },
            {
                name: 'sweetpotato',
                value: '고구마',
            },
            {
                name: 'potato',
                value: '감자',
            },
            {
                name: 'broccoli',
                value: '브로콜리',
            },
            {
                name: 'carrot',
                value: '당근',
            },
        ],
    },
    {
        title: '기타',
        type: 'etc',
        icon: '/img/common-icon-food-allergy-etc.svg',
        all: [
            {
                name: 'nuts',
                value: '견과류',
            },
            {
                name: 'bean',
                value: '콩',
            },
            {
                name: 'cheese',
                value: '치즈/유제품',
            },
            {
                name: 'egg',
                value: '달걀',
            },
        ],
    },
];
