import React from 'react';
import './LGPromotion.scss';

const LGPromotionPage = () => {
    return (
        <div className="LGPromotionPage">
            <img className="mb" src="/img/lgpromotion-mb-01.jpg" />
            <a
                href="https://www.lge.co.kr/out_event_page/202109_baconbox/lg_baconbox.jsp?type=01&utm_source=baconbox&utm_medium=affiliate&utm_campaign=202109_baconbox&utm_content=baconbox01"
                target="_blank"
            >
                <img className="mb click" src="/img/lgpromotion-mb-02.jpg" />
            </a>
            <img className="mb" src="/img/lgpromotion-mb-03.jpg" />
            <img className="pc radius" src="/img/lgpromotion-pc-01.jpg" />
            <a
                href="https://www.lge.co.kr/out_event_page/202109_baconbox/lg_baconbox.jsp?type=01&utm_source=baconbox&utm_medium=affiliate&utm_campaign=202109_baconbox&utm_content=baconbox01"
                target="_blank"
            >
                <img className="pc click" src="/img/lgpromotion-pc-02.jpg" />
            </a>
            <img className="pc" src="/img/lgpromotion-pc-03.jpg" />
        </div>
    );
};

export default LGPromotionPage;
