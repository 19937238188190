import React, {useContext, useState} from 'react';
import './DropDown.scss';
import {AppContext} from '../../contexts/AppContext/AppContext';
import CartIcon from '../../assets/m-icon-gnb-btn-cart.svg';
import PreloadImage from 'react-preload-image';
import {
    addComma,
    displayOptionNameWithSquareBracket,
} from '../../utils/commonUtils';
import {defaultElementProps} from '../../types/types';
import {DOG_FOOD_PRODUCT_CLUSTER} from '../../constants/constants';
import {set} from 'mobx';

// import { useTranslation } from 'react-i18next/*';

interface DropDownProps extends defaultElementProps {
    dropDownItems?: Array<any>;
    defaultText: string;
    // itemClickEvent?: CallableFunction;
    itemClickEvent: any;
    dropDownType: string;
    optionProductId?: any;
    defaultTextIcon?: string;
    isBundleProduct?: boolean;
    isMultiOptions?: boolean;
    dropDownIdx?: number;
    selectedMultiOptions?: any;
}

const DropDown: React.FunctionComponent<DropDownProps> = ({
                                                              itemClickEvent,
                                                              defaultText,
                                                              className,
                                                              dropDownItems,
                                                              dropDownType,
                                                              optionProductId,
                                                              defaultTextIcon = '',
                                                              isBundleProduct,
                                                              isMultiOptions,
                                                              dropDownIdx,
                                                              selectedMultiOptions,
                                                              ...othreProps
                                                          }) => {
    const {rootStore, cartPageStore} = useContext(AppContext);

    const [isExpanded, setisExpanded] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState('');

    const {setIsSpinnerOn} = rootStore;
    const {
        setCartInfoList,
        selectedProduct,
        setSelectedDogfoodOption,
        selectedDogfoodOption,
    } = cartPageStore;

    return (
        <>
            {dropDownItems.length > 0 && (
                <div
                    className={`dropdownWrapper ${
                        isExpanded ? 'isExpanded' : ' '
                    } ${className ? className : ''}`}
                    {...othreProps}
                >
                    <div className="dropDownContentArea">
                        <div
                            className="defaultTextArea"
                            onClick={() => {
                                setisExpanded((prev) => !prev);
                            }}
                        >
                            <div className="leftSide">
                                {defaultTextIcon && (
                                    <img
                                        className="defaultTextIcon"
                                        src={defaultTextIcon}
                                    />
                                )}
                                {/* {selectedItem ? selectedItem : defaultText} */}
                                {selectedMultiOptions &&
                                selectedMultiOptions[dropDownIdx] !== undefined
                                    ? selectedMultiOptions[dropDownIdx]
                                    : !selectedMultiOptions && selectedItem
                                        ? selectedItem
                                        : defaultText}
                            </div>
                            <img
                                className={`expandButton ${
                                    isExpanded ? '' : 'expanded'
                                } `}
                                src="/img/m-btn-icon-dropdown-open-24.svg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div
                        className={`dropDowns ${
                            isExpanded ? 'isExpanded' : ''
                        }`}
                    >
                        {/* 쿠폰에서 쓰이는 드랍다운의 경우 */}
                        {dropDownType === 'selectCoupon' &&
                            dropDownItems?.map((item, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        onClick={
                                            idx + 1 === dropDownItems.length
                                                ? () => {
                                                    itemClickEvent(item);
                                                    setisExpanded(false);
                                                }
                                                : () => {
                                                    setIsSpinnerOn(true);
                                                    itemClickEvent(item.id)
                                                        .then(() =>
                                                            setCartInfoList()
                                                        )
                                                        .then(() => {
                                                            setIsSpinnerOn(
                                                                false
                                                            );
                                                            setisExpanded(
                                                                false
                                                            );
                                                        });
                                                }
                                        }
                                        className="dropdownItems"
                                    >
                                        {idx + 1 === dropDownItems.length ? (
                                            item
                                        ) : (
                                            <>
                                                <div>{item.coupon_name}</div>
                                                {item.coupon_description &&
                                                    item.coupon_description !==
                                                    '' && (
                                                        <div className="couponDesc">
                                                            {
                                                                item.coupon_description
                                                            }
                                                        </div>
                                                    )}
                                            </>
                                        )}
                                    </div>
                                );
                            })}
                        {dropDownType !== 'selectCoupon' &&
                            !isMultiOptions &&
                            selectedProduct.product.productCluster !==
                            DOG_FOOD_PRODUCT_CLUSTER &&
                            dropDownItems?.map((item) => {
                                return (
                                    <div
                                        key={item.id}
                                        onClick={
                                            item.stock_status === 'instock'
                                                ? () => {
                                                    itemClickEvent(item.id);
                                                    //   setSelectedItem(item);
                                                    setisExpanded(false);
                                                }
                                                : () => {
                                                    alert(
                                                        '해당 옵션은 품절된 옵션 입니다'
                                                    );
                                                }
                                        }
                                        className="dropdownItems options"
                                    >
                                        {isBundleProduct ? (
                                            <img
                                                src={item.thumbnail}
                                                alt={item.name}
                                            />
                                        ) : (
                                            item.image && (
                                                <img
                                                    src={item.image}
                                                    alt={
                                                        item.attributes[0]
                                                            .option
                                                    }
                                                />
                                            )
                                        )}
                                        <div className="optionInfo">
                                            <div
                                                className={`optionName ${
                                                    item.stock_status !==
                                                    'instock' && 'soldout'
                                                }`}
                                            >
                                                {isBundleProduct
                                                    ? item.name
                                                    : item.attributes[0].option}
                                            </div>

                                            {item.stock_status !== 'instock' ? (
                                                <div className="optionPrice soldout">
                                                    일시품절
                                                </div>
                                            ) : (
                                                <div className="optionPrice">
                                                    <div className="displayPrice options">
                                                        {isBundleProduct
                                                            ? `${addComma(
                                                                selectedProduct
                                                                    .product
                                                                    .bundled_items_discount[
                                                                    item.id
                                                                    ]
                                                            )}원`
                                                            : item.display_price}
                                                    </div>
                                                    {item.on_sale &&
                                                    !isBundleProduct ? (
                                                        <div className="regularPrice">
                                                            {
                                                                item.display_regular_price
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div className="regularPrice">
                                                            {
                                                                item.display_regular_price
                                                            }
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        {/* 멀티 옵션 상품인 경우 */}
                        {dropDownType !== 'selectCoupon' &&
                            isMultiOptions &&
                            dropDownItems.length > 0 &&
                            dropDownItems?.map((item, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        onClick={
                                            item.stockStatus &&
                                            item.stockStatus !== 'instock'
                                                ? () => {
                                                    alert(
                                                        '해당 옵션은 품절된 옵션 입니다'
                                                    );
                                                }
                                                : () => {
                                                    itemClickEvent(
                                                        item.optionName,
                                                        dropDownIdx
                                                    );
                                                    setSelectedItem(
                                                        item.optionName
                                                    );
                                                    setisExpanded(false);
                                                }
                                        }
                                        className="dropdownItems"
                                    >
                                        <div
                                            className={`optionName ${
                                                item.stockStatus &&
                                                item.stockStatus !== 'instock'
                                                    ? 'soldout'
                                                    : ''
                                            }`}
                                        >
                                            {item.optionName}
                                        </div>
                                        {item.stockStatus &&
                                        item.stockStatus !== 'instock' ? (
                                            <div className="soldout">
                                                일시품절
                                            </div>
                                        ) : (
                                            <div className="priceWrapper">
                                                <div className="displayPrice">
                                                    {dropDownIdx ===
                                                    selectedMultiOptions.length
                                                        ? `${addComma(
                                                            item.price
                                                        )}원`
                                                        : `${addComma(
                                                            item.price
                                                        )}원~`}
                                                </div>
                                                {item.price -
                                                    item.regularPrice !==
                                                    0 && (
                                                        <div className="regularPrice">
                                                            {addComma(
                                                                item.regularPrice
                                                            )}
                                                        </div>
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        {/* 사료 정기 구독인 경우 */}
                        {dropDownType !== 'selectCoupon' &&
                            selectedProduct.product.productCluster ===
                            DOG_FOOD_PRODUCT_CLUSTER &&
                            dropDownItems.length > 0 &&
                            dropDownItems?.map((item) => {
                                return (
                                    <div
                                        key={item.id}
                                        onClick={
                                            item.stock_status &&
                                            item.stock_status === 'instock'
                                                ? () => {
                                                    itemClickEvent(item.id);
                                                    setSelectedItem(
                                                        item.attributes
                                                            ? item
                                                                .attributes[0]
                                                                .option
                                                            : item.optionName
                                                    );
                                                    setisExpanded(false);
                                                    if (dropDownIdx === 0) {
                                                        setSelectedDogfoodOption(
                                                            item.id
                                                        );
                                                    }
                                                }
                                                : () => {
                                                    alert(
                                                        '해당 옵션은 품절된 옵션 입니다'
                                                    );
                                                }
                                        }
                                        className="dropdownItems options"
                                    >
                                        {item.image && (
                                            <img
                                                src={item.image}
                                                alt={item.attributes[0].option}
                                            />
                                        )}
                                        <div className="optionInfo">
                                            <div
                                                className={`optionName ${
                                                    item.stock_status &&
                                                    item.stock_status !==
                                                    'instock' &&
                                                    'soldout'
                                                }`}
                                            >
                                                {item.attributes ? (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: displayOptionNameWithSquareBracket(
                                                                item
                                                                    .attributes[0]
                                                                    ?.option,
                                                                ''
                                                            ),
                                                        }}
                                                    ></div>
                                                ) : (
                                                    item.optionName
                                                )}
                                            </div>
                                            {item.stock_status &&
                                            item.stock_status !== 'instock' ? (
                                                <div className="optionPrice soldout">
                                                    일시품절
                                                </div>
                                            ) : (
                                                <div className="optionPrice">
                                                    <div className="displayPrice options">
                                                        {dropDownIdx === 0
                                                            ? item.display_price
                                                            : dropDownIdx === 2
                                                                ? selectedProduct.display_price
                                                                : selectedProduct.product.product_variations.filter(
                                                                    (variation) =>
                                                                        variation.id ===
                                                                        selectedDogfoodOption
                                                                )[0]
                                                                    .display_price}
                                                    </div>
                                                    <div className="regularPrice">
                                                        {dropDownIdx === 0
                                                            ? item.display_regular_price
                                                            : dropDownIdx === 2
                                                                ? selectedProduct.display_regular_price
                                                                : selectedProduct.product.product_variations.filter(
                                                                    (variation) =>
                                                                        variation.id ===
                                                                        selectedDogfoodOption
                                                                )[0]
                                                                    .display_regular_price}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            )}
        </>
    );
};

export default DropDown;
