import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import { SwiperSlide } from 'swiper/react';
import SliderWrapper from '../../components/Sliders/SliderWrapper';
import {
    BREAKPOINT_TBL,
    DOG_FOOD_COLLECTION_SLUG,
    SANTA_COLLECTION_SLUG,
} from '../../constants/constants';
import { stringLineBreak } from '../../utils/commonUtils';

import './CollectionTopContentContainer.scss';
import CollectionPageStore from '../../stores/CollectionPageStore/CollectionPageStore';
// 이걸 그대로 따서 한느거랑 차이가 있나.... .
const CollectionTopContentContainer = ({ collectionData }) => {
    const { rootStore } = React.useContext(AppContext);
    const { initData } = rootStore;

    const { slug } = useParams<{ slug: string }>();

    const isDogFood = slug === DOG_FOOD_COLLECTION_SLUG;

    const history = useHistory();

    return (
        <>
            {collectionData ? (
                <>
                    <div className="CollectionTopContentContainer">
                        {collectionData.contents.topText.title && (
                            <div className="CollectionTopTextContent">
                                <div className="CollectionTopMainText">
                                    {stringLineBreak(
                                        collectionData.contents.topText.title
                                    )}
                                </div>
                                <div className="CollectionTopSubText">
                                    {
                                        collectionData.contents.topText
                                            .description
                                    }
                                </div>
                            </div>
                        )}
                        {collectionData.contents.topFlex.map((data, idx) => {
                            if (data.type === 'image') {
                                return (
                                    <div
                                        key={idx}
                                        className="CollectionTopImageContent"
                                        style={{
                                            cursor:
                                                slug ===
                                                    SANTA_COLLECTION_SLUG &&
                                                collectionData.contents.topFlex
                                                    .length -
                                                    1 ===
                                                    idx
                                                    ? 'pointer'
                                                    : 'auto',
                                        }}
                                        onClick={
                                            slug === SANTA_COLLECTION_SLUG &&
                                            collectionData.contents.topFlex
                                                .length -
                                                1 ===
                                                idx
                                                ? () => {
                                                      history.push('/box');
                                                  }
                                                : undefined
                                        }
                                    >
                                        <picture>
                                            <source
                                                srcSet={
                                                    initData &&
                                                    `${initData.resourcePrefix}${data.contents[0].srcDt}`
                                                }
                                                media={`(min-width: ${BREAKPOINT_TBL}px)`}
                                            ></source>
                                            <img
                                                src={
                                                    initData &&
                                                    `${initData.resourcePrefix}${data.contents[0].srcMo}`
                                                }
                                                alt=""
                                                className="CollectionTopImage"
                                            />
                                        </picture>
                                    </div>
                                );
                            }
                            if (data.type === 'slide') {
                                return (
                                    <div className="CollectionTopSlider">
                                        <SliderWrapper
                                            spaceBetween={16}
                                            // width={10}
                                            slidesPerView={1}
                                            // overFlowVisible
                                            withDefaultNavigation
                                            withDefaultPagination
                                            withProgressBar
                                            withProgressIndex
                                            breakpoints={{
                                                [BREAKPOINT_TBL]: {
                                                    spaceBetween: 64,
                                                },
                                            }}
                                        >
                                            {data.contents.map((content) => {
                                                return (
                                                    <SwiperSlide
                                                        key={content.id}
                                                    >
                                                        <picture>
                                                            <picture>
                                                                <source
                                                                    className="CollectionTopSlideImg"
                                                                    srcSet={
                                                                        initData &&
                                                                        `${initData.resourcePrefix}${content.srcDt}`
                                                                    }
                                                                    media={`(min-width: ${BREAKPOINT_TBL}px)`}
                                                                ></source>
                                                                <img
                                                                    className="CollectionTopSlideImg"
                                                                    src={
                                                                        initData &&
                                                                        `${initData.resourcePrefix}${content.srcMo}`
                                                                    }
                                                                    alt=""
                                                                />
                                                            </picture>
                                                        </picture>
                                                    </SwiperSlide>
                                                );
                                            })}
                                        </SliderWrapper>
                                    </div>
                                );
                            }
                        })}
                    </div>
                    {isDogFood && (
                        <div className="dogfoodCollecitonImgs">
                            <img
                                className="mb"
                                src="/img/bbs/bbs-collection-mb-01.jpg"
                            />
                            <a
                                href="https://baconbox.typeform.com/to/ExEegRyo?typeform-source=baconbox.co"
                                target="_blank"
                            >
                                <img
                                    className="click mb"
                                    src="/img/bbs/bbs-collection-mb-02.jpg"
                                />
                            </a>
                            {/* <img
                                className="mb"
                                src="/img/bbs/bbs-collection-mb-03.jpg"
                            /> */}
                            <img
                                className="pc"
                                src="/img/bbs/bbs-collection-pc-01.jpg"
                            />
                            <a
                                href="https://baconbox.typeform.com/to/ExEegRyo?typeform-source=baconbox.co"
                                target="_blank"
                            >
                                <img
                                    className="click pc"
                                    src="/img/bbs/bbs-collection-pc-02.jpg"
                                />
                            </a>
                            {/* <img
                                className="pc"
                                src="/img/bbs/bbs-collection-pc-03.jpg"
                            /> */}
                        </div>
                    )}
                </>
            ) : (
                <div>Loading...</div>
            )}
        </>
    );
};

export default CollectionTopContentContainer;
