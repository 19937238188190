import * as React from 'react';
import RootStore from '../../stores/RootStore/RootStore';
// import RootStore from '../stores/RootStore';

const rootStore = new RootStore();

export function createStores() {
    return {
        rootStore: rootStore,
        homeStore: rootStore.homeStore,
        topBannerStore: rootStore.topBannerStore,
        bbPageStore: rootStore.bbPageStore,
        collectionPageStore: rootStore.collectionPageStore,
        cartPageStore: rootStore.cartPageStore,
        miniCartPopupStore: rootStore.miniCartPopupStore,
        accountStore: rootStore.accountStore,
        userInfoStore: rootStore.userInfoStore,
        modalStore: rootStore.modalStore
    };
}

export const stores = createStores();
export const AppContext = React.createContext(stores);
