import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import HomeProductListsContainer from '../../containers/HomeProductListsContainer/HomeProductListsContainer';
import './EmptyCartNotice.scss';
import { useHistory } from 'react-router-dom';

const EmptyCartNotice = () => {
    const { cartPageStore, rootStore } = useContext(AppContext);
    const { setIsSpinnerOn } = rootStore;
    const { cartBestProductsList, setCartBestProductsList } = cartPageStore;
    const history = useHistory();
    useEffect(() => {
        setIsSpinnerOn(true);
        setCartBestProductsList().then(() => {
            setIsSpinnerOn(false);
        });
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className="EmptyCartNotice">
            <div className="noticeSection">
                <div className="contentWrapper">
                    <h2 className="emptyCartNoticeTitle">
                        장바구니에 담긴 상품이 <br /> 아직 없어요😭
                    </h2>
                    <p className="emptyCartNoticeDesc">
                        반려견을 위한 베이컨만의 다양한 제품을 만나보세요!
                    </p>
                    <div
                        className="emptyCartNoticeBtn"
                        onClick={() => {
                            history.push('/store/collection/all-collection');
                        }}
                    >
                        제품 보러가기
                    </div>
                </div>
            </div>
            <div className="bestItemsList">
                <h2 className="listTitle">지금 가장 인기 있는 제품</h2>
                {cartBestProductsList && (
                    <HomeProductListsContainer
                        // key={i}
                        slideCartegoryData={cartBestProductsList.categories}
                        slideData={cartBestProductsList.products}
                        // title={cartBestProductsList.title}
                        filterType={cartBestProductsList.filterType}
                        className="homeContainers"
                        type={'fix'}
                        linkText={'none'}
                        isRank={
                            cartBestProductsList.filterType === 'best' &&
                            cartBestProductsList.isVisibleBadge
                                ? true
                                : false
                        }
                    />
                )}
            </div>
        </section>
    );
};

export default EmptyCartNotice;
