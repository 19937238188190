import { makeAutoObservable, runInAction } from 'mobx';
import HomeStore from '../HomeStore/HomeStore';
import TopBannerStore from '../TopBannerStore/TopBannerStore';
import BBPageStore from '../BBPageStore/BBPageStore';
import CollectionPageStore from '../CollectionPageStore/CollectionPageStore';
import CartPageStore from '../CartPageStore/CartPageStore';
import MiniCartStore from '../MiniCartStore/MiniCartStore';
import AccountStore from '../AccountStore/AccountStore';
import UserInfoStore from '../UserInfoStore/UserInfoStroe';
import autobind from 'autobind-decorator';
import { HOME_SLUG } from '../../constants/constants';
import {ModalStore} from "../ModalStore/ModalStore";
@autobind
class RootStore {
    homeStore;
    topBannerStore;
    bbPageStore;
    cartPageStore;
    modalStore;
    languageBasedOnLocation = 'ko';
    initData;
    collectionPageStore: CollectionPageStore;
    entireAppTopPadding = 0;
    isBodyScrollable = true;
    isContentFromBottomOn = false;
    miniCartPopupStore;
    userInfoStore;
    accountStore;

    isStaticModalOn = false;
    isDimBgOn = false;
    isSpinnerOn = false;
    isScrollDown = false;
    isLoginModalOn = false;
    isLoginModalSignUpOn = false;
    loginModalStep = "";

    isAgreementModalOn = false;
    agreementType = 'terms';

    showSearch = false;

    currentTarget = { slug: HOME_SLUG };

    isGlobalError = false;
    isFixedTopMenu = false;
    commonPopupContetns = null;

    constructor() {
        makeAutoObservable(this);
        this.homeStore = new HomeStore();
        this.topBannerStore = new TopBannerStore();
        this.bbPageStore = new BBPageStore();
        this.collectionPageStore = new CollectionPageStore();
        this.cartPageStore = new CartPageStore(this);
        this.miniCartPopupStore = new MiniCartStore(this);
        this.userInfoStore = new UserInfoStore();
        this.accountStore = new AccountStore();
        this.modalStore = new ModalStore()
    }

    setIsFixedTopMenu = (bool) => {
        this.isFixedTopMenu = bool;
    };

    setIsGlobalError = (bool) => {
        this.isGlobalError = bool;
    };

    setCurrentTarget = (slug) => {
        this.currentTarget = slug;
    };

    setInitData = (initData) => {
        this.initData = initData;
    };

    setEntireAppTopPadding = (padding) => {
        this.entireAppTopPadding = padding;
    };

    setIsStaticModalOn = (bool) => {
        this.isStaticModalOn = bool;
    };

    setIsDimBgOn = (bool) => {
        this.isDimBgOn = bool;
    };

    setIsBodyScrollable = (bool) => {
        this.isBodyScrollable = bool;
    };

    setIsContentFromBottomOn = (bool) => {
        this.isContentFromBottomOn = bool;
    };

    setIsScrollDown = (bool) => {
        this.isScrollDown = bool;
    };

    setIsLoginModalOn = (bool) => {
        this.isLoginModalOn = bool;
        this.isLoginModalSignUpOn = false;
    };

    setSignUpModalOn = (bool , loginModalStep?:any) => {
        this.isLoginModalOn = bool;
        this.isLoginModalSignUpOn = bool;

        this.loginModalStep = loginModalStep ? loginModalStep : "init";
    }

    setIsAgreementModalOn = (bool) => {
        this.isAgreementModalOn = bool;
    };

    setAgreementType = (type) => {
        this.agreementType = type;
    };

    setLanguageBasedOnLocation = (lng) => {
        this.languageBasedOnLocation = lng;
    };

    setIsSpinnerOn = (bool) => {
        runInAction(() => {
            this.isSpinnerOn = bool;
        });
    };

    setShowSearch = (bool) => {
        this.showSearch = bool;
    };

    resetCommonPopupContents = (val) => {
        this.commonPopupContetns = val;
    };

    setCommonPopupContents = (
        title: string,
        content: string,
        btnInfo: Array<{
            buttonName: string;
            bgColor: string;
            clickFunc: () => void;
            customText?: string;
        }>
    ) => {
        this.commonPopupContetns = {
            title,
            content,
            btnInfo,
        };
    };
}

export default RootStore;
