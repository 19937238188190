import moment from 'moment';
import React from 'react';
import {DOG_FOOD_PRODUCT_CLUSTER} from '../constants/constants';
import {
    BB_ITEMS,
    BB_LITE_ITEMS,
    SANTA_BB_ITEMS,
    SANTA_BB_LITE_ITEMS,
} from '../constants/myAccountConstants';
import {isDogFoodProduct} from './miniCartUtils';

export const SubscribeFreeItemId = [1356520, 1356519, 1356522, 1356523, 1356521, 1356524]

export const getLineItemCnt = (lineItems) => {
    let cnt = 0;

    if (lineItems == undefined || lineItems.length < 1) {
        return 0;
    }

    lineItems.map((lineItem) => {
        if (lineItem.sku?.includes('bb_snack') == false) {
            cnt++;
        }
    });

    return cnt;
};

export const getDogFoodItems = (lineItems) => {
    if (!lineItems || lineItems.length < 1) {
        return [];
    }

    let dogFoodItems = [];

    lineItems.map((lineItem) => {
        // console.log(lineItem);

        if (lineItem.product.productCluster === DOG_FOOD_PRODUCT_CLUSTER) {
            // console.log("dogFoodItems" , lineItem);
            dogFoodItems.push(lineItem);
        }
    });
    // console.log("dogFoodItems" , dogFoodItems);

    return dogFoodItems;
};

export const getBBItems = (lineItems) => {
    if (!lineItems || lineItems.length < 1) {
        return [];
    }

    let bbParentItems: Array<any> = [];
    let bbChildItemMap: Map<string, Array<any>> = new Map();

    let bbItems = [];
    let legacyBBItems = [];
    let isIncludeSantaPresent = false;

    if (location.pathname.includes('/my-account/orders')) {
        isIncludeSantaPresent =
            lineItems.filter(
                (item) =>
                    item?.meta_data.filter((meta) => meta?.key === 'is_present')
                        .length > 0
            ).length > 0;
    }

    //get baconbox items
    lineItems.map((lineItem) => {
        const bbLineItem = lineItem.product.categories?.find((category) =>
            category.slug.includes('baconbox')
        );

        if (bbLineItem) {
            bbItems.push(lineItem);

            if (is_legacy_baconbox(lineItem.product)) {
                lineItem.bbData = {
                    total: new Number(lineItem.total),
                    regular_price: new Number(lineItem.product.regular_price),
                };
                legacyBBItems.push(lineItem);
            }
        }
    });

    //meta_data -> meta
    //meta or meta_data의 dispaly_key 와 key는 값이 다릅니다.
    for (let i = 0; i < bbItems.length; i++) {
        if (bbItems[i].meta_data && !bbItems[i].meta) {
            for (let z = 0; z < bbItems[i].meta_data.length; z++) {
                bbItems[i].meta = bbItems[i].meta_data;
                // console.log(lineItems[i].meta);
            }
        }

        let parentKey: string = '';
        let isParent = false;

        for (let k = 0; k < bbItems[i].meta?.length; k++) {
            if (
                bbItems[i].meta[k].key === 'is_parent' &&
                bbItems[i].meta[k].value == 'true'
            ) {
                bbParentItems.push(bbItems[i]);
                isParent = true;
            } else if (bbItems[i].meta[k].key === 'parent_key') {
                parentKey = bbItems[i].meta[k].value;
            }
        }

        if (parentKey.length > 0 && !isParent) {
            //child
            let childItems: Array<any> = [];
            if (bbChildItemMap.get(parentKey)) {
                //동일한 parent_key를 가진 child가 있는 경우

                // console.log(bbChildItemMap.get(parentKey));
                childItems = [...childItems, ...bbChildItemMap.get(parentKey)];
            }

            childItems.push(bbItems[i]);
            bbChildItemMap.set(parentKey, childItems);
        }
    }

    // mapping parent - child relationship
    for (let i = 0; i < bbParentItems.length; i++) {
        //부모 자식 상품 합 가격
        bbParentItems[i].bbData = {
            total: new Number(bbParentItems[i].total),
            regular_price: new Number(bbParentItems[i].product.regular_price),
        };

        let parentKeyMeta = bbParentItems[i].meta.find(
            (meta) => meta.key === 'parent_key'
        );
        let parent_key = parentKeyMeta?.value;

        bbParentItems[i].meta
            .filter(
                (metaFilter) =>
                    metaFilter.key === 'parent_key' &&
                    metaFilter.value === parent_key
            )
            .map((meta) => {
                // console.log(".." , meta);

                //기본 구성품
                let children =
                    bbParentItems[i].sku.includes('lite') &&
                    isIncludeSantaPresent
                        ? [SANTA_BB_LITE_ITEMS]
                        : !bbParentItems[i].sku.includes('lite') &&
                        isIncludeSantaPresent
                            ? [SANTA_BB_ITEMS]
                            : bbParentItems[i].sku.includes('lite') &&
                            !isIncludeSantaPresent
                                ? [BB_LITE_ITEMS]
                                : [BB_ITEMS];

                // console.log(bbChildItemMap);

                if (bbChildItemMap?.get(meta.value)) {
                    // console.log(bbChildItemMap.get(meta.value));

                    bbChildItemMap.get(meta.value).map((childItem) => {
                        // console.log(childItem);
                        bbParentItems[i].bbData.total += new Number(
                            childItem.total
                        );
                        bbParentItems[i].bbData.regular_price += new Number(
                            childItem.product.regular_price
                        );
                    });

                    children = [...children, ...bbChildItemMap.get(meta.value)];
                }

                bbParentItems[i].children = children;
            });
    }

    let BBItems = [...bbParentItems, ...legacyBBItems];

    return BBItems;
};

export const getBundleItems = (lineItems) => {
    let bundleParentItems = [];

    let bundleChildrenMap = new Map<string, Array<any>>();
    //get bundle parent
    for (let i = 0; i < lineItems.length; i++) {
        // console.log(i , lineItems[i]);

        //parent check
        if (lineItems[i].product.type !== 'bundle') {
            continue;
        }

        for (let z = 0; z < lineItems[i].meta_data?.length; z++) {
            lineItems[i].meta_data[z].key =
                lineItems[i].meta_data[z].display_key;
            lineItems[i].meta_data[z].value =
                lineItems[i].meta_data[z].display_value;
            lineItems[i].meta = lineItems[i].meta_data;
            // console.log(lineItems[i].meta);
        }

        bundleParentItems.push(lineItems[i]);
        // console.log(lineItems[i]);
    }

    for (let i = 0; i < lineItems.length; i++) {
        //child
        let bundledByMeta = lineItems[i].meta_data?.filter(
            (meta) => meta.key === '_bundled_by'
        );

        if (bundledByMeta?.length < 1) {
            continue;
        }

        for (let z = 0; z < lineItems[i].meta_data?.length; z++) {
            lineItems[i].meta_data[z].key =
                lineItems[i].meta_data[z].display_key;
            lineItems[i].meta_data[z].value =
                lineItems[i].meta_data[z].display_value;
            lineItems[i].meta = lineItems[i].meta_data;

            // console.log(lineItems[i].meta);
        }

        // console.log(lineItems[i]);

        //process bundleChildrenMap
        let bundledBy = lineItems[i].meta.find(
            (meta) => meta.key === '_bundled_by'
        );

        // console.log(bundledBy);
        if (bundledBy !== undefined) {
            let prevMap = bundleChildrenMap.get(bundledBy.value);

            let val =
                prevMap === null || prevMap === undefined
                    ? [lineItems[i]]
                    : [...prevMap, lineItems[i]];
            bundleChildrenMap.set(bundledBy.value, val);
        }
    }

    // console.log("map" , bundleChildrenMap);

    //get childMap
    for (let i = 0; i < bundleParentItems.length; i++) {
        // console.log(bundleParentItems[i]);

        //부모 자식 상품 합 가격
        bundleParentItems[i].priceSum = {
            total: new Number(bundleParentItems[i].total),
            regular_price:
                parseInt(bundleParentItems[i].product.regular_price) *
                bundleParentItems[i].quantity,
        };

        let bundleCartKeyMeta = bundleParentItems[i].meta_data.find(
            (meta) => meta.key === '_bundle_cart_key'
        );

        // console.log(i , bundleCartKeyMeta);

        let children = [];
        if (bundleCartKeyMeta !== undefined) {
            children = bundleChildrenMap.get(bundleCartKeyMeta.value);
        }

        for (let j = 0; j < children?.length; j++) {
            bundleParentItems[i].priceSum.total += parseInt(
                children[j].subtotal
            );
            bundleParentItems[i].priceSum.regular_price +=
                parseInt(children[j].product.regular_price) *
                children[j].quantity;
        }

        bundleParentItems[i].children = children?.length > 0 ? children : [];
    }

    return bundleParentItems;
};

export const getDateYYYYMMDDHHMMSS = (date) => {
    if (date === undefined || date === null || date.length < 1) {
        return '없음';
    }

    let momentDate = moment(date);
    return momentDate.format('YYYY-MM-DD HH:mm:ss');
};

const bbV2skus = ['bb_v2', 'bb_snack'];
export const is_legacy_baconbox = (product) => {
    if (!product) {
        return false;
    }

    for (let i = 0; i < bbV2skus.length; i++) {
        if (product.sku.includes(bbV2skus[i])) {
            return false;
        }
    }

    return true;
};

export const hasTermBB = (subscription) => {
    if (!subscription) {
        return false;
    }

    for (let i = 0; i < subscription.line_items.length; i++) {
        if (
            subscription.line_items[i].sku.includes(12) ||
            subscription.line_items[i].sku.includes(6)
        ) {
            return true;
        }
    }

    return false;
};

export const getSubscriptionPlan = (subscription) => {
    let subscriptionPlan = '정기배송';

    for (let i in subscription.line_items) {
        let line_item = subscription.line_items[i];
        let meta = line_item.meta ? line_item.meta : line_item.meta_data;

        if (isDogFoodProduct(line_item.product)) {
            if (subscription.billing_interval && subscription.billing_period) {
                const period =
                    subscription.billing_period === 'month' ? '달' : '주';
                subscriptionPlan =
                    subscription.billing_interval + period + ' 마다 정기배송';
            }

            return subscriptionPlan;
        } else {
            const subscriptionPlanMeta = meta.find(
                (meta) => meta.key === 'pa_subscription_terms'
            );
            if (subscriptionPlanMeta) {
                subscriptionPlan = subscriptionPlanMeta.display_value
                    ? subscriptionPlanMeta.display_value
                    : subscriptionPlanMeta.value;

                subscriptionPlan += ' 정기배송';
                return subscriptionPlan;
            }
        }
    }

    return '';
};

export const isMShopRenewalOrder = (order) => {
    return (
        !order.payment_method?.includes('iamport') &&
        order.parent_id &&
        order.parent_id > 0
    );
};

export const getDogFoodOptionName = (lineItem) => {
    // console.log(lineItem.product);

    let optionName = '';
    if (
        isDogFoodProduct(lineItem.product) &&
        lineItem.product.type.includes('variable')
    ) {
        // console.log("getDogFoodOptionName" , lineItem);

        if (lineItem.meta && lineItem.meta.length > 0) {
            optionName = lineItem.meta[0].value;
        } else if (lineItem.meta_data && lineItem.meta_data.length > 0) {
            optionName = lineItem.meta_data[0].display_value;
        }
    }

    // console.log(optionName);
    return optionName;
};
