import React, { useContext, useState, useEffect, useRef } from 'react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import BBOptionSelector from '../BBOptionSelector/BBOptionSelector';
import BBSantaPresentList from '../BBSantaPresentList/BBSantaPresentList';
import { defaultElementProps } from '../../types/types';
import { fetchAndReceiveJSON } from '../../utils/ajaxUtils';
import { BB_PAGE_DATA_URL } from '../../constants/constants';
import './ChangeBBOptionPopup.scss';
import { observer } from 'mobx-react';

interface IChangeBBOptionPopup extends defaultElementProps {
    currentWidth: number;
}

const ChangeBBOptionPopup: React.FunctionComponent<IChangeBBOptionPopup> =
    observer(({ currentWidth }) => {
        const { rootStore, cartPageStore, bbPageStore } =
            useContext(AppContext);
        const { setIsDimBgOn, setIsSpinnerOn } = rootStore;
        const {
            setIsBBOptionChanging,
            selectedBundleProducts,
            removeCartItem,
            selectedProduct,
            setCartInfoList,
            setCartListCount,
            selectedSantaPresentInCart,
            currentPopupFlow,
            setCurrentPopupFlow,
            cartInfoList,
        } = cartPageStore;
        const {
            setBBPageData,
            setSelectedSnack,
            addBBtoCart,
            selectedSnack,
            setSelectedPlan,
            setFunTypeObject,
            selectedSantaPresent,
            setSelectedBB,
        } = bbPageStore;
        // const [currentPopupFlow, setCurrentPopupFlow] = useState('option');

        useEffect(() => {
            document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;`;

            // setShowOptionBox(false);
            setIsDimBgOn(true);
            return () => {
                setIsDimBgOn(false);

                const scrollY = document.body.style.top;
                document.body.style.cssText = '';
                window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
            };
        }, []);

        useEffect(() => {
            const fetchBBData = async () => {
                const res = await fetchAndReceiveJSON({
                    url: BB_PAGE_DATA_URL,
                });

                setBBPageData(res);

                const bundleSlug =
                    selectedBundleProducts === undefined ||
                    !selectedBundleProducts.parent_cart_item_key
                        ? 'snack_zero'
                        : selectedBundleProducts.variations[0].attributes[0]
                              .slug;

                setSelectedSnack({
                    slug: bundleSlug,
                    oneMonth:
                        bundleSlug === 'snack_zero'
                            ? res.bbDetails.bbSnacks.once.variations.common[0]
                            : res.bbDetails.bbSnacks.once.variations._1month.filter(
                                  (snack) => snack.slug === bundleSlug
                              )[0],
                    sixMonth:
                        bundleSlug === 'snack_zero'
                            ? res.bbDetails.bbSnacks.once.variations.common[0]
                            : res.bbDetails.bbSnacks.subs.variations._6months.filter(
                                  (snack) => snack.slug === bundleSlug
                              )[0],
                    twelveMonth:
                        bundleSlug === 'snack_zero'
                            ? res.bbDetails.bbSnacks.once.variations.common[0]
                            : res.bbDetails.bbSnacks.subs.variations._12months.filter(
                                  (snack) => snack.slug === bundleSlug
                              )[0],
                    monthly:
                        bundleSlug === 'snack_zero'
                            ? res.bbDetails.bbSnacks.once.variations.common[0]
                            : res.bbDetails.bbSnacks.subs.variations.monthly.filter(
                                  (snack) => snack.slug === bundleSlug
                              )[0],
                });

                setFunTypeObject(
                    res.bbDetails.bbDetailsByBbType.filter(
                        (type) => type.bbType === 'Fun'
                    )[0]
                );
                setSelectedBB(
                    selectedProduct.product.sku === 'bb_v2' ||
                        selectedProduct.product.sku === 'bb_v2_1month'
                        ? 'Fun'
                        : 'Lite'
                );
            };

            fetchBBData();

            setSelectedPlan({
                id: selectedProduct.variation_id,
                name: '',
                slug: selectedProduct.variations
                    ? `_${selectedProduct.variations[0].attributes[0].slug}`
                    : selectedProduct.product.product_variations.attributes
                    ? `_${
                          selectedProduct.product.product_variations.filter(
                              (variation) =>
                                  variation.id === selectedProduct.variation_id
                          )[0].attributes[0].slug
                      }`
                    : selectedProduct.product.sku.substring(5),
                regularPrice: selectedProduct.regular_price,
                price: selectedProduct.price,
                discountPrice: '',
                discountRate: '',
                isOnSale: true,
            });
        }, []);

        const closePopupWhenClickDim = (ref) => {
            useEffect(() => {
                function handleClickOutside(event) {
                    if (ref.current && !ref.current.contains(event.target)) {
                        setIsDimBgOn(false);
                        setIsBBOptionChanging(false);
                    }
                }

                // Bind the event listener
                document.addEventListener('mousedown', handleClickOutside);
                return () => {
                    // Unbind the event listener on clean up
                    document.removeEventListener(
                        'mousedown',
                        handleClickOutside
                    );
                };
            }, [ref]);
        };

        const wrapperRef = useRef(null);
        closePopupWhenClickDim(wrapperRef);

        const BBoptionChangeHandler = (removeItems) => {
            setIsSpinnerOn(true);
            removeCartItem(removeItems).then(() =>
                addBBtoCart()
                    .then(() => setCartListCount())
                    .then(() => setCartInfoList())
                    .then(() => {
                        setIsBBOptionChanging(false);
                        setIsDimBgOn(false);
                        alert('옵션 변경이 완료되었습니다!');
                        setCurrentPopupFlow('option');
                        setIsSpinnerOn(false);
                    })
            );
        };

        return (
            <div
                className={`ChangeBBOptionPopup ${
                    currentPopupFlow === 'santaPresent' ? 'santa' : ''
                }`}
                ref={wrapperRef}
            >
                <div
                    onClick={() => {
                        setIsDimBgOn(false);
                        setIsBBOptionChanging(false);
                        setCurrentPopupFlow('option');
                    }}
                    className="closeOptionPopupBtn"
                >
                    <img
                        src={
                            currentWidth > 768
                                ? '/img/btn-gnb-popup-close.svg'
                                : '/img/btn-gnb-popup-close-white.svg'
                        }
                        alt="close-popup-button"
                    />
                </div>
                {currentPopupFlow === 'option' ? (
                    <BBOptionSelector />
                ) : (
                    <BBSantaPresentList />
                )}
                <div className="changeOptionBtnContainer">
                    <div
                        onClick={
                            selectedProduct.product.sku === 'bb_v2' ||
                            selectedProduct.product.sku === 'bb_v2_1month'
                                ? () => {
                                      if (
                                          !selectedBundleProducts ||
                                          !selectedBundleProducts.parent_cart_item_key
                                      ) {
                                          if (
                                              selectedSnack.slug !==
                                              'snack_zero'
                                          ) {
                                              BBoptionChangeHandler([
                                                  {
                                                      cart_item_key:
                                                          selectedProduct.cart_item_key,
                                                  },
                                                  //   {
                                                  //       cart_item_key:
                                                  //           selectedSantaPresentInCart.cart_item_key,
                                                  //   },
                                              ]);
                                          } else {
                                              setIsBBOptionChanging(false);
                                              alert(
                                                  '옵션이 변경되지 않았습니다.'
                                              );
                                              setCurrentPopupFlow('option');
                                              setIsDimBgOn(false);
                                          }
                                      } else {
                                          if (
                                              selectedSnack.slug !==
                                              selectedBundleProducts
                                                  .variations[0].attributes[0]
                                                  .slug
                                          ) {
                                              BBoptionChangeHandler([
                                                  {
                                                      cart_item_key:
                                                          selectedProduct.cart_item_key,
                                                  },
                                                  {
                                                      cart_item_key:
                                                          selectedBundleProducts.cart_item_key,
                                                  },
                                                  //   {
                                                  //       cart_item_key:
                                                  //           selectedSantaPresentInCart.cart_item_key,
                                                  //   },
                                              ]);
                                          } else {
                                              setIsBBOptionChanging(false);
                                              alert(
                                                  '옵션이 변경되지 않았습니다.'
                                              );
                                              setCurrentPopupFlow('option');
                                              setIsDimBgOn(false);
                                          }
                                      }
                                  }
                                : () => {
                                      setIsBBOptionChanging(false);
                                      alert('옵션이 변경되지 않았습니다.');
                                      setCurrentPopupFlow('option');
                                      setIsDimBgOn(false);
                                  }
                        }
                        className="changeOptionBtn"
                    >
                        변경하기
                    </div>
                </div>
            </div>
        );
    });

export default ChangeBBOptionPopup;
