import {makeAutoObservable, runInAction} from 'mobx';
import {Thumbs} from 'swiper';
import {CART_ADD_URL} from '../../constants/constants';
import {fetchAndReceiveJSON} from '../../utils/ajaxUtils';
import {addComma} from '../../utils/commonUtils';

class BBPageStore {
    isFullPopUpOn = false;
    notLogInPromoData = {
        text: '가입하고 무료 장난감 + 쿠폰 받기!',
        color: 'black',
        img: '/img/img-line-banner-thumbnail@2x.png',
    };
    BBTopBannerSlideIndex = 0;
    selectedSnack = {
        slug: '',
        twelveMonth: {
            discountPrice: '',
            discountRate: '',
            displayDiscountPrice: '',
            displayPrice: '',
            displayRegularPrice: '',
            id: 0,
            image: '',
            isOnSale: true,
            name: '',
            price: '',
            regularPrice: '',
            slug: '',
        },
        oneMonth: {
            discountPrice: '',
            discountRate: '',
            displayDiscountPrice: '',
            displayPrice: '',
            displayRegularPrice: '',
            id: 0,
            image: '',
            isOnSale: true,
            name: '',
            price: '',
            regularPrice: '',
            slug: '',
        },
        sixMonth: {
            discountPrice: '',
            discountRate: '',
            displayDiscountPrice: '',
            displayPrice: '',
            displayRegularPrice: '',
            id: 0,
            image: '',
            isOnSale: true,
            name: '',
            price: '',
            regularPrice: '',
            slug: '',
        },
        monthly: {
            discountPrice: '',
            discountRate: '',
            displayDiscountPrice: '',
            displayPrice: '',
            displayRegularPrice: '',
            id: 0,
            image: '',
            isOnSale: true,
            name: '',
            price: '',
            regularPrice: '',
            slug: '',
        },
    };
    // selectedSnack;

    selectedSupplements: any = [];
    selectedBB = 'Fun';
    selectedPlan = {
        id: '',
        name: '',
        slug: '_12months',
        regularPrice: 0,
        price: 0,
        discountPrice: '',
        discountRate: '',
        isOnSale: true,
    };
    selected;
    funTypeObject;
    liteTypeObject;
    purchasePrice = {
        price: '',
        regularPrice: '',
        discountRate: 'discountRate',
    };
    lowestPrice = {
        price: '',
        regularPrice: '',
        discountRate: 'discountRate',
    };

    BBTopBannerData = [
        {
            id: '1',
            mainText: `정성을 담다.
즐거움으로 가득 차다.`,
            desc: `행동 기반 장난감 세트와 맞춤 간식 세트, 
정성으로 가득 담은 매달의 새로운 맞춤 구성품이
반려견의 즐거움을 가득 채웁니다.`,
            file_mo: '/img/group-15@2x.png',
            file_dt: '/img/group-15@3x.png',
            bgColor: '#ed3421',
        },
        {
            id: '2',
            mainText: `정성을 담다2.
즐거움으로 가득 차다.`,
            desc: `행동 기반 장난감 세트와 맞춤 간식 세트, 
정성으로 가득 담은 매달의 새로운 맞춤 구성품이
반려견의 즐거움을 가득 채웁니다.`,
            file_mo: '/img/group-15@2x.png',
            file_dt: '/img/group-15@3x.png',
            bgColor: '#ed3421',
        },
        {
            id: '3',
            mainText: `정성을 담다3.
즐거움으로 가득 차다.`,
            desc: `행동 기반 장난감 세트와 맞춤 간식 세트, 
정성으로 가득 담은 매달의 새로운 맞춤 구성품이
반려견의 즐거움을 가득 채웁니다.`,
            file_mo: '/img/group-15@2x.png',
            file_dt: '/img/group-15@3x.png',
            bgColor: '#ed3421',
        },
    ];
    BBPageData;

    fullPagePopUpContent = 'option';
    selectedSantaPresent;

    constructor() {
        makeAutoObservable(this);
    }

    setSelectedSupplements = (supplements) => {
        if (
            this.selectedSupplements.filter((el) => el.id === supplements.id)
                .length > 0
        ) {
            this.selectedSupplements = this.selectedSupplements.filter(
                (el) => el.id !== supplements.id
            );
        } else {
            this.selectedSupplements = [
                ...this.selectedSupplements,
                supplements,
            ];
        }
    };

    setSelectedSantaPresent = (productInfo) => {
        this.selectedSantaPresent = productInfo;
    };

    setIsClicked = (click) => {
        this.setIsClicked = click;
    };

    setSelectedSnack = (snack) => {
        this.selectedSnack = snack;
        this.calculateLowestPrice();
        this.calculatePurchasePrice();

    };
    setIsFullPopUpOn = (bool) => {
        this.isFullPopUpOn = bool;
    };
    setBBTopBannerSlideIndex = (data) => {
        this.BBTopBannerSlideIndex = data;
    };

    setSelectedBB = (data) => {
        this.selectedBB = data;
    };

    setFullPagePopUpContent = (str) => {
        this.fullPagePopUpContent = str;
    };

    setBBPageData = (data) => {
        this.BBPageData = data;
    };

    setLiteTypeObject = (obj) => {
        this.liteTypeObject = obj;
    };

    setFunTypeObject = (obj) => {
        this.funTypeObject = obj;
    };

    setSelectedPlan = (obj) => {
        this.selectedPlan = obj;
        this.calculatePurchasePrice();
    };

    calculateLowestPrice = () => {
        if (this.funTypeObject && this.selectedBB === 'Fun') {
            const price =
                36900 +
                Number(
                    this.selectedSnack.twelveMonth
                        ? this.selectedSnack.twelveMonth.price
                        : 0
                );

            const regularPrice =
                55000 +
                Number(
                    this.selectedSnack.twelveMonth
                        ? this.selectedSnack.twelveMonth.regularPrice
                        : 0
                );
            const discountRate = `${Math.ceil(
                ((regularPrice - price) / regularPrice) * 100
            )}%`;

            this.lowestPrice = {
                price: addComma(price),
                regularPrice: addComma(regularPrice),
                discountRate: discountRate,
            };
        } else if (this.liteTypeObject && this.selectedBB === 'Lite') {
            const price =
                22900 +
                Number(
                    this.selectedSnack.twelveMonth
                        ? this.selectedSnack.twelveMonth.price
                        : 0
                );
            const regularPrice =
                32500 +
                Number(
                    this.selectedSnack.twelveMonth
                        ? this.selectedSnack.twelveMonth.regularPrice
                        : 0
                );
            const discountRate = `${Math.ceil(
                ((regularPrice - price) / regularPrice) * 100
            )}%`;

            this.lowestPrice = {
                price: addComma(price),
                regularPrice: addComma(regularPrice),
                discountRate: discountRate,
            };
        }
        return;
    };

    calculatePurchasePrice = () => {
        let regularPrice, price, discountRate;

        let snackPrice, snackRegularPrice;

        switch (this.selectedPlan.slug) {
            case '_1month':
                snackPrice = this.selectedSnack.oneMonth
                    ? this.selectedSnack.oneMonth.price
                    : 0;
                snackRegularPrice = this.selectedSnack.oneMonth
                    ? this.selectedSnack.oneMonth.regularPrice
                    : 0;
                break;
            case '_6months':
                snackPrice = this.selectedSnack.sixMonth
                    ? this.selectedSnack.sixMonth.price
                    : 0;
                snackRegularPrice = this.selectedSnack.sixMonth
                    ? this.selectedSnack.sixMonth.regularPrice
                    : 0;
                break;
            case '_12months':
                snackPrice = this.selectedSnack.twelveMonth
                    ? this.selectedSnack.twelveMonth.price
                    : 0;
                snackRegularPrice = this.selectedSnack.twelveMonth
                    ? this.selectedSnack.twelveMonth.regularPrice
                    : 0;
                break;
            case 'monthly':
                snackPrice = this.selectedSnack.monthly
                    ? this.selectedSnack.monthly.price
                    : 0;
                snackRegularPrice = this.selectedSnack.monthly
                    ? this.selectedSnack.monthly.regularPrice
                    : 0;
                break;
            default:
                break;
        }

        price = Number(this.selectedPlan.price) + Number(snackPrice);
        regularPrice =
            Number(this.selectedPlan.regularPrice) + Number(snackRegularPrice);

        discountRate = `${Math.ceil(
            ((regularPrice - price) / regularPrice) * 100
        )}%`;

        this.purchasePrice = {
            price: addComma(price),
            regularPrice: addComma(regularPrice),
            discountRate: discountRate,
        };

        return;
    };

    // 여기에서 서버로 콜 하는 함수를  써도 되지 않을까?
    // 이건 내일 하자
    addBBtoCart = async () => {
        let bbid, snackId, snackVariationId;

        if (this.selectedPlan.slug === '_1month') {
            if (this.selectedBB === 'Fun') {
                bbid = this.funTypeObject.bbIds.once;
            } else if (this.selectedBB === 'Lite') {
                bbid = this.liteTypeObject.bbIds.once;
            }
            snackId = this.BBPageData.bbDetails.bbSnacks.once.id;
        } else {
            if (this.selectedBB === 'Fun') {
                bbid = this.funTypeObject.bbIds.subs;
            } else if (this.selectedBB === 'Lite') {
                bbid = this.funTypeObject.bbIds.subs;
            }
            snackId = this.BBPageData.bbDetails.bbSnacks.subs.id;
        }

        switch (this.selectedPlan.slug) {
            case '_1month':
                snackVariationId = this.selectedSnack.oneMonth.id;
                break;
            case '_6months':
                snackVariationId = this.selectedSnack.sixMonth.id;
                break;
            case '_12months':
                snackVariationId = this.selectedSnack.twelveMonth.id;
                break;
            case 'monthly':
                snackVariationId = this.selectedSnack.monthly.id;
                break;
            case '_monthly':
                snackVariationId = this.selectedSnack.monthly.id;
                break;
            default:
                break;
        }
        let itemsToAdd;

        if (this.selectedBB === 'Fun') {
            if (snackVariationId === 0) {
                itemsToAdd = [
                    {
                        product_id: bbid,
                        variation_id: this.selectedPlan.id,
                        quantity: 1,
                    },
                    // {
                    //     product_id: this.selectedSantaPresent.id,
                    //     variation_id:
                    //         this.selectedSantaPresent.productVariation.length >
                    //         0
                    //             ? this.selectedSantaPresent.productVariation[0]
                    //                   .id
                    //             : 0,
                    //     quantity: 1,
                    //     is_present: true,
                    //     is_selected: true,
                    // },
                ];
            } else {
                itemsToAdd = [
                    {
                        product_id: bbid,
                        variation_id: this.selectedPlan.id,
                        quantity: 1,
                    },
                    {
                        product_id: snackId,
                        variation_id: snackVariationId,
                        quantity: 1,
                    },
                    // {
                    //     product_id: 571217,
                    //     quantity: 1,
                    //     is_present: true,
                    //     is_selected: true,
                    // },
                ];
            }
        } else if (this.selectedBB === 'Lite') {
            itemsToAdd = [
                {
                    product_id: bbid,
                    variation_id: this.selectedPlan.id,
                    quantity: 1,
                },
                // {
                //     product_id: this.selectedSantaPresent.id,
                //     variation_id:
                //         this.selectedSantaPresent.productVariation.length > 0
                //             ? this.selectedSantaPresent.productVariation[0].id
                //             : 0,
                //     quantity: 1,
                //     is_present: true,
                //     is_selected: true,
                // },
            ];
        }

        const res = await fetchAndReceiveJSON({
            url: CART_ADD_URL,
            method: 'POST',
            body: itemsToAdd,
        });

        return res;
    };
}

export default BBPageStore;
