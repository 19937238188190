// import { observer } from 'mobx-react';
import {useHistory} from 'react-router-dom';
import React, {useState, useRef, useDebugValue} from 'react';
import {defaultElementProps} from '../../types/types';
import './CartItemsContainer.scss';
import {AppContext} from '../../contexts/AppContext/AppContext';
import {addComma, openChannelIO} from '../../utils/commonUtils';
import {DOG_FOOD_PRODUCT_CLUSTER} from '../../constants/constants';

interface ICartItemsContainer extends defaultElementProps {
    isCartItemsOpen: boolean;
    setIsCartItemsOpen: any;
    qtyController: any;
    qtyInputHandler: any;
    qtyValue: any;
    setQtyValue: any;
}

const CartItemsContainer: React.FunctionComponent<ICartItemsContainer> = ({
                                                                              isCartItemsOpen,
                                                                              setIsCartItemsOpen,
                                                                              qtyController,
                                                                              qtyInputHandler,
                                                                              qtyValue,
                                                                              setQtyValue,
                                                                          }) => {
    const {cartPageStore, rootStore} = React.useContext(AppContext);
    const {setIsDimBgOn, setIsSpinnerOn, setCommonPopupContents} = rootStore;

    const {
        removeCartItem,
        cartListCount,
        resetCartList,
        cartInfoList,
        setShowOptionBox,
        setSelectedProduct,
        setIsBundleProduct,
        setSelectedBundleProducts,
        setIsBBOptionChanging,
        setCurrentSelectedBundleProducts,
        setCartInfoList,
        setCartListCount,
        setIsCartLoading,
        updateCartInfoList,
        setResetSelectedMultiOptions,
        setSelectedDogfoodOption,
        setSelectedSantaPresentInCart,
        setCurrentPopupFlow,
    } = cartPageStore;

    const history = useHistory();

    return (
        <div
            className={`cartItemsContainer ${
                isCartItemsOpen ? 'isCartItemsOpen' : ''
            }`}
        >
            <div className="cartItemsTopArea">
                <div className="cartItemsTopText">구매하실 상품</div>
            </div>
            <div className="cartItemsBodyArea">
                {cartInfoList.items &&
                    cartInfoList.items.cartItems.map(
                        (item, idx) =>
                            idx === 0 && (
                                <div
                                    key={item.cart_item_key}
                                    onClick={() => {
                                        setIsCartItemsOpen(!isCartItemsOpen);
                                    }}
                                    className={`cartItemsSummaryBox ${
                                        isCartItemsOpen ? 'isCartItemsOpen' : ''
                                    }`}
                                >
                                    <img
                                        className="cartItemsThumbnail"
                                        src={item.product.thumbnail}
                                        alt=""
                                    />
                                    <div className="cartItemsSummaryText">
                                        {cartInfoList.items.cartItems.length > 1
                                            ? `${item.product.name} 외 ${
                                                cartInfoList.items.cartItems
                                                    .length - 1
                                            }개 상품`
                                            : item.product.name}
                                    </div>
                                    <div className="dropDownIcon">
                                        <img src="/img/m-btn-icon-dropdown-open-24.svg"/>
                                    </div>
                                </div>
                            )
                    )}

                <div
                    className={`cartItemsDetail ${
                        isCartItemsOpen ? 'isCartItemsOpen' : ''
                    }`}
                >
                    {cartInfoList &&
                        cartInfoList.items.cartItems.filter(
                            (item) =>
                                (item.product.type ===
                                    'variable-subscription' &&
                                    (item.is_bb ||
                                        item.product.productCluster ===
                                        DOG_FOOD_PRODUCT_CLUSTER)) ||
                                (item.product.type === 'subscription' &&
                                    item.product.productCluster ===
                                    DOG_FOOD_PRODUCT_CLUSTER)
                        ).length > 0 && (
                            <div className="subscribtionItemWrapper">
                                <div className="cartItemCategory">
                                    <img
                                        src="img/icon-cart-subscription.svg"
                                        alt=""
                                        className="cartegoryLogo"
                                    />
                                    <div className="cartItemCategoryText">
                                        정기배송 상품
                                    </div>
                                </div>
                                {cartInfoList.items.cartItems.map((item) => {
                                    if (
                                        item.product.type ===
                                        'variable-subscription' &&
                                        item.is_bb
                                    ) {
                                        // const santaPresent =
                                        //     cartInfoList.items.cartItems.filter(
                                        //         (subitem) =>
                                        //             subitem.parent_cart_item_key ===
                                        //                 item.cart_item_key &&
                                        //             subitem.is_present
                                        //     ).length > 0 &&
                                        //     cartInfoList.items.cartItems.filter(
                                        //         (subitem) =>
                                        //             subitem.parent_cart_item_key ===
                                        //                 item.cart_item_key &&
                                        //             subitem.is_present
                                        //     )[0].product_name;
                                        return (
                                            <div
                                                key={item.cart_item_key}
                                                className="cartItemBox"
                                            >
                                                <img
                                                    onClick={
                                                        cartInfoList.items.cartItems.filter(
                                                            (subItem) =>
                                                                subItem.parent_cart_item_key ===
                                                                item.cart_item_key
                                                        ).length > 0
                                                            ? () => {
                                                                setIsSpinnerOn(
                                                                    true
                                                                );
                                                                removeCartItem(
                                                                    [
                                                                        {
                                                                            cart_item_key:
                                                                            item.cart_item_key,
                                                                        },
                                                                        ...cartInfoList.items.cartItems
                                                                            .filter(
                                                                                (
                                                                                    optionItem
                                                                                ) =>
                                                                                    optionItem.parent_cart_item_key ===
                                                                                    item.cart_item_key
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    removedItem
                                                                                ) => {
                                                                                    return {
                                                                                        cart_item_key:
                                                                                        removedItem.cart_item_key,
                                                                                    };
                                                                                }
                                                                            ),
                                                                        //   {
                                                                        //       cart_item_key:
                                                                        //           cartInfoList.items.cartItems.filter(
                                                                        //               (
                                                                        //                   optionItem
                                                                        //               ) =>
                                                                        //                   optionItem.parent_cart_item_key ===
                                                                        //                   item.cart_item_key
                                                                        //           )[0]
                                                                        //               .cart_item_key,
                                                                        //   },
                                                                    ]
                                                                )
                                                                    .then(
                                                                        () =>
                                                                            setCartListCount()
                                                                    )
                                                                    .then(
                                                                        () =>
                                                                            setCartInfoList()
                                                                    )
                                                                    .then(
                                                                        () =>
                                                                            setIsSpinnerOn(
                                                                                false
                                                                            )
                                                                    );
                                                            }
                                                            : () => {
                                                                setIsSpinnerOn(
                                                                    true
                                                                );
                                                                removeCartItem(
                                                                    [
                                                                        {
                                                                            cart_item_key:
                                                                            item.cart_item_key,
                                                                        },
                                                                    ]
                                                                )
                                                                    .then(
                                                                        () =>
                                                                            setCartListCount()
                                                                    )
                                                                    .then(
                                                                        () =>
                                                                            setCartInfoList()
                                                                    )
                                                                    .then(
                                                                        () =>
                                                                            setIsSpinnerOn(
                                                                                false
                                                                            )
                                                                    );
                                                            }
                                                    }
                                                    src="/img/common-btn-list-close-16.svg"
                                                    alt=""
                                                    className="cartItemBoxCloseButton"
                                                />
                                                <div>
                                                    <div className="cartItemHeadArea">
                                                        <img
                                                            onClick={() => {
                                                                history.push(
                                                                    '/box'
                                                                );
                                                            }}
                                                            className="cartItemsThumbnail"
                                                            src="/img/baconbox-multi.jpg"
                                                            alt={
                                                                item.product_name
                                                            }
                                                        />
                                                        <div className="cartItemInfoTexts">
                                                            <div
                                                                onClick={() => {
                                                                    history.push(
                                                                        '/box'
                                                                    );
                                                                }}
                                                                className="itemName"
                                                            >
                                                                {item.product.sku.substring(
                                                                    6
                                                                ) === 'lite'
                                                                    ? '베이컨 박스 LITE'
                                                                    : '베이컨 박스'}
                                                            </div>
                                                            <div className="itemType">
                                                                {
                                                                    item.product_option_name
                                                                }{' '}
                                                                정기배송
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="cartItemBodyArea">
                                                        <div className="optionsList">
                                                            <div className="optionItem">
                                                                <div className="optionProductName">
                                                                    {item.product.sku.substring(
                                                                        6
                                                                    ) === 'lite'
                                                                        ? '행동 발달 토이 1개, 반려견 맞춤 간식 1종'
                                                                        : '행동 발달토이 1개, 미니 토이 1개, 테마 생활용품 1개'}
                                                                </div>
                                                                <div className="optionPrice">
                                                                    <div className="displayPrice">
                                                                        {
                                                                            item.display_price
                                                                        }
                                                                    </div>
                                                                    {item.on_sale && (
                                                                        <div className="regularPrice">
                                                                            {
                                                                                item.display_regular_price
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {cartInfoList.items.cartItems
                                                                .filter(
                                                                    (
                                                                        optionItem
                                                                    ) =>
                                                                        optionItem.parent_cart_item_key ===
                                                                        item.cart_item_key
                                                                )
                                                                .map((item) => {
                                                                    return (
                                                                        <div
                                                                            className="optionItem"
                                                                            key={
                                                                                item.cart_item_key
                                                                            }
                                                                        >
                                                                            <div className="optionProductName">
                                                                                {
                                                                                    item.product_name
                                                                                }
                                                                            </div>
                                                                            <div className="optionPrice">
                                                                                <div className="displayPrice">
                                                                                    {
                                                                                        item.display_price
                                                                                    }
                                                                                </div>
                                                                                {item.on_sale && (
                                                                                    <div className="regularPrice">
                                                                                        {
                                                                                            item.display_regular_price
                                                                                        }
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                        {/* <div className="changeQty">
                                                                <img
                                                                    className=""
                                                                    src="/img/m-btn-count-num-minus.svg"
                                                                    alt=""
                                                                />
                                                                <span>
                                                                    {
                                                                        item.quantity
                                                                    }
                                                                </span>
                                                                <img
                                                                    className=""
                                                                    src="/img/m-btn-count-num-plus.svg"
                                                                    alt=""
                                                                />
                                                            </div> */}
                                                    </div>
                                                </div>
                                                <div
                                                    className={`cartItemBottomArea ${
                                                        item.product.sku.substring(
                                                            6
                                                        ) === 'lite' && 'lite'
                                                    }`}
                                                >
                                                    {item.product.sku ===
                                                        'bb_v2' && (
                                                            <div
                                                                onClick={() => {
                                                                    setIsDimBgOn(
                                                                        true
                                                                    );
                                                                    setSelectedProduct(
                                                                        item
                                                                    );
                                                                    setSelectedBundleProducts(
                                                                        cartInfoList.items.cartItems.filter(
                                                                            (
                                                                                optionItem
                                                                            ) =>
                                                                                optionItem.parent_cart_item_key ===
                                                                                item.cart_item_key
                                                                        )[0]
                                                                    );
                                                                    // setSelectedSantaPresentInCart(
                                                                    //     cartInfoList.items.cartItems.filter(
                                                                    //         (
                                                                    //             optionItem
                                                                    //         ) =>
                                                                    //             optionItem.parent_cart_item_key ===
                                                                    //                 item.cart_item_key
                                                                    //     )[0]
                                                                    // );
                                                                    setIsBBOptionChanging(
                                                                        true
                                                                    );
                                                                }}
                                                                className="changeOptionBtn"
                                                            >
                                                                옵션 변경
                                                            </div>
                                                        )}
                                                    <div className="bottomAreaRightSection">
                                                        {item.product_option_name !==
                                                            '매월' && (
                                                                <div className="firstMonthFreeLabel">
                                                                    첫 달 무료
                                                                </div>
                                                            )}
                                                        <div className="showTotalPrice">
                                                            <div className="displayPrice">
                                                                {/* {cartInfoList.items.cartItems.filter(
                                                                    (subItem) =>
                                                                        subItem
                                                                            .product
                                                                            .type ===
                                                                        'variable-subscription'
                                                                ).length > 1
                                                                    ? `${addComma(
                                                                          item.price +
                                                                              cartInfoList.items.cartItems.filter(
                                                                                  (
                                                                                      optionItem
                                                                                  ) =>
                                                                                      optionItem.parent_cart_item_key ===
                                                                                      item.cart_item_key
                                                                              )[0]
                                                                                  .price
                                                                      )}원`
                                                                    : `${addComma(
                                                                          item.price
                                                                      )}원`} */}
                                                                {cartInfoList.items.cartItems.filter(
                                                                    (snack) =>
                                                                        snack.parent_cart_item_key ===
                                                                        item.cart_item_key
                                                                ).length > 0 &&
                                                                item.product
                                                                    .sku ===
                                                                'bb_v2'
                                                                    ? `${addComma(
                                                                        item.price +
                                                                        cartInfoList.items.cartItems.filter(
                                                                            (
                                                                                optionItem
                                                                            ) =>
                                                                                optionItem.parent_cart_item_key ===
                                                                                item.cart_item_key
                                                                        )[0]
                                                                            .price
                                                                    )}원`
                                                                    : `${addComma(
                                                                        item.price
                                                                    )}원`}
                                                            </div>
                                                            <div className="regularPrice">
                                                                {cartInfoList.items.cartItems.filter(
                                                                    (snack) =>
                                                                        snack.parent_cart_item_key ===
                                                                        item.cart_item_key
                                                                ).length > 0 &&
                                                                item.product
                                                                    .sku ===
                                                                'bb_v2'
                                                                    ? `${addComma(
                                                                        item.regular_price +
                                                                        cartInfoList.items.cartItems.filter(
                                                                            (
                                                                                optionItem
                                                                            ) =>
                                                                                optionItem.parent_cart_item_key ===
                                                                                item.cart_item_key
                                                                        )[0]
                                                                            .regular_price
                                                                    )}원`
                                                                    : `${addComma(
                                                                        item.regular_price
                                                                    )}`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })}
                                {/* 사료 정기구독인 경우 */}
                                {cartInfoList.items.cartItems.map((item) => {
                                    if (
                                        // item.product.type ===
                                        //     'variable-subscription' &&
                                        item.product.productCluster ===
                                        DOG_FOOD_PRODUCT_CLUSTER
                                    ) {
                                        return (
                                            <div
                                                key={item.cart_item_key}
                                                className="cartItemBox"
                                            >
                                                <img
                                                    onClick={() => {
                                                        setIsSpinnerOn(true);
                                                        removeCartItem([
                                                            {
                                                                cart_item_key:
                                                                item.cart_item_key,
                                                            },
                                                        ])
                                                            .then(() =>
                                                                setCartListCount()
                                                            )
                                                            .then(() =>
                                                                setCartInfoList()
                                                            )
                                                            .then(() =>
                                                                setIsSpinnerOn(
                                                                    false
                                                                )
                                                            );
                                                    }}
                                                    src="/img/common-btn-list-close-16.svg"
                                                    alt=""
                                                    className="cartItemBoxCloseButton"
                                                />
                                                <div>
                                                    <div className="cartItemHeadArea dogFood">
                                                        <div className="dogFoodContent">
                                                            <img
                                                                onClick={() => {
                                                                    history.push(
                                                                        item
                                                                            .product
                                                                            .url
                                                                    );
                                                                }}
                                                                className="cartItemsThumbnail"
                                                                src={
                                                                    item.product
                                                                        .thumbnail
                                                                }
                                                                alt={
                                                                    item.product_name
                                                                }
                                                            />
                                                            <div className="cartItemInfoTexts">
                                                                <div
                                                                    onClick={() =>
                                                                        history.push(
                                                                            item
                                                                                .product
                                                                                .url
                                                                        )
                                                                    }
                                                                    className="itemName"
                                                                >
                                                                    {item
                                                                            .product
                                                                            .productBrand &&
                                                                        `[${item.product.productBrand}]`}
                                                                    <br/>
                                                                    {
                                                                        item
                                                                            .product
                                                                            .name
                                                                    }
                                                                </div>
                                                                <div
                                                                    className="dogfoodOption"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            item.variations &&
                                                                            item
                                                                                .variations[0]
                                                                                .attributes[0]
                                                                                .option,
                                                                    }}
                                                                ></div>
                                                                <div className="itemType">
                                                                    {`${
                                                                        item.billing_interval
                                                                    }${
                                                                        item.billing_period ===
                                                                        'week'
                                                                            ? '주'
                                                                            : ''
                                                                    } 마다`}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="changeQty">
                                                            <img
                                                                onClick={
                                                                    item.item_stock_status ===
                                                                    'instock'
                                                                        ? (
                                                                            evt
                                                                        ) => {
                                                                            qtyController(
                                                                                evt,
                                                                                item.cart_item_key
                                                                            );
                                                                        }
                                                                        : undefined
                                                                }
                                                                className=""
                                                                src={
                                                                    item.item_stock_status ===
                                                                    'instock'
                                                                        ? '/img/m-btn-count-num-minus.svg'
                                                                        : '/img/m-btn-icon-count-num-minus-disable.svg'
                                                                }
                                                                alt="minus"
                                                            />
                                                            <input
                                                                pattern="\d*"
                                                                onChange={(
                                                                    evt
                                                                ) => {
                                                                    if (
                                                                        parseInt(
                                                                            evt
                                                                                .target
                                                                                .value
                                                                        ) > 1
                                                                    ) {
                                                                        setCommonPopupContents(
                                                                            '이미 장바구니에 담은 베이컨 밥 솔루션 제품이 있습니다.',
                                                                            '현재 베이컨 밥 솔루션은 계정 당 1개의 상품 이용을 제공하고 있습니다. 복수 개 상품의 서비스 신청을 원하신다면, 채팅 상담으로 문의해주세요.',
                                                                            [
                                                                                {
                                                                                    buttonName:
                                                                                        '상담하기',
                                                                                    bgColor:
                                                                                        '#101010',
                                                                                    clickFunc:
                                                                                    openChannelIO,
                                                                                },
                                                                            ]
                                                                        );
                                                                    } else {
                                                                        if (
                                                                            /^[0-9\b]+$/.test(
                                                                                evt
                                                                                    .target
                                                                                    .value
                                                                            ) ||
                                                                            evt
                                                                                .target
                                                                                .value ===
                                                                            ''
                                                                        ) {
                                                                            setQtyValue(
                                                                                {
                                                                                    key: item.key,
                                                                                    value: evt
                                                                                        .target
                                                                                        .value,
                                                                                }
                                                                            );
                                                                            qtyInputHandler(
                                                                                evt,
                                                                                item.cart_item_key,
                                                                                item.product_id,
                                                                                item.variation_id
                                                                            );
                                                                        }
                                                                    }
                                                                }}
                                                                onKeyPress={(
                                                                    evt
                                                                ) => {
                                                                    if (
                                                                        evt.key ===
                                                                        'Enter'
                                                                    ) {
                                                                        qtyInputHandler(
                                                                            evt,
                                                                            item.cart_item_key,
                                                                            item.product_id,
                                                                            item.variation_id
                                                                        );
                                                                    }
                                                                }}
                                                                className={`qtyInput ${
                                                                    item.item_stock_status !==
                                                                    'instock'
                                                                        ? 'soldout'
                                                                        : ''
                                                                }`}
                                                                type="text"
                                                                value={
                                                                    qtyValue.key ===
                                                                    item.key
                                                                        ? qtyValue.value
                                                                        : item.quantity
                                                                }
                                                            />
                                                            <img
                                                                onClick={
                                                                    item.item_stock_status ===
                                                                    'instock'
                                                                        ? (
                                                                            evt
                                                                        ) => {
                                                                            qtyController(
                                                                                evt,
                                                                                item.cart_item_key
                                                                            );
                                                                        }
                                                                        : undefined
                                                                }
                                                                className=""
                                                                src={
                                                                    item.item_stock_status ===
                                                                    'instock'
                                                                        ? '/img/m-btn-count-num-plus.svg'
                                                                        : '/img/m-btn-icon-count-num-plus-disable.svg'
                                                                }
                                                                alt="plus"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`cartItemBottomArea`}
                                                >
                                                    <div
                                                        onClick={() => {
                                                            setIsDimBgOn(true);
                                                            setShowOptionBox(
                                                                true
                                                            );
                                                            setSelectedProduct(
                                                                item
                                                            );
                                                            setSelectedDogfoodOption(
                                                                null
                                                            );
                                                        }}
                                                        className="changeOptionBtn"
                                                    >
                                                        옵션 변경
                                                    </div>
                                                    <div className="bottomAreaRightSection dogFood">
                                                        <div className="upper">
                                                            {/* <div className="firstMonthFreeLabel">
                                                                {`첫 주문 ${item.discount_rate.slice(
                                                                    2,
                                                                    item
                                                                        .discount_rate
                                                                        .length -
                                                                        1
                                                                )} 할인`}
                                                            </div> */}
                                                            <div className="showTotalPrice">
                                                                <div className="displayPrice">
                                                                    {
                                                                        item.display_price
                                                                    }
                                                                </div>
                                                                <div className="regularPrice">
                                                                    {
                                                                        item.display_regular_price
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="dogFoodNextPurchaseInfo">
                                                            {`${
                                                                item.billing_interval
                                                            }${
                                                                item.billing_period ===
                                                                'week'
                                                                    ? '주'
                                                                    : ''
                                                            } 뒤 ${addComma(
                                                                item.origin_line_total
                                                            )}원 결제
                                                                예정`}
                                                        </div>
                                                        <div className="cancelInfo">
                                                            언제든지 취소 가능
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        )}

                    {cartInfoList.items.cartItems.filter(
                        (item) =>
                            item.wdr_free_product !== 'Free' &&
                            item.product.type !== 'variable-subscription' &&
                            !item.product.categories
                                .map((category) => category.slug)
                                .includes('baconselect') &&
                            !item.bundled_by && //번들의 자식상품인 경우
                            // item.bundled_items.length === 0 && //번들부모이지만 자식이 없는 경우
                            // !item.is_bb &&
                            !item.parent_cart_item_key && //bb상품의 자식상품
                            item.product.productCluster !==
                            DOG_FOOD_PRODUCT_CLUSTER
                    ).length > 0 && (
                        <div className="normalItemWrapper">
                            <div className="cartItemCategory">
                                <img
                                    src="img/icon-cart-nomal.svg"
                                    alt=""
                                    className="cartegoryLogo"
                                />
                                <div className="cartItemCategoryText">
                                    일반 상품
                                </div>
                            </div>
                            {/* 일반상품(옵션이 있거나 없거나) - 걸러지는거: 베이컨상품, 증정상품, 셀렉트상품, 번들상품, 번들상품의 자식, 베이컨 1개월짜리*/}
                            {cartInfoList.items.cartItems.map((item) => {
                                if (
                                    item.wdr_free_product !== 'Free' &&
                                    item.product.type !==
                                    'variable-subscription' &&
                                    !item.product.categories
                                        .map((category) => category.slug)
                                        .includes('baconselect') &&
                                    !item.is_bb &&
                                    !item.parent_cart_item_key &&
                                    !item.bundled_by &&
                                    item.product.productCluster !==
                                    DOG_FOOD_PRODUCT_CLUSTER
                                ) {
                                    if (
                                        item.bundled_items &&
                                        item.bundled_items.length === 0
                                    ) {
                                        return (
                                            <div
                                                key={item.cart_item_key}
                                                className="cartItemBox withOption"
                                            >
                                                <img
                                                    onClick={() => {
                                                        setIsSpinnerOn(true);
                                                        removeCartItem([
                                                            {
                                                                cart_item_key:
                                                                item.cart_item_key,
                                                            },
                                                        ])
                                                            .then(() =>
                                                                setCartListCount()
                                                            )
                                                            .then(() =>
                                                                setCartInfoList()
                                                            )
                                                            .then(() =>
                                                                setIsSpinnerOn(
                                                                    false
                                                                )
                                                            );
                                                    }}
                                                    src="/img/common-btn-list-close-16.svg"
                                                    alt="delete-item-button"
                                                    className="cartItemBoxCloseButton"
                                                />
                                                <div className="cartItemHeadArea">
                                                    <img
                                                        onClick={() =>
                                                            history.push(
                                                                item.product.url
                                                            )
                                                        }
                                                        className="cartItemsThumbnail"
                                                        src={
                                                            item.product
                                                                .thumbnail
                                                        }
                                                        alt={item.product_name}
                                                    />
                                                    <div className="cartItemInfoTexts">
                                                        <div
                                                            onClick={() =>
                                                                history.push(
                                                                    item.product
                                                                        .url
                                                                )
                                                            }
                                                            className="itemName"
                                                        >
                                                            {item.product.name}
                                                        </div>
                                                        <div
                                                            className={`itemDesc ${
                                                                item.item_stock_status !==
                                                                'instock' &&
                                                                'soldout'
                                                            }`}
                                                        >
                                                            <div className="optionName">
                                                                {
                                                                    item.product_option_name
                                                                }
                                                            </div>
                                                            {item.item_stock_status !==
                                                                'instock' && (
                                                                    <div className="soldoutNotice">
                                                                        일시 품절
                                                                    </div>
                                                                )}
                                                            {item.shippingAvailableToday && (
                                                                <div className="deliveryInfo">
                                                                    당일 출고
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cartItemBottomArea">
                                                    <div className="controlBox">
                                                        <div className="changeQty">
                                                            <img
                                                                onClick={
                                                                    item.item_stock_status ===
                                                                    'instock'
                                                                        ? (
                                                                            evt
                                                                        ) => {
                                                                            qtyController(
                                                                                evt,
                                                                                item.cart_item_key
                                                                            );
                                                                        }
                                                                        : undefined
                                                                }
                                                                className=""
                                                                src={
                                                                    item.item_stock_status ===
                                                                    'instock'
                                                                        ? '/img/m-btn-count-num-minus.svg'
                                                                        : '/img/m-btn-icon-count-num-minus-disable.svg'
                                                                }
                                                                alt="minus"
                                                            />
                                                            <input
                                                                pattern="\d*"
                                                                onChange={(
                                                                    evt
                                                                ) => {
                                                                    if (
                                                                        /^[0-9\b]+$/.test(
                                                                            evt
                                                                                .target
                                                                                .value
                                                                        ) ||
                                                                        evt
                                                                            .target
                                                                            .value ===
                                                                        ''
                                                                    ) {
                                                                        setQtyValue(
                                                                            {
                                                                                key: item.key,
                                                                                value: evt
                                                                                    .target
                                                                                    .value,
                                                                            }
                                                                        );
                                                                        qtyInputHandler(
                                                                            evt,
                                                                            item.cart_item_key,
                                                                            item.product_id,
                                                                            item.variation_id
                                                                        );
                                                                    }
                                                                }}
                                                                onKeyPress={(
                                                                    evt
                                                                ) => {
                                                                    if (
                                                                        evt.key ===
                                                                        'Enter'
                                                                    ) {
                                                                        qtyInputHandler(
                                                                            evt,
                                                                            item.cart_item_key,
                                                                            item.product_id,
                                                                            item.variation_id
                                                                        );
                                                                    }
                                                                }}
                                                                className={`qtyInput ${
                                                                    item.item_stock_status !==
                                                                    'instock'
                                                                        ? 'soldout'
                                                                        : ''
                                                                }`}
                                                                type="text"
                                                                value={
                                                                    qtyValue.key ===
                                                                    item.key
                                                                        ? qtyValue.value
                                                                        : item.quantity
                                                                }
                                                            />
                                                            <img
                                                                onClick={
                                                                    item.item_stock_status ===
                                                                    'instock'
                                                                        ? (
                                                                            evt
                                                                        ) => {
                                                                            qtyController(
                                                                                evt,
                                                                                item.cart_item_key
                                                                            );
                                                                        }
                                                                        : undefined
                                                                }
                                                                className=""
                                                                src={
                                                                    item.item_stock_status ===
                                                                    'instock'
                                                                        ? '/img/m-btn-count-num-plus.svg'
                                                                        : '/img/m-btn-icon-count-num-plus-disable.svg'
                                                                }
                                                                alt="plus"
                                                            />
                                                        </div>
                                                        {(item.product.type ===
                                                            'variable' ||
                                                            item.product
                                                                .children) && (
                                                            <div
                                                                onClick={
                                                                    item.item_stock_status ===
                                                                    'instock' &&
                                                                    item.product
                                                                        .children ===
                                                                    undefined
                                                                        ? () => {
                                                                            setShowOptionBox(
                                                                                true
                                                                            );
                                                                            setIsDimBgOn(
                                                                                true
                                                                            );
                                                                            setSelectedProduct(
                                                                                item
                                                                            );
                                                                            setResetSelectedMultiOptions();
                                                                        }
                                                                        : item.item_stock_status ===
                                                                        'instock' &&
                                                                        item
                                                                            .product
                                                                            .children
                                                                            ? () => {
                                                                                setShowOptionBox(
                                                                                    true
                                                                                );
                                                                                setIsDimBgOn(
                                                                                    true
                                                                                );
                                                                                setSelectedProduct(
                                                                                    item
                                                                                );
                                                                                setSelectedBundleProducts(
                                                                                    []
                                                                                );
                                                                                setCurrentSelectedBundleProducts(
                                                                                    []
                                                                                );
                                                                                setIsBundleProduct(
                                                                                    true
                                                                                );
                                                                            }
                                                                            : undefined
                                                                }
                                                                className="changeOptionBtn"
                                                            >
                                                                옵션 변경
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="showTotalPrice">
                                                        <div className="displayPrice">
                                                            {item.display_price}
                                                        </div>
                                                        {item.on_sale && (
                                                            <div className="regularPrice">
                                                                {
                                                                    item.display_regular_price
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                    if (!item.bundled_items) {
                                        return (
                                            <div
                                                key={item.cart_item_key}
                                                className="cartItemBox withOption"
                                            >
                                                <img
                                                    onClick={() => {
                                                        setIsSpinnerOn(true);
                                                        removeCartItem([
                                                            {
                                                                cart_item_key:
                                                                item.cart_item_key,
                                                            },
                                                        ])
                                                            .then(() =>
                                                                setCartListCount()
                                                            )
                                                            .then(() =>
                                                                setCartInfoList()
                                                            )
                                                            .then(() =>
                                                                setIsSpinnerOn(
                                                                    false
                                                                )
                                                            );
                                                    }}
                                                    src="/img/common-btn-list-close-16.svg"
                                                    alt="delete-item-button"
                                                    className="cartItemBoxCloseButton"
                                                />
                                                <div className="cartItemHeadArea">
                                                    <img
                                                        onClick={() =>
                                                            history.push(
                                                                item.product.url
                                                            )
                                                        }
                                                        className="cartItemsThumbnail"
                                                        src={
                                                            item.product
                                                                .thumbnail
                                                        }
                                                        alt={item.product_name}
                                                    />
                                                    <div className="cartItemInfoTexts">
                                                        <div
                                                            onClick={() =>
                                                                history.push(
                                                                    item.product
                                                                        .url
                                                                )
                                                            }
                                                            className="itemName"
                                                        >
                                                            {item.product.name}
                                                        </div>
                                                        <div
                                                            className={`itemDesc ${
                                                                item.item_stock_status !==
                                                                'instock' &&
                                                                'soldout'
                                                            }`}
                                                        >
                                                            <div className="optionName">
                                                                {item.variations &&
                                                                item
                                                                    .variations[0]
                                                                    .attributes
                                                                    .length > 1
                                                                    ? item.variations[0].attributes
                                                                        .map(
                                                                            (
                                                                                attr
                                                                            ) =>
                                                                                attr.option
                                                                        )
                                                                        .reduce(
                                                                            (
                                                                                option,
                                                                                str
                                                                            ) =>
                                                                                `${option}, ` +
                                                                                str
                                                                        )
                                                                    : item.product_option_name}
                                                            </div>
                                                            {item.item_stock_status !==
                                                                'instock' && (
                                                                    <div className="soldoutNotice">
                                                                        일시 품절
                                                                    </div>
                                                                )}
                                                            {item.shippingAvailableToday && (
                                                                <div className="deliveryInfo">
                                                                    당일 출고
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cartItemBottomArea">
                                                    <div className="controlBox">
                                                        <div className="changeQty">
                                                            <img
                                                                onClick={
                                                                    item.item_stock_status ===
                                                                    'instock' &&
                                                                    item.quantity >
                                                                    1
                                                                        ? (
                                                                            evt
                                                                        ) => {
                                                                            qtyController(
                                                                                evt,
                                                                                item.cart_item_key
                                                                            );
                                                                        }
                                                                        : undefined
                                                                }
                                                                className=""
                                                                src={
                                                                    item.item_stock_status ===
                                                                    'instock'
                                                                        ? '/img/m-btn-count-num-minus.svg'
                                                                        : '/img/m-btn-icon-count-num-minus-disable.svg'
                                                                }
                                                                alt="minus"
                                                            />
                                                            <input
                                                                pattern="\d*"
                                                                onChange={(
                                                                    evt
                                                                ) => {
                                                                    if (
                                                                        /^[0-9\b]+$/.test(
                                                                            evt
                                                                                .target
                                                                                .value
                                                                        ) ||
                                                                        evt
                                                                            .target
                                                                            .value ===
                                                                        ''
                                                                    ) {
                                                                        setQtyValue(
                                                                            {
                                                                                key: item.key,
                                                                                value: evt
                                                                                    .target
                                                                                    .value,
                                                                            }
                                                                        );
                                                                        qtyInputHandler(
                                                                            evt,
                                                                            item.cart_item_key,
                                                                            item.product_id,
                                                                            item.variation_id
                                                                        );
                                                                    }
                                                                }}
                                                                onKeyPress={(
                                                                    evt
                                                                ) => {
                                                                    if (
                                                                        evt.key ===
                                                                        'Enter'
                                                                    ) {
                                                                        qtyInputHandler(
                                                                            evt,
                                                                            item.cart_item_key,
                                                                            item.product_id,
                                                                            item.variation_id
                                                                        );
                                                                    }
                                                                }}
                                                                className={`qtyInput ${
                                                                    item.item_stock_status !==
                                                                    'instock'
                                                                        ? 'soldout'
                                                                        : ''
                                                                }`}
                                                                type="text"
                                                                value={
                                                                    qtyValue.key ===
                                                                    item.key
                                                                        ? qtyValue.value
                                                                        : item.quantity
                                                                }
                                                            />
                                                            <img
                                                                onClick={
                                                                    item.item_stock_status ===
                                                                    'instock'
                                                                        ? (
                                                                            evt
                                                                        ) => {
                                                                            qtyController(
                                                                                evt,
                                                                                item.cart_item_key
                                                                            );
                                                                        }
                                                                        : undefined
                                                                }
                                                                className=""
                                                                src={
                                                                    item.item_stock_status ===
                                                                    'instock'
                                                                        ? '/img/m-btn-count-num-plus.svg'
                                                                        : '/img/m-btn-icon-count-num-plus-disable.svg'
                                                                }
                                                                alt="plus"
                                                            />
                                                        </div>
                                                        {(item.product.type ===
                                                            'variable' ||
                                                            item.product
                                                                .children) && (
                                                            <div
                                                                onClick={
                                                                    item.item_stock_status ===
                                                                    'instock' &&
                                                                    item.product
                                                                        .children ===
                                                                    undefined
                                                                        ? () => {
                                                                            setShowOptionBox(
                                                                                true
                                                                            );
                                                                            setIsDimBgOn(
                                                                                true
                                                                            );
                                                                            setSelectedProduct(
                                                                                item
                                                                            );
                                                                        }
                                                                        : item.item_stock_status ===
                                                                        'instock' &&
                                                                        item
                                                                            .product
                                                                            .children
                                                                            ? () => {
                                                                                setShowOptionBox(
                                                                                    true
                                                                                );
                                                                                setIsDimBgOn(
                                                                                    true
                                                                                );
                                                                                setSelectedProduct(
                                                                                    item
                                                                                );
                                                                                setSelectedBundleProducts(
                                                                                    []
                                                                                );
                                                                                setCurrentSelectedBundleProducts(
                                                                                    []
                                                                                );
                                                                                setIsBundleProduct(
                                                                                    true
                                                                                );
                                                                            }
                                                                            : undefined
                                                                }
                                                                className="changeOptionBtn"
                                                            >
                                                                옵션 변경
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="showTotalPrice">
                                                        <div className="displayPrice">
                                                            {item.display_price}
                                                        </div>
                                                        {item.on_sale && (
                                                            <div className="regularPrice">
                                                                {
                                                                    item.display_regular_price
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                }
                            })}
                            {/* 일반상품(하위 품목들이 있는 경우 -> 번들상품, 그룹상품, 베이컨박스 1개월짜리) */}
                            {/* 일단은 bb 상품인 경우만 들어와 있음 번들상품 데이터 완료되면 잘 나오는지 더블체크! */}
                            {cartInfoList.items.cartItems.map((item) => {
                                if (
                                    item.wdr_free_product !== 'Free' &&
                                    item.product.type !==
                                    'variable-subscription' &&
                                    !item.product.categories
                                        .map((category) => category.slug)
                                        .includes('baconselect') &&
                                    (item.is_bb ||
                                        (item.bundled_items &&
                                            item.bundled_items.length !== 0))
                                ) {
                                    // const santaPresent =
                                    //     cartInfoList.items.cartItems.filter(
                                    //         (subitem) =>
                                    //             subitem.parent_cart_item_key ===
                                    //                 item.cart_item_key &&
                                    //             subitem.is_present
                                    //     )[0].product_name;
                                    return (
                                        <div
                                            key={item.cart_item_key}
                                            className="cartItemBox subItems"
                                        >
                                            <img
                                                onClick={() => {
                                                    const removeChildrenItems =
                                                        cartInfoList.items.cartItems
                                                            .filter(
                                                                (child) =>
                                                                    child.parent_cart_item_key ===
                                                                    item.cart_item_key
                                                            )
                                                            .map((child) => {
                                                                return {
                                                                    cart_item_key:
                                                                    child.cart_item_key,
                                                                };
                                                            });
                                                    // : cartInfoList.items.cartItems
                                                    //       .filter(
                                                    //           (bundle) =>
                                                    //               bundle.bundled_by ===
                                                    //               item.cart_item_key
                                                    //       )
                                                    //       .map((child) => {
                                                    //           return {
                                                    //               cart_item_key:
                                                    //                   child.cart_item_key,
                                                    //           };
                                                    //       });

                                                    setIsSpinnerOn(true);

                                                    removeCartItem(
                                                        item.is_bb
                                                            ? [
                                                                {
                                                                    cart_item_key:
                                                                    item.cart_item_key,
                                                                },
                                                                ...removeChildrenItems,
                                                            ]
                                                            : [
                                                                {
                                                                    cart_item_key:
                                                                    item.cart_item_key,
                                                                },
                                                            ]
                                                    )
                                                        .then(() =>
                                                            setCartListCount()
                                                        )
                                                        .then(() =>
                                                            setCartInfoList()
                                                        )
                                                        .then(() =>
                                                            setIsSpinnerOn(
                                                                false
                                                            )
                                                        );
                                                }}
                                                src="/img/common-btn-list-close-16.svg"
                                                alt="delete-item-button"
                                                className="cartItemBoxCloseButton"
                                            />
                                            <div className="cartItemHeadArea">
                                                <div
                                                    className={`cartItemInfo ${
                                                        item.is_bb && 'baconbox'
                                                    }`}
                                                >
                                                    <img
                                                        onClick={() =>
                                                            history.push(
                                                                item.product.url
                                                            )
                                                        }
                                                        className="cartItemsThumbnail"
                                                        src={
                                                            item.is_bb
                                                                ? '/img/baconbox-1.jpg'
                                                                : item.product
                                                                    .thumbnail
                                                        }
                                                        alt={item.product_name}
                                                    />
                                                    <div className="cartItemInfoTexts">
                                                        <div
                                                            onClick={() =>
                                                                history.push(
                                                                    item.product
                                                                        .url
                                                                )
                                                            }
                                                            className="itemName"
                                                        >
                                                            {item.product.name}
                                                        </div>
                                                        <div className="itemDesc">
                                                            {item.is_bb && (
                                                                <div className="optionName baconbox">
                                                                    1회 구매
                                                                </div>
                                                            )}
                                                            {item.shippingAvailableToday && (
                                                                <div className="deliveryInfo">
                                                                    당일 출고
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {!item.is_bb && (
                                                    <div className="cartItemQtyAndPrice">
                                                        <div className="changeQty">
                                                            <img
                                                                onClick={(
                                                                    evt
                                                                ) =>
                                                                    qtyController(
                                                                        evt,
                                                                        item.cart_item_key
                                                                    )
                                                                }
                                                                className="qtyBtn"
                                                                src="/img/m-btn-count-num-minus.svg"
                                                                alt="minus"
                                                            />
                                                            <input
                                                                pattern="\d*"
                                                                onChange={(
                                                                    evt
                                                                ) => {
                                                                    if (
                                                                        /^[0-9\b]+$/.test(
                                                                            evt
                                                                                .target
                                                                                .value
                                                                        ) ||
                                                                        evt
                                                                            .target
                                                                            .value ===
                                                                        ''
                                                                    ) {
                                                                        setQtyValue(
                                                                            {
                                                                                key: item.key,
                                                                                value: evt
                                                                                    .target
                                                                                    .value,
                                                                            }
                                                                        );
                                                                        qtyInputHandler(
                                                                            evt,
                                                                            item.cart_item_key,
                                                                            item.product_id,
                                                                            item.variation_id
                                                                        );
                                                                    }
                                                                }}
                                                                onKeyPress={(
                                                                    evt
                                                                ) => {
                                                                    if (
                                                                        evt.key ===
                                                                        'Enter'
                                                                    ) {
                                                                        qtyInputHandler(
                                                                            evt,
                                                                            item.cart_item_key,
                                                                            item.product_id,
                                                                            item.variation_id
                                                                        );
                                                                    }
                                                                }}
                                                                className={`qtyInput ${
                                                                    item.item_stock_status !==
                                                                    'instock'
                                                                        ? 'soldout'
                                                                        : ''
                                                                }`}
                                                                type="text"
                                                                value={
                                                                    qtyValue.key ===
                                                                    item.key
                                                                        ? qtyValue.value
                                                                        : item.quantity
                                                                }
                                                            />
                                                            <img
                                                                onClick={(
                                                                    evt
                                                                ) =>
                                                                    qtyController(
                                                                        evt,
                                                                        item.cart_item_key
                                                                    )
                                                                }
                                                                className="qtyBtn"
                                                                src="/img/m-btn-count-num-plus.svg"
                                                                alt="plus"
                                                            />
                                                        </div>
                                                        <div className="showTotalPrice">
                                                            <div className="displayPrice">
                                                                {
                                                                    item.display_price
                                                                }
                                                            </div>
                                                            {item.on_sale && (
                                                                <div className="regularPrice">
                                                                    {
                                                                        item.display_regular_price
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="cartItemBodyArea">
                                                {item.is_bb && (
                                                    <div className="optionsList">
                                                        <div className="optionItem">
                                                            <div className="optionProductName">
                                                                행동 발달토이 1개, 미니 토이 1개, 테마 생활용품 1개
                                                            </div>
                                                            <div className="optionPrice">
                                                                <div className="displayPrice">
                                                                    {
                                                                        item.display_price
                                                                    }
                                                                </div>
                                                                {item.on_sale && (
                                                                    <div className="regularPrice">
                                                                        {
                                                                            item.display_regular_price
                                                                        }
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {cartInfoList.items.cartItems
                                                            .filter(
                                                                (
                                                                    optionItem
                                                                ) =>
                                                                    optionItem.parent_cart_item_key ===
                                                                    item.cart_item_key
                                                            )
                                                            .map((item) => {
                                                                return (
                                                                    <div
                                                                        className="optionItem"
                                                                        key={
                                                                            item.cart_item_key
                                                                        }
                                                                    >
                                                                        <div className="optionProductName">
                                                                            {
                                                                                item.product_name
                                                                            }
                                                                        </div>
                                                                        <div className="optionPrice">
                                                                            <div className="displayPrice">
                                                                                {
                                                                                    item.display_price
                                                                                }
                                                                            </div>
                                                                            {item.on_sale && (
                                                                                <div className="regularPrice">
                                                                                    {
                                                                                        item.display_regular_price
                                                                                    }
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                    // <div className="itemList">
                                                    //     <div className="optionInfo">
                                                    //         {/* <img
                                                    //             className="optionThumbnail"
                                                    //             src={
                                                    //                 bundle
                                                    //                     .product
                                                    //                     .thumbnail
                                                    //             }
                                                    //             alt=""
                                                    //         /> */}
                                                    //         <div
                                                    //             className={`cartItemInfoTexts ${
                                                    //                 item.is_bb &&
                                                    //                 'baconbox'
                                                    //             }`}
                                                    //         >
                                                    //             <div>
                                                    //                 {/* <div className="itemName">
                                                    //                     {
                                                    //                         bundle.product_name
                                                    //                     }
                                                    //                 </div> */}
                                                    //                 <div className="itemOptionName">
                                                    //                     행동
                                                    //                     발달
                                                    //                     토이
                                                    //                     1개,
                                                    //                     미니
                                                    //                     토이
                                                    //                     1개,
                                                    //                     테마
                                                    //                     생활용품
                                                    //                     1개
                                                    //                 </div>
                                                    //             </div>
                                                    //         </div>
                                                    //     </div>
                                                    //     <div className="showTotalPrice">
                                                    //         <div className="displayPrice">
                                                    //             {
                                                    //                 item.display_price
                                                    //             }
                                                    //         </div>
                                                    //         {item.on_sale && (
                                                    //             <div className="regularPrice">
                                                    //                 {
                                                    //                     item.display_regular_price
                                                    //                 }
                                                    //             </div>
                                                    //         )}
                                                    //     </div>
                                                    // </div>
                                                )}
                                                {cartInfoList.items.cartItems.map(
                                                    (bundle) => {
                                                        if (
                                                            bundle.bundled_by ===
                                                            item.cart_item_key
                                                        ) {
                                                            return (
                                                                <div
                                                                    key={
                                                                        bundle.cart_item_key
                                                                    }
                                                                    className="bundleItem"
                                                                >
                                                                    <div
                                                                        className={`itemList ${
                                                                            !item.is_bb &&
                                                                            'bundle'
                                                                        }`}
                                                                    >
                                                                        <div className="optionInfo">
                                                                            {!item.is_bb && (
                                                                                <img
                                                                                    className="optionThumbnail"
                                                                                    src={
                                                                                        bundle
                                                                                            .product
                                                                                            .thumbnail
                                                                                    }
                                                                                    alt=""
                                                                                />
                                                                            )}
                                                                            <div
                                                                                className={`cartItemInfoTexts ${
                                                                                    item.is_bb &&
                                                                                    'baconbox'
                                                                                }`}
                                                                            >
                                                                                <div>
                                                                                    {!item.is_bb && (
                                                                                        <div className="itemName">
                                                                                            {
                                                                                                bundle.product_name
                                                                                            }
                                                                                        </div>
                                                                                    )}

                                                                                    <div className="itemOptionName">
                                                                                        {item.is_bb
                                                                                            ? bundle.product_name
                                                                                            : `${bundle.quantity}개`}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="showTotalPrice">
                                                                            <div className="displayPrice">
                                                                                {`${addComma(
                                                                                    bundle.price
                                                                                )}원`}
                                                                            </div>

                                                                            {(bundle.on_sale ||
                                                                                bundle.bundled_by) && (
                                                                                <div className="regularPrice">
                                                                                    {
                                                                                        bundle.display_regular_price
                                                                                    }
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    }
                                                )}
                                            </div>
                                            <div className="cartItemBottomArea">
                                                {(item.bundled_items ||
                                                    item.product.sku ===
                                                    'bb_v2_1month') && (
                                                    <div
                                                        onClick={
                                                            item.is_bb
                                                                ? () => {
                                                                    setIsDimBgOn(
                                                                        true
                                                                    );
                                                                    setSelectedProduct(
                                                                        item
                                                                    );
                                                                    setSelectedBundleProducts(
                                                                        cartInfoList.items.cartItems.filter(
                                                                            (
                                                                                optionItem
                                                                            ) =>
                                                                                optionItem.parent_cart_item_key ===
                                                                                item.cart_item_key
                                                                        )[0]
                                                                    );
                                                                    //   setSelectedSantaPresentInCart(
                                                                    //       cartInfoList.items.cartItems.filter(
                                                                    //           (
                                                                    //               optionItem
                                                                    //           ) =>
                                                                    //               optionItem.parent_cart_item_key ===
                                                                    //                   item.cart_item_key &&
                                                                    //               optionItem.is_present
                                                                    //       )[0]
                                                                    //   );
                                                                    setIsBBOptionChanging(
                                                                        true
                                                                    );
                                                                }
                                                                : () => {
                                                                    setShowOptionBox(
                                                                        true
                                                                    );
                                                                    setIsDimBgOn(
                                                                        true
                                                                    );
                                                                    setSelectedProduct(
                                                                        item
                                                                    );
                                                                    setSelectedBundleProducts(
                                                                        cartInfoList.items.cartItems.filter(
                                                                            (
                                                                                bundle
                                                                            ) =>
                                                                                bundle.bundled_by ===
                                                                                item.cart_item_key
                                                                        )
                                                                    );
                                                                    setCurrentSelectedBundleProducts(
                                                                        cartInfoList.items.cartItems.filter(
                                                                            (
                                                                                bundle
                                                                            ) =>
                                                                                bundle.bundled_by ===
                                                                                item.cart_item_key &&
                                                                                item.product.children
                                                                                    .map(
                                                                                        (
                                                                                            child
                                                                                        ) =>
                                                                                            child.id
                                                                                    )
                                                                                    .includes(
                                                                                        bundle.product_id
                                                                                    )
                                                                        )
                                                                    );
                                                                    setIsBundleProduct(
                                                                        true
                                                                    );
                                                                }
                                                        }
                                                        className="changeOptionBtn"
                                                    >
                                                        옵션 변경
                                                    </div>
                                                )}

                                                <div className="showTotalPrice">
                                                    <div className="displayPrice">
                                                        {item.is_bb &&
                                                        cartInfoList.items.cartItems.filter(
                                                            (bundle) =>
                                                                bundle.parent_cart_item_key ===
                                                                item.cart_item_key
                                                        ).length > 0
                                                            ? `${addComma(
                                                                item.price +
                                                                cartInfoList.items.cartItems.filter(
                                                                    (
                                                                        bundle
                                                                    ) =>
                                                                        bundle.parent_cart_item_key ===
                                                                        item.cart_item_key
                                                                )[0].price
                                                            )}원`
                                                            : item.is_bb &&
                                                            cartInfoList.items.cartItems.filter(
                                                                (bundle) =>
                                                                    bundle.parent_cart_item_key ===
                                                                    item.cart_item_key
                                                            ).length === 0
                                                                ? `${addComma(
                                                                    item.price
                                                                )}원`
                                                                : `${addComma(
                                                                    cartInfoList.items.cartItems
                                                                        .filter(
                                                                            (
                                                                                bundle
                                                                            ) =>
                                                                                bundle.bundled_by ===
                                                                                item.cart_item_key
                                                                        )
                                                                        .map(
                                                                            (
                                                                                child
                                                                            ) =>
                                                                                child.price
                                                                        )
                                                                        .reduce(
                                                                            (
                                                                                a,
                                                                                b
                                                                            ) => {
                                                                                return (
                                                                                    a +
                                                                                    b
                                                                                );
                                                                            },
                                                                            item.price
                                                                        ) *
                                                                    item.quantity
                                                                )}원`}
                                                    </div>
                                                    <div className="regularPrice">
                                                        {item.on_sale &&
                                                        item.is_bb &&
                                                        cartInfoList.items.cartItems.filter(
                                                            (bundle) =>
                                                                bundle.parent_cart_item_key ===
                                                                item.cart_item_key
                                                        ).length > 0
                                                            ? `${addComma(
                                                                item.regular_price +
                                                                cartInfoList.items.cartItems.filter(
                                                                    (
                                                                        bundle
                                                                    ) =>
                                                                        bundle.parent_cart_item_key ===
                                                                        item.cart_item_key
                                                                )[0]
                                                                    .regular_price
                                                            )}원`
                                                            : item.is_bb &&
                                                            cartInfoList.items.cartItems.filter(
                                                                (bundle) =>
                                                                    bundle.parent_cart_item_key ===
                                                                    item.cart_item_key
                                                            ).length === 0
                                                                ? `${addComma(
                                                                    item.regular_price
                                                                )}원`
                                                                : `${addComma(
                                                                    cartInfoList.items.cartItems
                                                                        .filter(
                                                                            (
                                                                                bundle
                                                                            ) =>
                                                                                bundle.bundled_by ===
                                                                                item.cart_item_key
                                                                        )
                                                                        .map(
                                                                            (
                                                                                child
                                                                            ) =>
                                                                                child.regular_price
                                                                        )
                                                                        .reduce(
                                                                            (
                                                                                a,
                                                                                b
                                                                            ) => {
                                                                                return (
                                                                                    a +
                                                                                    b
                                                                                );
                                                                            },
                                                                            item.regular_price
                                                                        ) *
                                                                    item.quantity
                                                                )}원`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    )}

                    {/* 별도 브랜드에서 배송되는 상품 */}
                    {cartInfoList.items.cartItems.filter((item) =>
                        item.product.categories
                            .map((category) => category.slug)
                            .includes('baconselect')
                    ).length > 0 && (
                        <div className="brandItemWrapper">
                            <div className="cartItemCategory">
                                <img
                                    src="img/icon-cart-delivery.svg"
                                    alt=""
                                    className="cartegoryLogo"
                                />

                                <div className="cartItemCategoryText">
                                    별도 배송 제품
                                </div>
                            </div>
                            <div className="sub">
                                브랜드에서 직접 배송되는 제품입니다.
                            </div>
                            {cartInfoList.items.cartItems.map((item) => {
                                if (
                                    item.product.categories
                                        .map((category) => category.slug)
                                        .includes('baconselect')
                                ) {
                                    return (
                                        <div
                                            key={item.key}
                                            className="cartItemBox withOption"
                                        >
                                            <img
                                                onClick={() => {
                                                    setIsSpinnerOn(true);
                                                    removeCartItem([
                                                        {
                                                            cart_item_key:
                                                            item.cart_item_key,
                                                        },
                                                    ])
                                                        .then(() =>
                                                            setCartListCount()
                                                        )
                                                        .then(() =>
                                                            setCartInfoList()
                                                        )
                                                        .then(() =>
                                                            setIsSpinnerOn(
                                                                false
                                                            )
                                                        );
                                                }}
                                                src="/img/common-btn-list-close-16.svg"
                                                alt="delete-item-button"
                                                className="cartItemBoxCloseButton"
                                            />
                                            <div className="cartItemHeadArea">
                                                <img
                                                    onClick={() =>
                                                        history.push(
                                                            item.product.url
                                                        )
                                                    }
                                                    className="cartItemsThumbnail"
                                                    src={item.product.thumbnail}
                                                    alt={item.product_name}
                                                />
                                                <div className="cartItemInfoTexts">
                                                    <div
                                                        onClick={() =>
                                                            history.push(
                                                                item.product.url
                                                            )
                                                        }
                                                        className="itemName"
                                                    >
                                                        {item.product.name}
                                                    </div>
                                                    <div
                                                        className={`itemDesc ${
                                                            item.item_stock_status !==
                                                            'instock' &&
                                                            'soldout'
                                                        }`}
                                                    >
                                                        <div className="optionName">
                                                            {
                                                                item.product_option_name
                                                            }
                                                        </div>
                                                        {item.item_stock_status !==
                                                            'instock' && (
                                                                <div className="soldoutNotice">
                                                                    일시 품절
                                                                </div>
                                                            )}
                                                        {item.shippingAvailableToday && (
                                                            <div className="deliveryInfo">
                                                                당일 출고
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cartItemBottomArea">
                                                <div className="controlBox">
                                                    <div className="changeQty">
                                                        <img
                                                            onClick={
                                                                item.item_stock_status ===
                                                                'instock' &&
                                                                item.quantity >
                                                                1
                                                                    ? (evt) => {
                                                                        qtyController(
                                                                            evt,
                                                                            item.cart_item_key
                                                                        );
                                                                    }
                                                                    : undefined
                                                            }
                                                            className=""
                                                            src={
                                                                item.item_stock_status ===
                                                                'instock'
                                                                    ? '/img/m-btn-count-num-minus.svg'
                                                                    : '/img/m-btn-icon-count-num-minus-disable.svg'
                                                            }
                                                            alt="minus"
                                                        />
                                                        <input
                                                            onChange={(evt) => {
                                                                if (
                                                                    /^[0-9\b]+$/.test(
                                                                        evt
                                                                            .target
                                                                            .value
                                                                    ) ||
                                                                    evt.target
                                                                        .value ===
                                                                    ''
                                                                ) {
                                                                    setQtyValue(
                                                                        {
                                                                            key: item.key,
                                                                            value: evt
                                                                                .target
                                                                                .value,
                                                                        }
                                                                    );
                                                                    qtyInputHandler(
                                                                        evt,
                                                                        item.cart_item_key,
                                                                        item.product_id,
                                                                        item.variation_id
                                                                    );
                                                                }
                                                            }}
                                                            onKeyPress={(
                                                                evt
                                                            ) => {
                                                                if (
                                                                    evt.key ===
                                                                    'Enter'
                                                                ) {
                                                                    qtyInputHandler(
                                                                        evt,
                                                                        item.cart_item_key,
                                                                        item.product_id,
                                                                        item.variation_id
                                                                    );
                                                                }
                                                            }}
                                                            className={`qtyInput ${
                                                                item.item_stock_status !==
                                                                'instock'
                                                                    ? 'soldout'
                                                                    : ''
                                                            }`}
                                                            type="text"
                                                            value={
                                                                qtyValue.key ===
                                                                item.key
                                                                    ? qtyValue.value
                                                                    : item.quantity
                                                            }
                                                        />
                                                        <img
                                                            onClick={
                                                                item.item_stock_status ===
                                                                'instock'
                                                                    ? (evt) => {
                                                                        qtyController(
                                                                            evt,
                                                                            item.cart_item_key
                                                                        );
                                                                    }
                                                                    : undefined
                                                            }
                                                            className=""
                                                            src={
                                                                item.item_stock_status ===
                                                                'instock'
                                                                    ? '/img/m-btn-count-num-plus.svg'
                                                                    : '/img/m-btn-icon-count-num-plus-disable.svg'
                                                            }
                                                            alt="plus"
                                                        />
                                                    </div>
                                                    {item.product.type ===
                                                        'variable' && (
                                                            <div
                                                                onClick={
                                                                    item.item_stock_status ===
                                                                    'instock'
                                                                        ? () => {
                                                                            setShowOptionBox(
                                                                                true
                                                                            );
                                                                            setIsDimBgOn(
                                                                                true
                                                                            );
                                                                            setSelectedProduct(
                                                                                item
                                                                            );
                                                                        }
                                                                        : undefined
                                                                }
                                                                className="changeOptionBtn"
                                                            >
                                                                옵션 변경
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="showTotalPrice">
                                                    <div className="displayPrice">
                                                        {item.display_price}
                                                    </div>
                                                    {item.on_sale && (
                                                        <div className="regularPrice">
                                                            {
                                                                item.display_regular_price
                                                            }
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    )}

                    {/* 증정상품 */}
                    {cartInfoList.items.cartItems.filter(
                        (item) => item.wdr_free_product === 'Free'
                    ).length > 0 && (
                        <div className="freeItemWrapper">
                            <div className="cartItemCategory">
                                <img
                                    src="img/icon-cart-gift.svg"
                                    alt=""
                                    className="cartegoryLogo"
                                />
                                <div className="cartItemCategoryText">
                                    증정 상품
                                </div>
                            </div>
                            {cartInfoList.items.cartItems.map((item) => {
                                if (item.wdr_free_product === 'Free') {
                                    return (
                                        <div
                                            key={item.cart_item_key}
                                            className="cartItemBox"
                                        >
                                            <div className="cartItemHeadArea">
                                                <img
                                                    className="cartItemsThumbnail"
                                                    src={item.product.thumbnail}
                                                    alt={item.product_name}
                                                />
                                                <div className="cartItemInfoTexts">
                                                    <div className="itemName">
                                                        {item.product_name}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    )}
                </div>
            </div>
            <div className="cartItemsBottomArea">
                <div
                    onClick={() => {
                        setIsSpinnerOn(true);
                        resetCartList()
                            .then(() => updateCartInfoList(null))
                            .then(() => setCartListCount())
                            .then(() => setIsSpinnerOn(false));
                    }}
                    className={`clearCartItems ${
                        isCartItemsOpen ? '' : 'noShowing'
                    }`}
                >
                    장바구니 비우기
                </div>
                <div
                    className={`cartContainerShrinkButton ${
                        isCartItemsOpen ? '' : 'noShowing'
                    }`}
                    onClick={() => {
                        setIsCartItemsOpen(!isCartItemsOpen);
                    }}
                >
                    전체 상품 닫기
                    <img
                        src="/img/m-btn-icon-accordion-arrow-up-gray-16.svg"
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
};

export default CartItemsContainer;
