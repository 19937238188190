import React, { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import './CollectionCard.scss';
import { defaultElementProps } from '../../types/types';
import { useHistory } from 'react-router-dom';

interface CollectionCardProps extends defaultElementProps {
    imgUrl?: string;
    badgeUrl?: string;
    text?: string;
    link?: string;
}

const CollectionCard: React.FunctionComponent<CollectionCardProps> = ({
    imgUrl,
    badgeUrl,
    text,
    link,
}) => {
    const { rootStore } = useContext(AppContext);
    const { initData } = rootStore;
    let history = useHistory();

    return (
        <div
            className="CollectionCardWrapper"
            onClick={() => {
                history.push(link);
            }}
        >
            <div className="makeItRectangle"></div>
            <div className="CollectionCardImgArea">
                <div className="imgWrapper">
                    <img
                        className="CollectionCardImage"
                        src={initData && initData.resourcePrefix + imgUrl}
                        alt=""
                    />
                    {badgeUrl && (
                        <img
                            className="CollectionCardBadge"
                            src={initData && initData.resourcePrefix + badgeUrl}
                            alt=""
                        />
                    )}
                </div>

                <div className="CollectionCardText">{text}</div>
            </div>
        </div>
    );
};

export default CollectionCard;
