import { observer } from 'mobx-react';

const MinicartHighlightText = observer(({
    text,
    isClickable,
    clickAction,
}) => {

    return <div className={`highlightBox ${isClickable ? 'clickable' : ''}`}
        onClick={() => {
            if (isClickable) {
                clickAction(true);
            }
        }}
    >
        {text}
    </div>;
});

export default MinicartHighlightText;

