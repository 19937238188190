import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { fetchAndReceiveJSON } from '../../utils/ajaxUtils';
import { GET_CUSTOM_HTML_PAGE_URL } from '../../constants/constants';
import './CustomHtmlPage.scss';

const CustomHtmlPage = () => {
    const [htmlContent, setHtmlContent] = useState('');

    const { slug } = useParams<{ slug: string }>();

    const fetchCustomHtmlData = async () => {
        const res = await fetchAndReceiveJSON({
            url: `${GET_CUSTOM_HTML_PAGE_URL}/${slug}`,
        });

        setHtmlContent(res);
    };

    useEffect(() => {
        fetchCustomHtmlData();
    }, [slug]);

    return (
        <div
            className="CustomHtmlPage"
            dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
    );
};

export default CustomHtmlPage;
