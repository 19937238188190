import {makeAutoObservable, autorun, action} from 'mobx';
import {isAddedSubcollectionParameter} from '../../utils/commonUtils';
import {fetchAndReceiveJSON} from '../../utils/ajaxUtils';

autorun(() => {
    console.log('CollectionPageStore update >>>>>');
});

class CollectionPageStore {
    curSort = 'basic';
    collectionData;
    productsList: any = [];
    selectedProductsList: any = [];
    currentSelectedCategory = {
        id: 0,
        name: '전체',
        slug: 'all',
    };
    currentTargetCategoryId = isAddedSubcollectionParameter(location.search)
        ? -1
        : 0;
    isSortingBoxOpen: false;

    constructor() {
        makeAutoObservable(this);
    }

    setIsSortingBoxOpen = (bool) => {
        this.isSortingBoxOpen = bool;
    };

    setCurSort = (sortType) => {
        this.curSort = sortType;
    };

    //메인 카테고리 눌렀을 때 데이터 받아오기
    setCollectionData = (data) => {
        this.collectionData = data;
    };

    // setCurrentSelectedCategory = (category) => {
    //     this.currentSelectedCategory = category;
    // };

    setCurrentSelectedCategory = (selectedCategoryInfo) => {
        this.currentSelectedCategory = selectedCategoryInfo;
    };

    setCurrentTargetCategoryId = (selectedCategoryId) => {
        this.currentTargetCategoryId = selectedCategoryId;
    };
    //상품리스트 데이터
    setProductsList = (arr) => {
        this.productsList = arr;
    };

    //서브카테고리 클릭에 따른 상품리스트 업데이트
    //? 상태의 불변성 없이 업데이트 되고 있음 -> 괜찮은지? 기존상품리스트 배열을 두고 새로운 배열로 정렬하는게 효율적인지?
    setSelectedProductsList = (currentCategory) => {
        if (currentCategory.id === 0) {
            this.selectedProductsList = this.productsList;
        } else {
            this.selectedProductsList = this.productsList.filter((product) =>
                product.categories.some(
                    (category) => category.id === currentCategory.id
                )
            );
        }
    };
}

export default CollectionPageStore;
