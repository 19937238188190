import React, { useState } from 'react';
import './SNSCard.scss';
import PreloadImage from 'react-preload-image';
import ContentBox from '../ContentBox/ContentBox';
import { defaultElementProps } from '../../types/types';
import { useTranslation } from 'react-i18next';
import { MIconLinkArrow10, MIconLinkArrow16 } from '../SVGs/Svgs';

interface SNSCardProps extends defaultElementProps {
    mediaUrl?: string;
    name?: string;
    snsId?: string;
    text?: string;
    linkUrl?: string;
    mediaType?: string;
}

const SNSCard: React.FunctionComponent<SNSCardProps> = ({
    mediaUrl,
    name,
    snsId,
    text,
    linkUrl,
    mediaType,
}) => {
    // ...으로 바뀌는 기능 구현
    // 어떻게  할 수 있지 ?
    // .mp4, .m4v
    // .avi
    // 미디어 타입으로 구분하고 비디오인 경우 적용시킨다.
    // 사이즈 맞추기
    // 썸네일 바꾸기

    // 길이 비교https,
    const { t } = useTranslation();
    return (
        <ContentBox className="SNSCardWrapper" withShadow={true}>
            {mediaType === 'video' ? (
                <video
                    // preload="metadata"
                    // autoPlay={i == 0 ? true : false}
                    preload={'auto'}
                    loop
                    className="SNSCardImage"
                    playsInline
                >
                    <source src={mediaUrl} type="video/mp4" />
                </video>
            ) : (
                <img className="SNSCardImage" src={mediaUrl} alt="" />
            )}

            <div className="SNSCardBottomArea">
                <div className="nameInfo">
                    <div className="SNScardName">
                        {snsId ? snsId : `@${name}`}
                    </div>
                    {/* <div className="SNScardUserId">{snsId}</div> */}
                </div>
                <div className="textInfo">{text}</div>
                <div
                    className="SNScardLink"
                    onClick={() => {
                        window.open(linkUrl);
                    }}
                >
                    {t('linkToBaconInsta')}
                    <MIconLinkArrow10 className="SNSMoArrow" />
                    <MIconLinkArrow16 className="SNSDtArrow" />
                </div>
            </div>
        </ContentBox>
    );
};

export default SNSCard;
