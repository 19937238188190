import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import HomeProductListsContainer from '../../containers/HomeProductListsContainer/HomeProductListsContainer';
import './EmptyOrderNotice.scss';
import { useHistory } from 'react-router-dom';

const EmptyOrderNotice = () => {
    const { cartPageStore } = useContext(AppContext);
    const { cartBestProductsList, setCartBestProductsList } = cartPageStore;

    const [bestProductList, setBestProductList] =
        useState<Array<any>>(cartBestProductsList);

    let history = useHistory();

    if (!bestProductList) {
        setCartBestProductsList();

        // setBestProductList(cartBestProductsList);
    }

    useEffect(() => {
        //TODO 초기 랜더시 , bestProduct가 없어서 그리지 못함 , 이후에 다시 랜더링 시 , best상품 있어서 표시됨
        setCartBestProductsList();
    }, []);

    return (
        <section className="EmptyOrderNotice">
            <div className="noticeSection">
                <h2 className="emptyMyAccountNoticeTitle">
                    주문하신 상품이 <br /> 아직 없어요😭
                </h2>
                <p className="emptyCartNoticeDesc">
                    반려견을 위한 베이컨만의 다양한 제품을 만나보세요!
                </p>
                <div
                    className="emptyCartNoticeBtn"
                    onClick={() => {
                        history.push('/');
                    }}
                >
                    제품 보러가기
                </div>
            </div>
            {/* <div className="bestItemsList">
                <h2 className="listTitle">베이컨 인기상품</h2>
                {bestProductList && (
                    <HomeProductListsContainer
                        key={`homePorudctList`}
                        slideCartegoryData={cartBestProductsList.categories}
                        slideData={cartBestProductsList.products}
                        // title={cartBestProductsList.title}
                        filterType={cartBestProductsList.filterType}
                        className="homeContainers"
                        type={'fix'}
                        linkText={'none'}
                    />
                )}
            </div> */}
        </section>
    );
};

export default EmptyOrderNotice;
