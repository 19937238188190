import React, {useEffect, useState} from "react"
import TermsContent from "../../components/AgreementModal/TermsContent";
import PersonalInfoContent from "../../components/AgreementModal/PersonalInfoContent";
import './TermsPage.scss'
import {AppContext} from "../../contexts/AppContext/AppContext";

function TermsPage() {
    const [isRegistered, setIsRegistered] = useState(true);
    return (
        <div className='terms-page-wrapper'>
            <div className="AgreementHeadArea">
                <div className="termSelectors">
                    <div
                        className={`termSelector ${
                            isRegistered ? 'termActive' : ''
                        }`}
                        onClick={() => {
                            setIsRegistered(true);
                        }}
                    >
                        쇼핑몰 이용약관
                    </div>
                    <div
                        className={`termSelector ${
                            !isRegistered ? 'termActive' : ''
                        }`}
                        onClick={() => {
                            setIsRegistered(false);
                        }}
                    >
                        비회원 이용약관
                    </div>
                </div>
            </div>
            <div className="AgreementBodyArea">
                {isRegistered ? (
                    <TermsContent
                        userAgreementType={'registeredUser'}
                    />
                ) : (
                    <TermsContent
                        userAgreementType={'nonRegisteredUser'}
                    />
                )}
            </div>
            {/* 바디 영역만 스크롤 되도록 */}
        </div>
    )

}

export default TermsPage