import {observer} from 'mobx-react';
import React, {useCallback, useEffect, useState} from 'react';
import {AppContext} from '../../contexts/AppContext/AppContext';
import {
    autoHypenCorpNum,
    autoHypenPhone,
    KAKAO_AUTH_URL,
    stringLineBreak,
    validateEmail,
    validatePhonenum
} from '../../utils/commonUtils';
import closeIcon from '../../assets/m-icon-gnb-btn-popup-close.svg';
import './SignUpContent.scss';
import InputWithValidation from '../InputWithValidation/InputWithValidation.component2';
import Button from '../Button/Button';
import CheckWithValidation from '../CheckWithValidation/CheckWithValidation.component';
import SearchAddressPopup from '../SearchAddressPopup/SearchAddressPopup';
import {fetchAndReceiveJSON} from '../../utils/ajaxUtils';
import {
    SIGNUP_URL,
    SIGNUP_PARTNER_URL,
    LOGIN_KAKAO_URL,
    UPLOAD_PARTNER_DOCS_URL,
    REDIRECT_URL_AFTER_SIGNUP,
} from '../../constants/constants';
import MIconLinkArrow10 from '../../assets/m-icon-link-arrow-10-black.svg';
import LoginCustomPopup from './LoginCustomPopup';
import {
    RESPONSE_REGISTER_BILLING_PHONE_ALREADY_EXIST,
    RESPONSE_REGISTER_BILLING_PHONE_ALREADY_EXIST_MSG
} from '../../constants/errorConstants';

const defaultHeadTitleDiv = <>지금, <span className="bgYellow">카카오로 가입</span>하시면 <br/>특별한 혜택을 드려요 :)</>
const initTexts = {
    headText: defaultHeadTitleDiv
}

const emailTexts = {
    headText: "회원가입에 필요한\n 정보를 입력해주세요 :)",
    subText: "신규 가입 시 특별 할인 혜택과 쿠폰을 드립니다!"
}

const partnerTexts = {
    headText: "파트너 계정 신청에 필요한\n 정보를 입력해 주세요 :)",
    subText: "웹을 통해 손쉽게, 저렴한 가격으로 대량 구매가 가능합니다."
}

let texts: any = initTexts;
const benefitTitle = <>인기 제품 <span style={{color: '#ed2e1b'}}>100원</span> 득템 찬스</>;
const benefits = [
    {
        imgUrl: "/img/login/join_reward_56_02.svg",
        title: '카카오 플친 1,000원 할인 쿠폰',
        desc: '플러스 친구 추가 시 쿠폰 증정!'
    },
    {
        imgUrl: "/img/login/join_reward_56_03.svg",
        title: '무료배송 쿠폰',
        desc: '첫 주문 시 사용 가능!'
    }

]

const SignupContent = observer(({
                                    setIsLoginContent
                                }) => {
    const {rootStore} = React.useContext(AppContext);
    // 첫 화면 노출시 불러올 이미지
    const {
        isLoginModalOn,
        loginModalStep,
        setIsLoginModalOn,
        setIsAgreementModalOn,
        setAgreementType,
        setIsBodyScrollable,
        setIsSpinnerOn,
    } = rootStore;

    const [openSearchAddressPopup, setOpenSearchAddressPopup] = useState(false);

    const [stepStatus, setStepStatus] = useState(loginModalStep ? loginModalStep : "init");
    const [headText, setHeadText] = useState<any>(initTexts);
    const [signupInfo, setSignupInfo] = useState([]);
    const [errorMsgs, setErrorMsgs] = useState([]);
    const [termErrorMsgs, setTermErrorMsgs] = useState([]);

    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userName, setUserName] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [isTermAgreed, setIsTermAgreed] = useState(false);
    const [isPrivacyAgreed, setIsPrivacyAgreed] = useState(false);
    const [passwordCheck, setPasswordCheck] = useState('');
    const [loginCustomPopupData, setLoginCustomPopupData] = useState(null);
    // const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(false);
    // const [isAlreadyUnsub, setIsAlreadyUnsub] = useState(false);

    const [isAllDataInputed, setIsAllDataInputed] = useState(false);

    //파트너
    const [companyName, setCompanyName] = useState('');
    const [CEOName, setCEOName] = useState('');
    const [businessNumber, setBusinessNumber] = useState('');
    const [companyPostcode, setCompanyPostcode] = useState('');
    const [companyAddress1, setCompanyAddress1] = useState('');
    const [companyAddress2, setCompanyAddress2] = useState('');
    const [businessLicense, setBusinessLicense] = useState('');
    const [businessLicenseUrl, setBusinessLicenseUrl] = useState('');
    const [userData, setUserData] = useState(null);

    const termStates = [isTermAgreed, isPrivacyAgreed];
    const emailStates = [userEmail, userPassword, passwordCheck, userName, userPhone];
    const partnerStates = [userName, userPhone, userEmail, companyName, CEOName
        , businessNumber, companyPostcode, companyAddress1, companyAddress2, businessLicense, userPassword, passwordCheck];

    const postcodeAddObj = <div
        onClick={() =>
            setOpenSearchAddressPopup(
                true
            )
        }
        className="searchAddressBtn"
    >
        주소 찾기
    </div>;
    const businessLicenseAddObj = <form
        encType="multipart/form-data"
        action=""
    >
        <label
            htmlFor="uploadFile"
            className="uploadFileBtn"
        >
            <input
                onChange={(evt) =>
                    uploadPartnerDocs(
                        evt
                    )
                }
                id="uploadFile"
                type="file"
            />
            파일 선택
        </label>
    </form>;


    const emailInputs = [
        {
            className: 'signinInput',
            setStateFunction: setUserEmail,
            targetState: userEmail,
            placeHolder: '이메일 주소',
            errMsg: '이메일을 올바르게 입력해 주세요',
            inputType: 'email',
            validator: validateEmail
        },
        {
            className: 'signinInput',
            setStateFunction: setUserPassword,
            targetState: userPassword,
            placeHolder: '비밀번호',
            errMsg: '비밀번호를 8자 이상 입력해 주세요',
            inputType: 'password',
        },
        {
            className: 'signinInput',
            setStateFunction: setPasswordCheck,
            targetState: passwordCheck,
            placeHolder: '비밀번호 확인',
            errMsg: '비밀번호를 입력해 주세요',
            inputType: 'password',
        },
        {
            className: 'signinInput',
            setStateFunction: setUserName,
            targetState: userName,
            placeHolder: '이름',
            errMsg: '이름을 입력해 주세요',
            inputType: 'text',
        },
        {
            className: 'signinInCheck',
            setStateFunction: setUserPhone,
            targetState: userPhone,
            placeHolder: '휴대전화 번호',
            errMsg: '휴대전화 번호를 입력해 주세요',
            inputType: 'phone',
        }

    ];

    const partnerInputs = [
        {
            setStateFunction: setUserName,
            targetState: userName,
            placeHolder: '담당자 이름',
            errMsg: '담당자 이름을 입력해 주세요',
            inputType: 'text',
        },
        {
            className: 'signinInput',
            setStateFunction: setUserPhone,
            targetState: userPhone,
            placeHolder: '담당자 휴대전화 번호',
            errMsg: '담당자 휴대전화 번호를 입력해 주세요',
            inputType: 'phone',
        },
        {
            className: 'signinInput',
            setStateFunction: setUserEmail,
            targetState: userEmail,
            placeHolder: '이메일 주소',
            errMsg: '이메일을 올바르게 입력해 주세요',
            inputType: 'email',
            validator: validateEmail
        },
        {
            className: 'signinInput',
            setStateFunction: setCompanyName,
            targetState: companyName,
            placeHolder: '법인명',
            errMsg: '법인명을 입력해 주세요',
            inputType: 'text',
        },
        {
            className: 'signinInput',
            setStateFunction: setCEOName,
            targetState: CEOName,
            placeHolder: '대표자 이름',
            errMsg: '대표자 이름을 입력해주세요',
            inputType: 'text',
        },
        {
            className: 'signinInput',
            setStateFunction: setBusinessNumber,
            targetState: businessNumber,
            placeHolder: '사업자 등록번호',
            errMsg: '사업자 등록번호를 입력해주세요',
            inputType: 'corpNumber',
        },
        {
            className: 'signinInput withBtn',
            setStateFunction: setCompanyPostcode,
            targetState: companyPostcode,
            placeHolder: '우편번호',
            errMsg: '우편번호를 입력해주세요',
            inputType: 'text',
            readOnly: true,
            addiTionalObj: postcodeAddObj
        },
        {
            className: 'signinInput',
            setStateFunction: setCompanyAddress1,
            targetState: companyAddress1,
            placeHolder: '기본주소',
            errMsg: '기본주소를 입력해 주세요',
            inputType: 'text',
            readOnly: true,
        },
        {
            className: 'signinInput',
            setStateFunction: setCompanyAddress2,
            targetState: companyAddress2,
            placeHolder: '상세주소',
            errMsg: '상세주소를 입력해 주세요',
            inputType: 'text',
        },
        {
            className: 'signinInput withBtn',
            setStateFunction: setBusinessLicense,
            targetState: businessLicense,
            placeHolder: '사업자 등록증',
            errMsg: '사업자 등록증을 추가해 주세요',
            inputType: 'text',
            addiTionalObj: businessLicenseAddObj,
            readOnly: true
        },
        {
            className: 'signinInput',
            setStateFunction: setUserPassword,
            targetState: userPassword,
            placeHolder: '비밀번호',
            errMsg: '비밀번호를 8자 이상 입력해 주세요',
            inputType: 'password',
        },
        {
            className: 'signinInput',
            setStateFunction: setPasswordCheck,
            targetState: passwordCheck,
            placeHolder: '비밀번호 확인',
            errMsg: '비밀번호를 확인해 주세요',
            inputType: 'password',
        },

    ]

    const termOnClick = () => {
        // showOverlay('#terms');
        setIsAgreementModalOn(true);
        setAgreementType('terms');
    };

    const privacyAgreeOnClick = () => {
        // showOverlay('#terms');
        setIsAgreementModalOn(true);
        setAgreementType(
            'personalPolicy'
        );
    };
    const userAgent = navigator.userAgent.toLowerCase();

    const termInputs = [
        {
            className: 'signinInput mt',
            setStateFunction: setIsTermAgreed,
            targetState: isTermAgreed,
            errMsg: '약관에 동의해 주세요',
            onClick: termOnClick,
            text: '이용약관 동의'
        },
        {
            className: 'signinInput mt',
            setStateFunction: setIsPrivacyAgreed,
            targetState: isPrivacyAgreed,
            errMsg: '개인정보 수집 및 이용에 동의해 주세요',
            onClick: privacyAgreeOnClick,
            text: '개인정보 수집 및 이용동의'
        },

    ];

    useEffect(() => {
        setUserPhone(autoHypenPhone(userPhone));
    }, [userPhone]);

    useEffect(() => {
        setBusinessNumber(autoHypenCorpNum(businessNumber));
    }, [businessNumber]);

    const resetState = useCallback(() => {

        setIsLoginContent(true);

        setStepStatus('init');
        setUserEmail('');
        setUserPassword('');
        setUserName('');
        setUserPhone('');
        setIsTermAgreed(false);
        setIsPrivacyAgreed(false);
        setPasswordCheck('');

        // setIsAlreadyRegistered(false);

        // setPartnerManagerName('');
        // setPartnerManagerPhone('');
        // setPartnerEmail('');
        setCompanyName('');
        setCEOName('');
        setBusinessNumber('');
        setCompanyAddress1('');
        setCompanyAddress2('');
        setBusinessLicense('');
        setBusinessLicenseUrl('');
        // setPartnerPassword('');
        // setPartnerPasswordCheck('');
    }, []);

    useEffect(() => {
        setIsBodyScrollable(!isLoginModalOn);
    }, [isLoginModalOn]);

    useEffect(() => {
        setKakaoToken()
    }, [])

    useEffect(() => {

        // console.log("stepStatus change !", stepStatus);
        if (stepStatus === 'email') {
            // texts = emailTexts;
            setHeadText(emailTexts);
            setSignupInfo(emailInputs);
        } else if (stepStatus === 'partner') {
            // texts = partnerTexts;
            setHeadText(partnerTexts);
            setSignupInfo(partnerInputs);

        }

    }, [stepStatus]);

    useEffect(() => {

        // console.log("state change !!");

        setIsAllDataInputed(isAllValidInputs());

    }, [...emailStates, ...partnerStates, ...termStates]);

    const isAllValidInputs = () => {

        let isValid = true;

        const inputStates = stepStatus === 'partner' ? partnerStates : emailStates;

        signupInfo.map((input, idx) => {

            if (!inputStates[idx]) {
                isValid = false;
            }
        });

        //email , password check , phone num
        if (!validateEmail(userEmail) || userPassword !== passwordCheck || !validatePhonenum(userPhone)) {
            isValid = false;
        }

        termInputs.map((input, idx) => {
            if (!termStates[idx]) {
                isValid = false;
            }
        });

        // console.log("return ", isValid);

        return isValid;
    }

    const displayErrorMsg = () => {

        const inputStates = stepStatus === 'partner' ? partnerStates : emailStates;

        signupInfo.map((input, idx) => {

            errorMsgs[idx] = !inputStates[idx] ? input.errMsg : "";
        });

        //email
        if (stepStatus === 'email' && !validateEmail(userEmail)) {
            errorMsgs[0] = "이메일을 올바르게 입력해 주세요";
        }

        //password check
        if (userPassword !== passwordCheck) {
            //TODO..
            errorMsgs[2] = "비밀번호를 확인해 주세요";
            setPasswordCheck('');
        }

        //phone num
        if (!validatePhonenum(userPhone)) {
            errorMsgs[4] = "휴대전화 번호를 입력해 주세요";
        }

        setErrorMsgs(errorMsgs.slice());

        //terms
        termInputs.map((input, idx) => {
            termErrorMsgs[idx] = !termStates[idx] ? input.errMsg : "";
        });

        setTermErrorMsgs(termErrorMsgs.slice());

    }

    // body 값을 인자로 받자
    const signUp = useCallback(
        async ({userEmail, userFullName, phone, password}) => {
            setIsSpinnerOn(true);
            const res = await fetchAndReceiveJSON({
                url: SIGNUP_URL,
                method: 'POST',
                body: {
                    username: userEmail,
                    first_name: userFullName,
                    billing: {
                        phone: phone,
                    },
                    password: password,
                },
            });

            if (res == RESPONSE_REGISTER_BILLING_PHONE_ALREADY_EXIST) {
                alert(RESPONSE_REGISTER_BILLING_PHONE_ALREADY_EXIST_MSG);
            } else if (res.id) {
                setIsLoginModalOn(false);
                location.href = REDIRECT_URL_AFTER_SIGNUP;
            } else {
                // alert('회원가입에 실패했습니다. 잠시 후 다시 시도해 주세요');
            }

            setIsSpinnerOn(false);
            // 로그인 후 새로고침?
            // setIsSpinnerOn(false);
        }, []);

    const partnerSignup = useCallback(
        async ({
                   managerName,
                   managerPhone,
                   email,
                   companyName,
                   ceoName,
                   businessNumber,
                   companyPostcode,
                   companyAddress1,
                   companyAddress2,
                   businessLicenseUrl,
                   partnerPassword
               }) => {
            setIsSpinnerOn(true);
            const res = await fetchAndReceiveJSON({
                url: SIGNUP_PARTNER_URL,
                method: 'POST',
                body: {
                    username: email,
                    last_name: ' ',
                    first_name: managerName,
                    billing: {
                        phone: managerPhone,
                        address_1: companyAddress1,
                        address_2: companyAddress2,
                        postcode: companyPostcode,
                    },
                    shipping: {
                        phone: managerPhone,
                        address_1: companyAddress1,
                        address_2: companyAddress2,
                        postcode: companyPostcode,
                    },
                    password: partnerPassword,
                    company_name: companyName,
                    company_rep_name: ceoName,
                    business_num: businessNumber,
                    business_doc: businessLicenseUrl,
                },
            });

            // console.log(res);

            if (res == RESPONSE_REGISTER_BILLING_PHONE_ALREADY_EXIST) {
                alert(RESPONSE_REGISTER_BILLING_PHONE_ALREADY_EXIST_MSG);
            } else {
                alert(
                    '파트너 계정 승인 대기 중 입니다. \n 승인 완료 이후 파트너 조건의 구매가 가능하며, 승인이 완료되면 담당자의 연락처로 안내가 진행될 예정입니다.'
                );
                location.reload();
            }

            setIsSpinnerOn(false);
        },
        []
    );

    const uploadPartnerDocs = useCallback((evt) => {
        const target = evt.target;
        const file = target.files[0];

        setBusinessLicense(file.name);

        const imageData = new FormData();
        imageData.append('file', file);

        const fetchUploadFile = async () => {
            const res = await fetch(UPLOAD_PARTNER_DOCS_URL, {
                method: 'POST',
                credentials: 'include',
                body: imageData,
            });

            if ((await res.status) !== 200) {
                alert(res.text());
                return;
            }

            res.json().then((data) => setBusinessLicenseUrl(data.data.url));
        };

        fetchUploadFile();
    }, []);

    function kakao_auth() {
        if (userAgent.indexOf("iphone") > -1 ||
            userAgent.indexOf("ipad") > -1 ||
            userAgent.indexOf("ipod") > -1) {
            window.Kakao.Auth.authorize({
                redirectUri: window.location.origin,
                state: window.location.pathname
            })
        } else {
            window.location.href = KAKAO_AUTH_URL(window.location.origin, String(window.location.pathname))
        }
    }


    async function getKakaoToken(code, state) {
        const data = {
            grant_type: 'authorization_code',
            client_id: process.env.REACT_APP_KAKAO_APP_KEY,
            redirect_uri: window.location.origin,
            code: code,
        };
        fetch("https://kauth.kakao.com/oauth/token", {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            body: new URLSearchParams(data).toString()
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(response => {
                window.Kakao.Auth.setAccessToken(response.access_token);
                kakao_user(state);
            })
            .catch(error => {
                alert('카카오 로그인 중 오류가 발생했습니다.');
            });
    }

    function setKakaoToken() {
        const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get('code')
        const state = decodeURI(searchParams.get('state'))
        if (code) {
            getKakaoToken(code, state)
        } else {
            return null
        }
    }

    // function kakao_auth() {
    //     window.Kakao.Auth.login({
    //         success: function (response) {
    //             kakao_user();
    //         },
    //         fail: function (error) {
    //             console.error(error);
    //             alert('카카오 로그인중 오류가 발생 하였습니다.');
    //             setIsSpinnerOn(false);
    //         },
    //     });
    // }
    // var user_data;
    function kakao_user(state) {
        setIsSpinnerOn(true);

        window.Kakao.API.request({
            url: '/v2/user/me',
            success: function (response) {
                // user_data = response;

                setUserData(response);

                kakao_login(response, '', state);
            },
            fail: function (error) {
                console.error(error);
                alert('카카오 로그인중 오류가 발생 하였습니다.');
                setIsSpinnerOn(false);
            },
        });
    }

    function kakao_login(user, merge, state) {
        if (merge == '1') {
            setIsSpinnerOn(true);
        }

        window.jQuery.ajax({
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            xhrFields: {
                withCredentials: true,
            },
            url: LOGIN_KAKAO_URL,
            data: JSON.stringify({user: user, merge: merge}),
            success: function (response) {
                if (response.success == true) {

                    if (response.data.option == 'merge') {
                        setIsLoginModalOn(true);
                        setLoginCustomPopupData({

                            mainText: stringLineBreak(`이미 가입한 계정이 있어 
                                간편하게 로그인하실 수 있어요!`),
                            userData: user,
                            action: () => {
                                setLoginCustomPopupData(null);
                            },
                            actionBtnText: "취소하기",
                            action2: () => {
                                setLoginCustomPopupData(null);
                                kakao_login(userData, '1', state);
                            },
                            actionBtnText2: "간편로그인 하기"
                        })

                        setIsSpinnerOn(false);
                    } else if (response.data.option == 'unsub') {
                        // setIsAlreadyUnsub(true);
                        setIsLoginModalOn(true);
                        setLoginCustomPopupData({

                            mainText: "이미 탈퇴한 회원입니다.",
                            userData: null,
                            action: () => {
                                setLoginCustomPopupData(null);
                            },
                            actionBtnText: "확인",
                            action2: () => {
                                setLoginCustomPopupData(null);
                                setStepStatus('email');
                            },
                            actionBtnText2: "회원가입 하기"
                        })
                        setIsSpinnerOn(false);
                    } else {
                        if (response.data.option == 'new') {
                            // 새로 가입 하는 경우 뜨는 페이지
                            setIsSpinnerOn(false);
                            setIsLoginModalOn(false);
                            location.href = REDIRECT_URL_AFTER_SIGNUP;
                        } else {
                            location.href = state
                        }
                    }
                } else {

                    let alertMsg = '카카오 로그인중 오류가 발생 하였습니다.';
                    if (response === RESPONSE_REGISTER_BILLING_PHONE_ALREADY_EXIST) {
                        alertMsg = RESPONSE_REGISTER_BILLING_PHONE_ALREADY_EXIST_MSG;
                    } else if (response.msg) {
                        alertMsg = response.msg;
                    } else if (response.message) {
                        alertMsg = response.message;
                    }
                    alert(alertMsg);
                }

                setIsSpinnerOn(false);

            },
            error: function (err) {
                if (err && err.responseJSON.msg) {
                    alert(err.responseJSON.msg);
                }
                setIsSpinnerOn(false);
            },
        });
    }

    const signup = () => {

        displayErrorMsg();

        if (isAllValidInputs()) {

            if (stepStatus === 'partner') {
                partnerSignup({
                    managerName: userName,
                    managerPhone: userPhone,
                    email: userEmail,
                    companyName: companyName,
                    ceoName: CEOName,
                    businessNumber: businessNumber,
                    companyPostcode: companyPostcode,
                    companyAddress1: companyAddress1,
                    companyAddress2: companyAddress2,
                    businessLicenseUrl: businessLicenseUrl,
                    partnerPassword: userPassword
                });
            } else {
                signUp({
                    userFullName: userName,
                    userEmail: userEmail,
                    password: userPassword,
                    phone: userPhone,
                });
            }
        }

    }

    if (openSearchAddressPopup) {
        return (<SearchAddressPopup
            setOpenSearchAddressPopup={setOpenSearchAddressPopup}
            loginModal={true}
            setCompanyPostcode={setCompanyPostcode}
            setCompanyAddress1={setCompanyAddress1}
        />)
    }

    return (
        <div>
            <div className="signupContent">
                <div className="signupHeadText">
                    {stringLineBreak(headText.headText)}
                    {headText.subText && <div className="loginSubText">
                        {headText.subText}
                    </div>}
                </div>

                <img
                    className="closeIcon"
                    src={closeIcon}
                    onClick={() => {
                        setIsLoginModalOn(false);
                        setIsBodyScrollable(true);
                        // setOrderForNonUser(false);
                        resetState();
                    }}
                />

                {stepStatus === 'init' &&

                    <>
                        {/* benefits */}
                        <div className="signupBenefits">
                            {benefits && benefits.map((benefit, idx) => {

                                return (
                                    <div className="signupBenefit"
                                         key={"benefit" + idx}>
                                        <div className="benefitImg">
                                            <img src={benefit.imgUrl}/>
                                        </div>
                                        <div className="benefitTexts">
                                            <div className="benefitTitle">
                                                {benefit.title}
                                            </div>
                                            {benefit.desc && <div className="benefitDesc">
                                                {benefit.desc}
                                            </div>}

                                        </div>
                                    </div>
                                );
                            })}

                        </div>
                        <div className="signupMethodWrapper">
                            <div className="signupMethods">
                                <div
                                    className="signupMethod kakao"
                                    onClick={() => {
                                        kakao_auth();
                                    }}
                                    style={{backgroundColor: '#fae301'}}
                                >
                                    <div className="loginLogo">
                                        <img
                                            src="/img/icon-login-kakao-2.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="loginMethodText">
                                        카카오로 1초 회원가입
                                    </div>
                                </div>

                                {/* desc */}
                                <div className="signupMethodTopDesc">
                                    혜택 없이 가입하시겠어요?
                                </div>
                                <div
                                    className={`signupMethod`}
                                    onClick={() => {
                                        setStepStatus('email');
                                    }}
                                >
                                    <div className="loginLogo">
                                        <img
                                            src="/img/icon-login-email.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="signupMethodText">
                                        이메일로 가입하기
                                    </div>
                                </div>

                                {/* desc */}
                                <div className="signupMethodTopDesc">
                                    유통/업무 제휴가 궁금하신가요?
                                </div>
                                <div
                                    className={`signupMethod`}
                                    onClick={() => {
                                        window.open('/partner')
                                    }}
                                >
                                    <div className="loginLogo">
                                        <img
                                            src="/img/icon-login-business.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="signupMethodText">
                                        알아보기
                                    </div>
                                </div>
                            </div>

                            {/* to login btn */}
                            <div className="signupToggleBtn"
                                 onClick={() => {
                                     setIsLoginContent(true);
                                 }}
                            >
                                로그인 하기
                                <img src={MIconLinkArrow10} alt=""/>
                            </div>
                        </div>
                    </>
                }

                {(stepStatus === 'partner' || stepStatus === 'email') &&

                    <div className="singUpWrapper">
                        <form
                            action=""
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                            style={{width: '100%'}}
                        >
                            {signupInfo && signupInfo.map((input, idx) => {

                                return (
                                    <InputWithValidation
                                        key={idx}
                                        className={input.className}
                                        setStateFunction={input.setStateFunction}
                                        targetState={stepStatus === 'partner' ? partnerStates[idx] : emailStates[idx]}
                                        placeHolder={input.placeHolder}
                                        errMsg={errorMsgs[idx]}
                                        inputType={input.inputType}
                                        readOnly={input.readOnly}
                                        addiTionalObj={input.addiTionalObj}
                                    />
                                )
                            })}

                            <div className="termBtns">
                                {termInputs && termInputs.map((input, idx) => {

                                    return (
                                        <CheckWithValidation
                                            className={input.className}
                                            setStateFunction={input.setStateFunction}
                                            targetState={input.targetState}
                                            errMsg={termErrorMsgs[idx]}
                                            text={
                                                <span
                                                    onClick={input.onClick}
                                                    style={{
                                                        cursor: 'pointer',
                                                        textDecoration: 'underline',
                                                    }}
                                                >
                                                    {input.text}
                                                </span>
                                            }
                                        />
                                    )
                                })}
                            </div>
                            {stepStatus === 'partner' && <div className="signupPartnerNotice">
                                파트너 계정 신청 시, 담당자 검토 후 계정
                                승인 절차가 이뤄집니다. <br/>
                                계정 승인이 되면 담당자 연락처로 결과가
                                안내되며, 승인 이후 파트너 계정으로
                                로그인 및 구매가 가능합니다.
                            </div>}

                            <Button
                                className={`signinButton ${isAllDataInputed ? 'active' : ''
                                }`}
                                onClick={() => {
                                    signup();
                                }}
                            >
                                가입하고 혜택받기
                                {/* {stepStatus === 'partner' ? '파트너 계정 신청하기' : ''} */}
                            </Button>
                        </form>
                    </div>
                }
            </div>

            {loginCustomPopupData && <LoginCustomPopup
                data={loginCustomPopupData}
            />}
        </div>
    );
});
export default SignupContent;
