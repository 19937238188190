import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import {
    SANTA_COLLECTION_SLUG,
    COLLECTION_DETAIL_DATA_URL,
} from '../../constants/constants';

import { fetchAndReceiveJSON } from '../../utils/ajaxUtils';
import './BBSantaPresentList.scss';

const BBSantaPresentList = observer(() => {
    const [presentList, setPresentList] = useState([]);

    const { rootStore, bbPageStore, cartPageStore } = useContext(AppContext);

    const { initData, setIsSpinnerOn } = rootStore;
    const { selectedSantaPresent, setSelectedSantaPresent } = bbPageStore;
    const { selectedSantaPresentInCart } = cartPageStore;

    useEffect(() => {
        setIsSpinnerOn(true);
        const fetchData = async () => {
            const res = await fetchAndReceiveJSON({
                url: `${COLLECTION_DETAIL_DATA_URL}?slug=${SANTA_COLLECTION_SLUG}`,
            });

            return res;
        };

        fetchData().then((res) => {
            setPresentList(
                res.contents.products.sort(
                    (a, b) =>
                        a.stockStatus.charCodeAt() - b.stockStatus.charCodeAt()
                )
            );
            setSelectedSantaPresent(
                location.pathname === '/box' ||
                    location.pathname === '/santa2021'
                    ? res.contents.products[0]
                    : selectedSantaPresentInCart &&
                          res.contents.products.filter(
                              (product) =>
                                  product.id ===
                                  selectedSantaPresentInCart.product_id
                          )[0]
            );
            setIsSpinnerOn(false);
        });
    }, []);

    return (
        <div className="BBSantaPresentList">
            <div className="title">
                <img src="/img/santa-present-icon.png" />
                <br />
                산타에게 받고 싶은 선물을 알려주세요.
            </div>
            {presentList && (
                <div className="presentsList">
                    {presentList.map((item) => {
                        return (
                            <div
                                key={item.id}
                                className={`presentItem ${
                                    selectedSantaPresent &&
                                    item.id === selectedSantaPresent.id
                                        ? 'selected'
                                        : ''
                                }
                                   `}
                            >
                                <div className="imgWrapper">
                                    <img
                                        onClick={
                                            item.stockStatus === 'instock'
                                                ? () => {
                                                      setSelectedSantaPresent(
                                                          item
                                                      );
                                                  }
                                                : undefined
                                        }
                                        src={`${initData.resourcePrefix}${
                                            item.images[item.images.length - 1]
                                                .src
                                        }`}
                                        alt={item.name}
                                    />
                                    {item.stockStatus !== 'instock' && (
                                        <div className="soldoutDim">
                                            SOLD OUT
                                        </div>
                                    )}
                                </div>
                                <div className="productName">{item.name}</div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
});

export default BBSantaPresentList;
