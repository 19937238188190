import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import ChangePersonalInfoPopup from '../ChangePersonalInfoPopup/ChangePersonalInfoPopup';
import './PersonalInfo.scss';
import MyAccountPopup from '../MyAccountPopup/MyAccountPopup';
import DeleteAccount from '../DeleteAccount/DeleteAccount';
import { hideChatBtn } from '../../utils/commonUtils';

const PersonalInfo = observer(() => {
    const [openChangeInfoPopup, setOpenChangeInfoPopup] = useState({
        isOpen: false,
        type: '',
    });

    const { rootStore, userInfoStore } = useContext(AppContext);

    const { setIsDimBgOn, setIsSpinnerOn, initData } = rootStore;
    // const { setUserInfo, userInfo } = userInfoStore;
    const [userInfo, setUserInfo] = useState(null);
    const [isDeleteAccountPopup, setIsDeleteAccountPopup] = useState(false);

    // useEffect(() => {
    //     if (userInfo === undefined) {
    //         setIsSpinnerOn(true);
    //         setUserInfo().then(() => setIsSpinnerOn(false));
    //     }
    // }, []);

    useEffect(() => {
        setUserInfo(initData.userInfo);
    }, []);

    useEffect(() => {
        hideChatBtn(isDeleteAccountPopup || openChangeInfoPopup.isOpen);
    },[isDeleteAccountPopup , openChangeInfoPopup.isOpen]);

    return (
        <>
            {userInfo && (
                <section className="PersonalInfo">
                    <h3 className="PersonalInfoTitle">나의 기본 정보</h3>
                    <div className="userInfo">
                        <div className="infoType">
                            <div className="typeName">회원 정보</div>
                            <div
                                onClick={() => {
                                    setIsDimBgOn(true);
                                    setOpenChangeInfoPopup({
                                        isOpen: true,
                                        type: 'userInfo',
                                    });
                                }}
                                className="changeInfoBtn"
                            >
                                회원 정보 변경
                            </div>
                        </div>
                        <table>
                            {initData.isPartner ? (
                                <tbody>
                                    <tr>
                                        <th scope="row">담당자 이름</th>
                                        <td>{userInfo.first_name}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            담당자 휴대전화 번호
                                        </th>
                                        <td>{userInfo.billing.phone}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">담당자 이메일</th>
                                        <td>{userInfo.username}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">법인명</th>
                                        <td>{userInfo.company_name}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">대표자 이름</th>
                                        <td>{userInfo.company_rep_name}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">사업자 등록번호</th>
                                        <td>{userInfo.business_num}</td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <th scope="row">이름</th>
                                        <td>{userInfo.first_name}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">이메일 아이디</th>
                                        <td>{userInfo.email}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">휴대전화 번호</th>
                                        <td>{userInfo.billing.phone}</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </div>
                    <div
                        onClick={() => {
                            setIsDimBgOn(true);
                            setOpenChangeInfoPopup({
                                isOpen: true,
                                type: 'modifyPassword',
                            });
                        }}
                        className="modifyPassword"
                    >
                        비밀번호 변경
                    </div>
                    <div className="divider" />
                    <div className="deliveryAddressInfo">
                        <div className="infoType">
                            <div className="typeName">배송지 정보</div>
                            <div
                                onClick={() => {
                                    setIsDimBgOn(true);
                                    setOpenChangeInfoPopup({
                                        isOpen: true,
                                        type: 'deliveryAddressInfo',
                                    });
                                }}
                                className="changeInfoBtn"
                            >
                                배송지 변경
                            </div>
                        </div>
                        <table>
                            <tbody>
                                <tr>
                                    <th scope="row">받는 분</th>
                                    <td>{userInfo.shipping.first_name}</td>
                                </tr>
                                <tr>
                                    <th scope="row">휴대전화 번호</th>
                                    <td>{userInfo.shipping.phone}</td>
                                </tr>
                                <tr>
                                    <th scope="row">우편번호</th>
                                    <td>{userInfo.shipping.postcode}</td>
                                </tr>
                                <tr>
                                    <th scope="row">기본주소</th>
                                    <td>{userInfo.shipping.address_1}</td>
                                </tr>
                                <tr>
                                    <th scope="row">상세주소</th>
                                    <td>{userInfo.shipping.address_2}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="withdrawBtn"
                        onClick={() => {
                            setIsDeleteAccountPopup(true);
                        }}
                    >회원 탈퇴 하기</div>
                </section>
            )}

            {openChangeInfoPopup.isOpen && (
                <ChangePersonalInfoPopup
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    openChangeInfoPopup={openChangeInfoPopup}
                    setOpenChangeInfoPopup={setOpenChangeInfoPopup}
                />
            )}

            {isDeleteAccountPopup && (
                <MyAccountPopup
                    content={<DeleteAccount
                        setIsShowPopup={setIsDeleteAccountPopup}

                    />
                    }
                    setIsShowPopup={setIsDeleteAccountPopup}
                    isCustomPadding={false}
                    isFullPopup={false}
                />)
            }
        </>
    );
});

export default PersonalInfo;
