//error status
export const RESPONSE_STATUS_FAIL_LOGIN =
    'bad_request_not_registered_user_or_invalid_info';
export const RESPONSE_DOGFOOD_PRODUCT_LIMIT_COUNT =
    'bad_request_dogfood_product_limit_count_by_subscription_exceeded_case_when_add_cart';
export const RESPONSE_ALREADY_HAS_DOGFOOD =
    'bad_request_dogfood_subscription_limit_count_by_customer_exceeded';
export const RESPONSE_REGISTER_BILLING_PHONE_ALREADY_EXIST =
    'bad_request_billing_phone_already_exist';
export const RESPONSE_UNAVAILABLE_UNSUBSCRIPTION_HAS_ACTIVE_SUBSCRIPTION =
    'bad_request_unavailable_unsubscribe_has_active_subscription';
export const RESPONSE_REGISTER_BILLING_PHONE_ALREADY_EXIST_MSG =
    '입력하신 휴대전화 번호로 가입한 회원이 이미 존재합니다. 다른 휴대전화 번호를 입력해 주세요.';

//error msg
export const RESPONSE_STATUS_FAIL_LOGIN_OBJ = {
    RESPONSE_STATUS_FAIL_LOGIN: '아이디 또는 비밀번호가 일치하지 않습니다.',
};
