import { observer } from 'mobx-react';


const ProductEventBanner = ({
    className,
    productDescription,
}) => {

    return (
        <>{productDescription &&
            productDescription.text?.length > 0 && (
                <div
                    className={className}
                    onClick={() => {
                        // location.href = '/signup/menu';
                    }}
                    style={{backgroundColor: productDescription.bgColor, color: productDescription.fontColor}}
                >
                    {productDescription.text}
                </div>
            )}
        </>
    );
};
export default ProductEventBanner;
