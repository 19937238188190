import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { defaultElementProps, sliderContainerProps } from '../../types/types';
import { fetchAndReceiveJSON } from '../../utils/ajaxUtils';
import { stringLineBreak } from '../../utils/commonUtils';
import './CollectionProductsContainer.scss';
import {
    PRODUCTS_IN_CATEGORY_ENDPOINT,
    DOG_FOOD_COLLECTION_SLUG,
    HUNDRED_SHOP_SLUG,
} from '../../constants/constants';
import ProductCard from '../../components/ProductCard/ProductCard';

import NavigationSlider from '../../components/Sliders/NavigationSlider/NavigationSlider';
import CollectionSortingSelector from '../../components/CollectionSortingSelector/CollectionSortingSelector';
import { AppContext } from '../../contexts/AppContext/AppContext';
import { createImportSpecifier } from 'typescript';
interface ICollectionProductsContainer extends sliderContainerProps {
    collectionData?: any;
    useInSearch?: boolean;
}

const CollectionProductsContainer: React.FunctionComponent<ICollectionProductsContainer> =
    ({ className, collectionData, useInSearch, ...otherProps }) => {
        let productsList;

        const [innerWidth, setInnerWidth] = useState(window.innerWidth);

        const { collectionPageStore } = React.useContext(AppContext);

        if (!productsList) {
            productsList = collectionData
                ? collectionData.contents.products
                : [];
        }

        const {
            curSort,
            setCurSort,
            setSelectedProductsList,
            selectedProductsList,
            currentSelectedCategory,
            setCurrentSelectedCategory,
            setCurrentTargetCategoryId,
        } = collectionPageStore;

        const navRef = useRef<HTMLDivElement>(null);

        const { slug } = useParams<{ slug: string }>();

        const isDogFood = slug === DOG_FOOD_COLLECTION_SLUG;

        return (
            <>
                {isDogFood ? (
                    <div
                        className={`CollectionProductsContainer ${
                            className ? className : ''
                        }`}
                    >
                        {!useInSearch &&
                            collectionData.contents.subCollections.length >
                                0 && (
                                <div ref={navRef} id="subCollectionNav">
                                    <div className="subCollectionNavWrapper">
                                        <NavigationSlider
                                            className="CollectionProductsNavSlider"
                                            slideData={[
                                                {
                                                    id: 0,
                                                    name: '전체',
                                                    slug: 'all',
                                                },
                                                ...collectionData.contents
                                                    .subCollections,
                                            ]}
                                            overFlowVisible={true}
                                            target={currentSelectedCategory}
                                            spaceBetween={20}
                                            changeTargetFunction={
                                                setCurrentSelectedCategory
                                            }
                                            getCurrentTargetFunction={
                                                setCurrentTargetCategoryId
                                            }
                                            withSizeConditionalNavigation
                                        />
                                    </div>
                                </div>
                            )}

                        <div className="quantityAndSorting">
                            <div className="collectionProductsQuantity">
                                총{' '}
                                <span className="collectionPointText">
                                    {useInSearch
                                        ? productsList.length
                                        : slug === DOG_FOOD_COLLECTION_SLUG
                                        ? collectionData.pageinfo.itemCnt + 1
                                        : collectionData.pageinfo.itemCnt}
                                    개
                                </span>{' '}
                                상품
                            </div>
                            {!useInSearch && <CollectionSortingSelector />}
                        </div>
                        <div className="collectionProducts">
                            {productsList.map((product) => {
                                return (
                                    <ProductCard
                                        key={product.id}
                                        product={product}
                                    />
                                );
                            })}
                            {collectionData.pageinfo.totalPageCnt ===
                                collectionData.pageinfo.page && (
                                <ProductCard
                                    product={specialOrderForDogFood}
                                    isSpecialOrderCard
                                    isAddCartAvailable={false}
                                />
                            )}
                        </div>
                    </div>
                ) : (
                    <div
                        className={`CollectionProductsContainer ${
                            className ? className : ''
                        }`}
                        // style={{
                        //     position: 'relative',
                        // }}
                    >
                        {!useInSearch &&
                            collectionData.contents.subCollections.length >
                                0 && (
                                <div ref={navRef} id="subCollectionNav">
                                    <div className="subCollectionNavWrapper">
                                        <NavigationSlider
                                            className="CollectionProductsNavSlider"
                                            slideData={[
                                                {
                                                    id: 0,
                                                    name: '전체',
                                                    slug: 'all',
                                                },
                                                ...collectionData.contents
                                                    .subCollections,
                                            ]}
                                            overFlowVisible={true}
                                            target={currentSelectedCategory}
                                            spaceBetween={20}
                                            changeTargetFunction={
                                                setCurrentSelectedCategory
                                            }
                                            getCurrentTargetFunction={
                                                setCurrentTargetCategoryId
                                            }
                                            withSizeConditionalNavigation
                                        />
                                    </div>
                                </div>
                            )}

                        <div className="quantityAndSorting">
                            <div className="collectionProductsQuantity">
                                총{' '}
                                <span className="collectionPointText">
                                    {useInSearch
                                        ? productsList.length
                                        : collectionData.pageinfo.itemCnt}
                                    개
                                </span>{' '}
                                상품
                            </div>

                            {!useInSearch && <CollectionSortingSelector />}
                        </div>
                        <div className="collectionProducts">
                            {productsList.map((product) => {
                                return (
                                    <ProductCard
                                        key={product.id}
                                        // productId={product.id}
                                        // title={product.name}
                                        // regularPrice={product.regularPrice}
                                        // disCountedPrice={`${product.price}원`}
                                        // disCountRate={product.discountRate}
                                        // images={product.images}
                                        // badgeText={
                                        //     product.promoText &&
                                        //     product.promoText
                                        // }
                                        // isOnSale={product.isOnSale}
                                        product={product}
                                        isHundredShop={
                                            slug === HUNDRED_SHOP_SLUG
                                        }
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}
            </>
        );
    };
export default CollectionProductsContainer;

const specialOrderForDogFood = {
    productBrand: '스페셜오더',
    name: '원하는 사료 특별 주문하기',
    displayPrice: '0원~',
    isOnSale: false,
    imgSrc: '/img/special-order-thumbnail.jpg',
};
