import React, {useContext, useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {AppContext} from '../../contexts/AppContext/AppContext';
import {openChannelIO} from '../../utils/commonUtils';
import {HUNDRED_SHOP_SLUG} from '../../constants/constants';
import './HamburgerMenu.scss';

const HamburgerMenu = ({openMenu, setOpenMenu}) => {
    const {rootStore, cartPageStore, homeStore, collectionPageStore} =
        useContext(AppContext);
    const {
        initData,
        setIsDimBgOn,
        setIsLoginModalOn,
        setShowSearch,
        setSignUpModalOn,
    } = rootStore;
    const {cartListCount, setIsCartLoading} = cartPageStore;
    //const { openMenu, setOpenMenu } = homeStore;
    const {setCurrentSelectedCategory, setCurSort} = collectionPageStore;

    const [openSubCollectionsList, setOpenSubCollectionList] = useState({
        isOpened: false,
        slug: '',
    });

    const history = useHistory();

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    return (
        <section className={`HamburgerMenu ${openMenu ? 'slideIn' : ''}`}>
            <section className="navAndAccountInfo">
                <nav className="navMenu">
                    <div className="leftSide">
                        <img
                            onClick={() => {
                                history.push('/');
                                setOpenMenu(false);
                                setIsDimBgOn(false);
                            }}
                            src="/img/m-icon-gnb-btn-home-white.svg"
                            alt="go to home"
                        />
                    </div>
                    <div className="rightSide">
                        <img
                            onClick={() => {
                                //카트 이동은 상태변경으로
                                setShowSearch(true);
                                setOpenMenu(false);
                                setIsDimBgOn(false);
                            }}
                            src="/img/m-icon-gnb-btn-search-white.svg"
                            alt="search"
                        />
                        {cartListCount ? (
                            <div
                                onClick={() => {
                                    //카트 이동은 상태변경으로
                                    setIsCartLoading(true);
                                    setOpenMenu(false);
                                    setIsDimBgOn(false);
                                }}
                                className="cartWithItems"
                            >
                                {cartListCount}
                            </div>
                        ) : (
                            <img
                                onClick={() => {
                                    //카트 이동은 상태변경으로
                                    setIsCartLoading(true);
                                    setOpenMenu(false);
                                    setIsDimBgOn(false);
                                }}
                                className="iconWithGap"
                                src="/img/m-icon-gnb-btn-cart-white.svg"
                                alt="cart"
                            />
                        )}

                        <img
                            onClick={() => {
                                setOpenMenu(false);
                                setIsDimBgOn(false);
                            }}
                            src="/img/btn-gnb-popup-close-white.svg"
                            alt="close hamburger menu"
                        />
                    </div>
                </nav>
                <div className="userAccountSection">
                    {initData.isLogin ? (
                        <div
                            onClick={() => {
                                history.push('/my-account/orders');
                                setOpenMenu(false);
                                setIsDimBgOn(false);
                            }}
                            className="userAccountTitle"
                        >
                            {`${initData.userFirstName}님`}
                        </div>
                    ) : (
                        <div className="userAccountTitle nonLogin">
                            <div
                                onClick={() => {
                                    setIsLoginModalOn(true);
                                    setOpenMenu(false);
                                    setIsDimBgOn(false);
                                }}
                            >
                                로그인
                            </div>
                            <span/>
                            <div
                                onClick={() => {
                                    setSignUpModalOn(true);
                                    setOpenMenu(false);
                                    setIsDimBgOn(false);
                                }}
                            >
                                회원가입
                            </div>
                        </div>
                    )}
                    <div className="userAccountDesc">
                        {initData.isLogin ? (
                            <>
                                <span
                                    onClick={() => {
                                        history.push('/my-account/orders');
                                        setOpenMenu(false);
                                        setIsDimBgOn(false);
                                    }}
                                >
                                    주문배송 조회
                                </span>
                            </>
                        ) : (
                            <span
                                onClick={() => {
                                    setOpenMenu(false);
                                    setIsDimBgOn(false);
                                    history.push('/welcome');
                                }}
                                className="nonLogin"
                            >
                                    신규 회원가입 특별한 2가지 혜택
                                </span>
                        )}
                    </div>
                </div>
            </section>
            <div className="whiteBgArea">
                <div className="collectionMenuList">
                    {initData.eventText && initData.eventText.length > 0 && (
                        <button
                            onClick={() => {
                                history.push(initData.eventText[0].link);
                                setOpenMenu(false);
                                setIsDimBgOn(false);
                            }}
                            type="button"
                            className="eventBanner"
                            style={{
                                backgroundColor: `${initData.eventText[0].bgColor}`,
                                color: `${initData.eventText[0].fontColor}`,
                            }}
                        >
                            {initData &&
                                initData.eventText &&
                                initData.eventText[0] &&
                                initData.eventText[0].text}
                        </button>
                    )}

                    <div className="collectionMenuItem">
                        {initData.topMenu.map((menu) => {
                            // if (
                            //     menu.slug === HUNDRED_SHOP_SLUG &&
                            //     initData.isLogin &&
                            //     initData.isWelcomeSpecialPriceCouponUsed
                            // )
                            //     return;
                            if (menu.slug !== 'home') {
                                return (
                                    <React.Fragment key={menu.id}>
                                        <div className="menuNameWrapper">
                                            {openSubCollectionsList.isOpened &&
                                                menu.slug ===
                                                openSubCollectionsList.slug && (
                                                    <div className="dot"/>
                                                )}
                                            <div
                                                onClick={
                                                    menu.slug === 'box' ||
                                                    menu.slug ===
                                                    HUNDRED_SHOP_SLUG
                                                        ? () => {
                                                            history.push(
                                                                menu.link
                                                            );
                                                            setOpenMenu(
                                                                false
                                                            );
                                                            setIsDimBgOn(
                                                                false
                                                            );
                                                        }
                                                        : menu.slug !== 'box' &&
                                                        menu.slug !==
                                                        HUNDRED_SHOP_SLUG &&
                                                        initData.collectionList.filter(
                                                            (collection) =>
                                                                collection.url ===
                                                                menu.slug
                                                        ).length > 0 &&
                                                        initData.collectionList.filter(
                                                            (collection) =>
                                                                collection.url ===
                                                                menu.slug
                                                        )[0].subCollections
                                                            ?.length > 0
                                                            ? () => {
                                                                setOpenSubCollectionList(
                                                                    {
                                                                        isOpened:
                                                                            !openSubCollectionsList.isOpened,
                                                                        slug: menu.slug,
                                                                    }
                                                                );
                                                            }
                                                            : () => {
                                                                history.push(
                                                                    menu.link
                                                                );
                                                                setOpenMenu(
                                                                    false
                                                                );
                                                                setIsDimBgOn(
                                                                    false
                                                                );
                                                            }
                                                }
                                                className="menuName"
                                            >
                                                {menu.name}
                                            </div>
                                            {initData.collectionList.filter(
                                                    (collection) =>
                                                        collection.url === menu.slug
                                                ).length > 0 &&
                                                initData.collectionList
                                                    .filter(
                                                        (collection) =>
                                                            collection.url ===
                                                            menu.slug
                                                    )
                                                    .map((collection) => {
                                                        if (
                                                            collection.subCollections &&
                                                            collection
                                                                .subCollections
                                                                .length > 0
                                                        ) {
                                                            return (
                                                                <img
                                                                    key={
                                                                        collection.id
                                                                    }
                                                                    className="showSubCollectionsBtn"
                                                                    src={
                                                                        openSubCollectionsList.isOpened &&
                                                                        openSubCollectionsList.slug ===
                                                                        menu.slug
                                                                            ? '/img/m-icon-hamberger-minus-plus-10.svg'
                                                                            : '/img/m-icon-hamberger-more-plus-10.svg'
                                                                    }
                                                                    alt="show sub colletions"
                                                                />
                                                            );
                                                        }
                                                    })}
                                        </div>
                                        {openSubCollectionsList.isOpened &&
                                            openSubCollectionsList.slug ===
                                            menu.slug && (
                                                <div className="subCollectionListArea">
                                                    {[
                                                        {
                                                            id: 0,
                                                            name: '모든 제품',
                                                            slug: 'all',
                                                        },
                                                        ...initData.collectionList.filter(
                                                            (collection) =>
                                                                collection.url ===
                                                                openSubCollectionsList.slug
                                                        )[0].subCollections,
                                                    ].map((item) => {
                                                        return (
                                                            <div
                                                                key={item.id}
                                                                onClick={() => {
                                                                    setCurrentSelectedCategory(
                                                                        {
                                                                            id: item.id,
                                                                            name: item.name,
                                                                            slug: item.slug,
                                                                        }
                                                                    );
                                                                    history.push(
                                                                        menu.link
                                                                    );
                                                                    setOpenMenu(
                                                                        false
                                                                    );
                                                                    setIsDimBgOn(
                                                                        false
                                                                    );
                                                                }}
                                                                className="subCollectionItem"
                                                            >
                                                                {item.name}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                    </React.Fragment>
                                );
                            }
                        })}
                    </div>
                    <div className="bestOrNewItems">
                        <button
                            onClick={() => {
                                history.push(
                                    '/store/collection/all-collection'
                                );
                                setOpenMenu(false);
                                setCurSort('hot');
                                setIsDimBgOn(false);
                            }}
                            type="button"
                        >
                            BEST
                        </button>
                        <button
                            onClick={() => {
                                history.push(
                                    '/store/collection/all-collection'
                                );
                                setCurSort('new');
                                setOpenMenu(false);
                                setIsDimBgOn(false);
                            }}
                            type="button"
                        >
                            NEW
                        </button>
                    </div>
                </div>
                <footer className="socialIcons">
                    <div className="leftSide">
                        <a
                            href="https://www.instagram.com/dogslovebacon/"
                            target="_blank"
                        >
                            <img src="/img/instagram.svg" alt="instagram"/>
                        </a>
                        <a
                            href="https://www.facebook.com/baconbox.co/"
                            target="_blank"
                        >
                            <img src="/img/facebook.svg" alt="facebook"/>
                        </a>
                        <a href="https://pf.kakao.com/_YWDxnj" target="_blank">
                            <img src="/img/kakao.svg" alt="kakao"/>
                        </a>
                    </div>
                    <div onClick={() => openChannelIO()} className="rightSide">
                        {/* <a href="" target="_blank"> */}
                        {/* <img src="/img/chat.svg" alt="request via chat" /> */}
                        문의하기
                        {/* </a> */}
                    </div>
                </footer>
            </div>
        </section>
    );
};

export default HamburgerMenu;
