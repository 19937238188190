import React, {useContext, useState, useEffect, useRef} from 'react';
import {AppContext} from '../../contexts/AppContext/AppContext';
import {observer} from 'mobx-react';
import './MiniCart.scss';
import {addComma, getSrcUrl, isEventPriceProduct} from '../../utils/commonUtils';
import {IAMPORT_CODE, NPAY_BUTTON_KEY} from '../../constants/constants';
import {buyNowNpayAction, getProductsForNpayZzim, makeNpayButton} from '../../utils/paymentUtils';
import {
    isDogFoodProduct,
    isSubscriptionProduct,
} from '../../utils/miniCartUtils';
import {useHistory, useLocation} from 'react-router-dom';
import {npayBtnClick} from '../../utils/npayUtils';

import MiniCartBottom from './MiniCartBottom';
import MinicartDropDownArea from './MinicartDropDownArea';
import MinicartSelectedArea from './MinicartSelectedArea';
import {toJS} from 'mobx';
import MinicartHighlightText from './MinicartHighlightArea';

const MiniCart = observer(({isShowNpayBtn, isProductInfo}) => {
    const {miniCartPopupStore, cartPageStore, rootStore} =
        useContext(AppContext);
    const {setCartListCount, setIsCartLoading} = cartPageStore;
    const {
        setIsSpinnerOn, initData, setIsLoginModalOn,
        setSignUpModalOn,
    } = rootStore;
    const {
        miniCartProduct,
        miniCartProductQty,
        mainProductQtyInputHandler,
        optionProductQtyInputHandler,
        optionProducts,
        changeOptionProductQty,
        removeOptionProduct,
        miniCartPrice,
        changeMiniCartQty,
        addItemToCart,
        addItemToCartBuyNow,
        keys,
        setNextSelectOption,
        getCartAddData,
        updateSelectedOption,
        updateSelectedDogOption,
        getSelectableOptions,
        addDataToOption,
        tempOptionProduct,
    } = miniCartPopupStore;

    const [isNpayClicked, setIsNpayClicked] = useState(false);
    const [isBuyClicked, setIsBuyClicked] = useState(false);
    const productData = useRef(miniCartProduct)
    const location = useLocation();

    useEffect(() => {
        productData.current = miniCartProduct
    }, [miniCartProduct])
    const addCart = async () => {

        await addItemToCart();

        if (location.pathname.includes('/checkout')) {
            setIsCartLoading(true);
        } else {
            setCartListCount();
        }
    };

    const npayButtonIds = [
        'iamport-naverpay-product-button',
        'iamport-naverpay-product-button2',
        'iamport-naverpay-product-button3',
    ];
    let retryCnt = 0;

    const renderNpayButton = () => {

        if (retryCnt <= 3) {
            if (document.getElementById(npayButtonIds[0]) == undefined) {

                setTimeout(() => {
                    renderNpayButton();
                    retryCnt++;
                }, 500);
                return;
            }
        }

        makeNpayButton({
            elemIds: npayButtonIds,
            buttonKey: NPAY_BUTTON_KEY,
            buyAction: () => {
                buyNowNpayAction(getCartAddData(), setIsSpinnerOn, initData);
            },
            getProductsForNpayZzim: () => {
                // @ts-ignore
                window.IMP.init(IAMPORT_CODE);
                if (productData) {
                    return getProductsForNpayZzim({
                        id: productData.current?.id,
                        name: productData.current?.name,
                        uprice:productData.current?.price,
                        slug: productData.current?.slug,
                        image: "https://fly.gitt.co/baconbox" + productData.current?.images[0]?.src,
                    });
                }
            },
            withZzim: true,
        });
    };

    useEffect(() => {
        renderNpayButton();
    }, []);

    const isNpayQueryString = location.search === '?npay';

    // console.log(toJS(miniCartProduct));

    const isDogFood = isDogFoodProduct(miniCartProduct);
    const isSubscriptionProd = isSubscriptionProduct(miniCartProduct);

    let discountRate = "";
    if (miniCartProduct.discountRate) {
        discountRate = miniCartProduct.discountRate.replace(/\(/g, '').replace(/\)/g, '');

        if (discountRate === '-0%' || discountRate === '0%') {
            discountRate = null;
        }
    }


    return (
        <>
            <div className="miniCartTopArea">
                {isProductInfo === false && (
                    <div className="OptionTitle">
                        <img
                            className="productImg"
                            src={getSrcUrl(miniCartProduct.images[0]?.src)}
                        />
                        <div className="productInfoArea">
                            <div className="productTitle">
                                {miniCartProduct.productBrand && <div className="">
                                    [{miniCartProduct.productBrand}]
                                </div>}
                                {miniCartProduct.name}
                            </div>

                            {miniCartProduct.type === 'grouped' && (
                                <div className="priceArea">
                                    <div className="priceInfo">
                                        {miniCartProduct.price?.length > 0 && (
                                            <div className="price">
                                                {miniCartProduct.price}
                                                <div className="won">원</div>
                                            </div>
                                        )}
                                        {miniCartProduct.isOnSale && (
                                            <div className="regularPrice">
                                                {miniCartProduct.regularPrice}
                                            </div>
                                        )}
                                    </div>
                                    <div className="discount">
                                        {discountRate &&
                                            discountRate}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {miniCartProduct.stockStatus === "instock" &&

                    <MinicartDropDownArea
                        miniCartProduct={miniCartProduct}
                        changeMiniCartQty={changeMiniCartQty}
                        mainProductQtyInputHandler={mainProductQtyInputHandler}
                        miniCartProductQty={miniCartProductQty}
                        setNextSelectOption={setNextSelectOption}
                        getSelectableOptions={getSelectableOptions}
                        keys={keys}
                        updateSelectedOption={updateSelectedOption}
                        updateSelectedDogOption={updateSelectedDogOption}
                        optionProducts={optionProducts}
                        addDataToOption={addDataToOption}
                        tempOptionProduct={tempOptionProduct}
                        removeOptionProduct={removeOptionProduct}
                    />
                }

                {/* 선택된 아이템 */}
                <MinicartSelectedArea
                    optionProducts={optionProducts}
                    miniCartProduct={miniCartProduct}
                    removeOptionProduct={removeOptionProduct}
                    changeOptionProductQty={changeOptionProductQty}
                    optionProductQtyInputHandler={optionProductQtyInputHandler}
                />

            </div>

            {/* bottomArea */}
            <MiniCartBottom
                miniCartPrice={miniCartPrice}
                miniCartProduct={miniCartProduct}
                isNpayQueryString={isNpayQueryString}
                isNpayClicked={isNpayClicked}
                isBuyClicked={isBuyClicked}
                isProductInfo={isProductInfo}
                setIsNpayClicked={setIsNpayClicked}
                setIsBuyClicked={setIsBuyClicked}
                addCart={addCart}
                addItemToCartBuyNow={addItemToCartBuyNow}
                isSubscriptionProd={isSubscriptionProd}
                isDogFood={isDogFood}
                isShowNpayBtn={isShowNpayBtn}
            />

        </>
    );
});

export default MiniCart;
