import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/AppContext/AppContext';
import './AgreementModal.scss';
import PersonalInfoContent from './PersonalInfoContent';
import TermsContent from './TermsContent';
import {useHistory} from "react-router-dom";
const AgreementModal: React.FunctionComponent = observer(() => {
    const [isRegistered, setIsRegistered] = useState(true);
    const { rootStore } = React.useContext(AppContext);
    const history = useHistory();

    const {
        agreementType,
        setIsAgreementModalOn,
        isAgreementModalOn,
        setIsBodyScrollable,
    } = rootStore;

    useEffect(() => {
        if (isAgreementModalOn) {
            setIsBodyScrollable(false);
        } else {
            setIsBodyScrollable(true);
        }
    }, [isAgreementModalOn]);

    return (
        <div
            className={`AgreementModalWrapper ${
                isAgreementModalOn ? 'AMvisible' : ''
            }`}
        >
            <div
                className={`agreementContents ${
                    isAgreementModalOn ? 'AMvisible' : ''
                }`}
            >
                <div
                    className="closeButton"
                    onClick={() => {
                        setIsAgreementModalOn(false);
                        history.push(`${history.location.pathname}`)
                    }}
                >
                    <img
                        className="whiteClose"
                        src="/img/btn-gnb-popup-close-white.svg"
                        alt=""
                    />
                    <img
                        className="blackClose"
                        src="/img/btn-gnb-popup-close.svg"
                        alt=""
                    />
                </div>

                <div className="AgreementHeadArea">
                    {agreementType === 'terms' ? (
                        <div className="termSelectors">
                            <div
                                className={`termSelector ${
                                    isRegistered ? 'termActive' : ''
                                }`}
                                onClick={() => {
                                    setIsRegistered(true);
                                }}
                            >
                                쇼핑몰 이용약관
                            </div>

                            <div
                                className={`termSelector ${
                                    !isRegistered ? 'termActive' : ''
                                }`}
                                onClick={() => {
                                    setIsRegistered(false);
                                }}
                            >
                                비회원 이용약관
                            </div>
                        </div>
                    ) : (
                        '개인정보 취급방침'
                    )}
                </div>
                <div className="AgreementBodyArea">
                    {agreementType === 'terms' ? (
                        isRegistered ? (
                            <TermsContent
                                userAgreementType={'registeredUser'}
                            />
                        ) : (
                            <TermsContent
                                userAgreementType={'nonRegisteredUser'}
                            />
                        )
                    ) : (
                        <PersonalInfoContent />
                    )}
                </div>
                {/* 바디 영역만 스크롤 되도록 */}
            </div>
        </div>
    );
});
export default AgreementModal;
