import {observer} from 'mobx-react';
import {Helmet} from 'react-helmet';
import React, {useEffect} from 'react';
import '../../i18n/config';
import {useTranslation} from 'react-i18next';
import NavigationSlider from '../../components/Sliders/NavigationSlider/NavigationSlider';
import HomeTopContainer from '../../containers/HomeTopContainer/HomeTopContainer';
import PersonalizedContainer from '../../containers/PersonalizedContainer/PersonalizedContainer';
import HomeCollectionNavContainer from '../../containers/HomeCollectionNavContainer/HomeCollectionNavContainer';
import HomeServiceSummaryContainer from '../../containers/HomeServiceSummaryContainer/HomeServiceSummaryContainer';
import HomeProductListsContainer from '../../containers/HomeProductListsContainer/HomeProductListsContainer';
import HomeSingleProductHighlightContainer
    from '../../containers/HomeSingleProductHighlightContainer/HomeSingleProductHighlightContainer';
import HomeSocialInteractContainer from '../../containers/HomeSocialInteractContainer/HomeSocialInteractContainer';
import './HomePage.scss';
import HomeNavContainer from '../../containers/NavContainer/NavContainer';
import HomeReviewsContainer from '../../containers/HomeHighlightedSectionContainer/HomeReviewsContainer';
import {AppContext} from '../../contexts/AppContext/AppContext';
import {fetchAndReceiveJSON} from '../../utils/ajaxUtils';
import {HOME_DATA_FETCH_URL, HOME_SLUG} from '../../constants/constants';
import BBThemeContainer from "../../containers/BBThemeContainer/BBThemeContainer";

const HomePage = observer(() => {
    const {t} = useTranslation();
    // TODO: 렌더링 순서 동적으로 바뀌어야 함
    // 홈데이터 불러오기 gggg
    const {homeStore, rootStore} = React.useContext(AppContext);
    const {homeData, setHomeData, homePromotion} = homeStore;
    const {setCurrentTarget} = rootStore;
    useEffect(() => {
        setCurrentTarget({slug: HOME_SLUG});
        const fetchHomeData = async () => {
            const resData = await fetchAndReceiveJSON({
                url: HOME_DATA_FETCH_URL,
            });
            //console.log(resData);

            setHomeData(resData);
        };
        fetchHomeData();
    }, []);

    useEffect(() => {
        if (homeData && homeData.homeSection) {
            console.log('home page loaded!', homeData);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'dataLayer.append.home',
            });
        }
    }, [homeData]);

    // console.dir(homeData .homeMenu[0].id);
    return (
        <>
            <Helmet>
                <title>베이컨박스 | Dogs Love BACON!</title>
            </Helmet>
            <div className="homeWrapper">
                {!homeData ? (
                    <>
                        {/* <HomeTopContainer className="homeContainers" />
                    <PersonalizedContainer className="homeContainers" />
                    <HomeCollectionNavContainer className="homeContainers" />
                    <HomeServiceSummaryContainer className="homeContainers" />
                    <HomeServiceSummaryContainer className="homeContainers" />
                    <HomeProductListsContainer
                        className="homeContainers"
                        type={'flex'}
                    />
                    <HomeProductListsContainer
                        className="homeContainers"
                        type={'fix'}
                    />
                    <HomeSingleProductHighlightContainer className="homeContainers" />
                    <HomeReviewsContainer className="homeContainers" />

                    <HomeSocialInteractContainer className="homeContainers" /> */}
                    </>
                ) : (
                    <>
                        <HomeTopContainer className="homeContainers"/>
                        <PersonalizedContainer
                            className="homeContainers"
                            promoData={homePromotion}
                        />

                        {homeData.homeSection &&
                            homeData.homeSection.map((data, i) => {
                                switch (data.type) {
                                    case 'collection':
                                        return (
                                            <HomeCollectionNavContainer
                                                key={i}
                                                containerTitle={data.title}
                                                collectionNavData={
                                                    data.collectionItem
                                                }
                                                className="homeContainers"
                                            />
                                        );
                                    case 'custom_highlight':
                                        return (
                                            <HomeServiceSummaryContainer
                                                key={i}
                                                imgUrl={data.imgMo}
                                                imgUrl2={data.imgDt}
                                                mainText={data.title}
                                                subText={data.text}
                                                buttonText={data.btnText}
                                                buttonLink={data.btnLink}
                                                bgColor={data.bgColor}
                                                className="homeContainers"
                                            />
                                        );
                                    case 'flex':
                                        return (
                                            <HomeProductListsContainer
                                                key={i}
                                                slideCartegoryData={
                                                    data.categories
                                                }
                                                slideData={data.products}
                                                productDisplayLimit={
                                                    data.productDisplayLimit
                                                }
                                                title={data.title}
                                                filterType={data.filterType}
                                                className="homeContainers"
                                                type={'flex'}
                                                linkText={
                                                    data.isCustomBtn &&
                                                    data.btnText
                                                }
                                                isRank={
                                                    data.filterType ===
                                                    'best' &&
                                                    data.isVisibleBadge
                                                        ? true
                                                        : false
                                                }
                                                btnLink={data.btnLink}
                                            />
                                        );
                                    case 'fix':
                                        return (
                                            <HomeProductListsContainer
                                                key={i}
                                                slideCartegoryData={
                                                    data.categories
                                                }
                                                slideData={data.products}
                                                title={data.title}
                                                filterType={data.filterType}
                                                className="homeContainers"
                                                type={'fix'}
                                                linkText={
                                                    data.isCustomBtn &&
                                                    data.btnText
                                                }
                                                isRank={
                                                    data.filterType ===
                                                    'best' &&
                                                    data.isVisibleBadge
                                                        ? true
                                                        : false
                                                }
                                                btnLink={data.btnLink}
                                            />
                                        );
                                    case 'product_highlight':
                                        return (
                                            <HomeSingleProductHighlightContainer
                                                key={i}
                                                title={data.title}
                                                slideData={data.products}
                                                className="homeContainers"
                                                buttonText={data.btnText}
                                                buttonLink={data.btnLink}
                                            />
                                        );
                                    // case 'review':
                                    //     return (
                                    //         <HomeReviewsContainer
                                    //             key={i}
                                    //             title={data.title}
                                    //             className="homeContainers"
                                    //             // slideData={[
                                    //             //     { id: 1 },
                                    //             //     { id: 2 },
                                    //             //     { id: 2 },
                                    //             // ]}
                                    //             slideData={data.reviews}
                                    //         />
                                    //     );
                                    case 'insta':
                                        return (
                                            <HomeSocialInteractContainer
                                                key={i}
                                                title={data.title}
                                                icon={data.icon}
                                                className="homeContainers"
                                                slideData={data.insta}
                                            />
                                        );

                                    default:
                                    // return console.log(
                                    //     '등록되지 않은 홈 섹션 타입 입니다'
                                    // );
                                }
                            })}
                            <BBThemeContainer className="BBcontainer"/>
                    </>
                )}
            </div>
        </>
    );
});

export default HomePage;
