import { observer } from 'mobx-react';
import React from 'react';
import 'swiper/swiper.scss';
import { SwiperSlide } from 'swiper/react';
import { sliderProps } from '../../../types/types';
import SliderWrapper from '../SliderWrapper';
import ContentBox from '../../ContentBox/ContentBox';
import { AppContext } from '../../../contexts/AppContext/AppContext';
import { BREAKPOINT_DT, BREAKPOINT_TBL } from '../../../constants/constants';
import './HomeTopSlider.scss';
import { moveToLink } from '../../../utils/commonUtils';
import LazyImage from "../../../hooks/LazyImage";

const HomeTopSlider: React.FunctionComponent<sliderProps> = observer(
    ({ ...props }) => {
        const { homeStore, rootStore } = React.useContext(AppContext);
        const { initData } = rootStore;
        const { setActiveTopBannerSlideIndex, homeData } = homeStore;
        const { homeBanner } = homeData;
        return (
            <SliderWrapper
                setActiveSlideIndex={setActiveTopBannerSlideIndex}
                loop={true}
                breakpoints={{
                    0: {
                        width: null,
                        spaceBetween: 16,
                    },
                    [BREAKPOINT_DT]: {
                        spaceBetween: 64,
                    },
                }}
                withDefaultNavigation={true}
                withDefaultPagination={true}
                slidesPerView={1}
                {...props}
            >
                {/* {console.log(homeBanner, 'home menu')} */}
                {/* 구현 해야 하는 게 뭐냐, 동영상이 액티브 일때만 틀도록 하기 
                    onSlideChange={(swiper) => {
                            setProgressValue(swiper.activeIndex); 
                            setPageIndex(swiper.realIndex);
                            const activeSlider =swiper.slides[swiper.activeIndex];
                            const video = activeSlider.querySelector('video'); 
                            video && video.play();
                        }}
                */}
                {homeBanner &&
                    homeBanner.map((data, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <ContentBox
                                    className="homeTopSliderContentBox"
                                    style={
                                        data.cta?.btnLink
                                            ? { cursor: 'pointer' }
                                            : null
                                    }
                                    onClick={
                                        data.cta?.btnLink
                                            ? (e) =>
                                                  moveToLink(
                                                      data.cta.btnLink,
                                                      e
                                                  )
                                            : undefined
                                    }
                                >
                                    {data.type === 'video' ? (
                                        <video
                                            className="homeTopVideo"
                                            autoPlay={i == 0 ? true : false}
                                            preload={'auto'}
                                            loop
                                            playsInline
                                            // bufferd={'true'}
                                            src={
                                                'https://video-nrt1-1.cdninstagram.com/v/t50.2886-16/164386535_529491491367336_2778321142845225644_n.mp4?_nc_cat=104&vs=18168986875106426_1945839333&_nc_vs=HBksFQAYJEdPZFd6QW1vS1VIWWtlRUJBS3c2UC1kYmxJNG1ia1lMQUFBRhUAAsgBABUAGCRHT1kzeFFtX2FXM0N5MDhHQUFuN1dmai1tTjBBYmtZTEFBQUYVAgLIAQAoABgAGwGIB3VzZV9vaWwBMRUAACb0murNnKbGQBUCKAJDMywXP%2FgEGJN0vGoYEmRhc2hfYmFzZWxpbmVfM192MREAdeoHAA%3D%3D&ccb=1-3&_nc_sid=59939d&efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5mZWVkIn0%3D&_nc_ohc=PabEmpzbyScAX-8IguF&_nc_ht=video-nrt1-1.cdninstagram.com&oh=67a31b3f7c492bb2e7f30f470c9af743&oe=6082C9B4&_nc_rid=012b547f22'
                                            }
                                            muted
                                        ></video>
                                    ) : (
                                        <picture>
                                            <source
                                                srcSet={
                                                    initData &&
                                                    initData.resourcePrefix +
                                                        data.fileDt
                                                }
                                                media={`(min-width: ${BREAKPOINT_TBL}px)`}
                                            ></source>
                                            <LazyImage
                                                className="homeTopSliderImg"
                                                imgUrl={
                                                    initData &&
                                                    initData.resourcePrefix +
                                                        data.fileMo
                                                }
                                            />
                                        </picture>
                                    )}
                                </ContentBox>
                            </SwiperSlide>
                        );
                    })}
            </SliderWrapper>
        );
    }
);

export default HomeTopSlider;
