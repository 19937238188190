import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import Button from '../../components/Button/Button';
import MultiProductSlider from '../../components/Sliders/MultiProductsSlider/MultiProductsSlider';
import NavigationSlider from '../../components/Sliders/NavigationSlider/NavigationSlider';
import {BREAKPOINT_DT, BREAKPOINT_TBL} from '../../constants/constants';
import {defaultElementProps, sliderContainerProps} from '../../types/types';
import {fetchAndReceiveJSON} from '../../utils/ajaxUtils';
import {stringLineBreak} from '../../utils/commonUtils';
import './HomeProductListsContainer.scss';
import {PRODUCTS_IN_CATEGORY_ENDPOINT} from '../../constants/constants';

interface IHomeProductListsContainer extends sliderContainerProps {
    isRank?: boolean;
    productDisplayLimit?: number;
}

const HomeProductListsContainer: React.FunctionComponent<IHomeProductListsContainer> = observer(
    ({
         slideCartegoryData,
         type,
         linkText = '더보기',
         className,
         slideData = [],
         title,
         filterType,
         isRank,
         btnLink,
         productDisplayLimit = 0,
         ...otherProps
     }) => {
        const [target, setTarget] = useState(
            slideCartegoryData && slideCartegoryData[0]
        );
        // 여기에 changeFilter or changeApiKey 가 들어가고 이게 targetFunction으로 들어간다.
        const [productsSlideData, setProductsSlideData] = useState<any>(
            slideData
        );
        const history = useHistory();

        useEffect(() => {
            //홈영역 + 가변영역 + 콜렉션인 경우 -> productDisplayLimit 갯수 이하로 필터링한다.
            if (
                history.location.pathname === '/' &&
                type === 'flex' &&
                filterType == 'collection'
            ) {
                setProductsSlideData(
                    slideData.filter((_, i) => i < productDisplayLimit)
                );

                return;
            }

            setProductsSlideData(slideData);
        }, [slideData]);

        useEffect(() => {
            if (target == undefined) {
                return;
            }

            if (type === 'flex') {
                // fetch new data and replace slideData
                // 조건 1, 처음 보는 데이터

                //홈화면 + 필터타입이 콜렉션인 경우 -> 주어진 데이터를 사용한다.
                if (
                    history.location.pathname === '/' &&
                    filterType === 'collection'
                ) {
                    if (target.id === 0) {
                        setProductsSlideData(
                            slideData.filter((_, i) => i < productDisplayLimit)
                        );

                        return;
                    } else {
                        setProductsSlideData(
                            slideData.filter((data) =>
                                data.categories
                                    .map((category) => category.id)
                                    .includes(target.id)
                            )
                        );
                        return;
                    }
                }

                const getProductsInCategory = async () => {
                    if (target.id === 0) {
                        setProductsSlideData(slideData);
                        return;
                    }

                    const categoriId = target.id;

                    const res = await fetchAndReceiveJSON({
                        url: `${PRODUCTS_IN_CATEGORY_ENDPOINT}?filterType=${filterType}&categoryId=${categoriId}`,
                    });

                    setProductsSlideData(res);
                };

                getProductsInCategory();
            } else if (type === 'fix') {
                // filter esisting data and replace slideData

                if (target.slug === 'product-all') {
                    setProductsSlideData(slideData);
                } else {
                    let filteredData: Array<any> = [];
                    slideData.forEach((data) => {
                        if (
                            data.categories.find(
                                (el) => el.slug === target.slug
                            )
                        ) {
                            filteredData.push(data);
                        }
                    });

                    // 이게 왜 안될까?
                    setProductsSlideData(filteredData);
                    // setTarget('asfasdf');
                }
            }
            // sudo filter
            // else if (target === 'product-toys') {
            //     setProductsSlideData(slideData2);
            // }
        }, [target]);
        return (
            <div
                className={`homeProductListsContainer ${
                    className ? className : ''
                }`}
            >
                <h2
                    className={`homeProductListname ${
                        !slideCartegoryData || slideCartegoryData.length === 0
                            ? 'withoutCategory'
                            : ''
                    }`}
                >
                    {stringLineBreak(title)}
                </h2>
                {slideCartegoryData && slideCartegoryData.length > 0 && (
                    <div style={{width: '100%'}}>
                        <NavigationSlider
                            className={`homeProductListsSlider ${
                                history.location.pathname === '/' &&
                                type === 'flex' &&
                                filterType == 'collection'
                                    ? 'collection'
                                    : ''
                            }`}
                            slideData={slideCartegoryData}
                            overFlowVisible={true}
                            target={target}
                            spaceBetween={20}
                            changeTargetFunction={setTarget}
                            withSizeConditionalNavigation
                        />
                    </div>
                )}
                <div
                    style={{
                        position: 'relative',
                    }}
                >
                    <MultiProductSlider
                        key={Math.random()}
                        slidesPerView={2}
                        slidesPerColumn={type === 'fix' ? 1 : 2}
                        slidesPerGroup={type === 'fix' ? 1 : 2}
                        className={`homeProductListsProductsSlider ${
                            type === 'fix' && 'fix'
                        }`}
                        slideData={productsSlideData}
                        withDefaultPagination={type === 'fix' ? false : true}
                        withDefaultNavigation={true}
                        // overFlowVisible={type === 'fix' ? true : false}
                        // overFlowVisible
                        // centeredSlides={false}
                        withRank={isRank}
                        breakpoints={{
                            [BREAKPOINT_TBL]: {
                                // spaceBetween: 24,
                                slidesPerColumn: 1,
                                slidesPerGroup: type === 'fix' ? 1 : 4,
                                slidesPerView: 4,
                            },
                            [BREAKPOINT_DT]: {
                                spaceBetween: 24,
                                slidesPerColumn: type === 'fix' ? 2 : 1,
                                slidesPerGroup: 4,
                                slidesPerView: 4,
                            },
                        }}
                    />
                </div>

                {/* TODO: target을 기준으로 데이터 링크가 달라지게 만들기 */}
                <Button
                    className={`homeProductListsButton ${
                        linkText === 'none' && 'noShow'
                    }`}
                    onClick={() => history.push(btnLink)}
                >
                    {linkText}
                </Button>
            </div>
        );
    }
);

export default HomeProductListsContainer;
