import {observer} from 'mobx-react';
import {useContext} from 'react';
import {AppContext} from '../../contexts/AppContext/AppContext';
import {isEventPriceProduct} from '../../utils/commonUtils';
import MinicartHighlightText from './MinicartHighlightArea';
// import './MiniCart.scss';
const MiniCartBottom = observer(({
                                     miniCartPrice,
                                     miniCartProduct,
                                     isNpayQueryString,
                                     isNpayClicked,
                                     isBuyClicked,
                                     isProductInfo,
                                     setIsNpayClicked,
                                     setIsBuyClicked,
                                     addCart,
                                     addItemToCartBuyNow,
                                     isSubscriptionProd,
                                     isDogFood,
                                     isShowNpayBtn
                                 }) => {

    const {rootStore, miniCartPopupStore, modalStore} =
        useContext(AppContext);
    const {initData, setSignUpModalOn} = rootStore;
    const {subscriptionDiscountInfo} = miniCartPopupStore;
    const {setIsRestockingModalVisible, setIsRestockingModalProductId} = modalStore
    const isShowEventPrice = !initData.isWelcomeSpecialPriceCouponUsed && isEventPriceProduct(miniCartProduct);
    const guestHighlightDiv = <><span className="underlineTextBtn">로그인 또는 가입</span> 후 장바구니에서 쿠폰 적용 시 신규가입 100원 구매 혜택을
        받으실 수 있습니다.</>;
    const miniCartHighlightText = initData.isLogin ? '장바구니에서 쿠폰 적용 시 신규가입 100원 혜택을 받으실 수 있습니다.' : guestHighlightDiv;

    let priceAdditionalInfo = null;
    if (subscriptionDiscountInfo) {
        // console.log("subcriptionDiscountInfo" , subscriptionDiscountInfo , miniCartProduct);
        priceAdditionalInfo = <span className="priceAddtionalText">2회차부터 <span
            style={{color: "#ed2e1b"}}>{subscriptionDiscountInfo.discountRate}% 할인 적용</span>되어 {subscriptionDiscountInfo.displayPrice}원으로 결제</span>;
    }

    return (
        <div className="bottom-area">
            <div className="total-price-area">
                <div className="total-price-main">
                    <div className="total-price-text">총 상품 금액</div>
                    <div className="total-price">
                        {miniCartPrice}
                        <span className="won">원</span>
                    </div>
                </div>
                {priceAdditionalInfo && <div className="priceAddtionalArea">{priceAdditionalInfo}</div>}
            </div>

            {isShowEventPrice && !isProductInfo &&

                <MinicartHighlightText
                    text={miniCartHighlightText}
                    isClickable={!initData.isLogin}
                    clickAction={() => {
                        setSignUpModalOn(true)
                    }}
                />
            }

            <div className="cta-btns2">
                {/* mobile  */}
                <div className="pcHide">

                    {!isProductInfo &&
                        <div className="btnRow">
                            {miniCartProduct.stockStatus === 'outofstock' && (
                                miniCartProduct.saleInFuture ?
                                    <div className="cta-btn disabled">
                                        판매예정 상품입니다.
                                    </div> :
                                    <div className="cta-btn red" onClick={() => {
                                        setIsRestockingModalVisible(true);
                                        setIsRestockingModalProductId(miniCartProduct.id);
                                        event.stopPropagation();
                                    }}>
                                        재입고 알림 받기
                                    </div>
                            )}
                            {miniCartProduct.stockStatus !== 'outofstock' && isSubscriptionProd && isProductInfo == false && (
                                <div
                                    className="cta-btn red"
                                    onClick={() => {
                                        addCart();
                                    }}
                                >
                                    정기배송 신청하기
                                </div>
                            )}


                            {miniCartProduct.stockStatus !== 'outofstock' && !isSubscriptionProd && ((miniCartProduct.isNpayAvailable || isNpayQueryString) &&
                                isNpayClicked === false &&
                                isBuyClicked == false &&
                                isProductInfo == false) && (
                                <>
                                    <div
                                        className="cta-btn small"
                                        onClick={() => {
                                            setIsNpayClicked(true);
                                        }}
                                    >
                                        <div className="naverPayBtn">
                                            <img
                                                src="/img/common-logo-npay-02@2x.png"
                                                alt="naver-pay"
                                                style={{height: '20px'}}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="cta-btn red"
                                        onClick={() => {
                                            setIsBuyClicked(true);
                                        }}
                                    >
                                        구매하기
                                    </div>
                                </>
                            )}

                            {miniCartProduct.stockStatus !== 'outofstock' && !isSubscriptionProd && (miniCartProduct.isNpayAvailable == false ||
                                isBuyClicked) && (
                                <>
                                    <div
                                        className="cta-btn"
                                        onClick={() => {
                                            addCart();
                                        }}
                                    >
                                        장바구니 담기
                                    </div>
                                    <div
                                        className="cta-btn red"
                                        onClick={() => {
                                            addItemToCartBuyNow();
                                        }}
                                    >
                                        바로 구매하기
                                    </div>
                                </>
                            )}

                            <div
                                id="iamport-naverpay-product-button2"
                                style={{
                                    width: '100%',
                                    display: isNpayClicked ? 'block' : 'none',
                                }}
                            ></div>

                        </div>}
                </div>

                {/* table - pc */}
                <div className="pcOnly">

                    <div
                        className={`btnRow ${isProductInfo === true ? 'mobileHide' : ''
                        }`}
                    >
                        {miniCartProduct.stockStatus === 'outofstock' && (
                            miniCartProduct.saleInFuture ?
                                <div className="cta-btn disabled">
                                    판매예정 상품입니다.
                                </div> :
                                <div className="cta-btn red"
                                     onClick={() => {
                                         setIsRestockingModalVisible(true);
                                         setIsRestockingModalProductId(miniCartProduct.id);
                                         event.stopPropagation();
                                     }}>
                                    재입고 알림 받기
                                </div>
                        )}

                        {miniCartProduct.stockStatus !== 'outofstock' && isSubscriptionProd && (
                            <div
                                className="cta-btn red"
                                onClick={() => {
                                    addCart();
                                }}
                            >
                                정기배송 신청하기
                            </div>
                        )}
                        {miniCartProduct.stockStatus !== 'outofstock' && !isSubscriptionProd && (
                            <>
                                <div
                                    className="cta-btn"
                                    onClick={() => {
                                        addCart();
                                    }}
                                >
                                    장바구니 담기
                                </div>
                                <div
                                    className="cta-btn red"
                                    onClick={() => {
                                        addItemToCartBuyNow();
                                    }}
                                >
                                    바로 구매하기
                                </div>
                            </>
                        )}
                    </div>

                    {(miniCartProduct.stockStatus !== 'outofstock' && !isSubscriptionProd &&
                        (miniCartProduct.isNpayAvailable || isNpayQueryString) &&
                        isShowNpayBtn) && (
                        <>
                            <div id="iamport-naverpay-product-button"></div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
});

export default MiniCartBottom;

